<ng-container *ngIf="{
  selectedFiles: selectedFiles$ | async
} as ctx">
  <tamo-file-manager [editable]="false" [showBreadcrumbs]="false">
    <ng-template #actionsTemplate>
      <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="5px">
        <button mat-button color="warn" (click)="closeDialog()">Закрыть</button>
        <button [disabled]="!ctx.selectedFiles.length" mat-flat-button color="accent" (click)="selectFiles()">Выбрать ({{ ctx.selectedFiles.length }})</button>
      </div>
    </ng-template>
  </tamo-file-manager>
</ng-container>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import {
  OrdersComponent,
  OrdersListComponent,
  OrdersDetailsComponent,
  OrdersMessagesComponent,
  OrdersProfileComponent
} from './orders';
import { AuthComponent } from './auth/auth/auth.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { QuestionaryComponent } from './questionary/questionary/questionary.component';
import { ManagersListComponent, ManagersDetailsComponent } from './managers';
import {
  ServiceComponent,
  ServiceDetailsComponent,
  ServiceNotificationsComponent,
  ServiceDocumentsComponent
} from './services';
import { StubComponent } from './stub';
import { ConditionalReleaseListComponent } from './conditional-release';
import { IframeCalculatorComponent } from './iframe-calculator';
import { BookingComponent } from './booking';
import { NewsComponent } from './news';
import { DocumentsComponent } from './documents';
import { PendingChangesGuard } from './shared/guards';

const appRoutes: Routes = [
  { path: '', redirectTo: '/app/orders/list', pathMatch: 'full' },
  { path: 'calculator', component: IframeCalculatorComponent },
  {
    path: 'app', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      { path: 'orders', component: OrdersComponent, data: { collapsedMenu: true },
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: OrdersListComponent, data: { collapsedMenu: false } },
          {
            path: ':orderid', component: OrdersDetailsComponent, data: { collapsedMenu: true },
            children: [
              { path: 'messages', component: OrdersMessagesComponent },
              { path: 'profile', component: OrdersProfileComponent },
              {
                path: 'services/:serviceid', component: ServiceComponent, canDeactivate: [PendingChangesGuard],
                children: [
                  { path: '', redirectTo: 'details', pathMatch: 'full' },
                  { path: 'details', component: ServiceDetailsComponent },
                  { path: 'notifications', component: ServiceNotificationsComponent },
                  { path: 'documents', component: ServiceDocumentsComponent }
                ]
              },
            ] 
          }
        ]
      },
      { path: 'conditionalrelease', component: ConditionalReleaseListComponent },
      { path: 'stub1', component: StubComponent },
      { path: 'booking', component: BookingComponent },
      { path: 'managers', component: ManagersListComponent },
      { path: 'managers/:id', component: ManagersDetailsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'news', component: NewsComponent },
      { path: 'documents', component: DocumentsComponent }
    ]
  },
  {
      path: 'auth', component: AuthComponent,
      children: [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'reset-password', component: ResetPasswordComponent }
      ]
  },
  { path: 'questionary', component: QuestionaryComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes, { useHash: true, onSameUrlNavigation: 'reload' }
    )
  ],
  declarations: []
})
export class AppRoutingModule { }

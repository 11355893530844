import { Component, ViewChild, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { takeUntil, filter, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { SocketService } from './socket';
import { ReactiveComponent, AuthUser, GlobalUploaderService } from './shared';
import { AuthState, Logout, AuthComplete, ResetFileManager, InitFileManager } from './shared/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends ReactiveComponent {
  title = 'app';

  @Select(AuthState.user)
  public user$: Observable<AuthUser>;

  constructor(
      @Inject('socketUrl') private socketUrl: string,
      private store: Store,
      private socketSrv: SocketService,
      private route: ActivatedRoute,
      private router: Router,
      private actions$: Actions,
      private uploaderService: GlobalUploaderService) {
    super();
  }

  ngOnInit() {
    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(Logout)
    ).subscribe(() => {
      this.router.navigate(['/auth']);
    });

    // this.actions$.pipe(
    //   takeUntil(this.ngUnsubscribe$),
    //   ofActionDispatched(AuthComplete)
    // ).subscribe((data) => {
    //   console.log(data);
    // });

    // this.user$.pipe(
    //   takeUntil(this.ngUnsubscribe$),
    //   filter(user => !!(user && user.account)),
    //   map(user => user.account),
    //   distinctUntilChanged()
    // ).subscribe(account => {
    //   this.store.dispatch(new InitFileManager(account));
    // });
  }
}

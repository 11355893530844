import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, switchMap, debounceTime, first, finalize, map, filter, tap, distinctUntilChanged, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { plainToClass, Transform, classToPlain } from "class-transformer";
import { ToastrService } from 'ngx-toastr';
import { Store, Select } from '@ngxs/store';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';

import { FileChooserComponent } from '../../file-manager';
import { OrderState, UpdateService, AuthState, PatchService, CountriesState, TransportsState } from '../../shared/store';
import {
  Transport,
  Country,
  Importer,
  Product,
  Manager,
  Declaration,
  ReactiveComponent,
  AuthUser,
  Order
} from '../../shared';
import {
  AccountServiceFactory,
  TransportService,
  CountriesService,
  ProductsService,
  ImportersService,
  DeclarationService
} from '../../shared/services';

export class DeclarationOperator {
  dt_date_filing: string;

  dt_number: string;

  dt_date_release: string;

  declarant: string;

  payment_state: string;
}

@Component({
  selector: 'tamo-declaration-details',
  templateUrl: './declaration-details.component.html',
  styleUrls: ['./declaration-details.component.less']
})
export class DeclarationDetailsComponent extends ReactiveComponent {
  @Input()
  public service: Declaration;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(OrderState.selectedOrder)
  order$: Observable<Order>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  @Select(CountriesState.countries)
  countries$: Observable<Country[]>;

  @Select(TransportsState.transports)
  transports$: Observable<Transport[]>;

  @Select(CountriesState.loading)
  countriesLoading$: Observable<boolean>;

  @Select(TransportsState.loading)
  transportsLoading$: Observable<boolean>;


  public selectedTransport$: Observable<Transport | null>;
  // public transports: Transport[];

  // public countries: Country[];

  public serviceForm: FormGroup;

  public operatorForm: FormGroup;

  public products: FormArray;

  public step: number = -1;

  private importersLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public importers$: Observable<Importer[]>;

  public importersLoading$: Observable<boolean> = this.importersLoadingSource$.asObservable();

  // public productsLoading: boolean = false;

  public declarationUploadUrl$: Observable<string>;

  public releasedUploadUrl$: Observable<string>;

  constructor(
      @Inject('apiUrl') private apiUrl: string,
      private route: ActivatedRoute,
      private store: Store,
      private declarationService: DeclarationService,
      private productsSrv: ProductsService,
      private fb: FormBuilder,
      private toastr: ToastrService,
      private accountServiceFactory: AccountServiceFactory,
      private scrollToService: ScrollToService,
      private dialog: MatDialog) {
    super();
    this.createForms();
  }

  get f() { return this.serviceForm.controls; }

  get fo() { return this.operatorForm.controls; }

  // selectFiles() {
  //   const dialogRef = this.dialog.open(FileChooserComponent, {
  //     autoFocus: false,
  //     height: '95%',
  //     width: '95%',
  //     panelClass: 'dialog_relative'
  //   });
  // }

  ngOnInit() {
    // this.productsLoading = true;

    const service$ = this.observePropertyCurrentValue<Declaration>('service');
    service$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((service: Declaration) => {
      const rawService = classToPlain(service);
      // console.log(rawService);
      this.serviceForm.patchValue(rawService);
      this.operatorForm.patchValue(rawService);
      if(service.transport) {
        this.serviceForm.patchValue({
          'transport': service.transport.toString()
        });
      }

      const currProducts = this.serviceForm.get('products') as FormArray;
      currProducts.reset();
      while (currProducts.length !== 0) {
        currProducts.removeAt(0);
      }

      service.products.forEach((product: Product) => this.addProduct(product));
      
      // this.productsSrv.all(service.id).subscribe(
      //   (products) => {
      //     const user = this.store.selectSnapshot(AuthState.user);
      //     if(!products.length) {
      //       this.addProduct(null);
      //     } else {
      //       products.forEach((product: Product) => this.addProduct(product));
      //     }
      //   },
      //   (err) => {},
      //   () => this.productsLoading = false
      // );
    });

    this.declarationUploadUrl$ = service$.pipe(
      map(service => `${this.apiUrl}/declarations/${service.id}/upload_released/`));

    this.releasedUploadUrl$ = service$.pipe(
      map(service => `${this.apiUrl}/declarations/${service.id}/upload_declaration/`));

    this.importers$ = this.order$.pipe(
      takeUntil(this.ngUnsubscribe$),
      filter(order => !!order),
      distinctUntilChanged((o1, o2) => o1.id === o2.id),
      switchMap(order => this.accountServiceFactory.getService(order.account_id).getImporters()),
      tap(() => this.importersLoadingSource$.next(false))
    );

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.operatorForm.disable();
        this.serviceForm.disable();
      } else {
        this.operatorForm.enable();
        this.serviceForm.enable();
      }
    });

    this.selectedTransport$ = combineLatest([
      this.transports$,
      this.serviceForm.get('transport').valueChanges.pipe(
        startWith(this.serviceForm.get('transport').value),
        map(value => value ? parseInt(value) : null)
      )
    ]).pipe(
      map(([transports, transportId]) =>
        !transports || !transportId ? null : transports.find(t => t.id === transportId))
    );
  }

  // public onSubmit() {
  //   var toSave = this.prepareSave();
  //   this.store.dispatch(new UpdateService(toSave));
  // }

  public onOperatorSubmit() {
    if (!this.operatorForm.valid) {
      return;
    }

    const value = this.operatorForm.value;
    const data = Object.assign({}, value, {
      dt_date_release: value['dt_date_release'] ? moment(value['dt_date_release']).format('YYYY-MM-DD') : null,
      dt_date_filing: value['dt_date_filing'] ? moment(value['dt_date_filing']).format('YYYY-MM-DD') : null
    });

    this.store.dispatch(new PatchService(data));
  }

  // public process() {
  //   var toSave = this.prepareSave();
  //   this.store.dispatch(new UpdateService(toSave, true));
  // }

  public addProduct(product: any) {
    this.products = this.serviceForm.get('products') as FormArray;
    let productForm = this.createProductForm();
    if (product) {
      productForm.patchValue(product);
      this.products.push(productForm);  
    } else {
      productForm.patchValue({ name: '' });
      this.products.insert(0, productForm);
      this.step = 0;
    }
  }

  public clearProducts() {
    this.products = this.serviceForm.get('products') as FormArray;
    this.products = this.fb.array([]);
  }

  public removeProduct(index: number, productForm: FormGroup) {
    let products = this.serviceForm.get('products') as FormArray;
    products.removeAt(index);
    this.serviceForm.markAsDirty();
    this.step = -1;
  }

  public saveProduct(productForm: FormGroup) {
    if (!productForm.valid) {
      this.toastr.error('Имя товара не может быть пустым!', 'Ошибка!');
      return;
    }

    let product: Product = plainToClass(Product, productForm.value as Object);
    product.service = this.service.id;
    productForm.disable();
    if(product.id) {
      this.productsSrv.save(this.service.id, product.id, product)
        .subscribe(
          (product) => {
            this.toastr.success(`Товар ${product.name} успешно сохранен!`, 'Товар');
          },
          (err) => {},
          () => productForm.enable()
        );
    } else {
      this.productsSrv.create(this.service.id, product)
        .subscribe(
          (product: Product) => {
            this.toastr.success(`Товар ${product.name} успешно сохранен!`, 'Товар');
            productForm.patchValue(product);
          },
          (err) => {},
          () => productForm.enable()
        );
    }
  }

  public getProductUploadPrefix(productId: number) {
    return `/declarations/${this.service.id}/products/${productId}/`;
  }

  public uploadCompleted(url, type) {
  }

  public unknownClicked(event, message) {
    if(event.checked) {
      this.toastr.warning(message, 'Внимание!');
    }
  }

  // private prepareSave() {
  //   const model = this.serviceForm.value;
  //   return plainToClass(Declaration, model as Object);
  // }

  private createForms() {
    this.serviceForm = this.fb.group({
      direction: [''],
      contract_absent: [''],
      transport: [''],
      payment: [''],
      source_country: [null],
      packing_absent: [''],
      direction_unknown: [''],
      incoterms: [null],
      custom_sum_unknown: [''],
      cert_not_necessary: [''],
      cert_unknown: [''],
      invoice: [''],
      cargo_dest: [''],
      declaration_dest: [''],
      td_issue_date: [''],
      importer: [null],
      products: this.fb.array([])
    });

    this.operatorForm = this.fb.group({
      dt_date_filing: [null],
      dt_date_release: [null],
      dt_number: [''],
      declarant: [''],
      payment_state: [''],
      tnved_codes: ['']
    });
  }

  private createProductForm(): FormGroup {
    return this.fb.group({
      id: [''],
      name: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      link: [''],
      description: [''],
      tnved_code: ['']
    });
  }

  onDeclarationUploaded(evt) {
    this.toastr.success('Декларация успешно загружена', 'Файл загружен');
  }

  onReleasedUploaded(evt) {
    this.toastr.success('Выпущенная декларация успешно загружена', 'Файл загружен');
  }
}

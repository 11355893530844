import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { StorageFolder, ContentType } from '../../shared/models';
import { ReactiveComponent } from '../../shared/components';

@Component({
  selector: 'folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderComponent extends ReactiveComponent {
  private hoverSource$: Subject<boolean> = new Subject();

  public hover$: Observable<boolean> = this.hoverSource$.asObservable();
  
  @Output()
  onSelectFolder = new EventEmitter();

  @Output()
  onDeleteFolder = new EventEmitter();

  @Output()
  onEditFolder = new EventEmitter();

  @Input()
  folder: StorageFolder;

  @Input()
  editable: boolean = true;

  @Input()
  disabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onMouseEnter(evt: MouseEvent) {
    this.hoverSource$.next(true);
  }

  onMouseLeave(evt: MouseEvent) {
    this.hoverSource$.next(false);
  }
  
  deleteFolder(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.editable && this.folder) {
      this.onDeleteFolder.emit();
    }
  }

  editFolder(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.editable && this.folder) {
      this.onEditFolder.emit();
    }
  }

  selectFolder(evt: MouseEvent) {
    if (this.folder) {
      this.onSelectFolder.emit();
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, mergeMap } from 'rxjs/operators';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { ManagersService } from '../../shared/services';
import { Manager } from '../../shared/models';

@Component({
  selector: 'app-managers-picker',
  templateUrl: './managers-picker.component.html',
  styleUrls: ['./managers-picker.component.less']
})
export class ManagersPickerComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  public managers$: Observable<Manager[]>;

  public loading: boolean = true;

  @Output()
  public managerSelect: EventEmitter<Manager> = new EventEmitter();

  constructor(private managersService: ManagersService,
              private bottomSheetRef: MatBottomSheetRef<ManagersPickerComponent>) { }

  selectVariant(manager: Manager) {
    this.bottomSheetRef.dismiss(manager);
  }

  ngOnInit() {
    this.managers$ = this.managersService.all()
      .pipe(tap(() => this.loading = false));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import {
  AutoDeliverySettings,
  RailwayTariff,
  SeaTariff,
  AviaTariff,
  AviaTariffSettings,
  AutoTariff,
  AutoSettings
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ShippingTariffsService {
  private prefix: string = '/tariffs/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public railwayTariffs(departure: number, arrival: number, container: number):
      Observable<RailwayTariff[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}railway/${departure}/${arrival}/${container}/`)
      .pipe(map(res => plainToClass(RailwayTariff, res as Object[])));
  }

  public seaTariffs(departure: string, arrival: string, container: string):
      Observable<SeaTariff[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}sea/${departure}/${arrival}/${container}/`)
      .pipe(map(res => plainToClass(SeaTariff, res as Object[])));
  }

  // public aviaTariffs(departure: number, arrival: number, weight: number, size: number):
  //     Observable<AviaTariff[]> {
  //   return this.http
  //     .get(`${this.apiUrl}${this.prefix}avia/${departure}/${arrival}/${weight}/${size}/`)
  //     .pipe(map(res => plainToClass(AviaTariff, res as Object[])));
  // }

  public aviaTariffs(departure: string, arrival: string):
      Observable<AviaTariff[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}avia/${departure}/${arrival}/`)
      .pipe(map(res => plainToClass(AviaTariff, res as Object[])));
  }

  public aviaTariffSettings(): Observable<AviaTariffSettings> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}avia/settings/`)
      .pipe(map(res => plainToClass(AviaTariffSettings, res)));
  }

  public autoTariffs(): Observable<AutoTariff[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}auto/`)
      .pipe(map(res => plainToClass(AutoTariff, res as Object[])));
  }

  public autoDeliverySettings(): Observable<AutoDeliverySettings> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}auto/delivery-settings/`)
      .pipe(map(res => plainToClass(AutoDeliverySettings, res as Object)));
  }

  public autoSettings(): Observable<AutoSettings> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}auto/settings/`)
      .pipe(map(res => plainToClass(AutoSettings, res as Object)));
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, first } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Message } from '../models';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private touchCompleteSource: Subject<number> = new Subject();
  public touchComplete$: Observable<number> = this.touchCompleteSource.asObservable();

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient,
              private notifyService: NotificationsService) { }

  public all(order: number): Observable<Message[]> {
    return this.http.get(`${this.apiUrl}${this.prefix(order)}`)
      .pipe(map(res => plainToClass(Message, res as Object[])));
  }

  public create(order: number, text: string): Observable<Message> {
    return this.http
      .post(`${this.apiUrl}${this.prefix(order)}`, { message: text })
      .pipe(map(res => plainToClass(Message, res)));
  }

  public remove(order: number, id: number) {
    return this.http.delete(`${this.apiUrl}${this.prefix(order)}${id}/`);
  }

  public touch(order: number) {
    return this.http
      .put(`${this.apiUrl}${this.prefix(order)}touch/`, {})
      .pipe(
        map(() => this.touchCompleteSource.next(order))
      ).subscribe(() => this.notifyService.updateCount());
  }

  public unread(order: number): Observable<number> {
    return this.http
      .get(`${this.apiUrl}${this.prefix(order)}unread/`, {})
      .pipe(map((res: any) => parseInt(res)));
  }

  private prefix(order: number) {
    return `/orders/${order}/messages/`;
  }
}
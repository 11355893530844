import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { plainToClass, serialize, deserialize, classToPlain } from 'class-transformer';

import { Registration, User, AuthUser, AuthRequest, AuthResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public static readonly ACCESS_TOKEN_KEY: string = 'accessToken';

  public static readonly CURRENT_USER_KEY: string = 'currentUser';

  static jwtHelper = new JwtHelperService();

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient,
              private jwtHelper: JwtHelperService) { }

  login(payload: AuthRequest) {
    return this.http.post<any>(`${this.apiUrl}/token/obtain/`, classToPlain(payload))
      .pipe(map((res: any) => plainToClass(AuthResponse, res as Object) ));
  }

  refresh(refresh: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/token/refresh/`, { refresh })
      .pipe(map((res: any) => plainToClass(AuthResponse, res as Object) ));
  }

  register(phone, code, payload: Registration) {
    let headers = new HttpHeaders({'Tamo-Sms-Confirm': `${phone}|${code}`});
    return this.http
      .post(`${this.apiUrl}/register/`, payload, { headers });
  }

  validate(payload: Registration) {
    return this.http.post(`${this.apiUrl}/register/validate/`, payload);
  }

  isAuthUrl(url) {
    return url.startsWith(`${this.apiUrl}/register`) || url.startsWith(`${this.apiUrl}/token`);
  }

  isTokenExpired(token: string) {
    const decodedToken = AuthService.jwtHelper.decodeToken(token);
    const expirationDate = AuthService.jwtHelper.getTokenExpirationDate(token);
    return this.jwtHelper.isTokenExpired(token);
  }

  static extractUserFromToken(token: string): AuthUser {
    return plainToClass(AuthUser, AuthService.jwtHelper.decodeToken(token) as Object,
      { excludeExtraneousValues: true });
  }
}


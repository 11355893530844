import { Component, OnInit, Input, NgZone, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject, Observable, zip, of, BehaviorSubject, combineLatest, merge } from 'rxjs';
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter, first, delay, timeout, withLatestFrom,
  debounceTime, finalize, catchError, shareReplay, takeUntil, distinctUntilChanged
} from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { KladrCity } from '../../kladr';
import { YMapsService } from '../../ymaps';
import {
  AutoDeliverySettings,
  Shipping,
  ShippingPointsService,
  ShippingPoint,
  SeaTariff,
  ShippingTariffsService,
  SeaShipping,
  Selectable,
  ContainerType,
  ReactiveComponent,
  RouteInfo,
  CalculationResult
} from '../../shared';
import { ShippingCalculatorService } from '../shipping-calculator.service';

@Component({
  selector: 'shipping-calculator-sea',
  templateUrl: './calculator-sea.component.html',
  styleUrls: ['./calculator-sea.component.less']
})
export class CalculatorSeaComponent extends ReactiveComponent implements OnInit {
  @Input()
  service: SeaShipping;

  @Input()
  form: FormGroup;

  @Output()
  formFilled: EventEmitter<Shipping | null> = new EventEmitter();

  private departuresLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private arrivalsLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private arrivalPlaceSource$: BehaviorSubject<ShippingPoint> = new BehaviorSubject(null);
  private departurePlaceSource$: BehaviorSubject<ShippingPoint> = new BehaviorSubject(null);

  public arrivalPlace$: Observable<ShippingPoint> = this.arrivalPlaceSource$.asObservable();
  public departurePlace$: Observable<ShippingPoint> = this.departurePlaceSource$.asObservable();
  public filteredDeparturePlaces$: Observable<ShippingPoint[]>;
  public filteredArrivalPlaces$: Observable<ShippingPoint[]>;
  public availableDeparturesCount$: Observable<number>;
  public availableArrivalsCount$: Observable<number>;
  public departuresLoading$: Observable<boolean> = this.departuresLoadingSource$.asObservable();
  public arrivalsLoading$: Observable<boolean> = this.arrivalsLoadingSource$.asObservable();
  public deliveryAddressSource$: BehaviorSubject<string> = new BehaviorSubject(null);
  public deliveryAddress$: Observable<string> = this.deliveryAddressSource$.asObservable();

  public tariffsSource$: BehaviorSubject<SeaTariff[]> = new BehaviorSubject([]);
  public tariffs$: Observable<SeaTariff[]> = this.tariffsSource$.asObservable();
  public tariffLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public tariffLoading$: Observable<boolean> = this.tariffLoadingSource$.asObservable();
  public routeInfo$: Observable<RouteInfo>;
  // public deliveryRouteDataSource$: BehaviorSubject<any> = new BehaviorSubject(null);
  // public deliveryRouteData$: Observable<any> = this.deliveryRouteDataSource$.asObservable();
  public deliveryLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public deliveryLoading$: Observable<boolean> = this.deliveryLoadingSource$.asObservable();
  public deliveryTotalSource$: BehaviorSubject<number> = new BehaviorSubject(null);
  public deliveryTotal$: Observable<number> = this.deliveryTotalSource$.asObservable();
  public deliverySettings$: Observable<AutoDeliverySettings>;
  public deliveryResult$: Observable<CalculationResult>;
  public mainFormFilled$: Observable<boolean>;
  public deliveryFormFilled$: Observable<boolean>;
  public placesSelected$: Observable<boolean>;
  public weightExceeded$: Observable<boolean>;
  public isLocalDelivery$: Observable<boolean>;

  public containers$: Observable<ShippingPoint[]>;
  public containersLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public containersLoading$: Observable<boolean> = this.containersLoadingSource$.asObservable();
  public containerTypeSource$: BehaviorSubject<ShippingPoint> = new BehaviorSubject(null);
  public containerType$: Observable<ShippingPoint> = this.containerTypeSource$.asObservable();

  public formLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public formLoading$: Observable<Boolean> = this.formLoadingSource$.asObservable();

  constructor(
      private pointsSrv: ShippingPointsService,
      private tariffsService: ShippingTariffsService,
      private shippingService: ShippingCalculatorService,
      private yMapsService: YMapsService,
      private ref: ChangeDetectorRef) {
    super();
  }

  clearAutocomplete(trigger, field) {
    field.setValue('');
    setTimeout(() => trigger.openPanel(), 0);
  }

  fetchRestrictedList(point: ShippingPoint, exp: boolean) {
    if (!point) {
      return of(null);
    }
    return this.pointsSrv.portChoices(point.id, exp)
      .pipe(
        map(places => places.map(place => place.id)),
        map(places => Array.from(new Set(places)))
      );
  }


  initDeliveryCalculator() {
    const deliveryAddressControl = this.form.get('delivery_address');
    const deliveryLocalControl = this.form.get('delivery_local');
    const cargoWeightControl = this.form.get('cargo_weight');

    this.deliveryAddressSource$.next(deliveryAddressControl.value);

    const weight$ = cargoWeightControl.valueChanges.pipe(
      startWith(cargoWeightControl.value),
      debounceTime(200),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isLocalDelivery$ = deliveryLocalControl.valueChanges.pipe(
      startWith(deliveryLocalControl.value),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const deliverySelected$ = combineLatest([this.isLocalDelivery$, this.deliveryAddress$]).pipe(
      map(([isLocal, deliveryAddress]) => isLocal || deliveryAddress.length > 0)
    );

    this.routeInfo$ = combineLatest([
      this.isLocalDelivery$,
      this.placesSelected$,
      deliverySelected$,
      this.arrivalPlace$,
      this.deliveryAddress$,
    ]).pipe(
      tap(() => this.deliveryLoadingSource$.next(true)),
      switchMap(([isLocal, selected, deliverySelected, arrivalPlace, deliveryAddress]) => !isLocal && deliverySelected && selected ?
        this.yMapsService.getRoute(arrivalPlace.name, deliveryAddress).pipe(
          filter((data: any) => data.properties),
          map((data: any) => data.properties.get('RouterRouteMetaData')),
          map(data => plainToClass(RouteInfo, data as Object)),
          catchError((err) => of(null))
        ) : of(null)
      ),
      tap(() => this.deliveryLoadingSource$.next(false)),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.deliveryLoading$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      setTimeout(() => {
        if (!this.ref['destroyed']) this.ref.detectChanges()
      }, 0);
    });

    this.routeInfo$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => { });

    const deliveryFormFilled = ([address, isLocal, weight, , , ,]) =>
      (address || isLocal) && !isNaN(parseInt(weight));

    this.deliveryFormFilled$ = combineLatest([
      this.deliveryAddress$, this.isLocalDelivery$, weight$
    ]).pipe(
      map(deliveryFormFilled),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.weightExceeded$ = weight$.pipe(
      map(weight => weight >= 26000)
    );

    this.deliveryResult$ = combineLatest([
      this.deliveryAddress$,
      this.isLocalDelivery$, weight$,
      this.routeInfo$,
      this.deliverySettings$,
      this.arrivalPlace$
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      filter(deliveryFormFilled),
      map(([address, isLocal, weight, routeInfo, settings, arrivalPlace]) => {
        if (isLocal) {
          return {
            cost: settings.localCost,
            duration: settings.localDuration + ' дн',
            departureAddress: arrivalPlace.name,
            arrivalAddress: arrivalPlace.name,
            distance: null
          };
        } else if(routeInfo) {
          let totalSum = routeInfo.distance.value / 1000 * settings.deliveryCostFactor + settings.deliveryExtraCost;
          if(weight >= 21000 && weight < 26000) {
            totalSum += Math.floor((weight - 21000) / 1000) * settings.deliveryCostPerTon;
          }

          return {
            cost: totalSum,
            duration: routeInfo.duration.text,
            distance: routeInfo.distance.text,
            departureAddress: arrivalPlace.name,
            arrivalAddress: address,
          };
        }
      })
    );

    merge(this.mainFormFilled$, this.deliveryResult$, this.tariffs$, this.deliveryFormFilled$).pipe(
      takeUntil(this.ngUnsubscribe$),
      withLatestFrom(combineLatest([this.deliveryResult$.pipe(startWith(null)), this.tariffs$, this.deliveryFormFilled$, this.mainFormFilled$])),
      delay(0)
    ).subscribe(([, [deliveryResult, tariffs, deliveryFormFilled, mainFormFilled]]) => {
      const formFilled = deliveryResult !== null && tariffs.length > 0 && !!deliveryFormFilled && !!mainFormFilled;
      this.formFilled.emit(formFilled ? plainToClass(SeaShipping, this.form.value) : null);
    });
  }

  ngOnInit() {
    const departurePlaceControl = this.form.get('departure_place');
    const arrivalPlaceControl = this.form.get('arrival_place');
    const containerTypeControl = this.form.get('container_type');

    departurePlaceControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      startWith(departurePlaceControl.value),
      filter(value => !value || value instanceof ShippingPoint)
    ).subscribe((value) => this.departurePlaceSource$.next(value));

    arrivalPlaceControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      startWith(arrivalPlaceControl.value),
      filter(value => !value || value instanceof ShippingPoint)
    ).subscribe((value) => this.arrivalPlaceSource$.next(value));

    containerTypeControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      startWith(containerTypeControl.value)
    ).subscribe((value) => this.containerTypeSource$.next(value));

    this.departuresLoading$
      .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
      .subscribe((loading) => {
        loading ? departurePlaceControl.disable({ emitEvent: false }) : departurePlaceControl.enable({ emitEvent: false });
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    this.arrivalsLoading$
      .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
      .subscribe((loading) => {
        loading ? arrivalPlaceControl.disable({ emitEvent: false }) : arrivalPlaceControl.enable({ emitEvent: false });
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    this.containersLoading$
      .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
      .subscribe((loading) => {
        loading ? containerTypeControl.disable({ emitEvent: false }) : containerTypeControl.enable({ emitEvent: false })
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    const departurePlaces$ = this.pointsSrv.ports('', false)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const arrivalPlaces$ = this.pointsSrv.ports('', true)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.deliverySettings$ = this.tariffsService.autoDeliverySettings()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.containers$ = this.pointsSrv.seaContainers()
      .pipe(
        tap(() => this.containersLoadingSource$.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    combineLatest([
      departurePlaces$,
      arrivalPlaces$,
      this.yMapsService.libraryLoaded$,
      this.deliverySettings$
    ])
      .pipe(takeUntil(this.ngUnsubscribe$), first())
      .subscribe(() => {
        this.formLoadingSource$.next(false);
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    const restrictedArrivalPlaces$ = this.departurePlace$
      .pipe(
        tap(() => setTimeout(() => this.arrivalsLoadingSource$.next(true), 0)),
        switchMap(point => this.fetchRestrictedList(point, true)),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  
    const restrictedDeparturePlaces$ = this.arrivalPlace$
      .pipe(
        tap(() => setTimeout(() => this.departuresLoadingSource$.next(true), 0)),
        switchMap(point => this.fetchRestrictedList(point, false)),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.filteredDeparturePlaces$ = combineLatest([
      this.shippingService.autocompleteDebounce<ShippingPoint>(
        departurePlaces$, departurePlaceControl.valueChanges, departurePlaceControl.value),
      restrictedDeparturePlaces$.pipe(startWith(null))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(([points, restricted]) =>
        restricted ? points.filter(point => restricted.includes(point.id)) : points),
      tap(() => setTimeout(() => this.departuresLoadingSource$.next(false), 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.filteredArrivalPlaces$ = combineLatest([
      this.shippingService.autocompleteDebounce<ShippingPoint>(
        arrivalPlaces$, arrivalPlaceControl.valueChanges, arrivalPlaceControl.value),
      restrictedArrivalPlaces$.pipe(startWith(null))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(([points, restricted]) =>
        restricted ? points.filter(point => restricted.includes(point.id)) : points),
      tap(() => setTimeout(() => this.arrivalsLoadingSource$.next(false), 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.availableDeparturesCount$ = combineLatest([restrictedDeparturePlaces$, departurePlaces$])
      .pipe(takeUntil(this.ngUnsubscribe$), map(([ids, places]) => ids ? ids.length : places.length));

    this.availableArrivalsCount$ = combineLatest([restrictedArrivalPlaces$, arrivalPlaces$])
      .pipe(takeUntil(this.ngUnsubscribe$), map(([ids, places]) => ids ? ids.length : places.length));

    const mainFormFilled = ([departurePlace, arrivalPlace, containerType]) =>
      !!arrivalPlace && !!departurePlace && !!containerType;

    this.placesSelected$ = combineLatest([
      this.departurePlace$,
      this.arrivalPlace$
    ]).pipe(
      map(([departurePlace, arrivalPlace]) => !!arrivalPlace && !!departurePlace),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.mainFormFilled$ = combineLatest([
      this.placesSelected$,
      this.containerType$
    ]).pipe(
      map(([placesSelected, containerType]) =>
        placesSelected && !!containerType),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    combineLatest([
      this.departurePlace$,
      this.arrivalPlace$,
      this.containerType$,
    ]).pipe(
      filter(mainFormFilled),
      tap(() => setTimeout(() => this.tariffLoadingSource$.next(true), 0)),
      switchMap(([departurePlace, arrivalPlace, containerType]) =>
        this.tariffsService.seaTariffs(departurePlace.id, arrivalPlace.id, containerType.id)),
      tap(() => setTimeout(() => this.tariffLoadingSource$.next(false), 0)),
    ).subscribe((tariffs) => {
      this.tariffsSource$.next(tariffs);
    });

    this.initDeliveryCalculator();
  }

  displayFn(point?: ShippingPoint): string | undefined {
    return point ? point.name : undefined;
  }

  compareContainerTypeFn(type1: ContainerType, type2: ContainerType) {
    return type1 && type2 && type1.id == type2.id;
  }

  onAddressSelected(city: KladrCity) {
    this.deliveryAddressSource$.next(city.name);
  }

  get f() {
    return this.form.controls;
  }
}

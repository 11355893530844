import { SimpleChange, Directive } from '@angular/core';
import { Observable, ConnectableObservable, Observer } from 'rxjs';
import { filter, map, publishReplay, takeUntil } from 'rxjs/operators';

import { BaseComponent } from './base.component';

export interface TypedSimpleChange<T> {
  previousValue: T;
  currentValue: T;
}

@Directive()
export class ReactiveComponent extends BaseComponent {
  private changesObserver: Observer<{ [key: string]: SimpleChange }>;
  private changes$: ConnectableObservable<{ [key: string]: SimpleChange }>;

  constructor() {
    super();
    this.changes$ = Observable.create((observer: Observer<{ [key: string]: SimpleChange }>) =>
      this.changesObserver = observer).pipe(
        takeUntil(this.ngUnsubscribe$),
        publishReplay(1)
      );
    this.changes$.connect();
  }

  public observeProperty<T>(propertyName: string): Observable<TypedSimpleChange<T>> {
    return this.changes$
      .pipe(
        filter(changes => changes.hasOwnProperty(propertyName)),
        map(changes => changes[propertyName])
      );
  }

  public observePropertyCurrentValue<T>(propertyName: string): Observable<T> {
    return this.observeProperty<T>(propertyName)
      .pipe(map(change => change.currentValue));
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    this.changesObserver.next(changes);
  }
}
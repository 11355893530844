import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { take, finalize, first, takeUntil } from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched, ofActionCompleted } from '@ngxs/store';

import { ReactiveComponent } from '../../shared';
import { AuthState, Login } from '../../shared/store';
import { FormatErrorsPipe } from '../../shared/pipes';
import { RemoteErrorsService } from '../../remote-errors/remote-errors.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'tamo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [RemoteErrorsService, FormatErrorsPipe]
})
export class LoginComponent extends ReactiveComponent {
  public loginForm: FormGroup;

  public returnUrl: string;

  @Select(AuthState.authorizing)
  authorizing$: Observable<boolean>;

  get f() { return this.loginForm.controls; }

  constructor(
      private store: Store,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private formatErrors: FormatErrorsPipe,
      private actions$: Actions,
      public errSrv: RemoteErrorsService) {
    super();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.authorizing$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(authorizing => authorizing ?
      this.loginForm.disable() : this.loginForm.enable());

    const errorHandler = this.errSrv.handleErrors(this.loginForm);
    this.actions$.pipe(
      ofActionCompleted(Login)
    ).subscribe(action => {
      // this.errSrv.handleErrors(this.loginForm);
      if (!action.result.successful) {
        errorHandler(action.result.error);
      } else {
        const user = this.store.selectSnapshot(AuthState.user);
        let redirectUrl;
        if (!user.isStaff && !user.registrationCompleted) {
          redirectUrl = '/questionary'
        } else {
          redirectUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        }

        this.router.navigate([redirectUrl]);
      }
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
        return;
    }

    this.loginForm.disable();

    this.store.dispatch(new Login(this.loginForm.value))
    // this.authService.login(this.f.username.value, this.f.password.value)
    //   .pipe(
    //     first(),
    //     finalize(() => this.loginForm.enable())
    //   )
    //   .subscribe(
    //     data => this.router.navigate([this.returnUrl]),
    //     this.errSrv.handleErrors(this.loginForm)
    //   )
  }
}

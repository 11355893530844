import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { PagedResult, News } from '../models';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private prefix: string = '/news/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(page: number = 1): Observable<PagedResult<News>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<News>(News), res)));
  }
}

<div class="main main-theme" *ngIf="user$ | async as user">
<!--   <div class="mat-elevation-z2 main__header">
    <mat-toolbar class="main__toolbar main-toolbar">
      <img src="assets/images/logo_g.png" />
      <span class="main-toolbar__spacer"></span>

      <div [matMenuTriggerFor]="noticeMenu" xPosition="after"></div>

      <button mat-icon-button
          [disabled]="!notcesCount || noticeLoading"
          (click)="loadNotices($event)" class="main-toolbar__notices">
        <mat-icon aria-label="Нотификации" [matBadge]="notcesCount" matBadgeOverlap="true"
          [matBadgeColor]="notcesCount ? 'warn' : 'accent'">{{ notcesCount ? 'notifications' : 'notifications_none' }}</mat-icon>
      </button>

      <div class="main-toolbar__logout">
        {{ username$ | async }}
        <button mat-icon-button (click)="logout()">
          <mat-icon color="accent">exit_to_app</mat-icon>
        </button>
      </div>

      <mat-menu #noticeMenu="matMenu">
        <div *ngIf="noticeLoading" mat-menu-item fxLayout="row"
          fxLayoutAlign="center center"><mat-spinner diameter="25"></mat-spinner></div>
        <div *ngIf="!noticeLoading">
          <button *ngFor="let notice of notices" mat-menu-item (click)="openNotice(notice)">
            <strong>{{ notice.text }}</strong>
          </button>
        </div>
      </mat-menu> 
    </mat-toolbar>
  </div> -->

  <mat-sidenav-container class="main__container" autosize="true">
    <mat-sidenav #mainSidenav class="main__sidenav sidenav"
        ngClass.lt-md="sidenav_collapsed"
        mode="side" opened disableClose="true">

      <div class="sidenav__logo">
        <img src="assets/images/logo_g.png" />
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="sidenav__logout sidenav-logout">
        <span fxLayout="row" fxLayoutAlign="flex-start center">
          <mat-icon class="sidenav-logout__icon">person</mat-icon>
          <span>{{ username$ | async }}</span>
        </span>
        <button mat-icon-button (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>

      <mat-nav-list class="main__menu main-menu">
        <a mat-list-item routerLink="orders" class="main-menu__item" 
          routerLinkActive="main-menu__item_active">
          <mat-icon color="accent" mat-list-icon>shopping_cart</mat-icon>
          <span *ngIf="!user.isStaff" class="sidenav__text">Мои заказы</span>
          <span *ngIf="user.isStaff" class="sidenav__text">Заказы</span>
        </a>


        <ng-container *ngIf="!user.isStaff">
          <a mat-list-item routerLink="orders" [queryParams]="{ create: true }">
            <mat-icon color="accent" mat-list-icon>add</mat-icon>
            <span class="sidenav__text">Создать заказ</span>
          </a>
          <mat-divider></mat-divider>

          <ng-container *ngFor="let serviceType of serviceTypes; let last = last">
            <a fxHide.lt-md mat-list-item class="main-menu__item main-menu__item_second" [title]="serviceType.hint" (click)="createService(serviceType)">
              <!-- <mat-icon color="primary" mat-list-icon>{{ serviceType.icon }}</mat-icon> -->
              <span class="sidenav__text">{{ serviceType.title }}</span>
            </a>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-container>
        </ng-container>

        <mat-divider></mat-divider>

        <a mat-list-item routerLink="conditionalrelease" class="main-menu__item" 
          routerLinkActive="main-menu__item_active">
          <mat-icon color="accent" mat-list-icon>assignment_turned_in</mat-icon>
          <span class="sidenav__text">Условный выпуск</span>
        </a>

        <ng-container *ngIf="!user.isStaff">
          <mat-divider></mat-divider>

          <a mat-list-item routerLink="booking" class="main-menu__item" 
            routerLinkActive="main-menu__item_active">
            <mat-icon fontSet="fa" fontIcon="fa-calculator" color="accent" mat-list-icon [inline]="true"></mat-icon>
            <span class="sidenav__text">Расчёт перевозки</span>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item routerLink="managers" class="main-menu__item" 
            routerLinkActive="main-menu__item_active" *ngIf="user.isBoss">
            <mat-icon color="accent" mat-list-icon>people</mat-icon>
            <span class="sidenav__text">Менеджеры</span>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item routerLink="profile" class="main-menu__item" 
            routerLinkActive="main-menu__item_active">
            <mat-icon color="accent" mat-list-icon>person</mat-icon>
            <span class="sidenav__text">Профиль</span>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item routerLink="documents" class="main-menu__item" 
            routerLinkActive="main-menu__item_active">
            <mat-icon color="accent" mat-list-icon>folder</mat-icon>
            <span class="sidenav__text">Мои документы</span>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item routerLink="news" class="main-menu__item" 
            routerLinkActive="main-menu__item_active">
            <mat-icon color="accent" mat-list-icon>receipt_long</mat-icon>
            <span class="sidenav__text">Новости</span>
          </a>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <tamo-hint-content></tamo-hint-content>
    <!-- <mat-sidenav #hintSidenav mode="over" position="end"  class="main__hint main-hint" (closedStart)="mainSidenav.open()">
      <tamo-hint-content></tamo-hint-content>
      <mat-toolbar class="main-hint__toolbar">
        <button mat-raised-button color="warn" (click)="hintSidenav.close()">Закрыть</button>
      </mat-toolbar>
    </mat-sidenav> -->

    <mat-sidenav-content class="main__content">
      <div class="main__inner">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
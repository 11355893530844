<ng-container *ngIf="{
  user: user$ | async,
  transports: transports$ | async,
  countries: countries$ | async,
  countriesLoading: countriesLoading$ | async,
  transportsLoading: transportsLoading$ | async,
  selectedTransport: selectedTransport$ | async
} as ctx">
  <div *ngIf="service" class="service-form declaration">
  
    <div class="service-form__content">
      <mat-datepicker #picker></mat-datepicker>

      <!-- <button style="position: absolute; z-index: 1000" (click)="selectFiles()">тест</button> -->
      
      <form [formGroup]="operatorForm" (ngSubmit)="onOperatorSubmit()">
        <mat-card class="service-card service-form__card">
          <mat-card-content class="service-card__content">
            <div fxLayout="column" fxLayoutGap="10px">
              <div *ngIf="service.released_declaration_file || service.declaration_file"
                  fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-start">
                <div *ngIf="service.declaration_file" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="3px">
                  <mat-icon [inline]="true">attachment</mat-icon>
                  <a target="_blank" href="{{ service.declaration_file }}">Декларация</a>
                </div>

                <div *ngIf="service.released_declaration_file" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="3px">
                  <mat-icon [inline]="true">attachment</mat-icon>
                  <a target="_blank" href="{{ service.released_declaration_file }}">Выпущенная декларация</a>
                </div>
              </div>


              <div *ngIf="!ctx.user.isStaff" class="service-form__declaration-info" fxLayout="row inline" fxLayoutGap="13px">
                <div>Подача ДТ:&nbsp;<strong>{{ service.dt_date_filing ? (service.dt_date_filing | amDateFormat: 'DD.MM.YYYY') : '-' }}</strong></div>

                <div>Номер ДТ:&nbsp;<strong>{{ service.dt_number || '-' }}</strong></div>

                <div>Выпуск ДТ:&nbsp;<strong>{{ service.dt_date_release ? (service.dt_date_release | amDateFormat: 'DD.MM.YYYY') : '-' }}</strong></div>

                <div>Декларант:&nbsp;<strong>{{ service.declarant || '-' }}</strong></div>

                <div>Оплата:&nbsp;<strong>{{ service.payment_state || '-' }}</strong></div>
              </div>

              <div fxLayout="row" *ngIf="ctx.user.isStaff" fxLayoutGap="30px" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="10px">
                <div fxFlex="50%" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="flex-start">
                  <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex>
                      <input matInput [matDatepicker]="pickerDtDateFilling" formControlName="dt_date_filing"
                        placeholder="Подача ДТ">
                      <button mat-button *ngIf="fo.dt_date_filing.value" matSuffix mat-icon-button
                          aria-label="Очистить" (click)="fo.dt_date_filing.setValue(null)">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-datepicker-toggle matSuffix [for]="pickerDtDateFilling"></mat-datepicker-toggle>
                      <mat-datepicker #pickerDtDateFilling></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                      <input matInput [matDatepicker]="pickerDtDateRelease" formControlName="dt_date_release"
                        placeholder="Выпуск ДТ">
                      <button mat-button *ngIf="fo.dt_date_release.value" matSuffix mat-icon-button
                          aria-label="Очистить" (click)="fo.dt_date_release.setValue(null)">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-datepicker-toggle matSuffix [for]="pickerDtDateRelease"></mat-datepicker-toggle>
                      <mat-datepicker #pickerDtDateRelease></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <mat-form-field>
                    <input matInput formControlName="dt_number" placeholder="Номер ДТ">
                  </mat-form-field>

                  <div fxFlex fxLayout="row" fxLayoutGap="10px">
                    <file-uploader fxFlex="50%"
                      buttonTitle="Декларация"
                      blockTitle=""
                      queueLimit="1"
                      [uploadUrl]="releasedUploadUrl$ | async"
                      [autoUpload]="true"
                      [removeAfterUpload]="true"
                      (onCompleteAll)="onDeclarationUploaded($event)"></file-uploader>

                    <file-uploader fxFlex="50%"
                      buttonTitle="Выпущенная декларация"
                      blockTitle=""
                      queueLimit="1"
                      [uploadUrl]="declarationUploadUrl$ | async"
                      [autoUpload]="true"
                      [removeAfterUpload]="true"
                      (onCompleteAll)="onReleasedUploaded($event)"></file-uploader>
                  </div>
                </div>

                <div fxFlex fxLayout="column" fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex>
                      <input matInput formControlName="declarant" placeholder="Декларант">
                    </mat-form-field>

                    <mat-form-field fxFlex>
                      <input matInput formControlName="payment_state" placeholder="Оплата">
                    </mat-form-field>
                  </div>

                  <mat-form-field fxFlex appearance="outline">
                    <mat-label>Коды ТНВЭД (общие)</mat-label>
                    <textarea formControlName="tnved_codes" matInput placeholder="Коды ТНВЭД (общие)" rows="5"></textarea>
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutAlign="flex-end">
                    <button type="submit" [disabled]="operatorForm.disabled"
                      mat-raised-button color="primary">Сохранить</button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </form>

      <form [formGroup]="serviceForm" ngxsForm="order.serviceForm">
        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Основная информация</mat-card-title>
            
            <button type="button" class="btn-help" tamoRemoteHint="1-1"
                  mat-button color="accent">Что сюда входит
                <mat-icon>help</mat-icon>
            </button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="service-form__section">
              <div fxFlex="50">
                <div>
                  <label class="lable_class">Выбор таможенного режима</label>
                  <button type="button" class="btn-help" mat-button color="accent"
                      tamoRemoteHint="1-2">Что такое таможенный режим
                    <mat-icon>help</mat-icon>
                  </button>
                </div>

                <mat-form-field class="service-form__direction" *ngIf="!f.direction_unknown.value">
                  <mat-select placeholder="Таможенный режим" formControlName="direction">
                    <mat-option value="I">Импорт</mat-option>
                    <mat-option value="E">Экспорт</mat-option>
                  </mat-select>
                  <button mat-button *ngIf="f.direction.value" matSuffix mat-icon-button
                      aria-label="Очистить" (click)="f.direction.setValue(null)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <div class="form-group">
                  <mat-checkbox
                    class="lable_class service-form__checkbox service-form__checkbox_unknown"
                    color="warn"
                    (change)="unknownClicked($event, 'Хорошо, мы сами разберемся какой режим выбрать и согласуем с Вами.')"
                    formControlName="direction_unknown">Не знаю, что выбрать</mat-checkbox>
                </div>


              </div>
              <div fxFlex="50">
                
              </div>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-select placeholder="Импортер" formControlName="importer"
                  [disabled]="importersLoading$ | async">
                  <mat-option *ngFor="let importer of importers$ | async"
                    [value]="importer.id">{{ importer.org_name }}</mat-option>
                </mat-select>
                <mat-spinner *ngIf="importersLoading$ | async" diameter="15" matSuffix></mat-spinner>
                <button mat-button *ngIf="f.importer.value" matSuffix mat-icon-button
                    aria-label="Очистить" (click)="f.importer.setValue(null)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column">
              <div fxFlex="50">
                <div class="service-form__section">
                  <div>
                    <label class="lable_class">Контракт</label>
                    <button type="button" class="btn-help" tamoRemoteHint="1-8" mat-button color="accent">Что такое контракт
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <tamo-documents-uploader type="CONT" title="Файлы контракта"
                    [disabled]="ctx.user.isStaff"
                    [prefix]="service.getUploadPrefix()"
                    [counts]="service.getCounts('CONT')"></tamo-documents-uploader>

                  <div>
                    <label class="lable_class">Допсоглашения, приложения, спецификациии</label>
                    <button type="button" class="btn-help" mat-button color="accent" tamoRemoteHint="1-10">Что это такое
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <div *ngIf="!f.contract_absent.value">
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SPEC')"
                      type="SPEC" title="Файлы допсоглашений и тд"></tamo-documents-uploader>
                  </div>

                  <div>
                    <mat-checkbox class="lable_class" formControlName="contract_absent">Поставка идет без заключения контракта </mat-checkbox>
                    <button type="button" class="btn-help" mat-button color="accent" tamoRemoteHint="1-11">Что такое поставка без контракта
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <div class="service-form__field">
                    <label class="lable_class">Прочие документы</label>
                    <button type="button" class="btn-help" mat-button color="accent" tamoRemoteHint="1-12">Что в них входит
                      <mat-icon>help</mat-icon>
                    </button>
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('COAN')"
                      type="COAN" title="Прочие документы"></tamo-documents-uploader>
                  </div>
                </div><!-- contract form column -->
              </div>
              <div fxFlex="50">
                <tamo-section-hints slug="contract"></tamo-section-hints>
              </div><!-- contract info column -->
            </div>

            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column">
              <div fxFlex="50">
                <div class="service-form__section">
                  <div class="form-group">
                    <label class="lable_class">
                      Условия оплаты за товар
                      <button type="button" mat-button class="btn-help" color="accent" tamoRemoteHint="1-4">Какие условия оплаты
                        <mat-icon>help</mat-icon>
                      </button>
                    </label>

                    <div class="service-form__field">
                      <mat-radio-group class="vertical-radio-group" formControlName="payment">
                        <mat-radio-button class="vertical-radio-button" value="P">
                          Предоплата <button type="button" class="btn-help" tamoRemoteHint="1-5" 
                            mat-button color="accent">Что это такое <mat-icon>help</mat-icon></button>
                        </mat-radio-button>
                        <mat-radio-button class="vertical-radio-button" value="L">
                          Частичная пердоплата <button type="button" class="btn-help" tamoRemoteHint="1-6" 
                            mat-button color="accent">Что это такое <mat-icon>help</mat-icon></button>
                        </mat-radio-button>
                        <mat-radio-button class="vertical-radio-button" value="A">
                          Постоплата <button type="button" class="btn-help" tamoRemoteHint="1-7" 
                            mat-button color="accent">Что это такое <mat-icon>help</mat-icon></button>
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)"
                    [prefix]="service.getUploadPrefix()"
                    [counts]="service.getCounts('PATE')"
                    type="PATE" title="Файлы условий оплаты"></tamo-documents-uploader>
                </div><!-- payment form column -->
              </div>
              <div fxFlex="50">
                <tamo-section-hints slug="conditions"></tamo-section-hints>
              </div><!-- contract info column -->
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Документы на груз</mat-card-title>
            <button type="button" class="btn-help" tamoRemoteHint="2-1"
                  mat-button color="accent">Что сюда входит
                <mat-icon>help</mat-icon>
              </button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="service-form__section">
              <div fxFlex="50">
                <div class="service-form__section">
                  <div>
                    <label class="lable_class">
                      Инвойс/Invoice
                    </label>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-2">Что такое инвойс
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('INVO')"
                    type="INVO" title="Файлы инвойса"></tamo-documents-uploader>

                  <div>
                    <mat-checkbox class="lable_class" formControlName="invoice">У меня есть проформа инвойса</mat-checkbox>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-4">Что такое проформа
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" *ngIf="f.invoice.value" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('PRIN')" 
                    type="PRIN" title="Файлы проформы инвойса"></tamo-documents-uploader>

                  <div class="service-form__field">
                    <label class="lable_class">Упаковочный лист/Packing list</label>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-6">Что такое упаковочный лист
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>
                    
                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" *ngIf="!f.packing_absent.value" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('PALI')"
                    type="PALI" title="Файлы упаковочного листа"></tamo-documents-uploader>

                  <div>
                    <mat-checkbox class="lable_class" formControlName="packing_absent">Упаковочного листа нет, все данные в инвойсе</mat-checkbox>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-8">Что это значит
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>           
                </div>
                <div class="service-form__section">
                  <div class="service-form__field">
                    <label class="lable_class">Страна происхождения товара</label>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-9">Для чего нужно
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <mat-form-field>
                    <mat-select placeholder="Страна" formControlName="source_country"
                      [disabled]="ctx.countriesLoading">
                      <mat-option *ngFor="let country of ctx.countries"
                        value="{{ country.code }}">{{ country.name }}</mat-option>
                    </mat-select>
                    <mat-spinner *ngIf="ctx.countriesLoading" diameter="15" matSuffix></mat-spinner>
                    <button mat-button *ngIf="f.source_country.value" matSuffix mat-icon-button
                        aria-label="Очистить" (click)="f.source_country.setValue(null)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <button mat-button class="btn-help"
                    color="accent" tamoRemoteHint="2-10">Как узнать какая у меня страна <mat-icon>info</mat-icon></button>
                </div><!-- country form column -->


                <div class="service-form__section">
                  <div class="service-form__field">
                    <label class="lable_class">Условия поставки INCOTERMS</label>
                    <button class="btn-help" mat-button color="accent" tamoRemoteHint="2-11">Что такое Incoterms
                      <mat-icon>help</mat-icon>
                    </button>
                  </div>

                  <mat-form-field>
                    <mat-select placeholder="INCOTERMS" formControlName="incoterms">
                      <mat-option value="EXW">EXW</mat-option>
                      <mat-option value="FCA">FCA</mat-option>
                      <mat-option value="FAS">FAS</mat-option>
                      <mat-option value="FOB">FOB</mat-option>
                      <mat-option value="CFR">CFR</mat-option>
                      <mat-option value="CIF">CIF</mat-option>
                      <mat-option value="CPT">CPT</mat-option>
                      <mat-option value="CIP">CIP</mat-option>
                      <mat-option value="DAT">DAT</mat-option>
                      <mat-option value="DAP">DAP</mat-option>
                      <mat-option value="DDP">DDP</mat-option>
                    </mat-select>
                    <button mat-button *ngIf="f.incoterms.value" matSuffix mat-icon-button
                        aria-label="Очистить" (click)="f.incoterms.setValue(null)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <button mat-button class="btn-help" 
                    color="accent" tamoRemoteHint="2-12">Как узнать какие у меня условия <mat-icon>info</mat-icon></button>

                  <div class="service-form__field" *ngIf="f.incoterms.value | inArray: 'EXW, FCA, FAS, FOB'">
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('TRCO')" 
                      type="TRCO" title="Файлы договора с перевозчиком"></tamo-documents-uploader>
                  </div>

                  <div class="service-form__field" *ngIf="f.incoterms.value | inArray: 'EXW, FCA, FAS, FOB'">
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('FRBI')" 
                      type="FRBI" title="Счета за фрахт"></tamo-documents-uploader>
                  </div>

                  <div class="service-form__field" *ngIf="f.incoterms.value | inArray: 'CIF, CIP'">
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('INSU')" 
                      type="INSU" title="Страховка"></tamo-documents-uploader>
                  </div>

                  <div>
                    <label class="lable_class">Прочие документы</label>
                    <button type="button" class="btn-help" mat-button color="accent" tamoRemoteHint="2-13">Что в них входит
                      <mat-icon>help</mat-icon>
                    </button>
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('CAAN')"
                      type="CAAN" title="Прочие документы"></tamo-documents-uploader>
                  </div>
                </div>
              </div>
              <div fxFlex="50">
                <tamo-section-hints slug="invoice"></tamo-section-hints>
                <tamo-section-hints *ngIf="f.invoice.value" slug="proforma"></tamo-section-hints>
                <tamo-section-hints *ngIf="!f.packing_absent.value" slug="packing_list"></tamo-section-hints>
              </div><!-- documents info column -->
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Транспортные документы</mat-card-title>
            <button class="btn-help" tamoRemoteHint="3-1"
              mat-button color="accent">Что сюда входит <mat-icon>help</mat-icon></button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="service-form__section">
              <div fxFlex="50">
                <div class="service-form__section">
                  <mat-radio-group class="vertical-radio-group" formControlName="transport">
                    <mat-radio-button *ngFor="let trans of ctx.transports" class="vertical-radio-button" value="{{ trans.id }}">
                      <i class="fa fa-{{ trans.icon }}"></i> {{ trans.name }}
                      <button *ngIf="trans.help" type="button" mat-button class="btn-help"
                          [tamoRemoteHint]="trans.help" color="accent">Что это такое
                        <mat-icon>help</mat-icon>
                      </button>
                    </mat-radio-button>
                  </mat-radio-group>

                  <div class="service-form__field">
                    <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SHDO')"
                      type="SHDO" title="Файлы транспротных документов"></tamo-documents-uploader>
                  </div>

                  <div class="service-form__field" *ngIf="ctx.selectedTransport">
                    <label class="lable_class">
                      Куда приходит груз?
                      <button class="btn-help" tamoRemoteHint="3-2"
                        mat-button color="accent">Как определить <mat-icon>help</mat-icon></button>
                    </label>
                    <div class="service-form__field">
                      <mat-button-toggle-group appearance="legacy" #cargoInformation="matButtonToggleGroup">
                        <mat-button-toggle value="1">Знаю</mat-button-toggle>
                        <mat-button-toggle value>Не знаю</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <div class="service-form__field" *ngIf="(cargoInformation.value || f.cargo_dest.value.length)">
                      <mat-form-field><input matInput formControlName="cargo_dest"
                        [placeholder]="ctx.selectedTransport.dest_label"></mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="service-form__section">
                  <div class="service-form__field">
                    <label class="lable_class">
                      Куда подаём декларации
                      <button class="btn-help" tamoRemoteHint="3-3"
                        mat-button color="accent">Как определить <mat-icon>help</mat-icon></button>
                    </label>
                    <div class="service-form__field">
                      <mat-button-toggle-group appearance="legacy" #declInformation="matButtonToggleGroup">
                        <mat-button-toggle value="1">Знаю</mat-button-toggle>
                        <mat-button-toggle value>Не знаю</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <div class="service-form__field" *ngIf="(declInformation.value || f.declaration_dest.value.length)">
                      <mat-form-field><input matInput formControlName="declaration_dest"
                        placeholder="Выбрать таможенный пост"></mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="50">
                <tamo-section-hints *ngIf="!ctx.selectedTransport" slug="transport"></tamo-section-hints>
                <tamo-section-hints *ngIf="ctx.selectedTransport && ctx.selectedTransport.example"
                  [slug]="ctx.selectedTransport.example"></tamo-section-hints>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Описание товара</mat-card-title>
            <button class="btn-help" tamoRemoteHint="4-1" mat-button color="accent">Что сюда входит
              <mat-icon>help</mat-icon>
            </button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content" id="tnvedCodes">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column">
              <div fxFlex="50">
                <div class="service-form__section">
                  <div class="service-form__product-add">
                    <button type="button" mat-raised-button color="primary"
                      (click)="addProduct()">Добавить товар</button>
                  </div>

                  <!-- <mat-progress-bar mode="indeterminate" *ngIf="productsLoading"></mat-progress-bar> -->
                  <mat-accordion>
                    <mat-expansion-panel class="product-form" [ngClass]="{'product-form_saved': product.get('id').value}" formArrayName="products"
                        *ngFor="let product of serviceForm.get('products').controls; let i = index;"
                        [expanded]="step === i" (opened)="step == i">
                      <mat-expansion-panel-header class="product-form__header">
                        <mat-panel-title class="product-form__title">
                          {{ product.get('name').value }}
                        </mat-panel-title>
                        <mat-panel-description>
                          <ng-container *ngIf="product.get('id').value">
                            <span class="product-form__tnved" *ngIf="product.get('tnved_code').value">ТНВЭД: {{ product.get('tnved_code').value }}</span>
                            <span *ngIf="!product.get('tnved_code').value">Сохранено</span>
                            <mat-icon class="product-form__icon" [ngClass]="{ 'product-form__icon_ok' : product.get('tnved_code').value }">done</mat-icon>
                          </ng-container>
                          <ng-container *ngIf="!product.get('id').value">
                            Не сохранено
                            <mat-icon class="product-form__icon">warning</mat-icon>
                          </ng-container>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <div [formGroupName]="i">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
                          <mat-form-field fxFlex>
                            <input formControlName="name" matInput placeholder="Название">
                          </mat-form-field>

                          <mat-form-field fxFlex>
                            <input formControlName="link" matInput placeholder="Ссылка на сайт">
                          </mat-form-field>
                        </div>

                        <div>
                          <mat-form-field class="product-form__textarea">
                            <textarea formControlName="description" matInput placeholder="Описание товаров" rows="3"></textarea>
                          </mat-form-field>
                        </div>

                        <mat-card *ngIf="!product.get('id').value">
                          Загрузка файлов будет доступна после сохранения
                        </mat-card>

                        <ng-container *ngIf="product.get('id').value">
                          <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="getProductUploadPrefix(product.get('id').value)" counts="-"
                            type="TECH" title="Техника"></tamo-documents-uploader>

                          <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="getProductUploadPrefix(product.get('id').value)" counts="-"
                            type="PRIM" title="Изображения"></tamo-documents-uploader>
                        </ng-container>

                        <mat-action-row *ngIf="!ctx.user.isStaff">
                          <button type="button" [disabled]="serviceForm.disabled"
                            mat-button color="warn" (click)="removeProduct(i, product)">Удалить</button>
<!--                           <button type="button" [disabled]="product.disabled" 
                            mat-button color="accent" (click)="saveProduct(product)">Сохранить</button> -->
                        </mat-action-row>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div class="service-form__section">
                  <div class="form-group">
                      <mat-checkbox class="lable_class">У меня много товаров, загружу все, что есть</mat-checkbox>
                  </div>
                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('PROD')"
                    type="PROD" title="Все файлы товаров"></tamo-documents-uploader>
                  <mat-card *ngIf="service.tnved_codes">
                    <label class="lable_class">Коды ТНВЭД</label>
                    <p class="service-form__tnved-codes">{{ service.tnved_codes }}</p>
                  </mat-card>
                </div>
              </div>
              <div fxFlex="50">
                <tamo-section-hints  slug="products"></tamo-section-hints>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Разрешительная документация</mat-card-title>
            <button class="btn-help" tamoRemoteHint="5-1" mat-button color="accent">Что сюда входит
              <mat-icon>help</mat-icon>
            </button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="service-form__section">
              <div fxFlex="50">

                <label class="lable_class">Сертификаты</label>
                <button class="btn-help" mat-button color="accent" tamoRemoteHint="5-2">Что это такое <mat-icon>help</mat-icon></button>

                <div *ngIf="!f.cert_unknown.value">
                  <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" *ngIf="!f.cert_not_necessary.value" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('CERT')" 
                    type="CERT" title="Файлы сертификатов"></tamo-documents-uploader>

                  <div class="form-group">
                    <mat-checkbox class="lable_class" formControlName="cert_not_necessary">На мой товар сертификат не нужен</mat-checkbox>
                  </div>
                </div>

                <div class="form-group">
                  <mat-checkbox
                    class="lable_class service-form__checkbox service-form__checkbox_unknown"
                    color="warn"
                    (change)="unknownClicked($event, 'Мы сами разберемся нужен сертификат или нет и согласуем с Вами.')"
                    formControlName="cert_unknown">Я не знаю, нужен сертификат или нет</mat-checkbox>
                </div>
              </div>
              <div fxFlex="50">
                <tamo-section-hints slug="certificates"></tamo-section-hints>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="service-card service-form__card">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Платежи на таможню</mat-card-title>
            <button class="btn-help" tamoRemoteHint="6-1" mat-button color="accent">Что сюда входит
                <mat-icon>help</mat-icon>
              </button>
          </mat-card-header>
          <mat-divider class="service-card__divider"></mat-divider>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="service-form__section">
              <div fxFlex="50">
                <label class="lable_class">Платежки</label>
                <button class="btn-help" mat-button color="accent" tamoRemoteHint="6-2">Что это такое
                  <mat-icon>help</mat-icon>
                </button>
                <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" *ngIf="!f.custom_sum_unknown.value" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('PAYM')"
                  type="PAYM" title="Файлы платежек"></tamo-documents-uploader>
                <div>
                  <mat-checkbox class="lable_class"
                    formControlName="custom_sum_unknown">Я не знаю сумму таможенных платежей</mat-checkbox>
                </div>
              </div>
              <div fxFlex="50">
                <tamo-section-hints slug="payments"></tamo-section-hints>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card id="extraDocuments" class="service-card service-form__card" *ngIf="['FI', 'AS', 'RL'].includes(service.dt_status)">
          <mat-card-header class="service-card__header">
            <mat-card-title class="service-card__title">Документы запрошенные таможней</mat-card-title>
          </mat-card-header>
          <mat-card-content class="service-card__content">
            <div fxLayout fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column">
              <div fxFlex="50">
                <tamo-documents-uploader [disabled]="ctx.user.isStaff" (uploadComplete)="uploadCompleted($event)" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('OREX')"
                  type="OREX" title="Дополнительные документы"></tamo-documents-uploader>
              </div>
              <div fxFlex="50"></div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="service-card service-form__card">
          <mat-card-content class="service-card__content">
            <div><label class="lable_class">Ожидаемая дата оформления ТД</label></div>
            <mat-form-field>
              <input matInput [matDatepicker]="picker" formControlName="td_issue_date"
                placeholder="Дата оформления ТД">
              <button mat-button *ngIf="f.td_issue_date.value" matSuffix mat-icon-button
                  aria-label="Очистить" (click)="f.td_issue_date.setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

<!--         <mat-card class="service-card service-form__card">
          <mat-card-content class="service-card__content">
            <button type="submit" [disabled]="serviceForm.disabled"
              mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
            <button type="button" [disabled]="serviceForm.disabled" *ngIf="service.service_status?.id == 'DR'" (click)="process()"
              mat-raised-button color="accent">Отправить в работу</button>
            <button type="submit" [disabled]="serviceForm.disabled" *ngIf="service.service_status?.id == 'WA'" (click)="process()"
              mat-raised-button color="accent">Дослать документы</button>
          </mat-card-content>
        </mat-card> -->
      </form>
    </div>
  </div>
</ng-container>
<!--   </div>
</div> -->

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, tap, map, switchMap, mergeMap } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';

import { OrderState, LoadServiceStatuses } from '../../shared/store';
import { Service, Order, ServiceType } from '../../shared';
import { SocketService } from '../../socket';
import { OrderLog, OrderServicesService, NotificationsService, ReactiveComponent } from '../../shared';

@Component({
  selector: 'tamo-service-notifications',
  templateUrl: './service-notifications.component.html',
  styleUrls: ['./service-notifications.component.less']
})
export class ServiceNotificationsComponent extends ReactiveComponent {
  @Select(OrderState.serviceStatuses) statuses$: Observable<OrderLog[]>;

  @Select(OrderState.statusesLoading) loading$: Observable<boolean>;

  public statusesCount$: Observable<number> = this.statuses$.pipe(
    map(statuses => statuses ? statuses.length : 0));

  // @Input()
  // public orderId: number;

  // @Input()
  // public serviceId: number;

  // private loadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  // public loading$: Observable<boolean> = this.loadingSource$.asObservable();

  // public notifications: OrderLog[] = [];

  // public loading: false;

  constructor(private store: Store,
              private socketService: SocketService) {
    super();
    this.store.dispatch(new LoadServiceStatuses());
  }

  ngOnInit() {
    // combineLatest([
    //   this.observePropertyCurrentValue<number>('orderId'),
    //   this.observePropertyCurrentValue<number>('serviceId')
    // ]).pipe(
    //   filter(([orderId, serviceId]) => !!(orderId && serviceId)),
    //   switchMap(() => this.dataService.statuses(this.orderId, this.serviceId)),
    //   map(notifications => notifications.reverse())
    // ).subscribe((notifications: OrderLog[]) => {
    //   this.dataService.touch(this.orderId, this.serviceId);
    //   this.notifications = notifications;
    //   this.loadingSource$.next(false);
    // })

    // this.serviceId = this.route.snapshot.parent.params['serviceid'];
    // this.route.parent.params
    //   .pipe(
    //     map(params => this.serviceId = params['serviceid']),
    //     switchMap(() => this.dataService.statuses(this.orderId, this.serviceId)),
    //     tap(() => this.dataService.touch(this.orderId, this.serviceId))
    //   )
    //   .subscribe((notifications: OrderLog[]) => {
    //     this.notifications = notifications.reverse();
    //     this.loading = false;
    //   });

    // const statusChanged$ = this.socketService.statusChange$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe$),
    //     filter((orderLog: OrderLog) => orderLog.service == this.serviceId),
    //     tap(() => this.dataService.touch(this.orderId, this.serviceId))
    //   )
    //   .subscribe((orderLog: OrderLog) => {
    //     this.notifications.push(orderLog);
    //   });
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ReactiveComponent } from '../shared/components';
import { Hint } from '../shared/models/hint';
import { Section } from '../shared/models/section';
import { RemoteHintService } from './remote-hint.service';
import { HintsService } from '../shared/services/hints.service';

declare var jsPanel: any;

@Component({
  selector: 'tamo-section-hints',
  templateUrl: 'section-hints.component.html',
  styleUrls: ['./section-hints.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHintsComponent extends ReactiveComponent {
  public section$: Observable<Section>;

  private loadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public loading$: Observable<boolean> = this.loadingSource$.asObservable();

  public currentIndex: number;

  public sliderConfig = {
    slidesPerView: 3,
    centeredSlides: true,
    slideToClickedSlide: true,
    pagination: false,
    navigation: false,
    scrollbar: false
  };

  @Input()
  slug: string;

  constructor(private hintService: RemoteHintService,
              private dataService: HintsService) {
    super();

    this.section$ = this.observePropertyCurrentValue<string>('slug').pipe(
      tap(() => this.loadingSource$.next(true)),
      switchMap(slug => this.dataService.getSection(slug)),
      tap(() => this.loadingSource$.next(false))
    );
  }

  ngOnInit() {

  }
}
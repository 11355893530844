<button [disabled]="disabled"
	class="toolbar-button"
	matRipple
	[class.mat-elevation-z2]="!disabled"
	[style.width.px]="size"
	[style.height.px]="size"
	(click)="onClick($event)"
	><mat-icon
		[style.fontSize.px]="size * .72"
		[style.width.px]="size * .72"
		[style.height.px]="size * .72"
		[style.color]="color"
		>{{ icon }}</mat-icon></button>
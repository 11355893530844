import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { StubComponent } from './stub/stub.component';

@NgModule({
  declarations: [StubComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule
  ]
})
export class StubModule { }

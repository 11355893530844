import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StorageFolder } from '../../shared';
import { FileManagerState, SelectFolder, CreateFolder, DeleteFolder } from '../../shared/store';

@Component({
  selector: 'file-manager-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
  @Select(FileManagerState.foldersLoading)
  foldersLoading$: Observable<boolean>;
  
  @Select(FileManagerState.breadcrumbs)
  breadcrumbs$: Observable<StorageFolder[]>;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  selectFolderById(folderId: number | null) {
    this.store.dispatch(new SelectFolder(folderId));
  }

  trackByFolderId(index, folder: StorageFolder) {
    return folder.id;
  }
}

<ng-container *ngIf="user$ | async as user">
  <div class="sign" *ngIf="service">
    <form [formGroup]="serviceForm" class="sign-form" ngxsForm="order.serviceForm">   
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="30px">
          <mat-card fxFlex="50">
            <mat-card-content>
              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SIPM')"
                type="SIPM" title="Цветной скан 1 стр. паспорта"></tamo-documents-uploader>

              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SIPA')"
                type="SIPA" title="Цветной скан 2 стр. паспорта"></tamo-documents-uploader>

              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SISN')"
                type="SISN" title="Цветной скан СНИЛС"></tamo-documents-uploader>

              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SICL')"
                type="SICL" title="Фото клиента с паспортом"></tamo-documents-uploader>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="sign-form__group" fxFlex *ngIf="!user.isStaff">
                  <dadata-suggest (optionSelected)="onSelectionChanged($event)"></dadata-suggest>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="ИНН" formControlName="inn">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="Наименование компании" formControlName="org_name">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="ОГРН" formControlName="ogrn">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="КПП" formControlName="kpp">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="Юридический адрес" formControlName="legal_address">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="Физический адрес" formControlName="physical_address">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="Адрес для корреспонденции" formControlName="correspondence_address">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="Должность руководителя" formControlName="director_position">
                  </mat-form-field>
                </div>

                <div class="sign-form__group" fxFlex>
                  <mat-form-field class="sign-form__field" fxFlex="grow">
                    <input matInput placeholder="ФИО руководителя" formControlName="director_fio">
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

<!--         <mat-card fxFlex>
          <button type="submit" [disabled]="serviceForm.disabled"
            mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
          <button type="button" [disabled]="serviceForm.disabled" *ngIf="service.service_status.id == 'DR'" (click)="process()"
            mat-raised-button color="accent">Отправить в работу</button>
        </mat-card> -->
      </div>
    </form>
  </div>
</ng-container>
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, of, combineLatest } from 'rxjs';
import { plainToClass } from "class-transformer";
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { OrderState, UpdateService, AuthState } from '../../shared/store';
import { DadataOrg } from '../../dadata';
import {
  Consulting,
  ConsultingCategory,
  ConsultingService,
  ConsultingDataService,
  ReactiveComponent,
  AuthUser
} from '../../shared';

@Component({
  selector: 'tamo-consulting-details',
  templateUrl: './consulting-details.component.html',
  styleUrls: ['./consulting-details.component.less']
})
export class ConsultingDetailsComponent extends ReactiveComponent {
  @Input()
  public service: Consulting;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  public serviceForm: FormGroup;

  public categories$: Observable<ConsultingCategory[]>;

  public controlsOrder: number[] = [];

  constructor(
      private store: Store,
      private fb: FormBuilder,
      private dataSrv: ConsultingDataService) {
    super();

    this.serviceForm = this.fb.group({
      comment: [''],
      servicesChecked: new FormArray([]),
      services: [[]]
    });
  }

  get f() {
    return this.serviceForm.controls;
  }

  ngOnInit() {
    this.categories$ = this.dataSrv.categories();

    combineLatest(
      this.observePropertyCurrentValue<Consulting>('service'),
      this.categories$
    )
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([service, categories]) => {
        categories.forEach(category => {
          category.services.forEach(srv => {
            const control = new FormControl(service.services.includes(srv.id));
            (this.serviceForm.controls.servicesChecked as FormArray).push(control);
            this.controlsOrder.push(srv.id);
          });
        });

        this.serviceForm.patchValue({
          'comment': service.comment
        });
      });

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.serviceForm.disable();
      } else {
        this.serviceForm.enable();
      }
    });

    this.f.servicesChecked.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((data) => {
      this.f.services.setValue(data
        .map((checked, ind) => checked ? this.controlsOrder[ind]: null)
        .filter(id => id));
    })
  }

  get isSelected() {
    return this.f.servicesChecked.value.filter(v => v === true).length > 0;
  }

  getControlIndex(id) {
    return this.controlsOrder.indexOf(id);
  }

  // onSubmit(process = false) {
  //   if(!this.serviceForm.valid) {
  //     return;
  //   }

  //   const model = this.serviceForm.value;
    // model.services = model.services
    //   .map((checked, ind) => checked ? this.controlsOrder[ind]: null)
    //   .filter(id => id);
  //   const toSave = plainToClass(Consulting, model as Object);

  //   this.store.dispatch(new UpdateService(toSave, process));
  // }

  // public process() {
  //   this.onSubmit(true);
  // }
}

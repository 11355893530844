import { Component, ViewChild } from '@angular/core';
import { MatSidenav  }  from '@angular/material/sidenav';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, map, tap, finalize, shareReplay, pluck, buffer, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, ActivatedRoute, ActivationEnd, NavigationEnd } from '@angular/router';
import { Store, Select, ofActionDispatched, Actions } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { ServiceType } from '../shared/models';
import { AuthState, Logout, ChangeServiceStatus, AddChatMessage, UpdateServiceData, } from '../shared/store';
import { CreateServiceDialogComponent } from './create-service-dialog/create-service-dialog.component';

import {
  Order,
  Notification,
  OrdersService,
  AuthService,
  NotificationsService,
  ShippingService,
  Shipping,
  User,
  ReactiveComponent,
  AuthUser
} from '../shared';
import { RemoteHintService } from '../hints/remote-hint.service';
import { OrderStatusPipe } from '../order-status';
import { SocketService } from '../socket';

@Component({
  selector: 'tamo-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: 'dashboard.component.html',
  providers: [OrderStatusPipe]
})
export class DashboardComponent extends ReactiveComponent {
  public serviceTypes = (ServiceType.getValues() as ServiceType[]).filter(serviceType => !serviceType.hidden);
  public notices: Notification[] = [];
  public notcesCount: number;
  public collapsed: boolean | null = null;
  public noticeLoading: boolean = false;

  @Select(AuthState.username)
  public username$: Observable<string>;

  @Select(AuthState.user)
  public user$: Observable<AuthUser>;

  @ViewChild('hintSidenav', { static: true })
  private hintSidenav: MatSidenav;

  @ViewChild('mainSidenav', { static: true })
  private mainSidenav: MatSidenav;

  @ViewChild(MatMenuTrigger, { static: false })
  private menuTrigger: MatMenuTrigger;
  
  constructor(
      private store: Store,
      private hintService: RemoteHintService,
      private ordersService: OrdersService,
      private authService: AuthService,
      private socketService: SocketService,
      private snackBar: MatSnackBar,
      private statusPipe: OrderStatusPipe,
      private notifyService: NotificationsService,
      private shippingService: ShippingService,
      private router: Router,
      private route: ActivatedRoute,
      private actions$: Actions,
      private toastr: ToastrService,
      private dialog: MatDialog) {
    super();

    this.router.events.pipe(
      filter(evt => evt instanceof ActivationEnd),
      pluck("snapshot"),
      pluck("data"),
      buffer(this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))),
      map((data: any[]) => data.reverse().reduce((acc, item) => Object.assign(acc, item), {}))
    ).subscribe((data) => {
      this.collapsed = data.collapsedMenu || false;
    });
  }

  ngOnInit() {
    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(ChangeServiceStatus)
    ).subscribe(({ data }) => {
      const messagesRoute = this.router.createUrlTree(
        [`orders/${data.order_id}/services/${data.service_id}`], {relativeTo: this.route});
      const statusFrom = this.statusPipe.transform(data.status_from);
      const statusTo = this.statusPipe.transform(data.status_to);

      this.toastr.info(
        `Статус услуги изменен с <strong>${statusFrom}</strong> на <strong>${statusTo}</strong>`,
        `Заказ #${data.order_id}`,
        { timeOut: 0, closeButton: true, extendedTimeOut: 3000, progressBar: true, enableHtml: true }
      ).onTap.pipe(
        take(1)
      ).subscribe(() => this.router.navigateByUrl(messagesRoute));      
    });

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(UpdateServiceData)
    ).subscribe(({ data }) => {
      const messagesRoute = this.router.createUrlTree(
        [`orders/${data.order_id}/services/${data.service_id}`], {relativeTo: this.route});
      const statusFrom = this.statusPipe.transform(data.status_from);
      const statusTo = this.statusPipe.transform(data.status_to);

      this.toastr.info(
        `Услуга обновлена. <br> <strong>${data.changes.map(c => c.field_name).join(', ')}</strong>`,
        `Заказ #${data.order_id}`,
        { timeOut: 0, closeButton: true, extendedTimeOut: 3000, progressBar: true, enableHtml: true }
      ).onTap.pipe(
        take(1)
      ).subscribe(() => this.router.navigateByUrl(messagesRoute));      
    });

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(AddChatMessage)
    ).subscribe(({ data }) => {
      const user = this.store.selectSnapshot(AuthState.user);
      const messagesRoute = this.router.createUrlTree(
        [`orders/${data.object_id}/messages`], {relativeTo: this.route});

      if (user && data.is_client === user.isStaff) {
        // !this.router.isActive(messagesRoute, true)
        this.toastr.info(
          `Новое сообщение в чате`,
          `Заказ #${data.object_id}`,
          { timeOut: 0, closeButton: true, extendedTimeOut: 3000, progressBar: true }
        ).onTap.pipe(
          take(1)
        ).subscribe(() => this.router.navigateByUrl(messagesRoute));
      }
    });

    // this.socketService.statusChange$
    //   .pipe(takeUntil(this.ngUnsubscribe$))
    //   .subscribe(log => {
    //     this.snackBar.open(
    //       `#${log.order_number} ${this.statusPipe.transform(log.status_from)} -> ` + 
    //       `${this.statusPipe.transform(log.status_to)}`,
    //       "Перейти"
    //     ).onAction().subscribe(() => {
    //       if(log.service_type == 'declaration') {
    //         this.router.navigate([`app/orders/${log.order}/${log.service_type}/${log.service}/notifications`]);
    //       } else {
    //         this.router.navigate([`app/orders/${log.order}/${log.service_type}/${log.service}`]);
    //       }
    //     });
    //     this.notifyService.updateCount();
    //   });

    // this.socketService.messageSent$
    //   .pipe(takeUntil(this.ngUnsubscribe$))
    //   .subscribe(message => {
    //     this.snackBar.open(
    //       `Новое сообщение по заказу #${message.object_id}`, "Перейти"
    //     ).onAction().subscribe(() => {
    //       this.router.navigate([`app/orders/${message.object_id}/messages`]);
    //     });
    //     this.notifyService.updateCount();
    //   });

    this.notifyService.count$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(count => {
        this.notcesCount = count;
      });

    this.notifyService.updateCount();
  }

  public loadNotices($event) {
    this.notifyService.all().pipe(
      tap(() => this.noticeLoading = true),
      finalize(() => this.noticeLoading = false)
    ).subscribe((notices: Notification[]) => {
      this.notices = notices;
      setTimeout(() => {
        this.menuTrigger.openMenu()
      }, 0);
    });
  }

  public getStatusName(status) {
    return this.statusPipe.transform(status)
  }

  public openNotice(notice: Notification) {
    if(notice.type == 'ME') {
      this.router.navigate([`app/orders/${notice.data.object_id}/messages`]);
    } else if(notice.type == 'ST') {
      const log = notice.data;
      if(log.service_type == 'declaration') {
        this.router.navigate([`app/orders/${log.order}/${log.service_type}/${log.service}/notifications`]);
      } else {
        this.router.navigate([`app/orders/${log.order}/${log.service_type}/${log.service}`]);
      }
    }
  }

  public createOrder() {
    this.ordersService.create({
      name: 'Новый заказ'
    }).subscribe((order: Order) => {
      this.router.navigate([`app/orders/${order.id}`]);
    });
  }

  public createShipping() {
    this.shippingService.create({
      name: 'Новый заказ на перевозку'
    }).subscribe((shipping: Shipping) => {
      this.router.navigate([`app/shipping/${shipping.id}`]);
    });
  }

  public createService(serviceType: ServiceType) {
    const dialogRef = this.dialog.open(CreateServiceDialogComponent, {
      width: '300px',
      data: serviceType
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ordersService.create({
          name: result,
          manager_id: null,
          services: [{ 'resourcetype': serviceType.value }]
        }).subscribe(
          (order) => this.router.navigate([`/app/orders/${order.id}`])
        );
      }
    });
  }

  public logout() {
    this.store.dispatch(new Logout())
  }

  public toggleSidenav() {
    this.collapsed = this.collapsed ? false : true;
  }
}

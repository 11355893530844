<ng-container *ngIf="{
  foldersLoading: foldersLoading$ | async,
  filesLoading: filesLoading$ | async,
  levelFolders: levelFolders$ | async,
  levelFiles: levelFiles$ | async,
  selectedFolder: selectedFolder$ | async,
  selectedFilesIds: selectedFilesIds$ | async,
  selectedFiles: selectedFiles$ | async,
  selectedFolderUploadingFiles: selectedFolderUploadingFiles$ | async,
  showUnsorted: showUnsorted$ | async,
  hoveredFilesIds: hoveredFilesIds$ | async
} as ctx">
  <div class="file-manager" [class.file-manager_nobreadcrumbs]="!showBreadcrumbs">
    <div *ngIf="showBreadcrumbs" class="file-manager__breadcrumbs mat-elevation-z3" fxLayout="row" fxLayoutAlign="space-between center">
      <!-- <div style="border: 1px solid black" ngfDrop [files]="files" (filesChange)="onDropFiles($event)">TEST</div> -->

      <div>
        <file-manager-breadcrumbs *ngIf="!ctx.showUnsorted"></file-manager-breadcrumbs>
      </div>

      <div>
        <mat-slide-toggle [checked]="ctx.showUnsorted" (change)="showUnsorted($event)">Файлы без папок</mat-slide-toggle>
      </div>
    </div>

    <div class="file-manager__toolbar" fxLayout="row" fxLayoutAlign="flex-start center">
      <file-manager-toolbar [actionsTemplate]="actionsTemplate" [editable]="editable" fxFlex></file-manager-toolbar>
    </div>

    <mat-drawer-container autosize="true" class="file-manager__container">
      <mat-drawer mode="side" opened class="file-manager__sidenav">
        <div class="file-manager__header" fxLayout="row" fxLayoutAlign="flex-start center">
          <file-manager-folder-header *ngIf="!ctx.showUnsorted" [editable]="editable" fxFlex></file-manager-folder-header>
        </div>

        <div class="file-manager__selected" *ngIf="ctx.selectedFiles.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Выбрано файлов&nbsp;<strong>({{ ctx.selectedFiles.length }})</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let file of ctx.selectedFiles; trackBy: trackByFileId" class="selected-file" fxLayout="row" fxLayoutGap="7px">
              <div class="selected-file__toolbar">
                <file-manager-toolbar-button (action)="deselectFile(file)" icon="delete" color="red"></file-manager-toolbar-button>
              </div>

              <mat-icon class="selected-file__icon" fontSet="fa" [fontIcon]="file.content_type.icon" [style.color]="file.content_type.color" [inline]="true"></mat-icon>
              <span class="selected-file__title">{{ file.title }}</span>
            </div>
          </mat-expansion-panel>
        </div>

        <div class="file-manager__folders">
          <ng-container *ngIf="!ctx.showUnsorted">
            <folder *ngFor="let folder of ctx.levelFolders; trackBy: trackByFolderId"
              [folder]="folder"
              [disabeld]="ctx.foldersLoading"
              [editable]="editable"
              (onSelectFolder)="selectFolder(folder)"
              (onDeleteFolder)="deleteFolder(folder)"
              (onEditFolder)="editFolder(folder)"></folder>
          </ng-container>
        </div>
      </mat-drawer>

      <mat-drawer-content
          ngfDrop [files]="files" (filesChange)="uploadFiles($event)"
          [rangeSelection]="true"
          (selectionStart)="selectionStarted($event)"
          (selectionStop)="selectionComplete($event)"
          (selectionMove)="selectionMove($event)">
        <ng-container *ngIf="(ctx.filesLoading || ctx.selectedFolderUploadingFiles.length > 0 || ctx.levelFiles.length > 0) else noFiles">
          <div class="file-manager__files" fxLayout="row wrap" fxLayoutGap="20px">
            <mat-progress-bar *ngIf="ctx.filesLoading" class="file-manager__files-loader" mode="indeterminate"></mat-progress-bar>

            <ng-container *ngIf="editable">
              <ng-container *ngFor="let file of ctx.selectedFolderUploadingFiles; trackBy: trackByFileId">
                <upload-file [file]="file"></upload-file>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let file of ctx.levelFiles; trackBy: trackByFileId">
              <file
                [file]="file"
                [selected]="ctx.selectedFilesIds.includes(file.id)"
                [hovered]="ctx.hoveredFilesIds.includes(file.id)"
                [disabled]="ctx.filesLoading"
                [editable]="editable"
                (onSelectFile)="selectFile(file)"
                (onDeleteFile)="deleteFile(file)"
                (onEditFile)="editFile(file)"
                (onDownloadFile)="downloadFile(file)"></file>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #noFiles>
          <div *ngIf="!ctx.showUnsorted" class="file-manager__nofiles">Эта папка пуста. Для загрузки перетащите файлы</div>
          <div *ngIf="ctx.showUnsorted" class="file-manager__nofiles">У вас нет неотсортированных файлов</div>
        </ng-template>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</ng-container>
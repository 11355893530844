<div [formGroup]="form" fxLayout="column" fxLayoutGap="30px" class="shipping-auto">
  <div fxLayout="column" fxLayoutGap="20px">
    <div>
      <h4 class="shipping-form__title">Загрузка/разгрузка</h4>

      <div fxLayout fxLayoutGap="10px" fxLayout="row" fxLayout.lt-md="column"
          fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
        <kladr-suggest-city class="shipping-auto__place-field" appearance="legacy" placeholder="Место загрузки"
        (optionSelected)="onDepartureSelected($event)"
          ngDefaultControl [formControl]="f.departure_place"></kladr-suggest-city>

        <mat-icon fxHide.lt-md>arrow_right_alt</mat-icon>
        <kladr-suggest-city class="shipping-auto__place-field" appearance="legacy" placeholder="Место разгрузки"
          (optionSelected)="onArrivalSelected($event)"
          ngDefaultControl [formControl]="f.arrival_place"></kladr-suggest-city>
      </div>
    </div>

    <ng-container *ngIf="placesSelected$ | async">
      <div fxLayout="row" fxLayoutAling="flex-start center" fxLayoutGap="5px">
        <span>Длина маршрута:</span>
        <mat-spinner *ngIf="routeLoading$ | async" diameter="13"></mat-spinner>
        <ng-container *ngIf="routeInfo$ | async as routeInfo">
          <strong>{{ routeInfo.Length.text }}</strong>
        </ng-container>
      </div>

      <h4 class="shipping-form__title">Тип груза</h4>

      <div>
        <mat-radio-group fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-start center" formControlName="cargo_type">
          <mat-radio-button value="COMPLEX">Сборный груз</mat-radio-button>
          <mat-radio-button value="FULL">Целая машина</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="row" *ngIf="f.cargo_type.value == 'COMPLEX'">
        <div fxFlex="100%">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px"
               fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field class="shipping-auto__size-field">
                <input matInput formControlName="cargo_length" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Длина</mat-label>
              </mat-form-field>

              <mat-form-field class="shipping-auto__size-field">
                <input matInput formControlName="cargo_width" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Ширина</mat-label>
              </mat-form-field>

              <mat-form-field class="shipping-auto__size-field">
                <input matInput formControlName="cargo_height" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Высота</mat-label>
              </mat-form-field>
            </div>

            <mat-icon fxHide.lt-md fontSet="fa" fontIcon="fa-equals"></mat-icon>

            <mat-form-field class="shipping-auto__size-field">
              <input matInput formControlName="cargo_size" placeholder="Объем" autocomplete="off">
              <span matSuffix>м<sup>3</sup></span>
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-ruler-combined"></mat-icon> Объем</mat-label>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="f.cargo_type.value == 'FULL' || f.cargo_type.value == 'COMPLEX'">
        <mat-form-field class="shipping-auto__size-field">
          <input matInput formControlName="cargo_weight" autocomplete="off">
          <mat-label><mat-icon fontSet="fa" fontIcon="fa-weight-hanging"></mat-icon> Вес груза</mat-label>
          <span matSuffix>кг</span>
          <mat-hint align="start">{{ f.cargo_weight.value / 1000 | number }}</mat-hint>
          <mat-hint align="end">тонн</mat-hint>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <div *ngIf="dataReady$ | async" fxLayout="row">
    <div fxFlex="nogrow" fxFlex.lt-lg="100%" class="shipping__result shipping-result" fxLayout="column" fxLayoutGap="10px">

      <ng-container *ngIf="!(weightExceeded$ | async) else weightExceeded">
        <ng-container *ngIf="!(routeLoading$ | async) else tariffComputing">
          <ng-container *ngIf="routeInfo$ | async as routeInfo">
            <tamo-shipping-result
              [departurePlace]="(departurePlace$ | async)" departureIcon="map-marker"
              [arrivalPlace]="(arrivalPlace$ | async)" arrivalIcon="map-marker"
              [cost]="cost$ | async" currency="EUR"
              routeIcon="car">
              
              <ng-template #resultInfoTemplate>
                <span><mat-icon fontSet="fa" fontIcon="fa-clock" [inline]="true"></mat-icon>&nbsp;{{ routeInfo.Time.text }}</span>
                <span><mat-icon fontSet="fa" fontIcon="fa-arrows-alt-h" [inline]="true"></mat-icon>&nbsp;{{ routeInfo.Length.text }}</span>
                <span><mat-icon fontSet="fa" fontIcon="fa-weight-hanging" [inline]="true"></mat-icon>&nbsp;{{ cargoWeight$ | async }} кг</span>
              </ng-template>

<!--               <ng-template #resultNoteTemplate *ngIf="!(selectedTariff$ | async)">
                <font color="red">
                  Вес груза не подходит ни под один тариф:
                  <div *ngFor="let tariff of tariffs$ | async">
                    <strong>{{ tariff.weight_from }}</strong> - <strong>{{ tariff.weight_to }}</strong>, <strong>{{ tariff.cost }}</strong>{{ tariff.cost_currency }} за км
                  </div>
                </font>
              </ng-template> -->
            </tamo-shipping-result>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #weightExceeded>
        <div class="shipping-result__block shipping-result__block_error">
          У Вас очень тяжелый груз на который нужно оформлять отдельное разрешение.<br>
          Пожалуйста, укажите вес  до 21 тонны, либо отправьте отдельный запрос на перевозку тяжелого груза.
        </div>
      </ng-template>

      <ng-template #tariffComputing>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>

    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';

import { LoadNews } from '../actions/news.actions';
import { News, PagedResult } from '../../models';
import { NewsService } from '../../services';


export interface NewsStateModel {
  news: News[];
  loading: boolean;
  total: number;
  page: number;
  pageSize: number;
}


@State<NewsStateModel>({
  name: 'news',
  defaults: {
    news: [],
    loading: false,
    total: 0,
    page: 1,
    pageSize: 0
  }
})
@Injectable()
export class NewsState {
  @Selector([NewsState])
  static total(state: NewsStateModel): number {
    return state.total;
  }

  @Selector([NewsState])
  static page(state: NewsStateModel): number {
    return state.page;
  }

  @Selector([NewsState])
  static news(state: NewsStateModel): News[] {
    return state.news;
  }

  @Selector([NewsState])
  static loading(state: NewsStateModel): boolean {
    return state.loading;
  }

  @Selector([NewsState])
  static pageSize(state: NewsStateModel): number {
    return state.pageSize;
  }

  constructor(
    private store: Store,
    private newsService: NewsService) {}

  @Action(LoadNews)
  @ImmutableContext()
  loadNews(
    { setState, getState, dispatch }: StateContext<NewsStateModel>,
    { page }: LoadNews
  ) {
    setState((state: NewsStateModel) => {
      state.loading = true;
      return state;
    });

    return this.newsService.all(page).pipe(
      map((response: PagedResult<News>) => {
        return setState((state: NewsStateModel) => {
          state.news = response.results;
          state.total = response.count;
          state.page = response.page_number;
          state.pageSize = response.page_size;

          state.loading = false;
          return state;
        });
      }),
    );
  }
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, finalize, tap, filter } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { OrdersUIService } from '../orders-ui.service';
import { ReactiveComponent } from '../../shared';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.less']
})
export class OrdersComponent extends ReactiveComponent {
	@ViewChild('sidenav', { static: true })
	public sidenav: MatSidenav;

	public disableClose: boolean = false;

  constructor(private uiService: OrdersUIService,
              private router: Router) {
    super();
  }

  ngOnInit() {
  	this.uiService.requestCreation$.pipe(
  		takeUntil(this.ngUnsubscribe$)
  	).subscribe(() => {
  		this.sidenav.open();
  	});

  	this.uiService.createStart$.pipe(
  		takeUntil(this.ngUnsubscribe$)
  	).subscribe(() => {
  		this.disableClose = true;
  	});

		this.uiService.createComplete$.pipe(
  		takeUntil(this.ngUnsubscribe$)
  	).subscribe(() => {
  		this.disableClose = false;
  	});

    this.uiService.close$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      this.sidenav.close();
    });
  }

  onClose() {
    this.router.navigate([], {queryParams: { create: null }, queryParamsHandling: 'merge'});
  }
}

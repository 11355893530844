<mat-card class="organization__card">
  <div class="organization" [formGroup]="form">
    <div class="organization__group organization__group_search">
      <dadata-suggest (optionSelected)="onSelectionChanged($event)" appendTo="#questionary"></dadata-suggest>
    </div>

    <div class="organization__group">
      <mat-checkbox
        (change)="passSearch = !passSearch"
        >Я сам заполню данные организации</mat-checkbox>
    </div>

    <div *ngIf="org || passSearch">
      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="Наименование компании" formControlName="org_name">
        </mat-form-field>
      </div>

      <div class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="ИНН" formControlName="inn">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="ОГРН" formControlName="ogrn">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="КПП" formControlName="kpp">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="Юридический адрес" formControlName="legal_address">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="Физический адрес" formControlName="physical_address">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="Адрес для корреспонденции" formControlName="correspondence_address">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="Должность руководителя" formControlName="director_position">
        </mat-form-field>
      </div>

      <div  class="organization__group">
        <mat-form-field  class="organization__field">
          <input matInput placeholder="ФИО руководителя" formControlName="director_fio">
        </mat-form-field>
      </div>

    </div>
  </div>
</mat-card>
<ng-container *ngIf="{
  user: user$ | async,
  orders: orders$ | async,
  loading: loading$ | async,
  filterApplied: filterApplied$ | async,
  total: total$ | async,
  pageSize: pageSize$ | async,
  page: page$ | async
} as ctx">
  <div class="orders-list" fxLayout="column" fxLayoutGap="10px">
    <ng-container *ngIf="!ctx.user.isStaff">
      <div class="orders-list__table-tools">
        <button class="orders-list__create"
          (click)="createOrder()"
          mat-fab color="accent"><mat-icon>add</mat-icon></button>
      </div>
    </ng-container>

    <ng-container *ngIf="ctx.filterApplied || ctx.orders.length > 0">
      <mat-card class="orders-list__filter" class="mat-elevation-z3">
        <tamo-orders-filter></tamo-orders-filter>
      </mat-card>

      <div class="mat-elevation-z1">
        <!-- <mat-toolbar><mat-icon>shopping_cart</mat-icon>&nbsp;Заказы</mat-toolbar> -->
        <!-- <div *ngIf="ctx.loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div> -->
        <ngx-datatable
          #ordersTable
          [messages]="{
            emptyMessage: 'Заказы не найдены',
            totalMessage: 'Всего',
            selectedMessage: 'Выбрано'
          }"
          [loadingIndicator]="ctx.loading"
          [rows]="ctx.orders"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          (select)="selectRow($event)"
          columnMode="flex"
          selectionType="none"
          
          [externalPaging]="true"
          [count]="ctx.total"
          [offset]="ctx.page - 1"
          [limit]="ctx.pageSize"
          (page)="setPage($event)"

          class="material expandable">
          <!-- (page)='pageChanged($event)' -->
          <ngx-datatable-row-detail rowHeight="'auto'">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div fxLayout="column" fxLayoutGap="10px">
                <ng-container *ngFor="let service of row.services; let lastItem = last;">
                  <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="flex-start center">
                      <mat-icon>{{ service.service_type.icon }}</mat-icon>
                      <a class="orders-list__service-link" href="#" (click)="openService($event, row, service)">{{ service.service_type.title }}</a>
                      <tamo-order-status [status]="service.service_status.id" [small]="true"></tamo-order-status>

                      <ng-container [ngSwitch]="service.service_type.type">
                        <ng-container *ngSwitchCase="'Declaration'">
                          <tamo-order-status [status]="service.dt_status" [small]="true"></tamo-order-status>
                          <tamo-order-status [status]="service.tnved_status" [small]="true"></tamo-order-status>
                        </ng-container>

                        <ng-container *ngSwitchCase="'ConditionalRelease'">
                          <tamo-order-status *ngIf="service.decision" [status]="service.decision.id" [small]="true"></tamo-order-status>
                        </ng-container>
                      </ng-container>
                    </div>

                    <ng-container [ngSwitch]="service.service_type">
                      <div class="orders-list__declaration-info" *ngSwitchCase="ServiceType.DECLARATION" fxLayout="row inline" fxLayoutGap="13px">
                        <div>Подача ДТ:&nbsp;<strong>{{ service.dt_date_filing | amDateFormat: 'DD.MM.YYYY' || '-' }}</strong></div>
                        <div>Номер ДТ:&nbsp;<strong>{{ service.dt_number || '-' }}</strong></div>
                        <div>Выпуск ДТ:&nbsp;<strong>{{ service.dt_date_release | amDateFormat: 'DD.MM.YYYY' || '-' }}</strong></div>
                        <div>Декларант:&nbsp;<strong>{{ service.declarant || '-' }}</strong></div>
                        <div>Оплата:&nbsp;<strong>{{ service.payment_state || '-' }}</strong></div>
                      </div>

                      <div *ngSwitchDefault></div>
                    </ng-container>
                  </div>

                  <mat-divider *ngIf="!lastItem"></mat-divider>
                </ng-container>
              </div>
    <!--           <div style="padding-left:35px;">
                <div>{{ row.order_id }}</div>
              </div> -->
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            cellClass="orders-list__buttons">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <button matRipple class="orders-list__expand" (click)="toggleExpandRow($event, row)"
                [class.mat-elevation-z2]="!expanded" [class.mat-elevation-z4]="expanded" [class.orders-list__expand_expanded]="expanded">
                <mat-icon [inline]="true" *ngIf="!expanded">unfold_more</mat-icon>
                <mat-icon [inline]="true" *ngIf="expanded">unfold_less</mat-icon>
              </button>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="#" [sortable]="false" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong>{{ row.order_id }}</strong>
            </ng-template>
          </ngx-datatable-column>

          <ng-container *ngIf="ctx.user.isStaff">
            <ngx-datatable-column name="Клиент" [sortable]="false" [flexGrow]="2">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <strong>{{ row.client?.fullName }}</strong>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ngx-datatable-column name="Заказ" [sortable]="false" [flexGrow]="3">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.name }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Услуги" [sortable]="false" [flexGrow]="5">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div fxLayout="row" fxLayoutGap="3px">
                <ng-container *ngFor="let service of row.services">
                  <span *ngIf="service.service_type.type != 'ConditionalRelease'" class="status mat-elevation-z2"
                      [ngClass]="service.service_status.class" matRipple (click)="openService($event, row, service)">
                    <mat-icon class="status__icon">{{ service.service_status.icon }}</mat-icon>
                    {{ service.service_type.title }}
                  </span>

                  <span *ngIf="service.service_type.type == 'ConditionalRelease' && service.decision" class="status mat-elevation-z2"
                      [ngClass]="service.decision.class" matRipple (click)="openService($event, row, service)">
                    <mat-icon class="status__icon">{{ service.decision.icon }}</mat-icon>
                    {{ service.service_type.title }}
                  </span>
                </ng-container>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ng-container *ngIf="ctx.user.isStaff || ctx.user.isBoss">
            <ngx-datatable-column name="Менеджер" [sortable]="false" [flexGrow]="2">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.manager?.fullName }}
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ngx-datatable-column
            [width]="70"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            cellClass="orders-list__buttons">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button mat-icon-button color="primary"><mat-icon>edit</mat-icon></button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </div>
    </ng-container>

    <ng-container *ngIf="!ctx.loading && !ctx.filterApplied && ctx.orders.length === 0">
      <div class="orders-list__empty">
        <ng-container *ngIf="!ctx.user.isStaff">
          У Вас пока нет ни одного заказа.<br>Что бы создать нажмите <a href="#" (click)="createOrder()">здесь</a>
        </ng-container>
        <ng-container *ngIf="ctx.user.isStaff">
          Вам пока не назначен ни один заказ
        </ng-container>
      </div>
    </ng-container>
    
  </div>
</ng-container>
<ng-container *ngIf="user$ | async as user">
  <div class="chat">
    <form [formGroup]="messageForm" #form="ngForm" (ngSubmit)="onSubmit()">
      <div class="chat__content" #chatContainer>
        <!-- <div class="chat__scroller" -->

        <ng-container *ngIf="!(loading$ | async) else loadingTpl">
          <ng-container *ngIf="messages$ | async as messages">
            <ng-container *ngIf="messages.length > 0; else emptyMessagesTpl">
              <virtual-scroller #scroll [items]="messages" [enableUnequalChildrenSizes]="true" class="chat__scroller">
                <div *ngFor="let message of scroll.viewPortItems; index as i; last as isLast; trackBy: trackByMessageId" class="message-box__wrapper">
                  <div class="message-box mat-elevation-z2" [ngClass]="{
                    'message-box_right': (message.is_client && !user.isStaff) || (!message.is_client && user.isStaff),
                    'message-box_left': (message.is_client && user.isStaff) || (!message.is_client && !user.isStaff)
                  }" [@flash]="newMessages.includes(message.id) ? 'pushed' : ''">
                    <div fxLayout="row" fxLayoutAlign="space-between" class="message-box__meta">
                      <div class="message-box__user">{{ message.username }}</div>
                      <div class="message-box__date">{{ message.date_creation | amLocale: 'ru' | amDateFormat: 'MMM D, HH:mm' }}</div>
                    </div>
                    <div class="message-box__message">{{ message.message }}</div>
                  </div>
                </div>
              </virtual-scroller>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #emptyMessagesTpl>
          <div class="chat__empty"><span>Отправьте сообщение для начала переписки</span></div>
        </ng-template>

        <ng-template #loadingTpl>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
      </div>

      <div class="chat__toolbar">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
          <mat-form-field  appearance="outline" fxFlex="grow">
            <mat-label>Сообщение</mat-label>
            <textarea rows="2" matInput placeholder="Введите сообщение"
              formControlName="message" name="message" autocomplete="off"
              (keydown)="checkInputSubmit($event)" style="resize: none;"
              #messageControl></textarea>
            <mat-hint>Отправить: crtl + enter</mat-hint>
            <mat-error *ngIf="f.message.dirty && f.message.invalid && f.message.errors.required"
                >Поле обязательно для заполнения</mat-error>
          </mat-form-field>

          <div fxFlex fxLayout="column" fxLayoutAlign="center">
            <button mat-raised-button color="primary" [disabled]="disabled$ | async"><mat-icon>send</mat-icon></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
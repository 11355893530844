import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StorageFolder, ReactiveComponent, UploadFile } from '../../shared';
import { FileManagerState, SelectFolder, CreateFolder, DeleteFolder, ChangeFolderName } from '../../shared/store';

@Component({
  selector: 'file-manager-folder-header',
  templateUrl: './folder-header.component.html',
  styleUrls: ['./folder-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderHeaderComponent extends ReactiveComponent {
  @Select(FileManagerState.selectedFolder)
  selectedFolder$: Observable<StorageFolder>;

  @Select(FileManagerState.foldersLoading)
  foldersLoading$: Observable<boolean>;

  @Input()
  editable: boolean = true;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
  }

  createFolder(evt: MouseEvent, folder: StorageFolder) {
    if (this.editable) {
      const title = prompt("Введите название новой папки", "Новая папка");

      if (title !== null && title.length > 0) {
        const selectedFolder = this.store.selectSnapshot(FileManagerState.selectedFolder);
        this.store.dispatch(new CreateFolder(title, selectedFolder ? selectedFolder.id : null));
      }
    }
  }

  selectFolderById(evt: MouseEvent, folderId: number | null) {
    this.store.dispatch(new SelectFolder(folderId));
  }
}

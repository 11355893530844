import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError, combineLatest } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { classToPlain, plainToClass } from 'class-transformer';
import { Moment } from 'moment';
import * as _ from 'lodash';

import { ApplyOrdersFilter, ChangeOrdersPage, LoadOrders, ResetOrdersFilter } from '../actions/orders.actions';
import { OrdersService } from '../../services';
import { ServiceType, Order, Status, OrderFilter, PagedResult } from '../../models';


export interface OrdersStateModel {
  loading: boolean;
  page: number;
  total: number;
  pageSize: number;
  orders: Order[];
  activeFilter: OrderFilter;
  filterForm: {
    model?: {
      service_type: ServiceType;
      status: Status;
      search: string;
      date_start: Moment;
      date_end: Moment;
    }
  };
}

@State<OrdersStateModel>({
  name: 'orders',
  defaults: {
    loading: false,
    page: 1,
    total: 0,
    pageSize: 0,
    orders: [],
    activeFilter: { } as OrderFilter,
    filterForm: {
      model: undefined
    }
  }
})
@Injectable()
export class OrdersState {
  constructor(
    private store: Store,
    private ordersSrv: OrdersService) {}

  @Selector([OrdersState])
  static total(state: OrdersStateModel): number {
    return state.total;
  }

  @Selector([OrdersState])
  static page(state: OrdersStateModel): number {
    return state.page;
  }

  @Selector([OrdersState])
  static orders(state: OrdersStateModel): Order[] {
    return state.orders;
  }

  @Selector([OrdersState])
  static loading(state: OrdersStateModel): boolean {
    return state.loading;
  }

  @Selector([OrdersState])
  static filterApplied(state: OrdersStateModel): boolean {
    return !_.isEmpty(state.activeFilter, true);
  }

  @Selector([OrdersState])
  static pageSize(state: OrdersStateModel): number {
    return state.pageSize;
  }


  @Action(ApplyOrdersFilter)
  @ImmutableContext()
  applyFilter(
    { setState, getState, dispatch }: StateContext<OrdersStateModel>,
    { }: ApplyOrdersFilter
  ) {
    
    setState((state: OrdersStateModel) => {
      const model = Object.assign({}, state.filterForm.model) as any;

      model['date_from'] = model['date_from'] ? model['date_from'].toDate() : null;
      model['date_to'] = model['date_to'] ? model['date_to'].toDate() : null;

      state.activeFilter = plainToClass(OrderFilter, model);
      state.page = 1;
      return state;
    });

    return dispatch(new LoadOrders());
  }

  @Action(LoadOrders)
  @ImmutableContext()
  loadOrders(
    { setState, getState, dispatch }: StateContext<OrdersStateModel>,
    { }: LoadOrders
  ) {
    const { page, activeFilter } = getState();

    setState((state: OrdersStateModel) => {
      state.loading = true;
      return state;
    });

    return this.ordersSrv.filter(activeFilter, page).pipe(
      map((response: PagedResult<Order>) => {
        return setState((state: OrdersStateModel) => {
          state.orders = response.results;
          state.total = response.count;
          state.page = response.page_number;
          state.pageSize = response.page_size;

          state.loading = false;
          return state;
        });
      }),
    );
  }

  @Action(ChangeOrdersPage)
  @ImmutableContext()
  ChangePage(
    { setState, getState, dispatch }: StateContext<OrdersStateModel>,
    { page }: ChangeOrdersPage
  ) {
    setState((state: OrdersStateModel) => {
      state.page = page;
      return state;
    });

    return dispatch(new LoadOrders());
  }

  @Action(ResetOrdersFilter)
  @ImmutableContext()
  resetFilter(
    { setState, getState, dispatch }: StateContext<OrdersStateModel>,
    { }: ResetOrdersFilter
  ) {
    setState((state: OrdersStateModel) => {
      state.filterForm = {};
      return state;
    });

    return dispatch([new ApplyOrdersFilter, new LoadOrders()]);
  }
}
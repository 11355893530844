<ng-container *ngIf="{
  serviceSaving: serviceSaving$ | async,
  serviceSaved: serviceSaved$ | async,
  serviceLoading: serviceLoading$ | async,
  service: service$ | async
} as ctx">
<div class="service">
  <div *ngIf="ctx.serviceLoading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

  <ng-container *ngIf="!ctx.serviceLoading">
    <div *ngIf="ctx.service">
      <nav mat-tab-nav-bar class="service__tabs">
        <a mat-tab-link
           [routerLink]="['details']"
           routerLinkActive #rlaDetails="routerLinkActive"
           [active]="rlaDetails.isActive">
          <mat-icon>{{ ctx.service.service_type.icon }}</mat-icon>&nbsp;{{ ctx.service.service_type.title }}
        </a>
        <a mat-tab-link
           [routerLink]="['documents']"
           routerLinkActive #rlaDocuments="routerLinkActive"
           [active]="rlaDocuments.isActive">
          <span [matBadge]="ctx.service.documents_count" matBadgeOverlap="false"
            [matBadgeColor]="ctx.service.documents_count ? 'accent' : 'warn'">Документы</span>
        </a>
        <a mat-tab-link
           [routerLink]="['notifications']"
           routerLinkActive #rlaNotifications="routerLinkActive"
           [active]="rlaNotifications.isActive">
          Статусы
        </a>
      </nav>

      <div class="service__container" id="scrollContainer" [class.service__container_compact]="!ctx.serviceSaved || ['DR', 'WA'].includes(ctx.service.service_status.id)">
        <router-outlet></router-outlet>
      </div>

      <ng-container>
        <div *ngIf="!ctx.serviceSaved || ['DR', 'WA'].includes(ctx.service.service_status.id)" class="service__buttons" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
          <button type="submit" [disabled]="ctx.serviceSaving || ctx.serviceSaved" mat-flat-button
            color="primary" (click)="saveService()">Сохранить</button>

          <button type="button" [disabled]="ctx.serviceSaving" *ngIf="ctx.service.service_status.id == 'DR'" (click)="sendToProcess()"
            mat-flat-button color="accent">Отправить в работу</button>

          <button type="submit" [disabled]="ctx.serviceSaving" *ngIf="ctx.service.service_status.id == 'WA'" (click)="sendToProcess()"
            mat-flat-button color="accent">Дослать документы</button>
        </div>
      </ng-container>

    </div>
  </ng-container>
</div>
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { NotificationsService } from './notifications.service';
import { OrderLog, CommonService, Service, getServiceCls, StorageFile } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrderServicesService {
  private prefix: string = '/orders';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient,
              private notifyService: NotificationsService) { }

  public all(orderId: number): Observable<CommonService[]> {
    return this.http
      .get(`${this.getPrefix(orderId)}/`)
      .pipe(map(res => plainToClass(CommonService, res as Object[])));
  }

  public save(orderId: number, serviceId: number, data: Service): Observable<Service> {
    const toSave = classToPlain(data);
    const resourcetype = data.service_type.value;

    return this.http
      .put(`${this.getPrefix(orderId)}/${serviceId}/`, Object.assign({ resourcetype }, toSave))
      .pipe(map(res => plainToClassFromExist(getServiceCls(resourcetype), res)));
  }

  public patch(orderId: number, service: Service, toUpdate: object): Observable<Service> {
    const resourcetype = service.service_type.value;
    return this.http
      .patch(`${this.getPrefix(orderId)}/${service.id}/`, Object.assign({ resourcetype }, toUpdate))
      .pipe(map(res => plainToClassFromExist(getServiceCls(resourcetype), res)));
  }

  public zip(orderId: number, serviceId: number): Observable<Blob> {
    return this.http.get(`${this.getPrefix(orderId)}/${serviceId}/zip/`, {
      responseType: "blob"
    });
  }

  public get(orderId: number, serviceId: number): Observable<CommonService> {
    return this.http.get(`${this.getPrefix(orderId)}/${serviceId}/`)
      .pipe(map(res => plainToClass(CommonService, res as Object)));
  }

  public statuses(orderId: number, serviceId: number): Observable<OrderLog[]> {
    return this.http.get(`${this.getPrefix(orderId)}/${serviceId}/notifications/`)
      .pipe(map(res => plainToClass(OrderLog, res as Object[])));
  }

  public documents(orderId: number, serviceId: number): Observable<StorageFile[]> {
    return this.http.get(`${this.getPrefix(orderId)}/${serviceId}/documents/`)
      .pipe(map(res => plainToClass(StorageFile, res as Object[])));
  }

  public touch(orderId: number, serviceId: number) {
    return this.http
      .put(`${this.getPrefix(orderId)}/${serviceId}/notifications/touch/`, {})
      .subscribe(() => this.notifyService.updateCount());
  }

  public getPrefix(orderId) {
    return `${this.apiUrl}${this.prefix}/${orderId}/services`
  }
}

<mat-drawer-container autosize class="managers">
  <div class="managers__content">
    <div class="managers-list">
      <div *ngIf="loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

      <div class="managers-list__table-tools">
        <button class="managers-list__create"
          (click)="managersDrawer.toggle()"
          mat-fab color="accent"><mat-icon>add</mat-icon></button>
      </div>

      <div *ngIf="!loading && managers?.length > 0" class="mat-elevation-z4">
        <mat-toolbar><mat-icon>people</mat-icon>&nbsp;Менеджеры</mat-toolbar>

        <table mat-table [dataSource]="managers" class="managers-list__table managers-table">
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef width="100">Логин</mat-header-cell>
            <mat-cell *matCellDef="let manager"> {{ manager.username }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
            <mat-cell *matCellDef="let manager"> {{ manager.email }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fio">
            <mat-header-cell *matHeaderCellDef>ФИО</mat-header-cell>
            <mat-cell *matCellDef="let manager"> {{ manager.fullName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef>Телефон</mat-header-cell>
            <mat-cell *matCellDef="let manager"> {{ manager.phone }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
      </div>

      <div class="managers-list__empty" *ngIf="!loading && !managers?.length">
        У Вас пока нет ни одного менеджера.<br>Что бы создать нажмите <a href="#" (click)="openManagerDialog($event)">здесь</a>
      </div>
      
    </div>
  </div>

  <mat-drawer #managersDrawer mode="push" position="start" class="managers__sidebar">
    <tamo-managers-details (managerChange)="onManagerChanged($event)"></tamo-managers-details>
  </mat-drawer>
</mat-drawer-container>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DadataOrg } from '../../../dadata';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.less']
})
export class OrganizationComponent implements OnInit {
  @Input() form: FormGroup;

  get f() { return this.form.controls; }

  constructor() { }

  ngOnInit() {

  }

  public onSelectionChanged(org: DadataOrg) {
    this.form.patchValue({
      inn: org.data.inn,
      kpp: org.data.kpp,
      ogrn: org.data.ogrn,
      org_name: org.data.name.short_with_opf,

      director_position: org.data.management ? org.data.management.post : '',
      director_fio: org.data.management ? org.data.management.name : '',

      legal_address: org.data.address ? org.data.address.value : '',
      physical_address: org.data.address ? org.data.address.value : '',
      correspondence_address: org.data.address ? org.data.address.value: ''
    });
  }

}

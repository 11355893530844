import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';

import { IframeCalculatorComponent } from './iframe-calculator.component';
import { ShippingCalculatorModule }  from '../shipping-calculator';


@NgModule({
  declarations: [
    IframeCalculatorComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatFormFieldModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ShippingCalculatorModule
  ],
  exports: [
    IframeCalculatorComponent
  ]
})
export class IframeCalculatorModule { }

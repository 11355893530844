import { Message, OrderLog, ServiceChanges } from '../../models';

export class AddChatMessage {
  static readonly type = '[Socket] AddChatMessage';

  constructor(public data: Message) {}
}

export class ChangeServiceStatus {
  static readonly type = '[Socket] ChangeServiceStatus';

  constructor(public data: OrderLog) {}
}

export class UpdateServiceData {
  static readonly type = '[Socket] UpdateServiceData';

  constructor(public data: ServiceChanges) {}
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'http://lk.vedclub.ru/api',
  media: 'http://lk.vedclub.ru',
  // api: 'http://192.168.0.103:8000/api',
  // media: 'http://192.168.0.103:8000',
  dadataToken: '5fbc4fe3e49ba9b566cd21c38cf890d3cbb3e857',
  yandexToken: 'cf3b7202-6d08-4ee9-97a6-36212e8c101b',
  socket: 'http://lk.vedclub.ru/notify',
  domains: ['lk.vedclub.ru']
  // api: 'http://192.168.0.40:8000/api',
  // api: 'http://tamo2.prosto.co.uk:8082/api'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<form [formGroup]="resetForm" (ngSubmit)="onSubmit()" class="reset-password" *ngIf="!complete else completeMessage">
  <div fxLayout="column" fxLayoutGap="10px">
    <mat-form-field>
      <input matInput placeholder="Логин" formControlName="username">
      <mat-icon matSuffix>person</mat-icon>
      <mat-error *ngIf="f.username.invalid && f.username.errors.required">Поле обязательно для заполнения</mat-error>
      <mat-error *ngIf="f.username.invalid && f.username.errors.remote"><tamo-remote-errors controlName="username"></tamo-remote-errors></mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email" required>
      <mat-icon matSuffix>alternate_email</mat-icon>
      <mat-error *ngIf="f.email.invalid && f.email.errors.required">Поле обязательно для заполнения</mat-error>
      <mat-error *ngIf="f.email.invalid && f.email.errors.email">Введен некорректный email</mat-error>
      <mat-error *ngIf="f.email.invalid && f.email.errors.remote"><tamo-remote-errors controlName="email"></tamo-remote-errors></mat-error>
    </mat-form-field>

    <mat-error><tamo-remote-errors 
      controlName="non_field_errors"></tamo-remote-errors></mat-error>

    <div fxLayoutAlign="center">
      <button fxFlex="100%" mat-raised-button type="submit" [disabled]="resetForm.disabled"
        color="warn">Сбросить пароль</button>
    </div>
  </div>
</form>

<ng-template #completeMessage>
  <strong>Письмо с новым паролем отправлено на Вашу почту.</strong><br><br>
  Если письмо не отображается в почтовом ящике, проверьте спам.
</ng-template>
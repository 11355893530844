<form [formGroup]="registerForm" class="register" (ngSubmit)="onSubmit()">
    <div class="register__group">
      <mat-form-field class="register__field" fxFlex>
        <input matInput formControlName="last_name" placeholder="Фамилия">
      </mat-form-field>
    </div>

    <div class="register__group" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
      <mat-form-field class="register__field register__field_half" fxFlex>
        <input matInput formControlName="first_name" placeholder="Имя">
      </mat-form-field>

      <mat-form-field class="register__field register__field_half" fxFlex>
        <input matInput formControlName="middle_name" placeholder="Отчество">
      </mat-form-field>
    </div>

    <div class="register__group">
      <mat-form-field class="register__field">
        <input matInput placeholder="Логин" formControlName="username"
           autocomplete="new-password" required>
        <mat-icon matSuffix>person</mat-icon>
        <mat-hint>Идентификатор для входа в систему</mat-hint>
        <mat-error *ngIf="f.username.invalid && f.username.errors.required">Поле обязательно для заполнения</mat-error>
        <mat-error *ngIf="f.username.invalid && f.username.errors.remote"><tamo-remote-errors controlName="username"></tamo-remote-errors></mat-error>
      </mat-form-field>
    </div>

    <div class="register__group">
      <mat-form-field class="register__field">
        <input matInput type="password" formControlName="password"
           autocomplete="new-password" placeholder="Пароль" required>
        <mat-error *ngIf="f.password.invalid && f.password.errors.required">Поле обязательно для заполнения</mat-error>
        <mat-error *ngIf="f.password.invalid && f.password.errors.remote"><tamo-remote-errors controlName="password"></tamo-remote-errors></mat-error>
        <mat-icon matSuffix>lock</mat-icon>
      </mat-form-field>
    </div>

    <div class="register__group">
      <mat-form-field class="register__field">
        <input matInput placeholder="Email" formControlName="email" required>
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-error *ngIf="f.email.invalid && f.email.errors.required">Поле обязательно для заполнения</mat-error>
        <mat-error *ngIf="f.email.invalid && f.email.errors.email">Введен некорректный email</mat-error>
        <mat-error *ngIf="f.email.invalid && f.email.errors.remote"><tamo-remote-errors controlName="email"></tamo-remote-errors></mat-error>
      </mat-form-field>
    </div>

    <div class="register__group">
      <mat-form-field class="register__field">
        <input type="tel" matInput formControlName="phone" placeholder="Телефон" required>
        <mat-icon matSuffix>phone</mat-icon>
        <span matPrefix>+7&nbsp;</span>
        <mat-error *ngIf="f.phone.invalid && f.phone.errors.required">Поле обязательно для заполнения</mat-error>
        <mat-error *ngIf="f.phone.invalid && f.phone.errors.remote"><tamo-remote-errors controlName="phone"></tamo-remote-errors></mat-error>
      </mat-form-field>
    </div>

    <div class="register__group" [hidden]="!valid">
      <mat-card>
        <tamo-sms-confirmation
          [disabled]="checking"
          (onConfirm)="onSmsConfirmed($event)"
          (onCancel)="onSmsCanceled()"
          #smsConfirmation
          [phone]="registrationData?.phone"></tamo-sms-confirmation>
      </mat-card>
    </div>

    <div class="register__group">
        <mat-error><tamo-remote-errors
          controlName="non_field_errors"></tamo-remote-errors></mat-error>
    </div>

    <button mat-raised-button type="submit" *ngIf="!valid"
      class="register__submit" color="accent" [disabled]="loading">Регистрация</button>
</form>
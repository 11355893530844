import { Expose, Type } from "class-transformer";

import { DocumentOwner } from "./document-owner";

export class Profile implements DocumentOwner {
  first_name: string;
  last_name: string;
  middle_name: string;
  organization: string;
  email: string;
  phone: string;
  org_type: string;
  main_streams: number[];
  activity_kind: number;

  // inn: string;
  // org_name: string;
  // ogrn: string;
  // kpp: string;
  // legal_address: string;
  // physical_address: string;
  // correspondence_address: string;
  // director_position: string;
  // director_fio: string;
  note: string;
  questinary: boolean;

  notify_status: boolean;
  notify_message: boolean;
  notify_complete: boolean;

  contract: string;

  registration_completed: boolean;

  account_id: number;

  @Expose()
  getUploadPrefix() {
    return `/accounts/${this.account_id}/`;
  }

  @Expose()
  getCounts(type: string) {
    return null;
  }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { BookingData } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private prefix: string = '/booking/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public booking(bookingData: BookingData): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(bookingData));
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Transport } from '../models/transport';

@Injectable({
  providedIn: 'root'
})
export class TransportService {
  private prefix: string = '/transport/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(): Observable<Transport[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}`)
      .pipe(map(res => plainToClass(Transport, res as Object[])));
  }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { StorageFile } from '../models/file-manager';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(prefix: string): Observable<StorageFile[]> {
    return this.http.get(`${this.apiUrl}${prefix}`)
      .pipe(map(res => plainToClass(StorageFile, res as Object[])));
  }

  public byType(prefix: string, type: string): Observable<StorageFile[]> {
    return this.http.get(`${this.apiUrl}${prefix}${type}/`)
      .pipe(map(res => plainToClass(StorageFile, res as Object[])));
  }

  public remove(prefix: string, type: string, id: number) {
    return this.http.delete(`${this.apiUrl}${prefix}${type}/${id}/`);
  }
}
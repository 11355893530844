import { OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// TODO: Add Angular decorator.
@Directive()
export class BaseComponent implements OnDestroy {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  protected takeUntilDestroy() {
    return takeUntil(this.ngUnsubscribe$);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

<div #calculatorWrapper class="calculator calculator-theme" ngClass.lt-md="calculator_md" ngClass.lt-xs="calculator_xs">
  <ng-container *ngIf="!sendComplete; else emailSent">
    <mat-horizontal-stepper #stepper>
      <mat-step class="calculator__step">
        <ng-template matStepLabel>Калькулятор</ng-template>

        <h3 fxLayout="row" fxLayoutGap="10px" style="margin-top: 0" fxLayoutAlign="flex-start center">
          <mat-icon fontSet="fa" fontIcon="fa-calculator" [inline]="true"></mat-icon>
          <span>Расчет стоимости перевозки</span>
        </h3>

        <div fxLayout="column" fxLayoutGap="20px">
          <tamo-shipping-calculator (formFilled)="onFormFilled($event)"></tamo-shipping-calculator>

          <mat-card fxFlex>
            <button type="button" (click)="gotoEmail()" [disabled]="disabled$ | async"
              mat-raised-button color="accent">Далее</button>
          </mat-card>
        </div>
      </mat-step>

      <mat-step class="calculator__step">
        <ng-template matStepLabel>Отправка данных</ng-template>

        <form [formGroup]="sendForm" (ngSubmit)="sendData($event)">
          <div fxLayout="row" fxLayoutAlign="center">
            <mat-card fxFlex="380px" fxFlex.lt-md="90%">
              <div fxLayout="column" fxLayoutGap="5px">
                <mat-form-field>
                  <input matInput placeholder="Имя" formControlName="name"
                     autocomplete="new-password" required>
                  <mat-icon matSuffix>person</mat-icon>
                  <mat-error *ngIf="f.name.invalid && f.name.errors.required">Поле обязательно для заполнения</mat-error>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="Email" formControlName="email" required>
                  <mat-icon matSuffix>alternate_email</mat-icon>
                  <mat-error *ngIf="f.email.invalid && f.email.errors.required">Поле обязательно для заполнения</mat-error>
                  <mat-error *ngIf="f.email.invalid && f.email.errors.email">Введен некорректный email</mat-error>
                </mat-form-field>

                <mat-form-field>
                  <input type="tel" matInput formControlName="phone" placeholder="Телефон" required>
                  <mat-icon matSuffix>phone</mat-icon>
                  <span matPrefix>+7&nbsp;</span>
                  <mat-error *ngIf="f.phone.invalid && f.phone.errors.required">Поле обязательно для заполнения</mat-error>
                </mat-form-field>

                <div>
                  <button type="submit" mat-raised-button color="accent">Отправить</button>
                </div>
              </div>
            
            </mat-card>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>

  <ng-template #emailSent>
    <div style="padding: 20px; text-align: center;">
      <mat-card style="color: green">Данные успешно отправлены. С вами свяжутся в ближайшее время для уточнения заказа.</mat-card>
    </div>
  </ng-template>
</div>
import {
  Component
} from '@angular/core';
import { map } from 'rxjs/operators';

import { Hint } from '../shared/models/hint';
import { RemoteHintService } from './remote-hint.service';
import { HintsService } from '../shared/services/hints.service';

declare var jsPanel: any;

@Component({
  selector: 'tamo-hint-content',
  templateUrl: './remote-hint.component.html',
  styleUrls: ['./remote-hint.component.less']
})
export class RemoteHintComponent {
  public loading: boolean = false;
  
  public hint: Hint;

  constructor(private hintService: RemoteHintService,
              private dataService: HintsService) {
    // hintService.hintRequested$.subscribe(slug => {
    //   // this.loading = true;
    //   this.dataService.get(slug)
    //     .subscribe((hint: Hint) => {
    //       panel.content.innerHTML = '<div class="hint-content">' + hint.text + '</div>';
    //       panel.headertitle.innerHTML = hint.title;
    //       panel.resize('800 400');
    //       // this.loading = false;
    //     });
    //   var panel = jsPanel.create({
    //     position: 'center-top',
    //     contentSize: "800 100",
    //     maximizedMargin: 10,
    //     headerTitle: "Загрузка...",
    //     content: `<div class="hint-content"><mat-progress-bar aria-valuemax="100" aria-valuemin="0" class="mat-progress-bar mat-primary" mode="indeterminate" role="progressbar" aria-valuenow="0"><svg class="mat-progress-bar-background mat-progress-bar-element" focusable="false" height="5" width="100%"><defs><pattern height="5" patternUnits="userSpaceOnUse" width="10" x="5" y="0" id="mat-progress-bar-1"><circle cx="2.5" cy="2.5" r="2.5"></circle></pattern></defs><rect height="100%" width="100%" fill="url('/components/progress-bar/overview#mat-progress-bar-1')"></rect></svg><div class="mat-progress-bar-buffer mat-progress-bar-element"></div><div class="mat-progress-bar-primary mat-progress-bar-fill mat-progress-bar-element" style="transform: scaleX(0);"></div><div class="mat-progress-bar-secondary mat-progress-bar-fill mat-progress-bar-element"></div></mat-progress-bar></div>`,
    //     theme:       "black"
    //   });
    // });
  }
}
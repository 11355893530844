import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { plainToClass } from "class-transformer";
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, zip, of, combineLatest, BehaviorSubject } from 'rxjs';
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first
} from 'rxjs/operators';
import { ServiceType } from '../../shared/models/service-type';
import { serviceStatuses } from '../../shared';

import {
  Duty,
  DutyService,
  ShippingService,
  ShippingPointsService,
  Shipping,
  Manager,
  OrdersService,
  Order,
  ReactiveComponent
} from '../../shared';
import { ManagersPickerComponent } from '../../managers';

@Component({
  selector: 'tamo-shipping-calculator',
  templateUrl: './shipping-calculator.component.html',
  styleUrls: ['./shipping-calculator.component.less']
})
export class ShippingCalculatorComponent extends ReactiveComponent implements OnInit {
  readonly ServiceType: typeof ServiceType = ServiceType;

  public shippingForm: FormGroup;

  public duty: Duty;

  public dutyLoading: boolean = true;

  private savingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public saving$: Observable<boolean> = this.savingSource$.asObservable();

  private selectedServiceTypeSource$: BehaviorSubject<ServiceType> = new BehaviorSubject(null);
  public selectedServiceType$ = this.selectedServiceTypeSource$.asObservable();

  @Output()
  public shippingSave: EventEmitter<Shipping> = new EventEmitter();

  @Output()
  public formFilled: EventEmitter<boolean> = new EventEmitter();

  @Input('shippingType')
  set shippingType(value: ServiceType) {
    this.selectedServiceTypeSource$.next(value);
  }

  readonly DEFAULT_FORM_VALUE = {
    departure_place: '',
    arrival_place: '',
    delivery_address: '',
    delivery_local: false,
    cargo_weight: '',
    cargo_size_weight: null,
    cargo_size: '',
    cargo_length: '',
    cargo_height: '',
    cargo_width: '',
    cargo_type: '',
    container_type: '',
    duration: null,
    distance: null
  };

  constructor(
      private fb: FormBuilder,
      private dutyService: DutyService) {
    super();

    this.shippingForm = this.fb.group({
      duty: [''],
      destination_city: [''],

      direction: [{ value: 'I', disabled: false }],
      shipping_type: [''],
      product_cost: [null],
      product_cost_currency: [null],
      product_tax: [''],

      departure_place: [''],
      arrival_place: [''],
      delivery_address: [''],
      delivery_local: [false],
      cargo_weight: [''],
      cargo_size_weight: [{ value: null, disabled: true }],
      cargo_size: [''],
      cargo_length: [''],
      cargo_height: [''],
      cargo_width: [''],
      cargo_type: [''],
      container_type: [''],
      duration: [null],
      distance: [null],
      resourcetype: [null]
    });
  }

  ngOnInit() {
    this.selectedServiceType$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((serviceType: ServiceType) => {
      this.shippingForm.patchValue(Object.assign({
        resourcetype: serviceType ? serviceType.value : null
      }, this.DEFAULT_FORM_VALUE));
    });
  }

  selectRoute(serviceType: ServiceType) {
    this.selectedServiceTypeSource$.next(serviceType);
  }

  onSubmit() {
    const toSave = plainToClass(Shipping, this.shippingForm.value as Object);
    this.shippingSave.emit(toSave);
  }

  onDutySelected(duty: Duty) {
    this.shippingForm.patchValue({ "duty": duty ? duty.code : null });
    this.duty = duty;
  }

  onFormFilled(formFilled: boolean) {
    // console.log(formFilled);
    this.formFilled.emit(formFilled);
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deserialize, plainToClass } from 'class-transformer';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReactiveComponent } from '../../shared/components';
import { StorageFolder, StorageFile, UploadFile } from '../../shared/models';
import { GlobalUploaderService } from '../../shared/services';
import { FileManagerState, SelectFolder, CreateFolder, DeleteFolder } from '../../shared/store';

@Component({
  selector: 'tamo-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileChooserComponent extends ReactiveComponent {
  @Select(FileManagerState.selectedFiles)
  selectedFiles$: Observable<StorageFile[]>;
  
  constructor(
      private store: Store,
      public dialogRef: MatDialogRef<FileChooserComponent>) {
    super();
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectFiles() {
    this.dialogRef.close(
      this.store.selectSnapshot(FileManagerState.selectedFiles));
  }
}

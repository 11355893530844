import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  AfterViewInit
} from '@angular/core';

import { RemoteHintService } from './remote-hint.service';

@Directive({
  selector: '[tamoRemoteHint]'
})
export class RemoteHintDirective {
  private listenerFn: () => void;

  @Input('tamoRemoteHint')
  public slug: string; 

  @Input('tamoRemoteHintEvent')
  public evt: string = 'click';

  constructor(private elRef: ElementRef,
              private hintService: RemoteHintService,
              private renderer: Renderer2) {
      
  }

  ngAfterViewInit() {
    this.listenerFn = this.renderer.listen(this.elRef.nativeElement, this.evt, () => {
      this.hintService.open(this.slug)
      return false;
    });
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }
}

<ng-container *ngIf="{
  loading: loading$ | async,
  documents: documents$ | async,
  expended: expended$ | async,
  selecting: selecting$ | async,
  deleting: deleting$ | async,
  uploadingFiles: uploadingFiles$ | async,
  fileOver: fileOver$ | async
} as ctx">
  <ng-container *ngIf="!disabled">
    <mat-expansion-panel class="uploader mat-elevation-z3"
        ngfDrop [files]="files" (filesChange)="uploadFiles($event)"
        [expanded]="ctx.expended"
        [ngClass]="{'uploader_over': ctx.fileOver, 'mat-elevation-z10': ctx.fileOver}"
        (validDragChange)="onValidDrag($event)"
        (closed)="collapse()"
        (opened)="loadFiles()">
      <mat-expansion-panel-header matBadgePosition="above before"
          [matBadge]="counts"
          [matBadgeColor]="counts == '-' ? 'primary' : (!counts ? 'warn' : 'accent')">
        <mat-panel-title>{{ title }}</mat-panel-title>
        <mat-panel-description>
          <mat-chip-list class="uploader__button">
            <mat-chip color="accent" ngfSelect [files]="files" (filesChange)="uploadFiles($event)" multiple="1">С компьютера</mat-chip>
            <mat-chip color="accent" *ngIf="fileManager"
              (click)="openManager($event)">Из кабинета&nbsp;<mat-spinner *ngIf="ctx.selecting" color="accent" diameter="15"></mat-spinner></mat-chip>
          </mat-chip-list>
          <mat-icon>cloud_upload</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item *ngFor="let item of ctx.uploadingFiles; trackBy: trackByFileId">
          <mat-icon mat-list-icon fontSet="fa"
            [fontIcon]="item.content_type.icon" [style.color]="item.content_type.color"></mat-icon>
          <h4 mat-line>{{ item.title }}</h4>
          <!-- <p mat-line nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</p> -->

          <mat-progress-spinner diameter="16"
            class="uploader__spinner"
            mode="determinate"
            [value]="item.progress"></mat-progress-spinner>
          <button type="button" mat-icon-button color="warn"
            (click)="cancelFile(item)"><mat-icon>close</mat-icon></button>
        </mat-list-item>

        <h3 mat-subheader>Загруженные файлы</h3>
        <mat-list-item *ngFor="let item of ctx.documents">
          <mat-icon mat-list-icon fontSet="fa"
            [fontIcon]="item.content_type.icon" [style.color]="item.content_type.color"></mat-icon>
          <h4 mat-line><a class="uploader__link" [tamoFileDownloader]="item.file" target="_blank">{{ item.title }}</a></h4>
          <p mat-line nowrap class="uploader__date">{{ item.modified | amDateFormat: 'DD.MM.YY HH:mm' }}</p>
          <button type="button" mat-icon-button color="warn" [disabled]="ctx.deleting" (click)="removeDocument(item)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
      <mat-progress-bar *ngIf="ctx.loading" mode="indeterminate"></mat-progress-bar>
    </mat-expansion-panel>
  </ng-container>

  <ng-container *ngIf="disabled">
    <mat-expansion-panel class="uploader mat-elevation-z3" (opened)="loadFiles()">
      <mat-expansion-panel-header matBadgePosition="above before"
          [matBadge]="counts"
          [matBadgeColor]="counts == '-' ? 'primary' : (!counts ? 'warn' : 'accent')">
        <mat-panel-title>{{ title }}</mat-panel-title>
        <mat-panel-description *ngIf="!disabled">
          <mat-chip-list class="uploader__button"><mat-chip
            color="accent" [disabled]="true">Загрузка недоступна</mat-chip></mat-chip-list>
          <mat-icon>cloud_upload</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <h3 mat-subheader>Загруженные файлы</h3>
        <mat-list-item *ngFor="let item of ctx.documents">
          <!-- <mat-icon >note</mat-icon> -->
          <mat-icon mat-list-icon fontSet="fa"
            [fontIcon]="item.content_type.icon" [style.color]="item.content_type.color"></mat-icon>
          <h4 mat-line><a class="uploader__link" [tamoFileDownloader]="item.file" target="_blank">{{ item.title }}</a></h4>
          <p mat-line nowrap class="uploader__date">{{ item.modified | amDateFormat: 'DD.MM.YY HH:mm' }}</p>
        </mat-list-item>
      </mat-list>
      <mat-progress-bar *ngIf="ctx.loading" mode="indeterminate"></mat-progress-bar>
    </mat-expansion-panel>
  </ng-container>
</ng-container>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { first, finalize, shareReplay, map } from 'rxjs/operators';
import { plainToClass } from "class-transformer";
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FormatErrorsPipe } from '../../shared/pipes';
import { ProfileService, AuthService } from '../../shared/services';
import { Profile, Importer, User } from '../../shared/models';
import { RemoteErrorsService } from '../../remote-errors/remote-errors.service';
import { ProfileImportersComponent } from '../profile-importers/profile-importers.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less'],
  providers: [RemoteErrorsService, FormatErrorsPipe]
})
export class ProfileComponent implements OnInit {
  public profileForm: FormGroup;
  public organizationFrom: FormGroup;
  public passwordForm: FormGroup;
  public loading: boolean = true;
  public passwordSaving: boolean = false;
  public profileSaving: boolean = false;
  public orgSaving: boolean = false;
  public importersOpened: boolean = false;
  public profile: Profile;
  public editImporter: Importer;

  @ViewChild('importersDrawer', { static: true })
  private importersDrawer: MatDrawer;

  @ViewChild(ProfileImportersComponent, { static: true })
  private importersList: ProfileImportersComponent;

  profile$: Observable<Profile>;

  uplaodPrefix$: Observable<string>;

  get f() { return this.profileForm.controls; }

  get pwdForm() { return this.passwordForm.controls }

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private profileService: ProfileService,
              private formatErrors: FormatErrorsPipe,
              private errSrv: RemoteErrorsService,
              private snackBar: MatSnackBar,
              private authService: AuthService) { }

  ngOnInit() {
    this.profile$ = this.profileService.get();

    this.profile$.subscribe(profile => {
      this.loading = false;
      this.profile = profile;
      this.profileForm.patchValue(profile);
      this.organizationFrom.patchValue(profile);
    });

    this.uplaodPrefix$ = this.profile$.pipe(
      map((profile: Profile) => `/accounts/${profile.account_id}/`)
    );

    this.profileForm = this.formBuilder.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      middle_name: [''],
      company: [''],
      phone: [{value:'', disabled: true}],
      email: [{value:'', disabled: true}],
      city: [''],
      notify_status: [false],
      notify_message: [false],
      notify_complete: [false]
    });

    this.organizationFrom = this.formBuilder.group({
      // Organization
      inn: [''],
      org_name: [''],
      ogrn: [''],
      kpp: [''],
      legal_address: [''],
      physical_address: [''],
      correspondence_address: [''],
      director_position: [''],
      director_fio: ['']
    });

    this.passwordForm = this.formBuilder.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    });
  }

  saveProfile() {
    if (this.profileForm.invalid) {
      return;
    }
    const profile = plainToClass(Profile,
      this.profileForm.value as Object);

    this.profileSaving = true;
    this.profileService.save(profile).pipe(
      finalize(() => { this.profileSaving = false; })
    ).subscribe(
      () => {
        this.snackBar.open('Профиль успешно сохранен', 'закрыть');
      },
      this.errSrv.handleErrors(this.profileForm)
    );
  }

  saveOrganization() {
    if (this.organizationFrom.invalid) {
      return;
    }

    this.orgSaving = true;
    const organization = plainToClass(Profile,
      this.organizationFrom.value as Object);

    this.profileService.save(organization).pipe(
      finalize(() => { this.orgSaving = false; })
    ).subscribe(
      () => {
        this.snackBar.open('Организация успешно сохранена', 'закрыть');
      },
      this.errSrv.handleErrors(this.profileForm)
    );
  }

  changePassword() {
    const data = this.passwordForm.value;
    if(data.new_password != data.confirm_password) {
      this.passwordForm.get('confirm_password').setErrors({ match : true })
    }

    if (this.passwordForm.invalid) {
      return;
    }

    this.passwordSaving = true;
    this.profileService.changePassword(
      data.old_password, data.new_password
    ).pipe(
      finalize(() => this.passwordSaving = false)
    ).subscribe(
      () => {
        this.snackBar.open('Новый пароль успешно сохранен', 'закрыть');
        this.passwordForm.reset();
        Object.keys(this.passwordForm.controls).forEach(key => {
          this.passwordForm.controls[key].setErrors(null)
        });
      },
      this.errSrv.handleErrors(this.passwordForm)
    );
  }

  onCreateImporeter() {
    this.editImporter = new Importer();
    this.importersDrawer.open();
  }

  onEditImporeter(importer: Importer) {
    this.editImporter = importer;
    this.importersDrawer.open();
  }

  onImporterChanged(importer: Importer) {
    this.importersList.triggerUpdate();
  }
}

import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import * as shortid from 'shortid';

import { ReactiveComponent } from '../../shared/components';
import { RangeSelectionEvent } from './range-selection.event';

declare var Selection: any;

@Directive({
  selector: '[rangeSelection]'
})
export class RangeSelectionDirective extends ReactiveComponent implements OnInit, OnDestroy {
  private selection: any;

  private selectionClass: string;

  private id: string;

  private _enabled: boolean = true;
  @Input('rangeSelection')
  set enabled(enabled: boolean) {
    this._enabled = enabled;
    this.toggleEnabled();
  }
  get enabled(): boolean {
    return this._enabled;
  }

  @Output()
  selectionStart: EventEmitter<RangeSelectionEvent> = new EventEmitter();

  @Output()
  selectionStop: EventEmitter<RangeSelectionEvent> = new EventEmitter();

  @Output()
  selectionMove: EventEmitter<RangeSelectionEvent> = new EventEmitter();

  constructor(private el: ElementRef,
              private ngZone: NgZone) {
    super();
    this.id = `rs_${shortid.generate()}`;
    this.el.nativeElement.dataset.rangeSelection = this.id;
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.selection = new Selection({
        startareas: [`[data-range-selection=${this.id}]`],
        boundaries: [`[data-range-selection=${this.id}]`],
        singleClick: false,
        selectables: ['[data-selectable]'],
        scrollSpeedDivider: 10
      });

      this.selection.on('beforestart', ({ oe }) => {
        return !oe.target.hasAttribute('data-selection-disabled') &&
          ((oe.type.indexOf('touch') === -1 && oe.which === 1) || oe.type.indexOf('touch') >= 0);
      });
      this.selection.on('start', ({ selected: selectedElements, oe: originalEvent, area }) => {
        area.parentElement.style.zIndex = 1001;
        this.selectionStart.emit({ selectedElements, originalEvent })
      });
      this.selection.on('stop', ({ selected: selectedElements, oe: originalEvent }) => {
        this.selectionStop.emit({ selectedElements, originalEvent })
      });
      this.selection.on('move', ({ selected: selectedElements, oe: originalEvent }) => {
        this.selectionMove.emit({ selectedElements, originalEvent })
      });
    });

    this.toggleEnabled();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.selection) {
      this.selection.clearSelection();
      this.selection.destroy();
    }
  }

  private toggleEnabled() {
    if (this.selection) {
      if (this.enabled) {
        this.selection.enable();
      } else {
        this.selection.cancel();
        this.selection.disable();
      }
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ServiceType } from '../../shared';

@Component({
  selector: 'app-create-service-dialog',
  templateUrl: './create-service-dialog.component.html',
  styleUrls: ['./create-service-dialog.component.less']
})
export class CreateServiceDialogComponent implements OnInit {
  serviceName: string = null;

  constructor(
    public dialogRef: MatDialogRef<CreateServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public serviceType: ServiceType) {

  }

  ngOnInit() {

  }

  onNoClick() {
    this.dialogRef.close(null);
  }
}

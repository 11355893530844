<div [formGroup]="form" fxLayout="column" fxLayoutGap="20px" class="shipping-avia">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxFlex>
      <h4 class="shipping-form__title">Доставка по воздуху</h4>

      <div>
        <div fxLayout fxLayoutGap="10px" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
          <mat-form-field class="shipping-avia__place-field">
            <input matInput
              #departureListTrigger="matAutocompleteTrigger"
              formControlName="departure_place" [matAutocomplete]="departureList">

            <mat-label><mat-icon fontSet="fa" fontIcon="fa-plane-departure"></mat-icon> Аэропорт загрузки</mat-label>
            <mat-hint>Доступно <strong>{{ availableDeparturesCount$ | async }}</strong></mat-hint>
            <mat-hint align="end"><mat-spinner *ngIf="departuresLoading$ | async" diameter="13"></mat-spinner></mat-hint>

            <button matSuffix mat-button *ngIf="f.departure_place.value" mat-icon-button
                [disabled]="departuresLoading$ | async" aria-label="Очистить"
                (click)="clearAutocomplete(departureListTrigger, f.departure_place)">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #departureList="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let place of filteredDeparturePlaces$ | async" [value]="place">
                {{ place.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-icon fxHide.lt-md>arrow_forward</mat-icon>
        
          <mat-form-field class="shipping-avia__place-field">
            <input matInput
              #arrivalListTrigger="matAutocompleteTrigger"
              formControlName="arrival_place" [matAutocomplete]="arrivalList">
            <mat-label><mat-icon fontSet="fa" fontIcon="fa-plane-arrival"></mat-icon> Аэропорт выгрузки</mat-label>
            <mat-hint>Доступно <strong>{{ availableArrivalsCount$ | async }}</strong></mat-hint>
            <mat-hint align="end"><mat-spinner *ngIf="arrivalsLoading$ | async" diameter="13"></mat-spinner></mat-hint>

            <button matSuffix mat-button *ngIf="f.arrival_place.value" mat-icon-button
                [disabled]="arrivalsLoading$ | async" aria-label="Очистить"
                (click)="clearAutocomplete(arrivalListTrigger, f.arrival_place)">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #arrivalList="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let place of filteredArrivalPlaces$ | async" [value]="place">
                {{ place.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>


    <ng-container *ngIf="placesSelected$ | async">
      <h4 class="shipping-form__title">Данные по грузу</h4>

      <div fxLayout="row">
        <div fxFlex="100%">
          <div fxLayout="row" fxLayoutGap="10px"
              fxLayout.lt-md="column"
              fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field class="shipping-avia__size-field">
                <input matInput formControlName="cargo_length" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Длина</mat-label>
              </mat-form-field>

              <mat-form-field class="shipping-avia__size-field">
                <input matInput formControlName="cargo_width" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Ширина</mat-label>
              </mat-form-field>

              <mat-form-field class="shipping-avia__size-field">
                <input matInput formControlName="cargo_height" autocomplete="off">
                <span matSuffix>м</span>
                <mat-label>Высота</mat-label>
              </mat-form-field>
            </div>

            <mat-icon fontSet="fa" fontIcon="fa-equals" fxHide.lt-md></mat-icon>

            <mat-form-field class="shipping-avia__size-field">
              <input matInput formControlName="cargo_size" placeholder="Объем" autocomplete="off">
              <span matSuffix>м<sup>3</sup></span>
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-ruler-combined"></mat-icon> Объем</mat-label>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
              fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
            <mat-form-field class="shipping-avia__size-field">
              <input matInput formControlName="cargo_weight" autocomplete="off">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-weight-hanging"></mat-icon> Вес груза</mat-label>
              <span matSuffix>кг</span>
              <mat-hint align="start">{{ f.cargo_weight.value / 1000 | number }}</mat-hint>
              <mat-hint align="end">тонн</mat-hint>
            </mat-form-field>

            <mat-form-field class="shipping-avia__size-field">
              <input matInput formControlName="cargo_size_weight" autocomplete="off">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-weight-hanging"></mat-icon> Объемный вес</mat-label>
              <span matSuffix>кг</span>
              <mat-hint align="start">{{ f.cargo_size_weight.value / 1000 | number }}</mat-hint>
              <mat-hint align="end">тонн</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="mainFormFilled$ | async" fxLayout="row">
    <div fxFlex="nogrow" fxFlex.lt-lg="100%" class="shipping__result shipping-result" fxLayout="column" fxLayoutGap="10px">

      <ng-container *ngIf="!(tariffLoading$ | async) else tariffComputing">
        <ng-container *ngIf="(tariffs$ | async)?.length > 0 else tariffNotFound">
          <ng-container *ngIf="(tariffSettings$ | async) as tariffSettings">
            <ng-container *ngIf="(maxWeight$ | async) as maxWeight">
              <ng-container *ngFor="let tariff of tariffs$ | async">
                <tamo-shipping-result
                  [departurePlace]="(departurePlace$ | async)?.name" departureIcon="plane-departure"
                  [arrivalPlace]="(arrivalPlace$ | async)?.name" arrivalIcon="plane-arrival"
                  [cost]="tariff.cost * maxWeight + tariffSettings.add_cost" [currency]="tariff.cost_currency"
                  routeIcon="plane">
                  
                  <ng-template #resultInfoTemplate>
                    <span><mat-icon fontSet="fa" fontIcon="fa-clock" [inline]="true"></mat-icon> {{ tariff.duration }} дн.</span>
                    <span [style.color]="maxWeight < tariff.weight_from || maxWeight > tariff.weight_to ? 'red' : ''">
                      {{ tariff.weight_from }} <
                      <mat-icon fontSet="fa" fontIcon="fa-weight-hanging" [inline]="true"></mat-icon>&nbsp;<strong>{{ maxWeight }}</strong>
                      < {{ tariff.weight_to }} кг
                    </span>
                    <span [style.color]="f.cargo_size.value < tariff.size_from || f.cargo_size.value > tariff.size_to ? 'red' : ''">
                      {{ tariff.size_from }} <
                      <mat-icon fontSet="fa" fontIcon="fa-ruler-combined" [inline]="true"></mat-icon>&nbsp;<strong>{{ f.cargo_size.value }}</strong>
                      < {{ tariff.size_to }} м<sup>3</sup>
                    </span>
                  </ng-template>

                  <ng-template *ngIf="tariff.date_end || !isTarriffValid(tariff, maxWeight, f.cargo_size.value)" #resultNoteTemplate>
                    <ng-container *ngIf="!isTarriffValid(tariff, maxWeight, f.cargo_size.value)">
                      <font color="red">Параметры груза не подходят для тарифа</font>
                    </ng-container>

                    <ng-container *ngIf="tariff.date_end">
                      <span><strong>*</strong> Ставка действительна до <strong>{{ tariff.date_end | amLocal | amDateFormat: 'DD.MM.YYYY' }}</strong></span>
                    </ng-container>
                  </ng-template>
                </tamo-shipping-result>
              </ng-container><!-- Tariffs -->
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #tariffNotFound><div class="shipping-result__block shipping-result__block_error">Тариф не найден</div></ng-template>
      </ng-container><!-- Wrapper block -->
      <ng-template #tariffComputing><mat-progress-bar mode="indeterminate"></mat-progress-bar></ng-template>

    </div>
  </div>

</div>
<mat-card class="info__card">
  <h2>Здравствуйте, {{ profile.first_name }}!</h2>
  
  <p>
  	Это ваш личный кабинет "TAMO".<br>
    Чтобы завершить регистрацию и начать работать в системе:
  </p>

  <ul>
     <li>Заполните анкету.</li>
     <li>Загрузите документы.</li>
     <li>Ознакомьтесь с договором.</li>
  </ul>

  <p class="mat-body-strong">На данный момент сервис работает в тестовом режиме.</p>

  <p class="mat-body-strong">Для демо-доступа к системе вы можете пропустить заполнение анкеты и заполнить ее позднее.</p>
</mat-card>
import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, mergeMap, shareReplay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';

import {
  ConditionalRelease,
  ConditionalReleaseService,
  PagedResult,
  ReactiveComponent,
  User,
  AuthService,
  AuthUser
} from '../../shared';
import { AuthState } from '../../shared/store';

@Component({
  selector: 'app-conditional-release-list',
  templateUrl: './conditional-release-list.component.html',
  styleUrls: ['./conditional-release-list.component.less']
})
export class ConditionalReleaseListComponent extends ReactiveComponent {
  private listLoaderSource$: BehaviorSubject<number> =
    new BehaviorSubject(1);

  private listLoader$: Observable<number> =
    this.listLoaderSource$.asObservable();

  public loading: boolean = false;

  public services: PagedResult<ConditionalRelease>;

  public displayedColumns: string[] = [
    'id',
    'name',
    'buttons'
  ];

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  constructor(
      private store: Store,
      private dataService: ConditionalReleaseService,
      private router: Router,
      private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.loading = true;

    this.listLoader$.pipe(
      takeUntil(this.ngUnsubscribe$),
      mergeMap(page => this.dataService.all(page).pipe(first())),
      map(value => value as PagedResult<ConditionalRelease>)
    ).subscribe((services: PagedResult<ConditionalRelease>) => {
      this.services = services;
      this.loading = false;        
    });
  }

  public selectRow(evt) {
    const service = evt.selected[0];
    this.router.navigate([`app/orders/${service.order}/services/${service.id}`]);
  }

  public setPage(pageInfo) {
    this.listLoaderSource$.next(pageInfo.offset + 1);
  }

  public selectManager() {
    console.log('Select manager');
    return;
  }
}

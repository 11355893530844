import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, timer, Observable } from 'rxjs';
import { takeUntil, take, first, filter, distinctUntilChanged, map } from 'rxjs/operators';
import { SmsService } from '../shared/services/sms.service';
import { ToastrService } from 'ngx-toastr';

import { RemoteErrorsService } from '../remote-errors/remote-errors.service';

enum State {
  START = 'start',
  SENDING = 'sending',
  WAITING = 'waiting',
  READY = 'ready'
}

@Component({
  selector: 'tamo-sms-confirmation',
  templateUrl: './sms-confirmation.component.html',
  styleUrls: ['./sms-confirmation.component.less'],
  providers: [RemoteErrorsService]
})
export class SmsConfirmationComponent implements OnInit {
  readonly State: typeof State = State;
  readonly WAIT_TIMEOUT: number = 30;
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input() phone: string;

  private _disabled: boolean = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if(value) {
      this.codeCtrl.disable();
    } else {
      this.codeCtrl.enable();
    }
  }
  get disabled() {
    return this._disabled;
  }

  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  public timeout: number;
  public codeCtrl: FormControl = new FormControl({ value: '', disabled: true });
  public state: State = State.START;

  constructor(private smsService: SmsService,
              private toastr: ToastrService,
              private errSrv: RemoteErrorsService) { }

  ngOnInit() {
    this.codeCtrl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        map(val => val.trim()),
        filter(val => val.length == 4)
      )
      .subscribe((val) => {
        this.onConfirm.emit(val);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public cancel() {
    this.onCancel.emit();
  }

  public sendSms() {
    if(this.state == State.WAITING) {
      return;
    }

    this.state = State.SENDING;
    this.codeCtrl.setValue('');
    this.codeCtrl.disable();

    this.smsService.sendSms(this.phone)
      .pipe(first())
      .subscribe(() => {
        this.state = State.WAITING;
        this.toastr.info('SMS с кодом отправлена на ваш номер телефона', 'SMS отправлена!');
        timer(0, 1000)
          .pipe(takeUntil(this.ngUnsubscribe), take(this.WAIT_TIMEOUT))
          .subscribe(
            (cnt) => {
              this.timeout = this.WAIT_TIMEOUT - cnt;
            }, () => {}, 
            () => {
              this.state = State.READY;
            }
          );
        this.codeCtrl.enable();
      }, resp => {
        let msg = '';
        for (let [key, value] of Object.entries(resp.error)) {
          msg += `[${key}]: ${value}`;
        }
        this.toastr.error(msg, 'Ошибка!');
        this.codeCtrl.disable();
        this.state = State.START;
      })
  }
}

import { ShippingPoint, ShippingType } from '../../models';

export class InitPlaces {
  static readonly type = '[places] Init';
  constructor(public shippingType: ShippingType) {}
}

export class SetArrivalPlace {
  static readonly type = '[places] SetArrivalPlace';
  constructor(public placeId: string) {}
}

export class SetDeparturePlace {
  static readonly type = '[places] SetDeparturePlace';
  constructor(public placeId: string) {}
}
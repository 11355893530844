import { Exclude, Transform, Type, TransformationType } from 'class-transformer';
import * as moment from "moment";
import { Moment } from "moment";

import { Service } from './service';
import { DocumentOwner } from './document-owner'
import { ServiceStatus, serviceStatuses } from './service-status';

const dateConverter = (val, obj, type) => {
  // console.log('====');
  // console.log(`${type} ${val}`);
  // console.log(obj);

  if (!val) {
    return null;
  }

  if (type === TransformationType.PLAIN_TO_CLASS) {
    return moment.isMoment(val) ? val : moment(val, 'YYYY-MM-DD');
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    // console.log(val ? moment(val).format('YYYY-MM-DD') : null);
    return val ? moment(val).format('YYYY-MM-DD') : null;
  } else {
    return val;
  }
}

export class ConditionalRelease extends Service implements DocumentOwner {
  @Transform(dateConverter)
  extra_response_date: Moment;

  @Transform(dateConverter)
  request_date: Moment;

  @Transform(dateConverter)
  provision_end_date: Moment;

  @Transform(dateConverter)
  extra_issue_date: Moment;

  order_number: string;

  @Type(() => Number)
  days_left: number;

  @Type(() => Number)
  deposit_cost: number;

  deposit_file: string;

  requested_documents_file: string;

  income_number: string;

  extra_file: string;

  @Type(() => ServiceStatus)
  @Transform(value => serviceStatuses.find(s => s.id == value), { toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  decision: ServiceStatus;

  getPrefix() {
    return 'conditionalrelease';
  }  
}
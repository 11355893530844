<div
    class="file mat-elevation-z1"
    [attr.data-selectable]="file.id"
    (click)="selectFile(file)"
    [class.mat-elevation-z5]="selected"
    [class.file_selected]="selected"
    [class.file_hovered]="hovered || (hover$ | async)"
    fxLayout="column"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)">
  <div fxFlex="grow" fxLayout="row" class="file__preview" fxLayoutAlign="center center" [title]="file.title">
    <div *ngIf="editable" class="file__toolbar" fxLayout="column" fxLayoutGap="2px" [fxShow]="hover$ | async">
      <file-manager-toolbar-button [disabled]="disabled" (action)="downloadFile($event)" icon="cloud_download"></file-manager-toolbar-button>
      <file-manager-toolbar-button [disabled]="disabled" (action)="editFile($event)" icon="edit" color="orange"></file-manager-toolbar-button>
      <file-manager-toolbar-button [disabled]="disabled" (action)="deleteFile($event)" icon="delete" color="red"></file-manager-toolbar-button>
    </div>
    <mat-icon class="file__icon" fontSet="fa"
      [fontIcon]="file.content_type.icon" [style.color]="file.content_type.color"></mat-icon>
  </div>
  <div fxFlex="40px" fxLayout="row" class="file__title" fxLayoutAlign="flex-start center"
    ellipsis [ellipsis-content]="file.title" [title]="file.title"></div>
  <div fxFlex="17px" class="file__date">{{ file.modified | amDateFormat: 'DD.MM.YY HH:mm' }}</div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { OrderStatusModule } from '../order-status';
import { ServiceDocumentsComponent } from './service-documents';
import { ServiceNotificationsComponent } from './service-notifications';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceComponent } from './service/service.component';
import { DeclarationModule } from '../declaration';
import { ConditionalReleaseModule } from '../conditional-release';
import { CertificationModule } from '../certification';
import { SignModule } from '../sign';
import { AnalyticsModule } from '../analytics';
import { ClassificationModule } from '../classification';
import { ConsultingModule } from '../consulting';
import { ShippingModule } from '../shipping';
import { FileDownloaderModule } from '../file-downloader';
import { DocumentsUploaderModule } from '../documents-uploader/documents-uploader.module';

@NgModule({
  declarations: [
    ServiceDocumentsComponent,
    ServiceNotificationsComponent,
    ServiceDetailsComponent,
    ServiceComponent
  ],
  imports: [
    CommonModule,
    DeclarationModule,
    ConditionalReleaseModule,
    DocumentsUploaderModule,
    CertificationModule,
    ConsultingModule,
    ShippingModule,
    SignModule,
    AnalyticsModule,
    ClassificationModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatMenuModule,
    FlexLayoutModule,
    MomentModule,
    OrderStatusModule,
    MatTabsModule,
    RouterModule,
    FileDownloaderModule,
    ScrollToModule.forRoot()
  ],
  exports: [
    ServiceDetailsComponent,
    ServiceDocumentsComponent,
    ServiceNotificationsComponent
  ]
})
export class ServicesModule { }

import { Transform } from "class-transformer";

export class Registration {
	first_name: string;
	last_name: string;
  middle_name: string;
  username: string;
  password: string;
  email: string;

  @Transform(value => value.startsWith('+7') ? value : `+7${value}`, { toClassOnly: true })
  phone: string;
}
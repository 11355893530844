import { Exclude, Transform, Type } from 'class-transformer';

import { Service } from './service';
import { DocumentOwner } from './document-owner'

export class ConsultingService {
  id: number;

  name: string;

  description: string;

  cost: string;
}

export class ConsultingCategory {
  id: number;

  name: string;

  @Type(() => ConsultingService)
  services: ConsultingService[];
}

export class Consulting extends Service implements DocumentOwner {
  // @Type(() => String)
  // services: string[];
  services: number[];

  getPrefix() {
    return 'consulting';
  }  
}
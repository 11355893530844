import { Component, OnInit, Input, NgZone, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject, Observable, zip, of, BehaviorSubject, combineLatest, merge } from 'rxjs';
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter, first, delay, timeout, withLatestFrom,
  debounceTime, finalize, catchError, shareReplay, takeUntil, distinctUntilChanged
} from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import {
  AviaTariffSettings,
  Shipping,
  ShippingPointsService,
  ShippingPoint,
  AviaTariff,
  ShippingTariffsService,
  AviaShipping,
  Selectable,
  ReactiveComponent
} from '../../shared';
import { ShippingCalculatorService } from '../shipping-calculator.service';

@Component({
  selector: 'shipping-calculator-avia',
  templateUrl: './calculator-avia.component.html',
  styleUrls: ['./calculator-avia.component.less']
})
export class CalculatorAviaComponent extends ReactiveComponent implements OnInit {
  @Input()
  service: AviaShipping;

  @Input()
  form: FormGroup;
  
  @Output()
  formFilled: EventEmitter<Shipping> = new EventEmitter();

  private departuresLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private arrivalsLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private arrivalPlaceSource$: BehaviorSubject<ShippingPoint> = new BehaviorSubject(null);
  private departurePlaceSource$: BehaviorSubject<ShippingPoint> = new BehaviorSubject(null);

  public arrivalPlace$: Observable<ShippingPoint> = this.arrivalPlaceSource$.asObservable();
  public departurePlace$: Observable<ShippingPoint> = this.departurePlaceSource$.asObservable();
  public filteredDeparturePlaces$: Observable<ShippingPoint[]>;
  public filteredArrivalPlaces$: Observable<ShippingPoint[]>;
  public availableDeparturesCount$: Observable<number>;
  public availableArrivalsCount$: Observable<number>;
  public departuresLoading$: Observable<boolean> = this.departuresLoadingSource$.asObservable();
  public arrivalsLoading$: Observable<boolean> = this.arrivalsLoadingSource$.asObservable();

  public tariffsSource$: BehaviorSubject<AviaTariff[]> = new BehaviorSubject([]);
  public tariffs$: Observable<AviaTariff[]> = this.tariffsSource$.asObservable();
  public tariffLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public tariffLoading$: Observable<boolean> = this.tariffLoadingSource$.asObservable();
  public mainFormFilled$: Observable<boolean>;
  public placesSelected$: Observable<boolean>;

  public cargoWeightSource$: BehaviorSubject<number> = new BehaviorSubject(null);
  public cargoWeight$: Observable<number> = this.cargoWeightSource$.asObservable();
  public cargoSizeWeightSource$: BehaviorSubject<number> = new BehaviorSubject(null);
  public cargoSizeWeight$: Observable<number> = this.cargoSizeWeightSource$.asObservable();
  public maxWeight$: Observable<number>;

  public formLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public formLoading$: Observable<Boolean> = this.formLoadingSource$.asObservable();

  public tariffSettings$: Observable<AviaTariffSettings>;

  constructor(
      private pointsSrv: ShippingPointsService,
      private tariffsService: ShippingTariffsService,
      private shippingService: ShippingCalculatorService,
      private ref: ChangeDetectorRef) {
    super();
  }

  clearAutocomplete(trigger, field) {
    field.setValue('');
    setTimeout(() => trigger.openPanel(), 0);
  }

  fetchRestrictedList(point: ShippingPoint, exp: boolean) {
    if (!point) {
      return of(null);
    }

    return this.pointsSrv.airportChoices(point.id, exp).pipe(
      map(places => places.map(place => place.id)),
      map(places => Array.from(new Set(places)))
    );
  }

  ngOnInit() {
    const cargoWeightControl = this.form.get('cargo_weight');
    const cargoLengthControl = this.form.get('cargo_length');
    const cargoHeightControl = this.form.get('cargo_height');
    const cargoWidthControl = this.form.get('cargo_width');
    const cargoSizeControl = this.form.get('cargo_size');
    const cargoSizeWeightControl = this.form.get('cargo_size_weight');

    combineLatest([
      cargoLengthControl.valueChanges.pipe(startWith(cargoLengthControl.value)),
      cargoHeightControl.valueChanges.pipe(startWith(cargoHeightControl.value)),
      cargoWidthControl.valueChanges.pipe(startWith(cargoWidthControl.value))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((values: string[]) => values.map(v => parseFloat(v))),
      filter((values: number[]) => values.findIndex(v => isNaN(v)) == -1)
    ).subscribe(([l, h, w]: number[]) => {
      this.form.patchValue({
        'cargo_size': Math.ceil(l * h * w)
      })
    });

    const departurePlaceControl = this.form.get('departure_place');
    const arrivalPlaceControl = this.form.get('arrival_place');

    departurePlaceControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      startWith(departurePlaceControl.value),
      filter(value => !value || value instanceof ShippingPoint)
    ).subscribe((value) => this.departurePlaceSource$.next(value));

    arrivalPlaceControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      startWith(arrivalPlaceControl.value),
      filter(value => !value || value instanceof ShippingPoint)
    ).subscribe((value) => this.arrivalPlaceSource$.next(value));

    const cargoSizeWeight$ = cargoSizeWeightControl.valueChanges.pipe(
      startWith(cargoSizeWeightControl.value),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    cargoSizeWeight$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {})

    this.departuresLoading$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((loading) => {
        loading ? departurePlaceControl.disable({ emitEvent: false }) : departurePlaceControl.enable({ emitEvent: false });
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    this.arrivalsLoading$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((loading) => {
        loading ? arrivalPlaceControl.disable({ emitEvent: false }) : arrivalPlaceControl.enable({ emitEvent: false });
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    const cargoWeight$ = cargoWeightControl.valueChanges.pipe(
      startWith(cargoWeightControl.value),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const cargoSize$ = cargoSizeControl.valueChanges.pipe(
      startWith(cargoSizeControl.value),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.maxWeight$ = combineLatest([
      cargoSizeWeight$,
      cargoWeight$
    ]).pipe(
      filter(([sizeWeight, weight]) => sizeWeight && weight),
      map(([sizeWeight, weight]) => Math.max(sizeWeight, weight)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const departurePlaces$ = this.pointsSrv.airports('', false)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const arrivalPlaces$ = this.pointsSrv.airports('', true)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.tariffSettings$ = this.tariffsService.aviaTariffSettings()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    combineLatest([
      this.tariffSettings$.pipe(filter(settings => settings != null)),
      cargoSizeControl.valueChanges.pipe(
        startWith(cargoSizeControl.value),
        filter(value => !isNaN(value)),
        map(value => parseFloat(value))
      )
    ]).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([settings, value]) => {
        const calc = parseFloat(cargoLengthControl.value) *
                     parseFloat(cargoHeightControl.value) *
                     parseFloat(cargoWidthControl.value);

        if(Math.ceil(calc) != Math.ceil(value)) {
          this.form.patchValue({
            'cargo_length': '',
            'cargo_height': '',
            'cargo_width': ''
          });
        }

        const sizeWeight = settings.bulk_weight * value;
        cargoSizeWeightControl.setValue(isNaN(sizeWeight) ? '' : Math.ceil(sizeWeight), { emitEvent: true });
      });    

    combineLatest([
      departurePlaces$,
      arrivalPlaces$,
      this.tariffSettings$
    ])
      .pipe(takeUntil(this.ngUnsubscribe$), first())
      .subscribe(() => {
        this.formLoadingSource$.next(false);
        this.arrivalsLoadingSource$.next(false);
        this.departuresLoadingSource$.next(false);
        setTimeout(() => { if (!this.ref['destroyed']) this.ref.detectChanges() }, 0);
      });

    const restrictedArrivalPlaces$ = this.departurePlace$.pipe(
      tap(() => setTimeout(() => this.arrivalsLoadingSource$.next(true), 0)),
      switchMap(point => this.fetchRestrictedList(point, true)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  
    const restrictedDeparturePlaces$ = this.arrivalPlace$.pipe(
      tap(() => setTimeout(() => this.departuresLoadingSource$.next(true), 0)),
      switchMap(point => this.fetchRestrictedList(point, false)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.filteredDeparturePlaces$ = combineLatest([
      this.shippingService.autocompleteDebounce<ShippingPoint>(
        departurePlaces$, departurePlaceControl.valueChanges, departurePlaceControl.value),
      restrictedDeparturePlaces$.pipe(startWith(null))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(([points, restricted]) =>
        restricted ? points.filter(point => restricted.includes(point.id)) : points),
      tap(() => setTimeout(() => this.departuresLoadingSource$.next(false), 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.filteredArrivalPlaces$ = combineLatest([
      this.shippingService.autocompleteDebounce<ShippingPoint>(
        arrivalPlaces$, arrivalPlaceControl.valueChanges, arrivalPlaceControl.value),
      restrictedArrivalPlaces$.pipe(startWith(null))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(([points, restricted]) =>
        restricted ? points.filter(point => restricted.includes(point.id)) : points),
      tap(() => setTimeout(() => this.arrivalsLoadingSource$.next(false), 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // arrivalPlaces$.subscribe(data => console.log(data));
    // restrictedArrivalPlaces$.subscribe(data => console.log(data));
    // this.filteredArrivalPlaces$.subscribe(data => console.log(data));

    this.availableDeparturesCount$ = combineLatest([restrictedDeparturePlaces$, departurePlaces$])
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        map(([ids, places]) => ids ? ids.length : places.length)
      );

    this.availableArrivalsCount$ = combineLatest([restrictedArrivalPlaces$, arrivalPlaces$])
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        map(([ids, places]) => ids ? ids.length : places.length)
      );

    const mainFormFilled = ([departurePlace, arrivalPlace]) =>
      !!arrivalPlace && !!departurePlace;

    this.placesSelected$ = combineLatest([
      this.departurePlace$,
      this.arrivalPlace$
    ]).pipe(
      map(([departurePlace, arrivalPlace]) => !!arrivalPlace && !!departurePlace),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.mainFormFilled$ = combineLatest([
      this.placesSelected$,
      cargoWeight$,
      cargoSize$
    ]).pipe(
      map(([placesSelected, weight, size]) => placesSelected && weight && size),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    combineLatest([
      this.departurePlace$,
      this.arrivalPlace$
    ]).pipe(
      filter(mainFormFilled),
      tap(() => setTimeout(() => this.tariffLoadingSource$.next(true), 0)),
      switchMap(([departurePlace, arrivalPlace]) =>
        this.tariffsService.aviaTariffs(departurePlace.id, arrivalPlace.id)),
      tap(() => setTimeout(() => this.tariffLoadingSource$.next(false), 0)),
    ).subscribe((tariffs) => {
      this.tariffsSource$.next(tariffs);
    });

    merge(this.mainFormFilled$, this.tariffs$).pipe(
      takeUntil(this.ngUnsubscribe$),
      withLatestFrom(combineLatest([this.tariffs$, this.mainFormFilled$])),
      delay(0)
    ).subscribe(([, [tariffs, mainFormFilled]]) => {
      const formFilled = tariffs.length > 0 && !!mainFormFilled;
      this.formFilled.emit(formFilled ? plainToClass(AviaShipping, this.form.value) : null);
    });
  }

  displayFn(point?: ShippingPoint): string | undefined {
    return point ? point.name : undefined;
  }

  isTarriffValid(tariff, weight, size) {
    return weight >= tariff.weight_from && weight <= tariff.weight_to && size >= tariff.size_from && size <= tariff.size_to;
  }

  get f() {
    return this.form.controls;
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { DadataOrg } from './dadata.org';

@Injectable({
  providedIn: 'root'
})
export class DadataService {
  private readonly API_BASE_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1';

  constructor(private http: HttpClient,
              @Inject('dadataToken') public token: string) { }

  public suggest(query: string): Observable<DadataOrg[]> {
    return this.http.post(`${this.API_BASE_URL}/rs/suggest/party`, 
      { query }, { headers: this.getAuthHeaders() })
        .pipe(map((res: any) => plainToClass(DadataOrg, res.suggestions as Object[])));
  }

  private getAuthHeaders() {
    const headers = new HttpHeaders().set('Authorization', `Token ${this.token}`);
    return headers;
  }
}
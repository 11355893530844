import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { Shipping, ShippingPoint, ShippingType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ShippingPointsService {
  private prefix: string = '/shipping/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public airports(query: string, russia: boolean): Observable<ShippingPoint[]> {
    const isRussia = russia ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}airports/?name__startswith=${query}&russia=${isRussia}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public ports(query: string, russia: boolean): Observable<ShippingPoint[]> {
    const isRussia = russia ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}ports/?name__startswith=${query}&russia=${isRussia}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public stations(query: string, russia: boolean): Observable<ShippingPoint[]> {
    const isRussia = russia ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}stations/?name__startswith=${query}&russia=${isRussia}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public airportChoices(airportId: string, exp: boolean) {
    const isExport = exp ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}airports/${airportId}/choices/?export=${isExport}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public portChoices(portId: string, exp: boolean) {
    const isExport = exp ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}ports/${portId}/choices/?export=${isExport}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public stationChoices(stationId: string, exp: boolean) {
    const isExport = exp ? 1 : 0;
    return this.http
      .get(`${this.apiUrl}${this.prefix}stations/${stationId}/choices/?export=${isExport}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public placesByType(shippingType: ShippingType, isRussia: boolean, query: string = ''): Observable<ShippingPoint[]> {
    if (shippingType.placesPrefix) {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${shippingType.placesPrefix}/?name__startswith=${query}&russia=${Number(isRussia)}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
    } else {
      throw new Error("Not implemented")
    }
  }

  public choicesByType(shippingType: ShippingType, placeId: string, isExport: boolean): Observable<ShippingPoint[]> {
    if (shippingType.placesPrefix) {
      return this.http
        .get(`${this.apiUrl}${this.prefix}${shippingType.placesPrefix}/${placeId}/choices/?export=${Number(isExport)}`)
        .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
    } else {
      throw new Error("Not implemented")
    }
  }

  public airport(id: number): Observable<ShippingPoint> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}airports/${id}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object)));
  }

  public port(id: number): Observable<ShippingPoint> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}ports/${id}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object)));
  }

  public station(id: number): Observable<ShippingPoint> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}stations/${id}`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object)));
  }

  public railwayContainers(): Observable<ShippingPoint[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}railwaycontainers/`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }

  public seaContainers(): Observable<ShippingPoint[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}seacontainers/`)
      .pipe(map(res => plainToClass(ShippingPoint, res as Object[])));
  }
}

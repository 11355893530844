import { Expose } from "class-transformer";

export class Hint {
  slug: string;
  title: string;
  text: string;
  image: string;
  file: string;

  @Expose()
  get imageUrl() {
    return `${this.image}`;
  }

  @Expose()
  get fileUrl() {
    return `${this.file}`;
  }
}
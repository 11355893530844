import { Expose } from 'class-transformer';
import { immerable } from 'immer';

import { UserRole } from './user';

export class AuthRequest {
  username: string;
  password: string;
}

export class AuthResponse {
  access: string;
  refresh: string;
}

export class AuthUser {
  @Expose({ name: 'user_id' })
  id: string;

  @Expose({ name: 'is_staff' })
  isStaff: boolean;

  @Expose({ name: 'rc' })
  registrationCompleted: boolean;

  @Expose({ name: 'role' })
  role: string;

  @Expose({ name: 'account' })
  account: number;

  get isBoss() {
    return this.role == UserRole.BOSS;
  }

  get isManager() {
    return this.role == UserRole.MANAGER;
  }
}

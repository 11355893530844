import { Transform, Type, Expose } from "class-transformer";
import { immerable } from 'immer';

import { StorageFile } from './file-manager';
import { EnumModel } from './enum';
import { environment } from '../../../environments/environment';

export class DocumentCategory extends EnumModel {
  static readonly CONTRACT  = new DocumentCategory('CONTRACT', 'CONTRACT', 'Контракт', ['contract']);
  static readonly SPECIFICATION  = new DocumentCategory('SPEC', 'SPEC', 'Допсоглашения, приложения, спецификации', ['specification']);
  static readonly EXTRA_CONTRACT  = new DocumentCategory('EXTRA_CONTRACT', 'EXTRA_CONTRACT', 'Прочие документы (контракт)', ['extracontract']);
  static readonly PAYMENT_TERMS  = new DocumentCategory('PAYMENT_TERMS', 'PAYMENT_TERMS', 'Условия оплаты', ['paymentterms']);
  static readonly INVOICE  = new DocumentCategory('INVOICE', 'INVOICE', 'Инвойс', ['invoice']);
  static readonly INVOICE_PROFORM  = new DocumentCategory('INVOICE_PROFORM', 'INVOICE_PROFORM', 'Проформа инвойса', ['invoiceproform']);
  static readonly PACKING_LIST  = new DocumentCategory('PACKING_LIST', 'PACKING_LIST', 'Упаковочный лист', ['packinglist']);
  static readonly EXTRA_CARGO  = new DocumentCategory('EXTRA_CARGO', 'EXTRA_CARGO', 'Прочие документы (груз)', ['extracargo']);
  static readonly SHIPPING_DOCUMENT  = new DocumentCategory('SHIPPING_DOCUMENT', 'SHIPPING_DOCUMENT', 'Транспортный документ', ['shippingdocument']);
  static readonly CERTIFICATE  = new DocumentCategory('CERTIFICATE', 'CERTIFICATE', 'Сертификат', ['certificate']);
  static readonly PAYMENT  = new DocumentCategory('PAYMENT', 'PAYMENT', 'Платежка', ['payment']);
  static readonly PRODUCTS  = new DocumentCategory('PRODUCTS', 'PRODUCTS', 'Товар (техника)', ['products']);
  static readonly PRODUCT_IMAGE  = new DocumentCategory('PRODUCT_IMAGE', 'PRODUCT_IMAGE', 'Товар (картинка)', ['productimage']);
  static readonly PRODUCT_CONTRACT  = new DocumentCategory('PRODUCT_CONTRACT', 'PRODUCT_CONTRACT', 'Договор с перевозчиком', ['productcontract']);
  static readonly TRANSFER_CONTRACT  = new DocumentCategory('TRANSFER_CONTRACT', 'TRANSFER_CONTRACT', 'Счет за фрахт', ['transfercontract']);
  static readonly FREIGHT_BILL  = new DocumentCategory('FREIGHT_BILL', 'FREIGHT_BILL', 'Страховка', ['freightbill']);
  static readonly INSURANCE  = new DocumentCategory('INSURANCE', 'INSURANCE', 'Товары (все вместе)', ['insurance']);
  static readonly PROFILE  = new DocumentCategory('PROFILE', 'PROFILE', 'Документы пользователя', ['profile']);
  static readonly REQUESTED_DOCS  = new DocumentCategory('REQUESTED_DOCS', 'REQUESTED_DOCS', 'Документы запрошенные таможней', ['requesteddocs']);
  static readonly NORMATIVE_DOC  = new DocumentCategory('NORMATIVE_DOC', 'NORMATIVE_DOC', 'Нормативный документ', ['normativedoc']);

  static readonly SIGN_PASSPORT1 = new DocumentCategory('SIGN_PASSPORT1', 'SIGN_PASSPORT1', 'Цветной скан 1 стр. паспорта', ['sign_passport1']);
  static readonly SIGN_PASSPORT2 = new DocumentCategory('SIGN_PASSPORT2', 'SIGN_PASSPORT2', 'Цветной скан 2 стр. паспорта', ['sign_passport2']);
  static readonly SIGN_SNILS = new DocumentCategory('SIGN_SNILS', 'SIGN_SNILS', 'Цветной скан СНИЛС', ['signsnils']);
  static readonly SIGN_CLIENT = new DocumentCategory('SIGN_CLIENT', 'SIGN_CLIENT', 'Фото клиента с паспортом', ['signclient']);
  static readonly CONSULTING_COMMON = new DocumentCategory('CONSULTING_COMMON', 'CONSULTING_COMMON', 'Документы ВЭД', ['consultingcommon']);
  static readonly ANALYTICS_COMMON = new DocumentCategory('ANALYTICS_COMMON', 'ANALYTICS_COMMON', 'Документы аналитики', ['analyticscommon']);
  static readonly CONDITIONAL_RELEASE = new DocumentCategory('CONDITIONAL_RELEASE', 'CONDITIONAL_RELEASE', 'Предоставленные документы', ['conditionalrelease']);
  static readonly CONDITIONAL_EXTRA = new DocumentCategory('CONDITIONAL_EXTRA', 'CONDITIONAL_EXTRA', 'Документы по доп запросу', ['conditionalextra']);

  // static readonly CONDITIONAL_EXTRA = new DocumentCategory('CONDITIONAL_EXTRA', 'CONDITIONAL_EXTRA', 'Документы по доп запросу', ['conditionalextra']);
  // static readonly CONDITIONAL_EXTRA = new DocumentCategory('CONDITIONAL_EXTRA', 'CONDITIONAL_EXTRA', 'Документы по доп запросу', ['conditionalextra']);

  private constructor(
    key: string, value: string,
    public title: string,
    public tags: string[]) {
    super(key, value);
  }

  static notFound(value: any): DocumentCategory {
    return new DocumentCategory(value, value, value, value ? [value.toLowerCase()] : []);
  }
}


export enum DocumentType {
  CONT = 'Контракт',
  SPEC = 'Допсоглашения, приложения, спецификации',
  COAN = 'Прочие документы (контракт)',
  PATE = 'Условия оплаты',
  INVO = 'Инвойс',
  PRIN = 'Проформа инвойса',
  PALI = 'Упаковочный лист',
  CAAN = 'Прочие документы (груз)',
  SHDO = 'Транспортный документ',
  CERT = 'Сертификат',
  PAYM = 'Платежка',
  PROD = 'Товар (техника)',
  TECH = 'Товар (картинка)',
  PRIM = 'Договор с перевозчиком',
  TRCO = 'Счет за фрахт',
  FRBI = 'Страховка',
  INSU = 'Товары (все вместе)',
  PROF = 'Документы пользователя',
  OREX = 'Документы запрошенные таможней',
  CRNO = 'Нормативный документ',

  SIPM = 'Цветной скан 1 стр. паспорта',
  SIPA = 'Цветной скан 2 стр. паспорта',
  SISN = 'Цветной скан СНИЛС',
  SICL = 'Фото клиента с паспортом',

  CNCO = 'Документы ВЭД',
  ANCO = 'Документы аналитики',

  CORE = 'Предоставленные документы',
  COEX = 'Документы по доп запросу',

  MAAC = 'Бухгалтерские документы',
  MAPR = 'Документы по профилю',
  MACO = 'Договор с клиентом',

  SHIP = 'Документы перевозки'
}

// export class Document {
//   [immerable] = true;

//   id: number;
//   name: string;
//   document: string;
//   type: string;
  
//   @Expose()
//   get documentUrl() {
//     return `${environment.media}${this.document}`;
//   }

//   @Type(() => Date)
//   date: Date;
// }

import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';

import { Login, Logout, RefreshAuth, AuthComplete } from '../actions/auth.actions';
import { InitFileManager } from '../actions/filemanager.actions';
import { LoadCountries } from '../actions/countries.actions';
import { LoadTransports } from '../actions/transports.actions';
import { AuthService } from '../../services';
import { AuthResponse, AuthUser } from '../../models';


export interface AuthStateModel {
  access: string;
  refresh: string;
  authorizing: boolean;
  username: string;
  refreshing: boolean;
}


@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    access: null,
    refresh: null,
    authorizing: false,
    refreshing: false,
    username: null
  }
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector([AuthState])
  static accessToken(state: AuthStateModel): string | null {
    return state.access;
  }

  @Selector([AuthState])
  static refreshToken(state: AuthStateModel): string | null {
    return state.refresh;
  }

  @Selector([AuthState])
  static authorizing(state: AuthStateModel): boolean {
    return state.authorizing;
  }

  @Selector([AuthState])
  static refreshing(state: AuthStateModel): boolean {
    return state.refreshing;
  }

  @Selector([AuthState.accessToken])
  static authenticated(token: string): boolean {
    return !!token;
  }

  // @Selector([AuthState.authenticated, AuthState.accessToken])
  // static expired(authenticated: boolean, token: string): boolean {
  //   console.log(token);
  //   console.log(AuthService.isTokenExpired(token));
  //   return !authenticated || AuthService.isTokenExpired(token);
  // }

  @Selector([AuthState.accessToken])
  static user(token: string): AuthUser {
    if (!token) {
      return null;
    }

    return AuthService.extractUserFromToken(token);
  }

  @Selector([AuthState])
  static username(state: AuthStateModel): string | null {
    return state.username;
  }

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    const token = this.store.selectSnapshot(AuthState.accessToken);
    if (this.authService.isTokenExpired(token)) {
      this.store.dispatch(new RefreshAuth());
    } else {
      this.store.dispatch(new AuthComplete({
        access: this.store.selectSnapshot(AuthState.accessToken),
        refresh: this.store.selectSnapshot(AuthState.refreshToken)
      }, false));
    }
  }

  constructor(
    private store: Store,
    private authService: AuthService) {}

  @Action(Login)
  @ImmutableContext()
  login(
    { setState, getState, dispatch }: StateContext<AuthStateModel>,
    { payload }: Login
  ) {
    setState((state: AuthStateModel) => {
      state.authorizing = true;
      return state;
    });

    return this.authService.login(payload).pipe(
      tap((result: AuthResponse) => setState((state: AuthStateModel) => {
        state.refresh = result.refresh;
        state.username = payload.username;
        return state;
      })),
      map((result: AuthResponse) => dispatch(new AuthComplete(result, false))),
      finalize(() => setState((state: AuthStateModel) => {
        state.authorizing = false;
        return state;
      }))
    );
  }

  @Action(RefreshAuth)
  @ImmutableContext()
  refreshAuth(
    { setState, getState, dispatch }: StateContext<AuthStateModel>,
    { }: RefreshAuth
  ) {
    const refreshing = this.store.selectSnapshot(AuthState.refreshing);
    if (refreshing) {
      return;
    }

    const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);
    if (!refreshToken) {
      return dispatch(new Logout());
    }

    setState((state: AuthStateModel) => {
      state.refreshing = true;
      return state;
    });

    return this.authService.refresh(refreshToken).pipe(
      map(result => dispatch(new AuthComplete(result, true))),
      catchError(err => {
        if (err.status === 401) {
          return of(dispatch(new Logout()));
        }
        return throwError(err);
      }),
      finalize(() => setState((state: AuthStateModel) => {
        state.refreshing = false;
        return state;
      }))
    );
  }

  @Action(AuthComplete)
  @ImmutableContext()
  authComplete(
    { setState, getState, dispatch }: StateContext<AuthStateModel>,
    { payload, redirect }: AuthComplete
  ) {
    setState((state: AuthStateModel) => {
      state.access = payload.access;
      return state;
    });

    const user = this.store.selectSnapshot(AuthState.user);
    if (user) {
      return dispatch([
        new InitFileManager(user.account),
        new LoadCountries(),
        new LoadTransports()
      ]);
    }
  }

  @Action(Logout)
  @ImmutableContext()
  logout(
    { setState, getState }: StateContext<AuthStateModel>,
    { }: Logout
  ) {
    setState((state: AuthStateModel) => {
      state.access = null;
      state.refresh = null;
      return state;
    });
  }
}
import { Expose, Type } from "class-transformer";

export class Importer {
  id: number;
  inn: string;
  org_name: string;
  ogrn: string;
  kpp: string;
  legal_address: string;
  physical_address: string;
  correspondence_address: string;
  director_position: string;
  director_fio: string;
  questinary: boolean;
}
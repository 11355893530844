import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatErrors'})
export class FormatErrorsPipe implements PipeTransform {
  transform(errors: any): string {
    let msg = '';
    console.log(typeof errors);
    if(typeof errors === 'object') {
      for (let [key, value] of Object.entries(errors.error)) {
        msg += `<strong>[${key}]</strong>: ${value}<br>`;
      }
    } else {
      msg = errors;
    }
    return msg;
  }
}
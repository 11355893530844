<ng-container *ngIf="{
  news: news$ | async,
  loading: loading$ | async,
  total: total$ | async,
  pageSize: pageSize$ | async,
  page: page$ | async
} as ctx">
	<div class="news" fxLayout="column" fxLayoutGap="20px">
		<h1 style="text-align: center;">Новости и обновления</h1>
		<mat-card *ngIf="ctx.loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></mat-card>
		<div fxLayout="row" fxLayoutAlign="center" *ngFor="let article of ctx.news">
			<mat-card fxFlex="50%" fxFlex.lt-lg="70%" fxFlex.lt-sm="100%">
			  <mat-card-header>
			    <mat-card-title>{{ article.title }}</mat-card-title>
			    <mat-card-subtitle>{{ article.date_creation | amDateFormat: 'DD.MM.YYYY HH:mm' }}</mat-card-subtitle>
			  </mat-card-header>

				<mat-card-content [innerHTML]="article.text"></mat-card-content>
			</mat-card>
		</div>
	</div>
</ng-container>
<div class="profile-importers" *ngIf="!loading; else loadingTpl">
  <div class="profile-importers__toolbar">
    <button class="profile-importers__create"
      (click)="create()" mat-mini-fab color="accent"><mat-icon>add</mat-icon></button>
  </div>

  <div *ngIf="importers$ | async as importers">
    <mat-list  *ngIf="!isEmpty; else emptyTpl">
      <mat-list-item *ngFor="let importer of importers; last as last">
        <h3 mat-line><strong>{{ importer.org_name }}</strong></h3>
        <p mat-line>{{ importer.inn }}</p>
        <div fxLayout="row" *ngIf="(user$ | async)?.isBoss">
          <button mat-icon-button color="primary" (click)="edit(importer)"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button color="warn" (click)="remove(importer)"><mat-icon>delete</mat-icon></button>
        </div>

        <mat-divider *ngIf="!last"></mat-divider>
      </mat-list-item>
    </mat-list>

    <ng-template #emptyTpl>
      <p>У вас нет ни одного импортера</p>
    </ng-template>
  </div>
</div>

<ng-template #loadingTpl>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
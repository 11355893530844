import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Hint } from '../models/hint';
import { Section } from '../models/section';

@Injectable({
  providedIn: 'root'
})
export class HintsService {
  private prefix: string = '/hints/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public get(slug: string): Observable<Hint> {
    return this.http.get(`${this.apiUrl}${this.prefix}${slug}/`)
      .pipe(map(res => plainToClass(Hint, res)));
  }

  public getSection(slug: string): Observable<Section> {
    return this.http.get(`${this.apiUrl}/sections/${slug}/`)
      .pipe(map(res => plainToClass(Section, res)));
  }
}
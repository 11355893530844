<ng-container *ngIf="user$ | async as user">
  <div class="services-list">
    <div *ngIf="loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

    <div *ngIf="!loading && services.results?.length > 0" class="mat-elevation-z4">
      <mat-toolbar><mat-icon>assignment_turned_in</mat-icon>&nbsp;Журнал условных выпусков</mat-toolbar>

      <ngx-datatable
          [rows]="services.results"
          [headerHeight]="50"
          [rowHeight]="50"
          [swapColumns]="true"
          [reorderable]="true"
          (select)="selectRow($event)"
          columnMode="flex"
          selectionType="single"

          [externalPaging]="true"
          [count]="services.count"
          [offset]="services.page_number - 1"
          [limit]="services.page_size"
          (page)="setPage($event)"
          
          class="material">
        <ngx-datatable-column name="#"
            [sortable]="false"
            [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <strong>{{ row.order_number }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Решение" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <tamo-order-status *ngIf="row.decision" [status]="row.decision.id"></tamo-order-status>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Конечаня дата" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.provision_end_date">
              {{ row.provision_end_date ? (row.provision_end_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}
              <span [ngClass]="{ 'attention': row.days_left <= 7 }">({{ row.days_left }} дн)</span>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>
        

        <ngx-datatable-column name="Депозит" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.deposit_cost">
              <a target="_blank" href="{{ row.deposit_file }}">{{ row.deposit_cost }}</a>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Запрошенные документы" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.requested_documents_file">
              <a target="_blank" href="{{ row.requested_documents_file }}">{{ row.request_date ? (row.request_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}</a>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Дополнительный запрос" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.extra_file">
              <a target="_blank" href="{{ row.extra_file }}">{{ row.extra_issue_date ? (row.extra_issue_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}</a>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Номер входящего" [sortable]="false" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="row.income_number">
              {{ row.income_number }}
            </ng-container>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="" [sortable]="false" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <mat-icon color="primary" class="orders-list__action">edit</mat-icon>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <ng-container *ngIf="!loading && !services.results?.length">
      <div class="services-list__empty">
        <ng-container *ngIf="!user.isStaff">У Вас пока нет ни одного условного выпуска</ng-container>
        <ng-container *ngIf="user.isStaff">Вам не назначен ни один заказ с условным выпуском</ng-container>
      </div>
    </ng-container>
    
  </div>
</ng-container>
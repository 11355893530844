import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap, filter, tap, takeUntil, shareReplay } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import * as _ from 'lodash';

import { Service, Order, ServiceType } from '../../shared';
import { OrderState, LoadServiceDocuments, DownloadDocuments, DownloadDocumentsSuccess } from '../../shared/store';
import { DocumentType } from '../../shared/models/document';
import { StorageFile } from '../../shared/models/file-manager';
import { DocumentsService } from '../../shared/services/documents.service';
import { ProductsService } from '../../shared/services/products.service';
import { ReactiveComponent, OrderServicesService } from '../../shared';

@Component({
  selector: 'tamo-service-documents',
  templateUrl: './service-documents.component.html',
  styleUrls: ['./service-documents.component.less']
})
export class ServiceDocumentsComponent extends ReactiveComponent {
  readonly DocumentType: typeof DocumentType = DocumentType;

  // @Select(OrderState.selectedOrder) order$: Observable<Order>;

  @Select(OrderState.selectedService) service$: Observable<Service>;

  @Select(OrderState.serviceDocuments) documents$: Observable<StorageFile[]>;

  @Select(OrderState.documentsLoading) loading$: Observable<boolean>;

  @Select(OrderState.downloading) downloading$: Observable<boolean>;

  public documentsCount$: Observable<number> = this.documents$.pipe(map((documents) => documents.length));

  public documentsGroups$: Observable<Object> = combineLatest([
    this.service$, this.documents$
  ]).pipe(
    map(([service, documents]) => {
      const groups = { };
      for (const document of documents) {
        for (const tag of document.tags) {
          const parts = tag.split('@');
          if (parts.length >= 3 && parts[0] === 'service' && parseInt(parts[1]) === service.id) {
            const type = parts[parts.length - 1];
            if (!groups[type]) {
              groups[type] = [];
            }

            groups[type].push(document);
          }
        }
      }
      // console.log(_.map(_.toPairs(groups), d => _.fromPairs([d])));
      // return _.map(_.toPairs(groups), d => _.fromPairs([d]));
      return groups;
    })
  );

  public typesKeys: string[] = Object.keys(DocumentType);

  constructor(private store: Store,
              private route: ActivatedRoute,
              private actions$: Actions,
              private docService: DocumentsService,
              private productsService: ProductsService,
              private dataService: OrderServicesService) {
    super();
    this.store.dispatch(new LoadServiceDocuments());

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(DownloadDocumentsSuccess)
    ).subscribe(({ filename, data }) => {
      saveAs(data, filename)
    });
  }

  ngOnInit() {
  }

  public removeDocument(document: StorageFile, documents: StorageFile[]) {
    // this.docService.remove(`/declarations/${this.serviceId}/documents/`, document.type, document.id)
    //   .subscribe(() => {
    //     const index = documents.findIndex(d => d == document);
    //     documents.splice(index, 1);
    //   });
  }

  public downloadFiles(evt) {
    this.store.dispatch(new DownloadDocuments());
  }
}
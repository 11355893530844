<form [formGroup]="filterForm" ngxsForm="orders.filterForm"
    #filterFormControl="ngForm" (ngSubmit)="search($event)"
    fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center"
    fxLayout.lt-md="row wrap">

  <mat-form-field>
    <mat-label>Поиск</mat-label>

    <input matInput type="text" formControlName="search"
      placeholder="Введите запрос" autocomplete="off">
    <mat-hint>Название/номер заказа</mat-hint>
  </mat-form-field>

  <mat-form-field fxFlex="200px">
    <mat-label>Тип услуги</mat-label>

    <mat-select placeholder="Выберите тип" formControlName="service_type" [(value)]="selectedType">
      <mat-select-trigger>{{ selectedType?.title }}</mat-select-trigger>
      <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType">
        <mat-icon [inline]="true">{{ serviceType.icon }}</mat-icon> {{ serviceType.title }}
      </mat-option>
    </mat-select>

    <button mat-button *ngIf="f.service_type.value" matSuffix mat-icon-button
        aria-label="Очистить" (click)="f.service_type.setValue(null)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field fxFlex="140px">
    <mat-label>Статус</mat-label>

    <mat-select placeholder="Выберите статус" formControlName="status" [(value)]="selectedStatus">
      <mat-select-trigger>{{ selectedStatus?.title }}</mat-select-trigger>
      <mat-option *ngFor="let status of statuses" [value]="status">
        <mat-icon [style.color]="status.color" [inline]="true">{{ status.icon }}</mat-icon> {{ status.title }}
      </mat-option>
    </mat-select>

    <button mat-button *ngIf="f.status.value" matSuffix mat-icon-button
        aria-label="Очистить" (click)="f.status.setValue(null)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Дата создания услуги</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="date_from" placeholder="Дата с">
      <input matEndDate formControlName="date_to" placeholder="по">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <button type="button" mat-icon-button (click)="reset(filterFormControl)">
    <mat-icon [inline]="true">clear</mat-icon></button>

  <button mat-flat-button color="primary">Поиск</button>
</form>

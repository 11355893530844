import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthState, Logout } from '../shared/store';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private store: Store) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const canActivate = this.store.selectSnapshot(AuthState.authenticated);
        if (!canActivate) {
            this.store.dispatch(new Logout());
            return false;
        }

        return true;
    }
}
import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { catchError, switchMap, tap, filter, map, first } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';

import { AuthState, Logout, RefreshAuth } from '../shared/store';
import { AuthService } from '../shared/services';
import { AuthResponse } from '../shared/models';

 
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  @Select(AuthState.refreshing)
  refreshing$: Observable<boolean>;

  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private authService: AuthService,
    private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401 && request.url.startsWith(this.apiUrl) && !this.authService.isAuthUrl(request.url)) {
          this.store.dispatch(new RefreshAuth());
          return this.refreshing$.pipe(
            filter(refreshing => !refreshing),
            first(),
            switchMap(() => {
              const accessToken = this.store.selectSnapshot(AuthState.accessToken);
              if (accessToken) {
                let newRequest = request.clone({
                  setHeaders: { Authorization: `JWT ${accessToken}` }
                });
                return next.handle(newRequest);
              }
              return empty();
            })
          );
        } else {
          return throwError(err);
        }
      })
    );
  }
}
<mat-form-field class="kladr-autocomplete" [appearance]="appearance">
  <input matInput class="kladr-autocomplete__input"
    name="kladr-autocomplete"
    type="search"
    autocomplete="off"
    [placeholder]="placeholder"
    [attr.aria-label]="placeholder"
    [matAutocomplete]="auto"
    [formControl]="formControl">

  <!-- <mat-icon class="kladr-autocomplete__icon" matPrefix>my_location</mat-icon> -->

  <button mat-button *ngIf="formControl.value" matSuffix mat-icon-button
      aria-label="Очистить" (click)="clearData()">
    <mat-icon>close</mat-icon>
  </button>
  
  <mat-hint>{{ hint$ | async }}</mat-hint>
  <mat-hint align="end" class="kladr-autocomplete__status">
    <mat-spinner *ngIf="loading" diameter="13" matPrefix></mat-spinner>
    <mat-icon *ngIf="!!(selectedOption$ | async)" class="kladr-autocomplete__selected" [inline]="true">done</mat-icon>
  </mat-hint>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
    <mat-option *ngFor="let variant of variants$ | async" [value]="variant">
      <span class="kladr-autocomplete__name">{{ variant.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div class="result__block" fxLayout="column" fxLayoutGap="7px">
  <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="space-between center"
  	fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="flex-start">

  	<div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="flex-start center">
	  	<mat-icon fxHide.lt-sm class="result__route-icon" *ngIf="routeIcon"
	  		fontSet="fa" fontIcon="fa-{{ routeIcon }}" [inline]="true"></mat-icon>

	    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center"
	      fxLayout.lt-md="column" fxLayoutAlign.lt-md="flex-start">
	      <div class="result__place">
	        <mat-icon *ngIf="departureIcon" fontSet="fa" fontIcon="fa-{{ departureIcon }}" [inline]="true"></mat-icon> {{ departurePlace }}
	      </div>

	      <div class="result__info" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
	        <mat-icon>arrow_right_alt</mat-icon>

	        <div fxLayout="column" fxLayoutGap="2px">
	          <ng-template *ngTemplateOutlet="resultInfoTemplate"></ng-template>
	        </div>

	        <mat-icon>arrow_right_alt</mat-icon>
	      </div>

	      <div class="result__place">
	        <mat-icon *ngIf="arrivalIcon" fontSet="fa" fontIcon="fa-{{ arrivalIcon }}" [inline]="true"></mat-icon> {{ arrivalPlace }}
	      </div>
	    </div>
    </div>

    <div class="result__price" *ngIf="cost" fxLayout="row" fxLayoutGap="4px">
      <strong>{{ cost | number: '1.0-0' }}</strong>
      <ng-container *ngIf="currency"><span>{{ currency }}</span></ng-container>
    </div>
  </div>

  <div class="result__note" *ngIf="resultNoteTemplate" fxLayout="column">
  	<ng-template *ngTemplateOutlet="resultNoteTemplate"></ng-template>
  </div>
</div>
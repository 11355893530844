import { Transform, Type, Discriminator } from "class-transformer";

import { Message } from './message';
import { OrderLog } from './order-log';

const notificationDiscriminator: Discriminator = {
  property: "resourcetype",
  subTypes: [
    { value: Message, name: "ME" },
    { value: OrderLog, name: "ST" }
  ]
}

export class Data {
  resourcetype: string;
}

export class Notification {
  text: string;

  // type: string;
  // status_to: string;
  // message: string;
  // order: number;
  // sent: boolean;
  // read: boolean;

  type: string;

  object_id: string;

  read: boolean;

  @Transform(data => JSON.parse(data), { toClassOnly: true })
  data: any;

  @Type(() => Date)
  date_creation: Date;
}
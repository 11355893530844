import { Order, Service } from '../../models';

export class ApplyOrdersFilter {
  static readonly type = '[orders] ApplyOrdersFilter';
  constructor() {}
}

export class ChangeOrdersPage {
  static readonly type = '[orders] ChangeOrdersPage';
  constructor(public page: number) {}
}

export class LoadOrders {
  static readonly type = '[orders] LoadOrders';
  constructor() {}
}

export class ResetOrdersFilter {
  static readonly type = '[orders] ResetOrdersFilter';
  constructor() {}  
}
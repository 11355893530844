<ng-container *ngIf="{
  user: user$ | async,
  uplaodPrefix: uplaodPrefix$ | async,
  accountId: accountId$ | async
} as ctx">
  <div class="profile" fxLayout="row" fxLayoutGap="30px">
    <mat-card fxFlex>
      <mat-card-header>
        <mat-card-title>Аккаунт</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="profileForm">
          <div class="changesprof__group" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
            <mat-form-field class="changesprof__field" fxFlex="50">
              <input type="tel" matInput formControlName="phone" placeholder="Телефон" >
              <mat-icon matSuffix>phone</mat-icon>
            </mat-form-field>

            <mat-form-field class="changesprof__field" fxFlex="50">
              <input matInput placeholder="Email" formControlName="email">
              <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
          </div>

          <div class="changesprof__group">
            <mat-form-field class="profile__field" fxFlex>
              <input matInput placeholder="Фамилия" formControlName="last_name">
            </mat-form-field>
          </div>

          <div class="changesprof__group" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
            <mat-form-field class="profile__field" fxFlex="50">
              <input matInput placeholder="Имя" formControlName="first_name">
            </mat-form-field>
            
            <mat-form-field class="profile__field" fxFlex="50">
              <input matInput placeholder="Фамилия" formControlName="middle_name">
            </mat-form-field>
          </div>

          <div class="changesprof__group">
            <kladr-suggest-city ngDefaultControl name="city" [formControl]="f.city"></kladr-suggest-city>
          </div>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex>
      <mat-card-header>
        <mat-card-title>Документы</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div fxLayout="column">
          <tamo-documents-uploader
            [prefix]="ctx.uplaodPrefix" [disabled]="!ctx.user.isStaff" [fileManager]="false"
            [extraContext]="{ 'is_account': false }"
            counts="-" type="MACO" title="Договор с клиентом"></tamo-documents-uploader>

          <tamo-documents-uploader [prefix]="ctx.uplaodPrefix" [disabled]="ctx.user.isStaff"
            counts="-" type="PROF" title="Документы пользователя (ИНН)"></tamo-documents-uploader>
        </div>
      </mat-card-content>

<!--       <tamo-documents-uploader *ngIf="profile" [prefix]="ctx.uplaodPrefix"
        counts="-" type="PROF" title="ИНН"></tamo-documents-uploader> -->
    </mat-card>
  </div>
</ng-container>

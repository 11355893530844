import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, map, first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { KladrCity } from './kladr.city';

@Injectable({
  providedIn: 'root'
})
export class KladrService {
  private readonly API_BASE_URL = '//kladr-api.ru/api.php';

  constructor(private http: HttpClient) { }

  public findCities(query: string, limit: number = 10): Observable<KladrCity[]> {
    return this.http.jsonp(
      `${this.API_BASE_URL}?query=${query}&contentType=city&typeCode=1&limit=${limit}`,
      'callback'
    )
      .pipe(
        // tap(res => console.log(res)),
        // tap(res => console.log(res.json())),
        map((res: any) => plainToClass(KladrCity, res.result as Object[])),
        map((res: KladrCity[]) => res.filter(data => data.name.indexOf('есплатн') === -1))
      );
  }
}
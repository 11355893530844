import { Injectable, Inject } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class OrdersUIService {
	private requestCreationSource$: Subject<any> = new Subject();

	private createStartSource$: Subject<any> = new Subject();

	private createCompleteSource$: Subject<any> = new Subject();

	private closeSource$: Subject<any> = new Subject();

	public requestCreation$: Observable<any> = this.requestCreationSource$.asObservable();

	public createStart$: Observable<any> = this.createStartSource$.asObservable();

	public createComplete$: Observable<any> = this.createCompleteSource$.asObservable();

	public close$: Observable<any> = this.closeSource$.asObservable();

	constructor() {

	}

	public requestCreationForm() {
		this.requestCreationSource$.next();
	}

	public createStarted() {
		this.createStartSource$.next();
	}

	public createCompleted() {
		this.createCompleteSource$.next();
	}

	public close() {
		this.closeSource$.next();
	}
}
export abstract class EnumModel {
  constructor(readonly key: string,
              public readonly value: any) { }

  toString() {
    return this.key;
  }

  public static getByValue(value: any): EnumModel {
    for (let key in this) {
      const model = this[key];
      if (model.value === value) {
        return model;
      }
    }

    return (<typeof EnumModel> this).notFound(value);
  }

  public static getValues(): any[] {
    const models = [];
    for (let key in this) {
      if (this[key] instanceof EnumModel) {
        models.push(this[key]);
      }
    }

    const valuesOrder = (<typeof EnumModel> this).valuesOrder();
    if (valuesOrder) {
      return models.sort((m1, m2) => valuesOrder.indexOf(m1) - valuesOrder.indexOf(m2));
    }

    return models;
  }

  static valuesOrder(): EnumModel[] {
    return null;
  }

  static notFound(value: any): EnumModel | null {
    return null
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';

import { DictsService } from '../../../shared/services';
import { ActivityKind, MainStream } from '../../../shared/models';

@Component({
  selector: 'tamo-direction-activity',
  templateUrl: './direction-activity.component.html',
  styleUrls: ['./direction-activity.component.less']
})
export class DirectionActivityComponent implements OnInit {
  public activities$: Observable<ActivityKind[]>;
  public streams: MainStream[] = [];
  @Input() form: FormGroup;

  get f() { return this.form.controls; }

  constructor(private dictsService: DictsService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activities$ = this.dictsService.getActivities()
      .pipe(first());

    this.dictsService.getStreams()
      .pipe(first())
      .subscribe((streams) => {
        this.streams = streams;
      });
  }

  onSelectionChanged(city) {
    this.form.patchValue({ city: city.name });
  }

  onStreamsChanged(evt) {
    const streams = this.f.main_streams.value;
    const value = evt.source.value;
    if(evt.checked) {
      streams.push(value);
    } else {
      streams.splice(streams.findIndex(s => s == value), 1);
    }
    this.form.patchValue({ main_streams: streams });
  }
}

import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError, combineLatest } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';

import { InitPlaces, SetArrivalPlace, SetDeparturePlace } from '../actions/places.actions';
import { ShippingPoint, ShippingType } from '../../models';
import { ShippingPointsService } from '../../services';


export interface PlacesStateModel {
  shippingType: ShippingType,

  arrivalsLoading: boolean,
  arrivalPlaces: ShippingPoint[],
  restrictedArrivalPlacesIds: string[],
  arrivalPlaceId: string,

  departuresLoading: boolean,
  departurePlaces: ShippingPoint[],
  restrictedDeparturePlacesIds: string[],
  departurePlaceId: string,
}

const defaults: PlacesStateModel = {
  shippingType: null,

  arrivalsLoading: false,
  arrivalPlaces: [],
  restrictedArrivalPlacesIds: [],
  arrivalPlaceId: null,

  departuresLoading: false,
  departurePlaces: [],
  restrictedDeparturePlacesIds: [],
  departurePlaceId: null
}

@State<PlacesStateModel>({
  name: 'places',
  defaults
})
@Injectable()
export class PlacesState {
  constructor(
    private store: Store,
    private pointsService: ShippingPointsService) {}

  @Selector([PlacesState])
  static arrivalsLoading(state: PlacesStateModel): boolean {
    return state.arrivalsLoading;
  }

  @Selector([PlacesState])
  static departuresLoading(state: PlacesStateModel): boolean {
    return state.departuresLoading;
  }

  @Selector([PlacesState])
  static arrivalPlaces(state: PlacesStateModel): ShippingPoint[] {
    return state.arrivalPlaces;
  }

  @Selector([PlacesState])
  static departurePlaces(state: PlacesStateModel): ShippingPoint[] {
    return state.departurePlaces;
  }

  @Selector([PlacesState, PlacesState.arrivalPlaces])
  static selectedArrivalPlace(state: PlacesStateModel, places: ShippingPoint[]): ShippingPoint | null {
    return places.find(place => place.id === state.arrivalPlaceId);
  }

  @Selector([PlacesState, PlacesState.departurePlaces])
  static selectedDeparturePlace(state: PlacesStateModel, places: ShippingPoint[]): ShippingPoint | null {
    return places.find(place => place.id === state.departurePlaceId);
  }

  @Selector([PlacesState, PlacesState.arrivalPlaces])
  static filteredArrivalPlaces(state: PlacesStateModel, places: ShippingPoint[]): ShippingPoint[] {   
    if (state.departurePlaceId) {
      return places.filter(place => state.restrictedArrivalPlacesIds.includes(place.id));
    }
    return places;
  }

  @Selector([PlacesState, PlacesState.departurePlaces])
  static filteredDeparturePlaces(state: PlacesStateModel, places: ShippingPoint[]): ShippingPoint[] {
    if (state.arrivalPlaceId) {
      return places.filter(place => state.restrictedDeparturePlacesIds.includes(place.id));
    }
    return places;
  }

  @Action(InitPlaces)
  @ImmutableContext()
  init(
    { setState, getState, dispatch }: StateContext<PlacesStateModel>,
    { shippingType }: InitPlaces
  ) {
    setState((state: PlacesStateModel) => {
      return Object.assign({}, defaults, {
        arrivalsLoading: true,
        departuresLoading: true,
        shippingType
      });
    });

    return combineLatest([
      this.pointsService.placesByType(shippingType, false),
      this.pointsService.placesByType(shippingType, true),
    ]).pipe(
      tap(([departurePlaces, arrivalPlaces]) => setState((state: PlacesStateModel) => {
        state.departurePlaces = departurePlaces;
        state.arrivalPlaces = arrivalPlaces;
        state.arrivalsLoading = false,
        state.departuresLoading = false
        return state;
      })),      
    );
  }

  @Action(SetArrivalPlace)
  @ImmutableContext()
  setArrivalPlace(
    { setState, getState, dispatch }: StateContext<PlacesStateModel>,
    { placeId }: SetArrivalPlace
  ) {
    const { shippingType } = getState();
    setState((state: PlacesStateModel) => {
      state.arrivalPlaceId = placeId;
      state.departuresLoading = true;
      return state;
    });

    return this.pointsService.choicesByType(shippingType, placeId, false).pipe(
      tap((restrictedPlaces: ShippingPoint[]) => setState((state: PlacesStateModel) => {
        if (state.departurePlaceId) {
          const foundPlace = restrictedPlaces.find(place => place.id === state.departurePlaceId);
          if (!foundPlace) {
            state.departurePlaceId = null;
          }
        }

        state.restrictedDeparturePlacesIds = restrictedPlaces.map(place => place.id);
        state.departuresLoading = false;
        return state;
      }))
    )
  }
  
  @Action(SetDeparturePlace)
  @ImmutableContext()
  setDeparturePlace(
    { setState, getState, dispatch }: StateContext<PlacesStateModel>,
    { placeId }: SetDeparturePlace
  ) {
    const { shippingType } = getState();
    setState((state: PlacesStateModel) => {
      state.departurePlaceId = placeId;
      state.arrivalsLoading = true;
      return state;
    });

    return this.pointsService.choicesByType(shippingType, placeId, true).pipe(
      tap((restrictedPlaces: ShippingPoint[]) => setState((state: PlacesStateModel) => {
        if (state.arrivalPlaceId) {
          const foundPlace = restrictedPlaces.find(place => place.id === state.departurePlaceId);
          if (!foundPlace) {
            state.arrivalPlaceId = null;
          }
        }

        state.restrictedArrivalPlacesIds = restrictedPlaces.map(place => place.id);
        state.arrivalsLoading = false;
        return state;
      }))
    )
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DocumentsUploaderModule } from '../documents-uploader/documents-uploader.module';
import { ShippingCalculatorModule } from '../shipping-calculator';
import { ShippingAviaComponent } from './shipping-avia/shipping-avia.component';
import { ShippingAutoComponent } from './shipping-auto/shipping-auto.component';
import { ShippingSeaComponent } from './shipping-sea/shipping-sea.component';
import { ShippingRailwayComponent } from './shipping-railway/shipping-railway.component';

@NgModule({
  declarations: [
    ShippingAviaComponent,
    ShippingAutoComponent,
    ShippingSeaComponent,
    ShippingRailwayComponent
  ],
  imports: [
    CommonModule,
    DocumentsUploaderModule,
    FlexLayoutModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    ShippingCalculatorModule,
    FormsModule
  ],
  exports: [
    ShippingAviaComponent,
    ShippingAutoComponent,
    ShippingSeaComponent,
    ShippingRailwayComponent
  ]
})
export class ShippingModule { }

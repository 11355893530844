import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';

import { LoadTransports } from '../actions/transports.actions';
import { Transport } from '../../models';
import { TransportService } from '../../services';


export interface TransportsStateModel {
  transports: Transport[];
  loading: boolean;
}


@State<TransportsStateModel>({
  name: 'transports',
  defaults: {
    transports: [],
    loading: false
  }
})
@Injectable()
export class TransportsState {
  @Selector([TransportsState])
  static loading(state: TransportsStateModel): boolean {
    return state.loading;
  }

  @Selector([TransportsState])
  static transports(state: TransportsStateModel): Transport[] {
    return state.transports;
  }

  constructor(
    private store: Store,
    private service: TransportService) {}

  @Action(LoadTransports)
  @ImmutableContext()
  loadTransports(
    { setState, getState, dispatch }: StateContext<TransportsStateModel>,
    { }: LoadTransports
  ) {
    setState((state: TransportsStateModel) => {
      state.loading = true;
      return state;
    });

    return this.service.all().pipe(
      map((transports: Transport[]) => {
        return setState((state: TransportsStateModel) => {
          state.transports = transports;
          state.loading = false;
          return state;
        });
      }),
    );
  }
}
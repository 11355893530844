import { Component, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';

import { RemoteErrorsService } from './remote-errors.service';

@Component({
  selector: 'tamo-remote-errors',
  template: '{{ remoteErrors.getErrorMessage(controlName) }}'
})
export class RemoteErrorsComponent {
  @Input() controlName: string;

  constructor(public remoteErrors: RemoteErrorsService) {
  }
}
<ng-container *ngIf="user$ | async as user">
  <div class="service" *ngIf="service">
    <form [formGroup]="serviceForm" class="service-form" ngxsForm="order.serviceForm">   
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="30px">
          <mat-card fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="service__list" *ngIf="service.service_status.id != 'DR'" fxLayout="column" fxLayoutGap="10px">
                  <ng-container
                      *ngFor="let category of categories$ | async; let last = last">
                    <ng-container *ngFor="let srv of category.services">
                      <div *ngIf="service.services.indexOf(srv.id) !== -1"
                          fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between">
                        <div>{{ srv.name }}</div>
                        <div class="service__cost" [innerHTML]="srv.cost"></div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="service__list" *ngIf="service.service_status.id == 'DR'">
                  <ng-container [formGroup]="f.servicesChecked"
                      *ngFor="let category of categories$ | async; let last = last">
                    <h3>{{ category.name }}</h3>

                    <ng-container *ngFor="let srv of category.services">
                      <div class="service__item" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between">
                        <div>
                          <mat-checkbox [formControlName]="getControlIndex(srv.id)"
                            [value]="srv.id">{{ srv.name }}</mat-checkbox>
                        </div>
                        <div class="service__cost" [innerHTML]="srv.cost"></div>
                      </div>
                    </ng-container>
                    <mat-divider *ngIf="!last"></mat-divider>
                  </ng-container>
                </div>

                <ng-container *ngIf="isSelected">
                  <div>
                    <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('CNCO')"
                      type="CNCO" title="Документы"></tamo-documents-uploader>
                  </div>

                  <div class="service-form__group" fxFlex>
                    <mat-form-field  appearance="outline" fxFlex="grow">
                      <mat-label>Комментарий</mat-label>
                      <textarea rows="2" matInput placeholder="Комментарий"
                        formControlName="comment" name="comment" autocomplete="off"></textarea>
                    </mat-form-field>
                  </div>
                </ng-container>

              </div>
            </mat-card-content>
          </mat-card>
        </div>

<!--         <mat-card fxFlex *ngIf="isSelected">
          <button type="submit" [disabled]="serviceForm.disabled"
            mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
          <button type="button" [disabled]="serviceForm.disabled" *ngIf="service.service_status.id == 'DR'" (click)="process()"
            mat-raised-button color="accent">Отправить в работу</button>
        </mat-card> -->
      </div>
    </form>
  </div>
</ng-container>
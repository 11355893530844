import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { Certification, PagedResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CertificationService {
  private prefix: string = '/certifications/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(page: number = 1): Observable<PagedResult<Certification>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<Certification>(Certification), res)));
  }

  public get(id: number): Observable<Certification> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(Certification, res)));
  }

  public save(id, data): Observable<Certification> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Certification, res)));
  }

  public create(data): Observable<Certification> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(Certification, res)));
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, of, combineLatest } from 'rxjs';
import { plainToClass } from "class-transformer";
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { DadataOrg } from '../../dadata';
import { OrderState, UpdateService, AuthState } from '../../shared/store';
import {
  Sign,
  SignService,
  ReactiveComponent,
  AuthUser
} from '../../shared';

@Component({
  selector: 'tamo-sign-details',
  templateUrl: './sign-details.component.html',
  styleUrls: ['./sign-details.component.less']
})
export class SignDetailsComponent extends ReactiveComponent {
  @Input()
  public service: Sign;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  public serviceForm: FormGroup;

  constructor(private fb: FormBuilder,
              private store: Store,
              private signSrv: SignService) {
    super();

    this.serviceForm = this.fb.group({
      org_name: [''],
      inn: [''],
      ogrn: [''],
      kpp: [''],
      legal_address: [''],
      physical_address: [''],
      director_position: [''],
      director_fio: [''],
      correspondence_address: [''],
      comment: ['']
    });
  }

  get f() {
    return this.serviceForm.controls;
  }

  ngOnInit() {
    this.observePropertyCurrentValue<Sign>('service').pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((service: Sign) => {
      this.serviceForm.patchValue(service);
    });

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.serviceForm.disable();
      } else {
        this.serviceForm.enable();
      }
    });
  }

  // onSubmit(process = false) {
  //   if(!this.serviceForm.valid) {
  //     return;
  //   }

  //   const model = this.serviceForm.value;
  //   const toSave = plainToClass(Sign, model as Object);

  //   this.store.dispatch(new UpdateService(toSave, process));
  // }

  // public process() {
  //   this.onSubmit(true);
  // }

  onSelectionChanged(org: DadataOrg) {
    this.serviceForm.patchValue({
      inn: org.data.inn,
      kpp: org.data.kpp,
      ogrn: org.data.ogrn,
      org_name: org.data.name.short_with_opf,

      director_position: org.data.management ? org.data.management.post : '',
      director_fio: org.data.management ? org.data.management.name : '',

      legal_address: org.data.address ? org.data.address.value : '',
      physical_address: org.data.address ? org.data.address.value : '',
      correspondence_address: org.data.address ? org.data.address.value: ''
    }, { emitEvent: true });
    this.serviceForm.get('inn').markAsDirty();
  }

}

<div class="folder"
	matRipple
	[class.folder_hovered]="hover$ | async"
	(click)="selectFolder($event)"
	(mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)">
	<div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
		<div *ngIf="editable" class="folder__toolbar" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="3px" [fxShow]="hover$ | async">
			<file-manager-toolbar-button [disabled]="disabled" (action)="deleteFolder($event)" icon="delete" color="red" size="22"></file-manager-toolbar-button>
			<file-manager-toolbar-button [disabled]="disabled" (action)="editFolder($event)" icon="edit" color="orange" size="22"></file-manager-toolbar-button>
		</div>
		
		<mat-icon class="folder__icon">folder</mat-icon>
		<div class="folder__title" ellipsis [ellipsis-content]="folder.title"
			[title]="folder.title"></div>
	</div>

	<div class="folder__date">{{ folder.modified | amDateFormat: 'DD.MM.YY HH:mm' }}</div>
</div>
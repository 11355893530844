import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { plainToClass, deserialize } from 'class-transformer';
import { Subject, Subscription, throwError, empty } from 'rxjs';
import { catchError, shareReplay, map, last, tap, finalize } from 'rxjs/operators';
import { Store, ofActionDispatched, Actions } from '@ngxs/store';
import * as shortid from 'shortid';

import { UploadFile, StorageFile, UploadParams } from '../models';
import {
  SetUploaderUrl,
  CancelFileUpload,
  FileUploadStarted,
  FileUploadProgress,
  FileUploadSuccess,
  FileUploadFailed,
  FileUploadCanceled,
  UploadFiles
} from '../store/actions/uploader.actions';


class FileEntry {
  file: File;
  subscription: Subscription;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalUploaderService {
  private uploadUrl: string;

  private filesMap: Map<string, FileEntry> = new Map();

  constructor(private store: Store,
              private actions$: Actions,
              private http: HttpClient) {
    this.actions$.pipe(
      ofActionDispatched(SetUploaderUrl)
    ).subscribe(({ url }: SetUploaderUrl) => {
      this.uploadUrl = url;
    });

    this.actions$.pipe(
      ofActionDispatched(UploadFiles)
    ).subscribe(({ files, params }: UploadFiles) => {
      this.uploadFiles(files, params);
    });

    this.actions$.pipe(
      ofActionDispatched(CancelFileUpload)
    ).subscribe(({ uploadFileId }: CancelFileUpload) => {
      if (this.filesMap.has(uploadFileId)) {
        this.filesMap.get(uploadFileId).subscription.unsubscribe();
        this.store.dispatch(new FileUploadCanceled(uploadFileId));
      }
    });
  }

  uploadFiles(files: File[], params: UploadParams) {
    for (const file of files) {
      const formData = new FormData();

      formData.append('file', file);
      if (params.context) {
        for (const key in params.context) {
          const value = params.context[key];
          if (value !== null && value !== undefined) {
            formData.append(key, value)
          }
        }
      }

      const subscription = this.http.post<any>(params.uploadUrl || this.uploadUrl, formData,
        { reportProgress: true, observe: 'events' }
      ).pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round(100 * event.loaded / event.total);
            this.store.dispatch(new FileUploadProgress(uploadId, progress));
          }
        }),
        last(),
        map((evt: HttpResponse<any>) => plainToClass(StorageFile, evt.body)),
        catchError(err => {
          console.error(err);
          this.store.dispatch(new FileUploadFailed(uploadId, `[${err.status}] response`));
          return empty();
        }),
        finalize(() => {
          this.filesMap.delete(uploadId);
        })
      ).subscribe((file: StorageFile) => {
        this.store.dispatch(new FileUploadSuccess(uploadId, file, params.contextId));
      });

      const uploadId: string = shortid.generate();
      const uploadFile = plainToClass(UploadFile, {
        id: uploadId,
        title: file.name,
        content_type: file.type,
        progress: 0,
        contextId: params.contextId || null
      });

      this.filesMap.set(uploadId, { file, subscription });
      this.store.dispatch(new FileUploadStarted(uploadFile));
    }
  }
}

export enum UserRole {
  BOSS = 'B',
  MANAGER = 'M'
}

export class User {
  id: number;

  username: string;

  password: string;

  email: string;

  role: string;

  isStaff: boolean;

  get isBoss() {
    return this.role == UserRole.BOSS;
  }

  get isManager() {
    return this.role == UserRole.MANAGER;
  }
}
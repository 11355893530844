import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';

import { FileManagerState, CancelFileUpload } from '../../shared/store';
import { UploadFile } from '../../shared/models';
import { ReactiveComponent } from '../../shared/components';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileComponent extends ReactiveComponent {
  private hoverSource$: Subject<boolean> = new Subject();

  public hover$: Observable<boolean> = this.hoverSource$.asObservable();

  @Input()
  file: UploadFile;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
  }

  cancelFile(evt: MouseEvent, file: UploadFile) {
    this.store.dispatch(new CancelFileUpload(file.id));
  }

  onMouseEnter(evt: MouseEvent) {
    this.hoverSource$.next(true);
  }

  onMouseLeave(evt: MouseEvent) {
    this.hoverSource$.next(false);
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(private http: HttpClient) {

  }

  downloadFile(url) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }
}
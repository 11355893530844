<ng-container *ngIf="{
  user: user$ | async
} as ctx">
  <form *ngIf="service" [formGroup]="serviceForm" ngxsForm="order.serviceForm">   
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px">
      <mat-card fxFlex="50%" fxFlex.lt-lg="nogrow">
        <div fxLayout="column" fxLayoutGap="10px">
          <div>
            <mat-form-field fxFlex="grow">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-arrow-down"></mat-icon> Адрес загрузки</mat-label>
              <textarea rows="2" matInput placeholder="Адрес загрузки"
                formControlName="load_address" autocomplete="off"></textarea>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex="grow">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-id-card"></mat-icon> Контакт отправителя</mat-label>
              <textarea rows="2" matInput placeholder="Контакт отправителя"
                formControlName="sender_contact" autocomplete="off"></textarea>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <input matInput formControlName="invoice_number" placeholder="Номер инвойса или заказа">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex="grow">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-arrow-up"></mat-icon> Адрес выгрузки</mat-label>
              <textarea rows="2" matInput placeholder="Адрес загрузки"
                formControlName="upload_address" autocomplete="off"></textarea>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex="grow">
              <mat-label><mat-icon fontSet="fa" fontIcon="fa-id-card"></mat-icon> Контакты на выгрузке</mat-label>
              <textarea rows="2" matInput placeholder="Контакт отправителя"
                formControlName="upload_contact" autocomplete="off"></textarea>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <input matInput formControlName="cargo_name" placeholder="Название груза">
            </mat-form-field>
          </div>

          <div>
            <mat-checkbox color="warn" formControlName="insurance">Нужна страховка</mat-checkbox>
          </div>

          <tamo-documents-uploader [disabled]="ctx.user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('SHIP')"
            type="SHIP" title="Документы"></tamo-documents-uploader>

          <div>
            <mat-form-field appearance="outline" fxFlex="grow">
              <mat-label>Комментарий</mat-label>
              <textarea rows="2" matInput placeholder="Комментарий"
                formControlName="comment" autocomplete="off"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card>

      <mat-card fxFlex="50%" fxFlex.lt-lg="nogrow">
        <shipping-calculator-railway [form]="serviceForm"></shipping-calculator-railway>
      </mat-card>
    </div>
  </form>
</ng-container>
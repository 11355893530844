import { Exclude, Type, Expose, Transform, TransformationType } from 'class-transformer';
import { immerable } from 'immer';

import { DocumentOwner } from './document-owner';
import { ServiceType } from './service-type';
import { DocumnetCounts } from './document-counts';
import { ServiceStatus, serviceStatuses } from './service-status';

export abstract class Service implements DocumentOwner {
  [immerable] = true;
  
	id: number;

  @Type(() => ServiceType)
	@Expose({ name: "resourcetype" })
  @Transform(v => ServiceType.getByValue(v), { toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  service_type: ServiceType;

  @Type(() => ServiceStatus)
  @Expose({ name: "status" })
  @Transform(value => serviceStatuses.find(s => s.id == value), { toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  service_status: ServiceStatus;

	resourcetype: string;

	status: string;

	comment: string;

  order: number;

	@Type(() => DocumnetCounts)
	document_counts: DocumnetCounts[];

	abstract getPrefix();

  @Exclude()
  getUploadPrefix() {
    return `/${this.getPrefix()}/${this.id}/`;
  }

  @Exclude()
  getCounts(type: string) {
    const found = this.document_counts.find(
        (data: any) => data.type == type);
    return found ? found.count : 0;
  }

  @Exclude()
  get documents_count() {
    return this.document_counts.reduce(
      (acc, data) => acc + data.count, 0)
  }
}

export class CommonService extends Service {
  getPrefix() {
    return 'services';
  }
}
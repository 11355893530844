import { Expose, Type, Transform, Exclude } from "class-transformer";
import * as moment from 'moment';
import { Moment } from 'moment';

import { Product } from './product';
import { DocumentOwner } from "./document-owner";
import { Country } from "./country";
import { Service } from './service';

export class Declaration extends Service implements DocumentOwner {
  direction: string;
  
  contract: boolean;

  transport: string;

  status: string;

  dt_status: string;

  cargo_dest: string;

  declaration_dest: string;

  tnved_codes: string;

  tnved_confirmed: boolean;

  cert_unknown: boolean;

  direction_unknown: boolean;

  importer: number;

  source_country: string;

  @Type(() => Date)
  @Transform(val => val ? moment(val) : null, { toPlainOnly: true })
  dt_date_filing: Moment;

  dt_number: string;

  @Type(() => Date)
  @Transform(val => val ? moment(val) : null, { toPlainOnly: true })
  dt_date_release: Moment;

  declarant: string;

  payment_state: string;
  
  unread_count: number;

  notifications_count: number;

  declaration_file: string;
  
  released_declaration_file: string;

  @Type(() => Product)
  products: Product[];

  @Exclude()
  get tnved_status() {
    return this.tnved_confirmed ? 'TC' : 'TN';
  }

  @Type(() => Date)
  @Transform(value => {
    if(value) {
      return moment(value).format('YYYY-MM-DD');
    }
    return null;
  }, { toPlainOnly: true })
  td_issue_date: Date = null;

  @Type(() => Date)
  last_status_date: Date = null;

  getPrefix() {
    return 'declarations';
  }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private prefix: string = `/profile/`;

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public save(data: Profile): Observable<Profile> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}`, data)
      .pipe(first(), map(res => plainToClass(Profile, res)));
  }

  public get(): Observable<Profile> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}`)
      .pipe(first(), map(res => plainToClass(Profile, res)));
  }

  public changePassword(oldPassword, newPassword) {
    return this.http
      .put(`${this.apiUrl}${this.prefix}password/`,
        {'old_password': oldPassword, 'new_password': newPassword});
  }

  public resetPassword(username: string, email: string) {
    return this.http
      .post(`${this.apiUrl}${this.prefix}reset-password/`, { username, email });
  }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { NotificationsService } from './notifications.service';
import { Declaration, PagedResult, OrderLog } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DeclarationService {
  private prefix: string = '/declarations/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient,
              private notifyService: NotificationsService) { }

  public all(page: number = 1): Observable<PagedResult<Declaration>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<Declaration>(Declaration), res)));
  }

  public get(id: number): Observable<Declaration> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(Declaration, res)));
  }

  public save(id, data): Observable<Declaration> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Declaration, res)));
  }

  public create(data): Observable<Declaration> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(Declaration, res)));
  }

  public clone(id): Observable<Declaration> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}${id}/clone/`, {})
      .pipe(map(res => plainToClass(Declaration, res)));
  }

  public zip(id): Observable<Blob> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/zip/`, {
        responseType: "blob"
      });
  }

  public getStatuses(id: number): Observable<OrderLog[]> {
    return this.http.get(`${this.apiUrl}${this.prefix}${id}/notifications/`)
      .pipe(map(res => plainToClass(OrderLog, res as Object[])));
  }

  public touch(id: number) {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/notifications/touch/`, {})
      .subscribe(() => this.notifyService.updateCount());
  }
}

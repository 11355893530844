<span class="status__wrapper">
	<span class="status {{ status | orderStatusClass }} mat-elevation-z1" [ngClass]="{
	  'status_small': small,
	  'status_selectable': selectable,
	  'status_clickable': selectable || clickable,
	  'status_disabled': disabled
	}" matRipple [matRippleDisabled]="disabled || (!selectable && !clickable)">
	  <mat-icon class="status__icon">{{ status | orderStatusIcon }}</mat-icon>
	  <span class="status__text">{{ status | orderStatus }}</span>
	  <mat-spinner class="status__loader" *ngIf="loading" diameter="13"></mat-spinner>
	  <mat-icon *ngIf="selectable">arrow_drop_down</mat-icon>
	</span>
</span>
<ng-container *ngIf="user$ | async as user">
  <div class="order">
    <div *ngIf="orderLoading$ | async"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

    <ng-container *ngIf="!(orderLoading$ | async)">
      <ng-container *ngIf="order$ | async as order">
        <div *ngIf="order" class="order__header mat-elevation-z1">
          <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
              <div class="order__id">#{{ order.order_id }}</div>

              <ng-container *ngIf="!user.isStaff">
                <mat-form-field class="order__name">
                  <input matInput autocomplete="off"
                    [ngModel]="order.name" (ngModelChange)="saveName($event)">
                  <mat-spinner *ngIf="orderSaving$ | async" diameter="15" matSuffix></mat-spinner>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="user.isStaff">
                <span>{{ order.name }} ({{ order.client?.fullName }})</span>
              </ng-container>
            </div>

            <ng-container *ngIf="!user.isStaff && user.isBoss">
              <div class="order__manager">
                <strong *ngIf="order.manager">{{ order.manager?.fullName }}</strong>
                <span *ngIf="!order.manager">назначить менеджера</span>
                <button mat-icon-button color="accent" [disabled]="orderSaving$ | async"
                  (click)="openManagersSelect($event)"><mat-icon>person_add</mat-icon></button>
                <button mat-icon-button *ngIf="order.manager" color="warn" [disabled]="orderSaving$ | async"
                  (click)="removeManager($event)"><mat-icon>close</mat-icon></button>
              </div>
            </ng-container>

            <ng-container *ngIf="user.isStaff">
              <div class="order__manager">
                <strong *ngIf="order.manager">{{ order.manager?.fullName }}</strong>
                <span *ngIf="!order.manager">Менеджер не назначен</span>
              </div>
            </ng-container>
          </mat-toolbar>
        </div>

        <mat-drawer-container *ngIf="order" class="order__container" autosize="true">
          <mat-drawer-content>
            <router-outlet></router-outlet>
          </mat-drawer-content>
          <mat-drawer class="order__menu order__menu_collapsed" mode="side" position="start" disableClose="true" opened>
            <mat-nav-list class="order-menu">
              <a mat-list-item
                 routerLink="messages"
                 routerLinkActive="order-menu__item_active"
                 class="order-menu__item"
                 matTooltip="Чат с менеджером"
                 matTooltipPosition="after">
                <mat-icon color="primary" mat-list-icon
                 [matBadge]="unreadMessagesCount" matBadgeOverlap="false"
                 [matBadgeColor]="unreadMessagesCount ? 'accent' : 'warn'"
                 matBadgeOverlap="true">message</mat-icon>
                <span class="order-menu__text">Чат</span>
              </a>
              
              <mat-divider></mat-divider>

              <ng-container *ngIf="user.isStaff">
                <a mat-list-item
                   routerLink="profile"
                   routerLinkActive="order-menu__item_active"
                   class="order-menu__item"
                   matTooltip="Аккаунт"
                   matTooltipPosition="after">
                  <mat-icon color="primary" mat-list-icon>person</mat-icon>
                  <span class="order-menu__text">Чат</span>
                </a>
                
                <mat-divider></mat-divider>
              </ng-container>

              <ng-container *ngIf="epsentServiceTypes$ | async as epsentServiceTypes">
                <h3 mat-subheader *ngIf="!user.isStaff">
                  <span class="order-menu__text">Услуги</span>
                  <button mat-icon-button class="order-menu__add"
                      [disabled]="!epsentServiceTypes.length"
                      color="primary" [matMenuTriggerFor]="servicesMenu"
                      matTooltip="Добавить сервис"
                      matTooltipPosition="after">
                    <mat-icon>add</mat-icon>
                  </button>
                </h3>

                <mat-menu #servicesMenu="matMenu">
                  <button *ngFor="let serviceType of epsentServiceTypes" mat-menu-item
                      [disabled]="orderSaving$ | async"
                      (click)="createService(serviceType)">
                    <mat-icon>{{ serviceType.icon }}</mat-icon>
                    {{ serviceType.title }}
                  </button>
                </mat-menu>
              </ng-container>

              <ng-container *ngFor="let service of services$ | async">
                <a mat-list-item *ngIf="service.service_type as serviceType" 
                  routerLink="services/{{ service.id }}"
                  routerLinkActive="order-menu__item_active"
                  class="order-menu__item"
                  matTooltip="{{ serviceType.title }}"
                  matTooltipPosition="after">
                  <mat-icon color="primary" mat-list-icon>{{ serviceType.icon }}</mat-icon>
                  <span class="order-menu__text">{{ serviceType.title }}</span>
                </a>
              </ng-container>
            </mat-nav-list>

          </mat-drawer>
        </mat-drawer-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
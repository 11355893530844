<ng-container *ngIf="{
  selectedFolder: selectedFolder$ | async,
  foldersLoading: foldersLoading$ | async
} as ctx">
  <div class="folder-header" fxLayout="column" fxLayoutGap="5px"> 
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <ng-container *ngIf="ctx.selectedFolder">
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
          <file-manager-toolbar-button [disabled]="ctx.foldersLoading" size="24" icon="keyboard_backspace"
                  (action)="selectFolderById($event, ctx.selectedFolder.parent)"></file-manager-toolbar-button>
          <span class="folder-header__title" ellipsis [ellipsis-content]="ctx.selectedFolder.title"
            [title]="ctx.selectedFolder.title"></span>
        </div>
      </ng-container>

      <ng-container *ngIf="!ctx.selectedFolder">
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
          <mat-icon>home</mat-icon>
          <span class="folder-header__title">Мои документы</span>
        </div>
      </ng-container>

      <file-manager-toolbar-button *ngIf="editable" [disabled]="ctx.foldersLoading"
        (action)="createFolder($event, ctx.selectedFolder)" size="24"
        icon="add" color="green"></file-manager-toolbar-button>
    </div>        
  </div>
</ng-container>
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, merge, combineLatest } from 'rxjs';
import { map, takeUntil, withLatestFrom, finalize } from 'rxjs/operators';

import { OrderState, UpdateService } from '../shared/store';
import { Shipping, BookingData, BookingService, ShippingService, OrdersService, ReactiveComponent } from '../shared';

@Component({
  selector: 'tamo-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.less']
})
export class BookingComponent extends ReactiveComponent {
  private modelSource$: BehaviorSubject<Shipping> = new BehaviorSubject(null);
  public model$: Observable<Shipping> = this.modelSource$.asObservable();

  private savingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public saving$: Observable<boolean> = this.savingSource$.asObservable();

  public disabled$: Observable<boolean> = combineLatest([this.model$, this.saving$]).pipe(map(([model, saving]) => !saving && model === null));

  constructor(
      private store: Store,
      private ordersService: OrdersService,
      private router: Router,
      private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    merge(this.model$, this.disabled$).pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      setTimeout(() => {
        this.cdRef.detectChanges()
      }, 0)
    });
  }

  saveService() {
    const service = this.modelSource$.getValue();
    if (service) {
      this.savingSource$.next(true)
      service.resourcetype = service.service_type.value;
      this.ordersService.create({
        name: `Заказ "${service.service_type.title}"`,
        manager_id: null,
        services: [service]
      }).pipe(
        finalize(() => this.savingSource$.next(false))
      ).subscribe((order) => {
        this.router.navigate([`app/orders/${order.id}`]);
      });
    }
  }

  onFormFilled(model: Shipping) {
    this.modelSource$.next(model)
  }
}

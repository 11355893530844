import { Expose, Type } from 'class-transformer';

export class ViewableValue {
  value: number;

  text: string;
}

export class RouteInfo {
  @Type(() => ViewableValue)
  @Expose({ name: 'Time' })
  duration: ViewableValue;

  @Type(() => ViewableValue)
  @Expose({ name: 'Length' })
  distance: ViewableValue;
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { Importer, PagedResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ImportersService {
  private prefix: string = '/importers/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(): Observable<Importer[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}`)
      .pipe(map(res => plainToClass(Importer, res as Object[])));
  }

  public get(id: number): Observable<Importer> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(Importer, res)));
  }

  public delete(id: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}${this.prefix}${id}/`);
  }

  public save(id, data): Observable<Importer> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Importer, res)));
  }

  public create(data): Observable<Importer> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(Importer, res)));
  }
}

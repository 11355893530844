import { Exclude, Type } from "class-transformer";

export class Manager {
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  username: string;
  phone: string;
  password: string;
  user_id: number;
  note: string;

  get id() {
    return this.user_id;
  }

  @Exclude()
  get fullName() {
    let result = "";
    if(this.last_name) {
      result += this.last_name;
    }

    if(this.first_name) {
      result += ` ${this.first_name.substring(0, 1)}.`;
    }

    if(this.middle_name) {
      result += ` ${this.middle_name.substring(0, 1)}.`;
    }

    return result;
  }
}
<!-- <mat-card class="card_big"> -->
<ng-container *ngIf="{
  uplaodPrefix: uplaodPrefix$ | async
} as ctx">
  <mat-drawer-container autosize class="profile">
    <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayout.sm="column" class="profile__content">
      <div fxFlex>
        <!-- <mat-card class="card_small"> -->
        <div class="mat-elevation-z4">
          <mat-toolbar>
            <mat-icon class="icon">verified_user</mat-icon>&nbsp;
            <span>Редактирование профия</span>
          </mat-toolbar>
          <!-- <mat-card-content> -->
          <div>
            <mat-tab-group mat-stretch-tabs class="example-stretched-tabs">

              <mat-tab label="Основнная информация">
                <div class="profile__card">
                  <form [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <!-- <div class="changesprof__group">
                      <mat-form-field class="changesprof__field">
                        <input matInput placeholder="Компания" formControlName="company">
                        <mat-icon matSuffix>business_center</mat-icon>
                      </mat-form-field>
                    </div> -->
                    <div class="changesprof__group" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                      <mat-form-field class="changesprof__field" fxFlex="50">
                        <input type="tel" matInput formControlName="phone" placeholder="Телефон" >
                        <mat-icon matSuffix>phone</mat-icon>
                      </mat-form-field>

                      <mat-form-field class="changesprof__field" fxFlex="50">
                        <input matInput placeholder="Email" formControlName="email">
                        <mat-icon matSuffix>alternate_email</mat-icon>
                      </mat-form-field>
                    </div>

                    <div class="changesprof__group">
                      <mat-form-field class="profile__field" fxFlex>
                        <input matInput placeholder="Фамилия" formControlName="last_name">
                      </mat-form-field>
                    </div>

                    <div class="changesprof__group" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                      <mat-form-field class="profile__field" fxFlex="50">
                        <input matInput placeholder="Имя" formControlName="first_name">
                      </mat-form-field>
                      
                      <mat-form-field class="profile__field" fxFlex="50">
                        <input matInput placeholder="Фамилия" formControlName="middle_name">
                      </mat-form-field>
                    </div>

                    <div class="changesprof__group">
                      <kladr-suggest-city ngDefaultControl name="city" [formControl]="f.city"></kladr-suggest-city>
                    </div>

                    <!-- <app-organization [form]="profileForm"></app-organization> -->
                    <div>
                      <button mat-raised-button type="submit" [disabled]="loading || profileForm.disabled"
                        color="accent" class="changepass__submit">Сохранить</button>
                    </div>
                  </form>
                </div>
              </mat-tab>

              <mat-tab label="Пароль">
                <div class="profile__card">
                  <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
                    <div class="changesprof__group">
                      <mat-form-field class="changepass__field">
                        <input matInput type="password" placeholder="Введите старый пароль"
                          formControlName="old_password">
                        <mat-icon matSuffix>lock</mat-icon>
                        <mat-error *ngIf="pwdForm.old_password.invalid && pwdForm.old_password.errors.required">Поле обязательно для заполнения</mat-error>
                        <mat-error *ngIf="pwdForm.old_password.invalid && pwdForm.old_password.errors.remote"><tamo-remote-errors controlName="old_password"></tamo-remote-errors></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="changesprof__group">
                      <mat-form-field class="changepass__field">
                        <input matInput type="password" placeholder="Введите новый пароль"
                          formControlName="new_password">
                        <mat-icon matSuffix>lock</mat-icon>
                        <mat-error *ngIf="pwdForm.new_password.invalid && pwdForm.new_password.errors.required">Поле обязательно для заполнения</mat-error>
                        <mat-error *ngIf="pwdForm.new_password.invalid && pwdForm.new_password.errors.remote"><tamo-remote-errors controlName="new_password"></tamo-remote-errors></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="changesprof__group">
                      <mat-form-field class="changepass__field">
                        <input matInput type="password" placeholder="Повторите пароль"
                          formControlName="confirm_password">
                        <mat-icon matSuffix>lock</mat-icon>
                        <mat-error *ngIf="pwdForm.confirm_password.invalid && pwdForm.confirm_password.errors.match">Проверочный пароль не совпадает</mat-error>
                        <mat-error *ngIf="pwdForm.confirm_password.invalid && pwdForm.confirm_password.errors.required">Поле обязательно для заполнения</mat-error>
                      </mat-form-field>
                    </div>

                    <div>
                      <button mat-raised-button type="submit" [disabled]="loading || passwordSaving"
                        color="accent" class="changepass__submit">Сохранить</button>
                    </div>
                  </form>
                </div>
              </mat-tab>

              <mat-tab label="Нотификации">
                <div class="profile__card">
                  <form [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <div class="changesprof__group">
                      <mat-slide-toggle formControlName="notify_status">Смена статуса</mat-slide-toggle>
                    </div>

                    <div class="changesprof__group">
                      <mat-slide-toggle formControlName="notify_message">Сообщение от модератора</mat-slide-toggle>
                    </div>

                    <div class="changesprof__group">
                      <mat-slide-toggle formControlName="notify_complete">Завершение заказа</mat-slide-toggle>
                    </div>

                    <div>
                      <button mat-raised-button type="submit" [disabled]="loading || profileForm.disabled"
                        color="accent" class="changepass__submit">Сохранить</button>
                    </div>
                  </form>
                </div>
              </mat-tab>

            </mat-tab-group>
          <!-- </mat-card-content> -->
          </div>
        </div>
      </div>

      <div fxFlex>
        <div class="mat-elevation-z4">
          <mat-toolbar>
            <mat-toolbar-row>
              <mat-icon class="icon">public</mat-icon>&nbsp;
              <span>Информация о компании</span>
            </mat-toolbar-row>
          </mat-toolbar>

          <mat-tab-group mat-stretch-tabs class="example-stretched-tabs">
            <mat-tab label="Импортеры">
              <div class="profile__card">
                <tamo-profile-importers
                    (createImporeter)="onCreateImporeter()"
                    (editImporeter)="onEditImporeter($event)"
                  ></tamo-profile-importers>
              </div>
            </mat-tab>
  <!--           <mat-tab label="Основная информация">
              <div class="profile__card">
                <form [formGroup]="organizationFrom" (ngSubmit)="saveOrganization()">
                <app-organization [form]="organizationFrom"></app-organization>
                <div>
                  <button mat-raised-button type="submit" [disabled]="loading || organizationFrom.disabled"
                    color="accent" class="changepass__submit">Сохранить</button>
                </div>
              </form>
              </div>
            </mat-tab> -->
            <mat-tab label="Документы">
              <div class="profile__card">
  <!--               <div *ngIf="profile && profile.contract">
                  <a href="{{ profile.contract }}" target="_blank">Скачать договор</a>
                </div> -->

                <ng-container *ngIf="profile">
                  <tamo-documents-uploader [prefix]="ctx.uplaodPrefix" [disabled]="true" [fileManager]="false"
                    counts="-" type="MACO" title="Договор с клиентом"></tamo-documents-uploader>

                  <tamo-documents-uploader [prefix]="ctx.uplaodPrefix"
                    counts="-" type="PROF" title="ИНН"></tamo-documents-uploader>
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <mat-drawer #importersDrawer mode="over" position="end" class="profile__sidebar"
        (openedStart)="importersOpened = true"
        (closedStart)="importersOpened = false">
      <tamo-profile-importers-form fxFlex="200" *ngIf="importersOpened"
        (importerChange)="onImporterChanged($event)"
        [importer]="editImporter"></tamo-profile-importers-form>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
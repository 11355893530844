import { ArrayDataSource } from '@angular/cdk/collections';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store'
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TreeNode } from '../../shared';
import { FileManagerState } from '../../shared/store';

@Component({
  selector: 'app-folder-select',
  templateUrl: './folder-select.component.html',
  styleUrls: ['./folder-select.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderSelectComponent implements OnInit {
  @Select(FileManagerState.foldersTree)
  foldersTree$: Observable<TreeNode[]>;

  private selectedFolderIdSource$: BehaviorSubject<number | null> = new BehaviorSubject(null);

  selectedFolderId$ = this.selectedFolderIdSource$.asObservable();

  treeControl = new NestedTreeControl<TreeNode>(node => node.children);

  constructor(private store: Store,
              public dialogRef: MatDialogRef<FolderSelectComponent>) { }

  ngOnInit(): void {
  }

  hasChild(_: number, node: TreeNode) {
    return !!node.children && node.children.length > 0;
  }

  selectNode(node: TreeNode) {
    this.selectedFolderIdSource$.next(node.folder.id);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

import { Order, Service, Manager, ServiceType, Message } from '../../models';

export class SelectOrderById {
  static readonly type = '[Order] SelectOrderById';

  constructor(public orderId: number) {}
}

export class PatchOrderName {
  static readonly type = '[Order] PatchOrderName';

  constructor(public name: string) {}
}

export class PatchOrderManager {
  static readonly type = '[Order] PatchOrderManager';

  constructor(public manager: Manager) {}
}

export class PatchService {
  static readonly type = '[Order] PatchService';

  constructor(public data: object) {}
}

export class SelectServiceById {
  static readonly type = '[Order] SelectService';

  constructor(public serviceId: number) {}
}

export class UpdateService {
  static readonly type = '[Order] UpdateService';

  constructor(public toUpdate: Service, public sendToProcess: boolean = false) {}
}

export class AddService {
  static readonly type = '[Order] AddService';

  constructor(public serviceType: ServiceType) {}
}

export class AddServiceSuccess {
  static readonly type = '[Order] AddServiceSuccess';

  constructor(public service: Service) {}
}

export class LoadServiceDocuments {
  static readonly type = '[Order] LoadServiceDocuments';

  constructor() {}
}

export class DownloadDocuments {
  static readonly type = '[Order] DownloadDocuments';

  constructor() {}
}

export class DownloadDocumentsSuccess {
  static readonly type = '[Order] DownloadDocumentsSuccess';

  constructor(public data: Blob, public filename: string) {}
}

export class LoadServiceStatuses {
  static readonly type = '[Order] LoadServiceStatuses';

  constructor() {}
}

export class SendChatMessage {
  static readonly type = '[Order] SendChatMessage';

  constructor(public text: string) {}
}

export class LoadChatMessages {
  static readonly type = '[Order] LoadChatMessages';

  constructor() {}
}

export class SaveServiceForm {
  static readonly type = '[Order] SaveServiceForm';

  constructor(public sendToProcess: boolean = false) {}
}

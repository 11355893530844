import { Expose, Type, Transform, Exclude, Discriminator, JsonSubType } from "class-transformer";
import * as moment from 'moment';
import { immerable } from 'immer';

import { Manager } from "./manager";
import { Service } from './service';
import { Declaration } from './declaration';
import { Shipping } from './shipping';
import { Certification } from './certification';
import { ConditionalRelease } from './conditional-release';
import { Analytics } from './analytics';
import { Consulting } from './consulting';
import { Sign } from './sign';
import { ServiceType } from './service-type';
import { Classification } from './classification';
import { AutoShipping, AviaShipping, RailwayShipping, SeaShipping } from './shipping';


const subTypes: JsonSubType[] = [
  { value: Declaration, name: "Declaration" },
  // { value: Shipping, name: "Shipping" },
  { value: Certification, name: "Certification" },
  { value: Classification, name: "Classification" },
  { value: Consulting, name: "Consulting" },
  { value: Analytics, name: "Analytics" },
  { value: Sign, name: "Sign" },
  { value: ConditionalRelease, name: "ConditionalRelease" },
  { value: AutoShipping, name: "AutoShipping" },
  { value: AviaShipping, name: "AviaShipping" },
  { value: RailwayShipping, name: "RailwayShipping" },
  { value: SeaShipping, name: "SeaShipping" }
];

export const servicesDiscriminator: Discriminator = {
  property: "resourcetype", subTypes
}

export const getServiceCls = (resourceType) => {
  const found = subTypes.find(subType => subType.name === resourceType);
  if (found) {
    return new found.value();
  }
  return Service;
}

export class Order {
  [immerable] = true;
  
  id: number;

  name: string;

  order_id: string;

  account_id: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Manager)
  manager: Manager;
  manager_id: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Manager)
  client: Manager;

  @Type(() => Service, {
    discriminator: servicesDiscriminator,
    keepDiscriminatorProperty: true
  })
  services: any[];
}

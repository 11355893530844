import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, Subject, of } from 'rxjs';
import { shareReplay, takeUntil, filter, mergeMap, distinctUntilChanged, first, tap, map, switchMap } from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { UploadFiles, FileUploadSuccess, CancelFileUpload } from '../../shared/store/actions';
import { FileUploaderState } from '../../shared/store/state';
import { ReactiveComponent } from '../../shared';
import { UploadFile } from '../../shared/models';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.less']
})
export class FileUploaderComponent extends ReactiveComponent implements OnInit {
  public uploadingFiles$: Observable<UploadFile[]>;

  public uploadingFile$: Observable<UploadFile>;

  @Output()
  onCompleteAll: EventEmitter<any> = new EventEmitter();

  @Input()
  private uploadUrl: string;

  @Input()
  buttonTitle: string = 'Выберите с устройства';

  @Input()
  blockTitle: string = 'Перенесите файлы или';

  private fileOverSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public fileOver$: Observable<boolean> = this.fileOverSource$.asObservable();

  public uploading$: Observable<boolean>;

  private uploadFileSource$: Subject<File> = new Subject();

  private uploadFile$: Observable<File> = this.uploadFileSource$.asObservable();

  @Input()
  postData: any[];

  public file: File = null;


  get uploaderContextId() {
    return `file-uploader_${this.uploadUrl}`;
  }

  onValidDrag(event) {
    this.fileOverSource$.next(!!event);
  }

  uploadFile(file: File) {
    this.uploadFileSource$.next(file);

    this.file = null;
    this.cdRef.detectChanges();
  }

  cancelUploading(file: UploadFile) {
    this.store.dispatch(new CancelFileUpload(file.id));
  }

  constructor(
      private store: Store,
      private cdRef: ChangeDetectorRef,
      private actions$: Actions) {
    super();
  }

  ngOnInit() {
    this.uploadingFiles$ = combineLatest([
      this.store.select(FileUploaderState.uploadingFilesByContextId),
      this.observePropertyCurrentValue<string>('uploadUrl')
    ]).pipe(
      map(([filterFn,]) => filterFn(this.uploaderContextId)),
      tap(() => setTimeout(() => this.cdRef.detectChanges(), 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.uploadingFile$ = this.uploadingFiles$.pipe(
      map(files => files && files.length > 0 ? files[0] : null)
    )

    this.uploading$ = this.uploadingFile$.pipe(
      map(file => !!file)
    );

    this.actions$.pipe(
      ofActionDispatched(FileUploadSuccess),
      filter(({ uploadFileId, contextId }: FileUploadSuccess) => contextId === this.uploaderContextId)
    ).subscribe(({ uploadFileId, contextId, storageFile }: FileUploadSuccess) => {
      this.onCompleteAll.emit();
    });

    this.uploadFile$.pipe(
      takeUntil(this.ngUnsubscribe$),
      switchMap((file) => combineLatest([
        of(file), this.uploading$.pipe(first())
      ])),
      filter(([, uploading]) => !uploading)
    ).subscribe(([file]) => {
      this.store.dispatch(new UploadFiles([file], {
        contextId: `${this.uploaderContextId}`,
        context: this.postData,
        uploadUrl: this.uploadUrl
      }));
    });
  }
}

<div [formGroup]="form" fxLayout="column" fxLayoutGap="20px" class="shipping-sea">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxFlex>
      <h4 class="shipping-form__title">Доставка по морю</h4>

      <div>
        <div fxLayout fxLayoutGap="10px" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start">
          <mat-form-field class="shipping-sea__place-field">
            <input matInput
              #departureListTrigger="matAutocompleteTrigger"
              formControlName="departure_place" [matAutocomplete]="departureList">

            <mat-label><mat-icon fontSet="fa" fontIcon="fa-anchor"></mat-icon> Порт загрузки</mat-label>
            <mat-hint>Доступно <strong>{{ availableDeparturesCount$ | async }}</strong></mat-hint>
            <mat-hint align="end"><mat-spinner *ngIf="departuresLoading$ | async" diameter="13"></mat-spinner></mat-hint>

            <button matSuffix mat-button *ngIf="f.departure_place.value" mat-icon-button
                [disabled]="departuresLoading$ | async" aria-label="Очистить"
                (click)="clearAutocomplete(departureListTrigger, f.departure_place)">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #departureList="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let place of filteredDeparturePlaces$ | async" [value]="place">
                {{ place.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-icon fxHide.lt-md>arrow_forward</mat-icon>
        
          <mat-form-field class="shipping-sea__place-field">
            <input matInput
              #arrivalListTrigger="matAutocompleteTrigger"
              formControlName="arrival_place" [matAutocomplete]="arrivalList">
            <mat-label><mat-icon fontSet="fa" fontIcon="fa-anchor"></mat-icon> Порт выгрузки</mat-label>
            <mat-hint>Доступно <strong>{{ availableArrivalsCount$ | async }}</strong></mat-hint>
            <mat-hint align="end"><mat-spinner *ngIf="arrivalsLoading$ | async" diameter="13"></mat-spinner></mat-hint>

            <button matSuffix mat-button *ngIf="f.arrival_place.value" mat-icon-button
                [disabled]="arrivalsLoading$ | async" aria-label="Очистить"
                (click)="clearAutocomplete(arrivalListTrigger, f.arrival_place)">
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #arrivalList="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let place of filteredArrivalPlaces$ | async" [value]="place">
                {{ place.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>


    <ng-container *ngIf="placesSelected$ | async">
      <div>
        <mat-form-field>
          <mat-spinner *ngIf="containersLoading" diameter="15" matSuffix></mat-spinner>
          <mat-label><mat-icon fontSet="fa" fontIcon="fa-cube"></mat-icon> Тип контейнера</mat-label>
          <mat-hint align="end"><mat-spinner *ngIf="containersLoading$ | async" diameter="13"></mat-spinner></mat-hint>
          <mat-select formControlName="container_type" [compareWith]="compareContainerTypeFn">
            <mat-option *ngFor="let container of containers$ | async" [value]="container">
              {{ container.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button type="button" class="btn-help" mat-button color="accent"
            tamoRemoteHint="shs_1-1">Какой тип контейнера выбрать?
          <mat-icon>help</mat-icon>
        </button>
      </div>

      <h4 class="shipping-form__title">Доставка от порта</h4>
      <div *ngIf="placesSelected$ | async">
        <div>
          <mat-checkbox color="accent"
            formControlName="delivery_local">В пределах города выгрузки</mat-checkbox>
        </div>
        <div style="width: 230px" *ngIf="!f.delivery_local.value">
          <kladr-suggest-city class="shipping__auto_field"
            (optionSelected)="onAddressSelected($event)"
            appearance="legacy" placeholder="Город доставки"
            ngDefaultControl [formControl]="f.delivery_address"></kladr-suggest-city>
        </div>
      </div>

      <div>
        <mat-form-field>
          <input matInput formControlName="cargo_weight" autocomplete="off">
          <mat-label><mat-icon fontSet="fa" fontIcon="fa-weight-hanging"></mat-icon> Вес груза</mat-label>
          <span matSuffix>кг</span>
          <mat-hint align="start">{{ f.cargo_weight.value / 1000 | number }}</mat-hint>
          <mat-hint align="end">тонн</mat-hint>
        </mat-form-field>

        <button type="button" class="btn-help" mat-button color="accent"
            tamoRemoteHint="shs_1-2">Ограничения по весу
          <mat-icon>help</mat-icon>
        </button>
      </div>

    </ng-container>
  </div>

  <div *ngIf="mainFormFilled$ | async" fxLayout="row">
    <div fxFlex="nogrow" fxFlex.lt-lg="100%" class="shipping__result shipping-result" fxLayout="column" fxLayoutGap="10px">

      <ng-container *ngIf="!(tariffLoading$ | async) else tariffComputing">

        <ng-container *ngIf="(tariffs$ | async)?.length > 0 else tariffNotFound">

          <ng-container *ngFor="let tariff of tariffs$ | async">
            <tamo-shipping-result
              [departurePlace]="(departurePlace$ | async)?.name" departureIcon="anchor"
              [arrivalPlace]="(arrivalPlace$ | async)?.name" arrivalIcon="anchor"
              [cost]="tariff.cost" [currency]="tariff.cost_currency"
              routeIcon="ship">
              
              <ng-template #resultInfoTemplate>
                <div><mat-icon fontSet="fa" fontIcon="fa-clock" [inline]="true"></mat-icon> {{ tariff.duration }} дн.</div>
                <div><mat-icon fontSet="fa" fontIcon="fa-cube" [inline]="true"></mat-icon> {{ tariff.container_type.name }}</div>
              </ng-template>

              <ng-template *ngIf="tariff.date_end" #resultNoteTemplate>
                <span><strong>*</strong> Ставка действительна до <strong>{{ tariff.date_end | amLocal | amDateFormat: 'DD.MM.YYYY' }}</strong></span>
              </ng-template>
            </tamo-shipping-result>
          </ng-container><!-- Tariffs -->

          <ng-container *ngIf="deliveryFormFilled$ | async">
            <ng-container *ngIf="!(weightExceeded$ | async) else weightExceeded">

              <ng-container *ngIf="!(deliveryLoading$ | async) else deliveryLoading">
                <ng-container *ngIf="(deliveryResult$ | async) as deliveryResult else deliveryNotFound">

                  <tamo-shipping-result
                    [departurePlace]="deliveryResult.departureAddress" departureIcon="anchor"
                    [arrivalPlace]="deliveryResult.arrivalAddress" arrivalIcon="map-marker"
                    [cost]="deliveryResult.cost" currency="RUB"
                    routeIcon="truck">

                    <ng-template #resultInfoTemplate>
                      <ng-container>
                        <div><mat-icon fontSet="fa" fontIcon="fa-clock" [inline]="true"></mat-icon> {{ deliveryResult.duration }}</div>
                        <div *ngIf="deliveryResult.distance"><mat-icon fontSet="fa" fontIcon="fa-arrows-alt-h" [inline]="true"></mat-icon> {{ deliveryResult.distance }}</div>
                      </ng-container>

                      <div><mat-icon fontSet="fa" fontIcon="fa-weight-hanging" [inline]="true"></mat-icon> {{ f.cargo_weight.value }} кг</div>
                    </ng-template>
                  </tamo-shipping-result>

                </ng-container>

                <ng-template #deliveryNotFound>
                  <div class="shipping-result__block shipping-result__block_error">Не удалось рассчитать маршрут</div>
                </ng-template>
              </ng-container>

              <ng-template #deliveryLoading>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </ng-template>
            </ng-container>

            <ng-template #weightExceeded>
              <div class="shipping-result__block shipping-result__block_error">
                У Вас очень тяжелый груз на который нужно оформлять отдельное разрешение.<br>
                Пожалуйста, укажите вес  до 26 тонн, либо отправьте отдельный запрос на перевозку тяжелого груза.
              </div>
            </ng-template>
          </ng-container><!-- Delivery -->

        </ng-container><!-- Common block -->

        <ng-template #tariffNotFound><div class="shipping-result__block shipping-result__block_error">Тариф не найден</div></ng-template>

      </ng-container><!-- Wrapper block -->

      <ng-template #tariffComputing><mat-progress-bar mode="indeterminate"></mat-progress-bar></ng-template>

    </div>
  </div>

</div>

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule }  from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MomentModule } from 'ngx-moment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ToastrModule } from 'ngx-toastr';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppRoutingModule } from './app-routing.module';
import { OrdersModule } from './orders/orders.module';
import { HintsModule } from './hints/hints.module';
import { RemoteHintService } from './hints/remote-hint.service';
import { AuthModule } from './auth/auth.module';
import { AuthErrorInterceptor } from './auth/auth.error.interceptor';
import { ProfileModule } from './profile/profile.module';
import { QuestionaryModule } from './questionary/questionary.module';
import { YMapsService } from './ymaps';
import { ManagersModule } from './managers';
import { StubModule } from './stub';
import { ConditionalReleaseModule } from './conditional-release';
import { IframeCalculatorModule } from './iframe-calculator';
import { BookingModule } from './booking';
import { NewsModule } from './news';
import { DocumentsModule } from './documents';
import { OrderState, AuthState, PlacesState, OrdersState, NewsState, FileManagerState, CountriesState, TransportsState, FileUploaderState } from './shared/store';
import { PendingChangesGuard } from './shared/guards';

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('auth.access'));
}

export const DateFormat = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // AnalyticsModule,
    AppRoutingModule,
    BrowserModule,
    IframeCalculatorModule,
    // ConsultingModule,
    // CertificationModule,
    ConditionalReleaseModule,
    // ClassificationModule,
    DashboardModule,
    // DeclarationModule,
    OrdersModule,
    HintsModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    ManagersModule,
    NewsModule,
    RouterModule,
    MomentModule,
    AuthModule,
    ProfileModule,
    QuestionaryModule,
    DocumentsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    // SignModule,
    StubModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.domains,
        blacklistedRoutes: [`${environment.api}/token/`, `${environment.api}/register/`],
        authScheme: 'JWT '
      }
    }),
    NgxsModule.forRoot([
      AuthState, OrderState, PlacesState, OrdersState, NewsState, FileManagerState, CountriesState, TransportsState, FileUploaderState
    ], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false
      }
    }),
    NgxsFormPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsWebsocketPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.access', 'auth.refresh', 'auth.username']
    })
  ],
  providers: [
    RemoteHintService,
    // OrderStatusPipe,
    YMapsService,
    { provide: 'dadataToken', useValue: environment.dadataToken },
    { provide: 'apiUrl', useValue: environment.api },
    { provide: 'mediaUrl', useValue: environment.media },
    { provide: 'socketUrl', useValue: environment.socket },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat },
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

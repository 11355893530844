import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription, timer, of } from 'rxjs';
import { map, distinctUntilChanged, filter, takeUntil, retryWhen, delayWhen, concatMap, tap, first, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { plainToClass, classToPlain } from "class-transformer";
import { DisconnectWebSocket, ConnectWebSocket, WebSocketDisconnected, WebSocketConnected } from '@ngxs/websocket-plugin';
import { Store, Select, Actions, ofActionDispatched, ofActionErrored, ofActionCompleted, ofActionSuccessful } from '@ngxs/store';

import { AuthService, User, Message, OrderLog, AuthUser } from '../shared';
import { AuthState, Logout, AuthComplete, RefreshAuth } from '../shared/store';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  @Select(AuthState.refreshing)
  refreshing$: Observable<boolean>;

  @Select(AuthState.authenticated)
  authenticated$: Observable<boolean>;

  private socketOpenSource$: Subject<null> = new Subject();

  private socketCloseSource$: Subject<null> = new Subject();

  public socketOpen$ = this.socketOpenSource$.asObservable();

  public socketClose$ = this.socketCloseSource$.asObservable();

  constructor(
      @Inject('socketUrl') private socketUrl: string,
      private store: Store,
      private actions$: Actions,
      private authService: AuthService) {

    this.socketOpen$.pipe(
      tap(() => {
        const token = this.store.selectSnapshot(AuthState.accessToken);
        if (this.authService.isTokenExpired(token)) {
          this.store.dispatch(new RefreshAuth());
        }
      }),
      switchMap(() => this.refreshing$.pipe(
        takeUntil(this.socketClose$),
        first(refreshing => !refreshing)
      ))
    ).subscribe(() => {
      if (!this.store.selectSnapshot(AuthState.authenticated)) {
        return;
      }

      const token = this.store.selectSnapshot(AuthState.accessToken);
      this.store.dispatch(new ConnectWebSocket({ url: `${this.socketUrl}/?${token}` }));
    })

    this.socketClose$.subscribe(() => this.store.dispatch(new DisconnectWebSocket()))

    this.actions$.pipe(
      ofActionDispatched(WebSocketDisconnected)
    ).subscribe(action => {
      if (this.store.selectSnapshot(AuthState.authenticated)) {
        setTimeout(() => {
          this.socketOpenSource$.next();
        }, 5000)
      }      
    });

    this.authenticated$.pipe(
      filter(authenticated => authenticated),
    ).subscribe(() => {
      this.socketOpenSource$.next();
    });

    this.authenticated$.pipe(
      filter(authenticated => !authenticated),
    ).subscribe(() => {
      this.socketCloseSource$.next();
    });
  }
}
import { UploadFile, StorageFile, UploadParams } from '../../models';

export class SetUploaderUrl {
  static readonly type = '[uploader] SetUploaderUrl';
  constructor(public url: string) {}
}

export class UploadFiles {
  static readonly type = '[uploader] UploadFiles';
  constructor(public files: File[], public params: UploadParams = { }) {}
}

export class CancelFileUpload {
  static readonly type = '[uploader] CancelFileUpload';
  constructor(public uploadFileId: string) {}
}

export class FileUploadStarted {
  static readonly type = '[uploader] FileUploadStarted';
  constructor(public uploadFile: UploadFile) {}
}

export class FileUploadProgress {
  static readonly type = '[uploader] FileUploadProgress';
  constructor(public uploadFileId: string, public progress: number) {}
}

export class FileUploadSuccess {
  static readonly type = '[uploader] FileUploadSuccess';
  constructor(public uploadFileId: string, public storageFile: StorageFile, public contextId: string) {}
}

export class FileUploadFailed {
  static readonly type = '[uploader] FileUploadFailed';
  constructor(public uploadFileId: string, public error: any) {}
}

export class FileUploadCanceled {
  static readonly type = '[uploader] FileUploadCanceled';
  constructor(public uploadFileId: string) {}
}
import { Exclude, Transform, Type } from 'class-transformer';

import { Service } from './service';
import { DocumentOwner } from './document-owner'


export class Certification extends Service implements DocumentOwner {
	certification_type: string;

	description: string;

	manufacturer: string;

	party_number: string;

	invoice_number: string;

	invoice_date: string;

	contract_number: string;

	contract_date: string;

  org_name: string;

	inn: string;

	ogrn: string;

	legal_address: string;

	physical_address: string;

  correspondence_address: string;

	director_position: string;

	director_fio: string;

  kpp: string;

  getPrefix() {
    return 'certifications';
  }  
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { switchMap, takeUntil, finalize, tap, filter, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { plainToClass } from 'class-transformer';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flash } from 'ng-animate';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

import { AuthState, OrderState, LoadChatMessages, SendChatMessage, AddChatMessage } from '../../shared/store';
import {
  Message,
  MessagesService,
  AuthService,
  ReactiveComponent,
  Order,
  AuthUser
} from '../../shared';

@Component({
  selector: 'app-orders-messages',
  templateUrl: './orders-messages.component.html',
  styleUrls: ['./orders-messages.component.less'],
  animations: [
    trigger('flash', [transition('* => pushed', useAnimation(flash))])
  ]
})
export class OrdersMessagesComponent extends ReactiveComponent implements OnInit {
  @ViewChild('form', { static: false })
  private formValues;

  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  @Select(OrderState.orderedMessages)
  messages$: Observable<Message[]>;

  @Select(OrderState.messagesLoading)
  loading$: Observable<boolean>;

  @Select(OrderState.messageSending)
  sending$: Observable<boolean>;

  @Select(OrderState.selectedOrder)
  order$: Observable<Order>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  disabled$: Observable<boolean> = combineLatest([this.loading$, this.sending$])
    .pipe(map(([loading, sending]) => loading || sending));
  
  public flash: any;

  public messageForm: FormGroup;

  public newMessages: number[] = [];

  constructor(
      private store: Store,
      private route: ActivatedRoute,
      private actions$: Actions,
      private messagesService: MessagesService,
      private fb: FormBuilder) {
    super();

    this.messageForm = this.fb.group({
      message: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
    });
  }

  get f() { return this.messageForm.controls; }

  ngOnInit() {
    this.route.parent.params.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((messages: Message[]) => {
      this.store.dispatch(new LoadChatMessages());
    });

    this.sending$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(sending => {
      if (sending) {
        this.messageForm.disable()
      } else {
        this.messageForm.enable();
      }
    })

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(AddChatMessage)
    ).subscribe(({ data }) => {
      this.formValues.resetForm();
      this.scrollChatToBottom(200);
      this.newMessages.push(data.id);
    });

    this.loading$.pipe(
      takeUntil(this.ngUnsubscribe$),
      filter(loading => !loading)
    ).subscribe(() => {
      this.scrollChatToBottom();
      // this.virtualScroll.scrollInto(item);
    });
  }

  checkInputSubmit(event: KeyboardEvent): void {
    if(event.keyCode === 13 && event.ctrlKey) {
      this.onSubmit();
    }
  }

  onSubmit() {
    if(this.messageForm.invalid) {
      return;
    }

    const model = this.messageForm.value;
    this.store.dispatch(new SendChatMessage(model.message));
  }

  trackByMessageId(index, item) {
    return item.id;
  }

  scrollChatToBottom(animationSpeed = 0) {
    setTimeout(() => {
      const messages = this.store.selectSnapshot(OrderState.messages);
      if (messages.length > 0) {
        this.virtualScroller.scrollToIndex(messages.length - 1, true, 0, animationSpeed);
      }
    }, 0);
  //   setTimeout(() => {
  //     this.scrollToService.scrollTo({
  //       target: 'tnvedCodes'
  //     });

  //     // try {
  //     //   this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  //     // } catch(err) {}
  //   }, 0)
  }
}

<div class="sms-confirmation" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
  <mat-form-field class="sms-confirmation__field" fxFlex="40">
    <input matInput class="sms-confirmation__input"
      [formControl]="codeCtrl"
      type="text"
      autocomplete="none"
      placeholder="SMS код"
      maxlength=6>
      <mat-hint *ngIf="state != State.START">Введите код из SMS</mat-hint>
  </mat-form-field>
  <div fxFlex="auto">
    <button class="sms-confirmation__btn"
      type="button"
      color="primary"
      [disabled]="(state != State.START && state != State.READY) || disabled"
      (click)="sendSms()"
      mat-raised-button>
        <span *ngIf="state == State.START">Получить SMS</span>
        <span *ngIf="state == State.READY">Повторить</span>
        <span *ngIf="state == State.WAITING">Повторить, {{ timeout }} сек</span>
        <span *ngIf="state == State.SENDING">Идет отправка <mat-spinner class="sms-confirmation__spinner"
          diameter="18" matSuffix></mat-spinner></span>
    </button>
  </div>
  <div fxFlex>
    <button
      [disabled]="state == State.WAITING || disabled"
      (click)="cancel()"
      mat-icon-button color="warn" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
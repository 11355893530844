import { Expose, Exclude, Type, plainToClass } from "class-transformer";

export class PagedResult<T> {
  @Exclude()
  private type: Function;

  count: number;

  page_size: number;

  page_number: number;

  @Type(options =>(options.newObject as PagedResult<T>).type)
  results: T[];

  constructor(type: Function) {
    this.type = type;
  }
}    

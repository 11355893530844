import {
  Component,
  OnInit,
  Input
} from '@angular/core';

@Component({
  selector: 'tamo-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.less']
})
export class OrdersStatusComponent {
  @Input()
  public status: string;

  @Input()
  public small: boolean = false;

  @Input()
  public selectable: boolean = false;

  @Input()
  public clickable: boolean = false;

  @Input()
  public loading: boolean = false;

  @Input()
  public disabled: boolean = false;

  constructor() { }

}

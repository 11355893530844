import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';

import { ConfirmationDialogModule } from '../confirmation-dialog';
import { DadataModule } from '../dadata/dadata.module';
import { KladrModule } from '../kladr/kladr.module';
import { ProfileComponent } from './profile/profile.component';
import { OrganizationComponent } from './partial/organization/organization.component';
import { RemoteErrorsModule } from '../remote-errors/remote-errors.module';
import { DocumentsUploaderModule } from '../documents-uploader/documents-uploader.module';
import { ProfileImportersComponent } from './profile-importers/profile-importers.component';
import { ProfileImportersFormComponent } from './profile-importers-form/profile-importers-form.component';

@NgModule({
  imports: [
    CommonModule,
    ConfirmationDialogModule,
    DocumentsUploaderModule,
    RouterModule,
    MatInputModule, 
    MatDividerModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatListModule,
    ReactiveFormsModule,
    DadataModule,
    KladrModule,
    RemoteErrorsModule,
    ToastrModule
  ],
  declarations: [
    ProfileComponent,
    OrganizationComponent,
    ProfileImportersComponent,
    ProfileImportersFormComponent
  ]
})
export class ProfileModule { }

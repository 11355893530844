import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(order: number): Observable<Product[]> {
    return this.http.get(`${this.apiUrl}${this.prefix(order)}`)
      .pipe(first(), map(res => plainToClass(Product, res as Object[])));
  }

  public save(order: number, id: number, data: Product): Observable<Product> {
    return this.http
      .put(`${this.apiUrl}${this.prefix(order)}${id}/`, data)
      .pipe(first(), map(res => plainToClass(Product, res)));
  }

  public create(order: number, data: Product): Observable<Product> {
    return this.http
      .post(`${this.apiUrl}${this.prefix(order)}`, data)
      .pipe(first(), map(res => plainToClass(Product, res)));
  }

  public remove(order: number, id: number) {
    return this.http.delete(`${this.apiUrl}${this.prefix(order)}${id}/`);
  }

  private prefix(order: number) {
    return `/declarations/${order}/products/`;
  }
}
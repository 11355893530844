<ng-select
    class="dadata-select"
    placeholder="Поиск организации по базе"
    typeToSearchText="ИНН/ОГРН, ФИО, название, адрес"
    loadingText="Загрузка..."
    notFoundText="Организация не найдена"
    bindLabel="value"
    [appendTo]="appendTo"
    [items]="variants$ | async"
    [trackByFn]="trackByFn"
    [loading]="loading"
    [typeahead]="typeheadInput$"
    [formControl]="formControl"
    [clearable]="false"
    (change)="onSelectionChanged($event)">
  <ng-template ng-option-tmp let-item="item">
    <div class="dadata-item">
      <div class="dadata-item__top">
        <div class="dadata-item__value">{{ item.value }}</div>
        <div class="dadata-item__inn">{{ item.data.inn }}</div>
      </div>
      <div class="dadata-item__address">{{ item.data.address.value }}</div>
    </div>
  </ng-template>
</ng-select>
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Notification } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private countSource: BehaviorSubject<number | null> = new BehaviorSubject(null);
  public count$: Observable<number | null> = this.countSource.asObservable();

  private prefix: string = '/notifications/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(): Observable<Notification[]> {
    return this.http.get(`${this.apiUrl}${this.prefix}`)
      .pipe(map(res => plainToClass(Notification, res as Object[])));
  }

  public updateCount() {
    return this.http.get(`${this.apiUrl}${this.prefix}unread/`)
      .pipe(map((res: any) => parseInt(res)))
      .subscribe(count => this.countSource.next(count));
  }
}
<ng-container *ngIf="{
  foldersTree: foldersTree$ | async,
  selectedFolderId: selectedFolderId$ | async
} as ctx">
  <h2 mat-dialog-title>Выберите папку для перемещения</h2>

  <div mat-dialog-content>
    <mat-tree [dataSource]="ctx.foldersTree" [treeControl]="treeControl" class="folder-select">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
          <button mat-icon-button disabled></button>
          <mat-icon>folder</mat-icon>
          <span class="folder-select__title" [class.folder-select__title_active]="ctx.selectedFolderId === node.folder.id"
            (click)="selectNode(node)" matRipple>{{ node.folder.title }}</span>
        </div>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li class="folder-select__item">
          <div class="mat-tree-node">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.folder.title">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
              <mat-icon>folder</mat-icon>
              <span class="folder-select__title" [class.folder-select__title_active]="ctx.selectedFolderId === node.folder.id"
                (click)="selectNode(node)" matRipple>{{ node.folder.title }}</span>
            </div>
          </div>
          <ul class="folder-select__list" [class.folder-select__list_hidden]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div mat-dialog-actions class="folder-select__actions">
    <button mat-button (click)="closeDialog()">Отменить</button>
    <button [mat-dialog-close]="null" mat-raised-button color="primary" cdkFocusInitial>Выбрать корень</button>
    <button [disabled]="!ctx.selectedFolderId" [mat-dialog-close]="ctx.selectedFolderId"
      mat-raised-button color="accent" cdkFocusInitial>Выбрать папку</button>
  </div>
</ng-container>
<h3 mat-dialog-title>Создание услуги "{{ serviceType.title }}"</h3>
<div mat-dialog-content fxLayout="column">
  <mat-form-field fxFlex>
    <mat-label>Название заказа</mat-label>
    <input matInput [(ngModel)]="serviceName">
  </mat-form-field>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button (click)="onNoClick()">Отмена</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="serviceName" cdkFocusInitial>Создать</button>
</div>
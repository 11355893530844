import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { Duty } from '../shared';
import { DutiesSearchModalComponent } from './duties-search-modal.component';

@Component({
  selector: 'tamo-duties-search',
  templateUrl: './duties-search.component.html',
  styleUrls: ['./duties-search.component.less']
})
export class DutiesSearchComponent implements OnInit {
  @Output()
  public dutySelect: EventEmitter<Duty> = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDailog() {
    const dialogRef = this.dialog.open(DutiesSearchModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.dutySelect.next(result);
    });
  }
}
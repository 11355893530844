import { Injectable } from '@angular/core';
import { get } from 'scriptjs';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { filter, shareReplay, first, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

declare const ymaps: any;

@Injectable({
  providedIn: 'root'
})
export class YMapsService {
  private static YMAPS_VERSION: string = '2.1';
  
  private static YMAPS_URLS: string =
    `https://api-maps.yandex.ru/${YMapsService.YMAPS_VERSION}/`;
  
  private libraryLoadedSource$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  public libraryLoaded$: Observable<boolean> =
    this.libraryLoadedSource$.asObservable().pipe(
      filter(loaded => loaded),
      first(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  constructor() {
    get(`${YMapsService.YMAPS_URLS}?lang=ru_RU&apikey=${environment.yandexToken}`, () => {
      ymaps.ready(() => {
        this.libraryLoadedSource$.next(true); 
      });
    });
  }

  public getRoute(routeStart: string, routeEnd: string) {
    // console.log(routeStart);
    // console.log(routeEnd);
    return this.libraryLoaded$.pipe(
      mergeMap(() => from(ymaps.route([routeStart, routeEnd])))
    );
  }
}
import { EnumModel } from './enum';

export class Status extends EnumModel {
  static readonly STATUS_DRAFT = new Status('STATUS_DRAFT', 'DR', 'черновик', 'fiber_new', 'status_draft', '#BDBDBD');
  static readonly STATUS_WAIT = new Status('STATUS_WAIT', 'WA', 'запрошены документы', 'access_time', 'status_wait', '#FFEB3B');
  static readonly STATUS_CHECKING = new Status('STATUS_CHECKING', 'CH', 'на проверке', 'search', 'status_checking', '#1AA395');
  static readonly STATUS_PROCESS = new Status('STATUS_PROCESS', 'PR', 'заказ в работе', 'settings', 'status_processing', '#03A9F4');
  static readonly STATUS_COMPLETE = new Status('STATUS_COMPLETE', 'CO', 'заказ выполнен', 'check', 'status_complete', '#8BC34A');
  static readonly STATUS_PAYMENT = new Status('STATUS_PAYMENT', 'PA', 'ожидает оплаты', 'payment', 'status_payment', 'orange');
  static readonly STATUS_CLOSED = new Status('STATUS_CLOSED', 'CL', 'заказ закрыт', 'lock', 'status_closed', '#246C60');

  private constructor(
    key: string, value: string,
    public readonly title: string,
    public readonly icon: string,
    public readonly cls: string,
    public readonly color: string) {
    super(key, value);
  }

  static notFound(value: any): Status {
    return new Status('UNKNOWN', value,  value, '', 'status_default', 'black');
  }
}

export class ServiceStatus {
  id: string;

  label: string;

  class: string;

  icon: string;
}

export const serviceStatuses: ServiceStatus[] = [
  {
    'id': 'DR',
    'label': 'черновик',
    'class': 'status_draft',
    'icon': 'fiber_new'
  },
  {
    'id': 'WA',
    'label': 'запрошены документы',
    'class': 'status_wait',
    'icon': 'access_time'
  },
  {
    'id': 'CH',
    'label': 'на проверке',
    'class': 'status_checking',
    'icon': 'search'
  },
  {
    'id': 'PR',
    'label': 'заказ в работе',
    'class': 'status_processing',
    'icon': 'settings'
  },
  {
    'id': 'CO',
    'label': 'заказ выполнен',
    'class': 'status_complete',
    'icon': 'check'
  },
  {
    'id': 'PA',
    'label': 'ожидает оплаты',
    'class': 'status_payment',
    'icon': 'payment'
  },
  {
    'id': 'CL',
    'label': 'заказ закрыт',
    'class': 'status_closed',
    'icon': 'lock'
  },
  {
    'id': 'RD',
    'label': 'ДТ готова к подаче',
    'class': 'status_dt',
    'icon': 'event_note'
  },
  {
    'id': 'FI',
    'label': 'ДТ подана',
    'class': 'status_dt',
    'icon': 'event_note'
  },
  {
    'id': 'AS',
    'label': 'ДТ присвоена',
    'class': 'status_dt',
    'icon': 'event_note'
  },
  {
    'id': 'CR',
    'label': 'ДТ условно выпущена',
    'class': 'status_dt',
    'icon': 'event_note'
  },
  {
    'id': 'RL',
    'label': 'ДТ выпущена',
    'class': 'status_dt',
    'icon': 'event_note'
  },
  {
    'id': 'TC',
    'label': 'ТНВЭД',
    'class': 'status_tnved status_tnved-completed',
    'icon': 'check'
  },
  {
    'id': 'TN',
    'label': 'ТНВЭД',
    'class': 'status_tnved status_tnved-not-completed',
    'icon': 'close'
  },

  {
    'id': 'E',
    'label': 'черновик',
    'class': 'status_draft',
    'icon': 'fiber_new'
  },
  {
    'id': 'D',
    'label': 'ждем документы',
    'class': 'status_wait',
    'icon': 'access_time'
  },
  {
    'id': 'W',
    'label': 'ждем решение',
    'class': 'status_wait',
    'icon': 'access_time'
  },
  {
    'id': 'R',
    'label': 'возвращено',
    'class': 'status_complete',
    'icon': 'check'
  },
  {
    'id': 'O',
    'label': 'списано',
    'class': 'status_error',
    'icon': 'close'
  }
];
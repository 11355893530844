<ng-container *ngIf="{
  uploading: uploading$ | async,
  fileOver: fileOver$ | async,
  uploadingFile: uploadingFile$ | async
} as ctx">
  <mat-card class="video-uploader"
      ngfDrop [file]="file" (fileChange)="uploadFile($event)"
      [ngClass]="{
        'video-uploader_over': ctx.fileOver,
        'mat-elevation-z8': ctx.fileOver || ctx.uploading,
        'video-uploader_uploading': ctx.uploading
      }"
      (fileOver)="onValidDrag($event)">
    <div fxFlex="grow" fxLayout="column" class="video-uploader__content" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span *ngIf="!ctx.uploading"
            fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="video-uploader__icon">cloud_upload</mat-icon>
          {{ blockTitle }}
          <button type="button" class="video-uploader__button" mat-raised-button
            ngfSelect [file]="file" (fileChange)="uploadFile($event)">{{ buttonTitle }}</button>
        </span>
        <span *ngIf="ctx.uploading"
            fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="video-uploader__icon">access_time</mat-icon>
          Идет загрузка файла...
        </span>

        <div class="video-uploader__toolbar" fxLayout="row" fxLayoutAlign="center center" *ngIf="ctx.uploading">
          <button type="button" mat-icon-button (click)="cancelUploading(ctx.uploadingFile)" color="warn">
            <mat-icon>cancel</mat-icon>
          </button>

          <mat-progress-spinner
              diameter="20"
              class="video-uploader__spinner"
              mode="determinate"
              [value]="ctx.uploadingFile.progress"></mat-progress-spinner>
        </div>
      </div>

  <!--     <div *ngFor="let item of uploader.queue"
          fxLayout="row" fxLayoutAlign="space-between center">
        <strong>{{ item?.file?.name }}</strong>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button *ngIf="item.isUploading" mat-icon-button (click)="item.cancel()" color="warn">
            <mat-icon>cancel</mat-icon>
          </button>

          <button *ngIf="!item.isUploading && !item.isUploaded" mat-icon-button (click)="removeItem(item)" color="warn">
            <mat-icon>cancel</mat-icon>
          </button>

          <mat-icon *ngIf="item.isUploaded">done</mat-icon>

          <mat-progress-spinner *ngIf="!item.isUploaded"
              diameter="20"
              class="video-uploader__spinner"
              mode="determinate"
              [value]="item.progress"></mat-progress-spinner>
        </div>
      </div> -->
      
  <!--     <div style="font-size: smaller" *ngIf="maxFileSize || allowedMimeType">
        <div *ngIf="maxFileSize">Максимальный размер: <strong>{{maxFileSize|bytes}}</strong></div>
        <div *ngIf="allowedMimeType">Типы файлов: {{normalizedMimeTypes}}</div>
      </div> -->
    </div>
  </mat-card>
</ng-container>
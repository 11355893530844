import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

import { RemoteErrorsComponent } from './remote-errors.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  declarations: [
    RemoteErrorsComponent
  ],
  exports: [
    RemoteErrorsComponent
  ]
})
export class RemoteErrorsModule { }

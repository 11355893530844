import { Type, Transform } from 'class-transformer';
import { Moment } from 'moment';
import { immerable } from 'immer';
import * as moment from 'moment';

import { EnumModel } from './enum';

export class ContentType extends EnumModel {
  static readonly PDF = new ContentType('PDF', ['application/pdf'], 'PDF', 'fa-file-pdf', '#F33209');
  static readonly TEXT = new ContentType('TEXT', ['text/plain'], 'Text', 'fa-file-alt', 'gray');
  static readonly CSV = new ContentType('CSV', ['text/csv'], 'CSV', 'fa-file-csv', '#207442');
  static readonly EXCEL = new ContentType('EXCEL', [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ], 'Office excel', 'fa-file-alt', '#1C6841');
  static readonly DOC = new ContentType('DOC', [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12'
  ], 'Office doc', 'fa-file-word', '#0067B4');
  static readonly IMAGE = new ContentType('IMAGE', [
    'image/gif',
    'image/jpeg',
    'image/pjpe',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/vnd.microsoft.icon',
    'image/vnd.wap.wbmp',
    'image/webp'
  ], 'Image', 'fa-file-image', '#404040');
  static readonly ARCHIVE = new ContentType('ARCHIVE', [
    'application/zip',
    'application/gzip',
    'application/x-rar-compressed',
    'application/x-zip-compressed'
  ], 'Archive', 'fa-file-archive', '#F8B900');


  private constructor(
    key: string, value: string[],
    public readonly title: string,
    public readonly icon: string,
    public readonly color: string) {
    super(key, value);
  }

  static notFound(value: any): ContentType {
    return new ContentType('TEXT', ['application/octet-stream'], 'unknown', 'fa-file', '#DFDFDF');
  }

  public static getByValue(value: any): ContentType {
    for (let key in this) {
      const model = this[key];
      if (model.value.includes(value)) {
        return model;
      }
    }

    return (<typeof ContentType> this).notFound(value);
  }
}

export class StorageFolder {
  [immerable] = true;

  id: number;
  title: string;
  parent: number;

  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  created: Moment;

  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  modified: Moment;
}

export class BaseFile {
  title: string;

  @Type(() => ContentType)
  @Transform(val => ContentType.getByValue(val), { toClassOnly: true })
  content_type: ContentType;
}

export class StorageFile extends BaseFile {
  [immerable] = true;
  
  id: number;
  file: string;
  folder: number;

  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  created: Moment;

  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  modified: Moment;

  sorted: boolean;

  tags: string[];
}

export class UploadParams {
  uploadUrl?: string;
  contextId?: string;
  context?: { [key: string]: any };
}

export class UploadFile extends BaseFile {
  [immerable] = true;
  
  id: string;
  progress: number;
  contextId: string;
}

export interface TreeNode {
  folder: StorageFolder | null;
  children?: TreeNode[];
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SlickModule } from 'ngx-slick';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { RemoteHintDirective } from './remote-hint.directive';
import { RemoteHintComponent } from './remote-hint.component';
import { SectionHintsComponent } from './section-hints.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    SwiperModule
    // SlickModule.forRoot()
  ],
  declarations: [
    RemoteHintDirective,
    RemoteHintComponent,
    SectionHintsComponent
  ],
  exports: [
    RemoteHintDirective,
    RemoteHintComponent,
    SectionHintsComponent
  ]
})
export class HintsModule { }

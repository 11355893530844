import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { Store, Select } from '@ngxs/store';

import { ConfirmationDialogComponent, ConfirmData } from '../../confirmation-dialog';
import { ImportersService, AuthService } from '../../shared/services';
import { Importer, PagedResult, AuthUser } from '../../shared/models';
import { AuthState } from '../../shared/store';

@Component({
  selector: 'tamo-profile-importers',
  templateUrl: './profile-importers.component.html',
  styleUrls: ['./profile-importers.component.less']
})
export class ProfileImportersComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  private listLoaderSource$: BehaviorSubject<any> =
    new BehaviorSubject(null);

  private listLoader$: Observable<number> =
    this.listLoaderSource$.asObservable();

  public importers$: Observable<Importer[]>;

  public loading: boolean = false;

  public isEmpty: boolean = false;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  @Output() createImporeter: EventEmitter<any> = new EventEmitter();

  @Output() editImporeter: EventEmitter<Importer> = new EventEmitter();

  @Output() removeImporeter: EventEmitter<Importer> = new EventEmitter();

  constructor(
    private store: Store,
    private importersService: ImportersService,
    private authService: AuthService,
    private dialog: MatDialog,
    private toastr: ToastrService) { }

  ngOnInit() {    
    this.importers$ = this.listLoader$.pipe(
      takeUntil(this.ngUnsubscribe$),
      tap(() => setTimeout(() => this.loading = true, 0)),
      mergeMap(() => this.importersService.all()),
      map(value => value as Importer[]),
      tap(() => setTimeout(() => this.loading = false, 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.importers$.subscribe((importers: Importer[]) => {
      this.isEmpty = importers.length == 0;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  create() {
    this.createImporeter.emit();
  }

  edit(importer: Importer) {
    this.editImporeter.emit(importer);
  }

  remove(importer: Importer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: plainToClass(ConfirmData, {
        message: `Вы уверены, что хотите удалить <strong>${importer.org_name}</strong>?`
      })
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.importersService.delete(importer.id).subscribe(() => {
          this.listLoaderSource$.next(null);
          this.toastr.info(`Импортер успешно удален`);
        });
      }
    });
  }

  triggerUpdate() {
    this.listLoaderSource$.next(null);
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, map, tap } from 'rxjs/operators';
import { UpdateFormDirty } from '@ngxs/form-plugin';

import { ReactiveComponent } from '../../shared/components';
import { ApplyOrdersFilter, ResetOrdersFilter } from '../../shared/store';
import { ServiceType, Status  } from '../../shared';

@Component({
  selector: 'tamo-orders-filter',
  templateUrl: './orders-filter.component.html',
  styleUrls: ['./orders-filter.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersFilterComponent extends ReactiveComponent {
  public serviceTypes = (ServiceType.getValues() as ServiceType[]).filter(serviceType => !serviceType.disabled);

  public statuses = (Status.getValues() as Status[]);

  public selectedType: ServiceType;

  public selectedStatus: Status;

  public filterForm: FormGroup;

  constructor(
      private store: Store,
      private actions$: Actions,
      private fb: FormBuilder) {
    super();

    this.filterForm = fb.group({
      service_type: [],
      status: [],
      search: [''],
      date_from: [],
      date_to: []
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  ngOnInit(): void {
  }

  search(evt) {
    this.store.dispatch(new ApplyOrdersFilter());
  }

  reset(filterFormControl) {
    filterFormControl.resetForm();
    this.store.dispatch(new ResetOrdersFilter());
  }
}

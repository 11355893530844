import { Expose, Type } from "class-transformer";

import { DocumentOwner } from "./document-owner";

export class Product implements DocumentOwner {
  id: number;
  name: string;
  link: string;
  description: string;
  service: number;
  tnved_code: string;

  @Expose()
  getUploadPrefix() {
    return `/declarations/${this.service}/products/${this.id}/`;
  }

  @Expose()
  getCounts(type: string) {
    return null;
  }
}    

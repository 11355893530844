<form [formGroup]="managerForm" (ngSubmit)="saveImporter()" class="managers-form">
  <div fxLayout="column" fxLayoutGap="10px" class="managers-form__content">
    <mat-card>
      <mat-card-title>Новый менеджер</mat-card-title>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="5px">
          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput placeholder="Логин" formControlName="username"
                 autocomplete="new-password" required>
              <mat-icon matSuffix>person</mat-icon>
              <mat-hint>Идентификатор для входа в систему</mat-hint>
              <mat-error *ngIf="f.username.invalid && f.username.errors.required">Поле обязательно для заполнения</mat-error>
              <mat-error *ngIf="f.username.invalid && f.username.errors.remote"><tamo-remote-errors controlName="username"></tamo-remote-errors></mat-error>
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput type="password" formControlName="password"
                 autocomplete="new-password" placeholder="Пароль" required>
              <mat-error *ngIf="f.password.invalid && f.password.errors.required">Поле обязательно для заполнения</mat-error>
              <mat-error *ngIf="f.password.invalid && f.password.errors.remote"><tamo-remote-errors controlName="password"></tamo-remote-errors></mat-error>
              <mat-icon matSuffix>lock</mat-icon>
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput placeholder="Email" formControlName="email" required>
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error *ngIf="f.email.invalid && f.email.errors.required">Поле обязательно для заполнения</mat-error>
              <mat-error *ngIf="f.email.invalid && f.email.errors.email">Введен некорректный email</mat-error>
              <mat-error *ngIf="f.email.invalid && f.email.errors.remote"><tamo-remote-errors controlName="email"></tamo-remote-errors></mat-error>
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input type="tel" matInput formControlName="phone" placeholder="Телефон" required>
              <mat-icon matSuffix>phone</mat-icon>
              <span matPrefix>+7&nbsp;</span>
              <mat-error *ngIf="f.phone.invalid && f.phone.errors.required">Поле обязательно для заполнения</mat-error>
              <mat-error *ngIf="f.phone.invalid && f.phone.errors.remote"><tamo-remote-errors controlName="phone"></tamo-remote-errors></mat-error>
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput placeholder="Фамилия" formControlName="last_name">
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput placeholder="Имя" formControlName="first_name">
            </mat-form-field>
          </div>
            
          <div class="managers-form__group" fxFlex>
            <mat-form-field class="managers-form__field">
              <input matInput placeholder="Отчество" formControlName="middle_name">
            </mat-form-field>
          </div>

          <div class="managers-form__group" fxFlex>
            <mat-form-field>
              <mat-label>Комментарий</mat-label>
              <textarea rows="3" matInput placeholder="Введите комментарий"
                formControlName="note" name="note" autocomplete="off"
                #messageControl></textarea>
            </mat-form-field>
          </div>

          <div class="importer-form__group" fxFlex>
            <button mat-raised-button color="primary" [disabled]="saving">Добавить</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
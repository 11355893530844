<ng-container *ngIf="{
	selectedFilesIds: selectedFilesIds$ | async,
	uploadingFiles: uploadingFiles$ | async
} as ctx">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
			<ng-container *ngIf="ctx.selectedFilesIds.length > 0">
				<div>Выбрано: <strong>{{ ctx.selectedFilesIds.length }}</strong></div>

		    <button mat-icon-button color="warn" (click)="clearSelection()"><mat-icon>clear</mat-icon></button>

        <ng-container *ngIf="editable">
          <button (click)="moveSelection($event)" mat-flat-button color="primary">Перенести</button>
          <button (click)="removeSelected($event)" mat-flat-button color="warn">Удалить</button>
        </ng-container>

<!-- 				<button *ngIf="editable" mat-flat-button color="primary" [matMenuTriggerFor]="actionsMenu">выполнить</button>
				<mat-menu #actionsMenu="matMenu">
				  <button mat-menu-item (click)="removeSelected($event)">Удалить</button>
				  <button mat-menu-item (click)="moveSelection($event)">Перенести</button>
				</mat-menu> -->
			</ng-container>
		</div>

	  <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="5px">
<!--       <input hidden type="file" #fileInput ng2FileSelect
        (click)="handleInputClick($event)"
        [uploader]="uploaderService.uploader" multiple> -->

      <ng-template *ngTemplateOutlet="actionsTemplate"></ng-template>

  		<button *ngIf="editable" class="toolbar__upload" mat-flat-button color="accent"
        ngfSelect [files]="files" (filesChange)="uploadFiles($event)" multiple="1">
  			<span *ngIf="ctx.uploadingFiles.length === 0">Загрузить</span>
  			<ng-container *ngIf="ctx.uploadingFiles.length > 0">
	  			<span>Идет загрузка <strong>({{ ctx.uploadingFiles.length }})</strong></span>
	  			<mat-spinner diameter="18"></mat-spinner>
  			</ng-container>
  		</button>
	  </div>
	</div>
</ng-container>

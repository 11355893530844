import { Component, OnInit, Input, NgZone, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject, Observable, zip, empty, of, combineLatest, BehaviorSubject, merge } from 'rxjs';
import { takeUntil, map, filter, tap, debounceTime, mergeMap, finalize, shareReplay, catchError, delay, switchMap, startWith, withLatestFrom } from 'rxjs/operators'; 
import { plainToClass } from 'class-transformer';

import { YMapsService } from '../../ymaps';
import {
  Shipping,
  AutoShipping,
  BaseComponent,
  ShippingTariffsService,
  AutoTariff,
  AutoSettings
} from '../../shared';
import { KladrCity } from '../../kladr';

@Component({
  selector: 'shipping-calculator-auto',
  templateUrl: './calculator-auto.component.html',
  styleUrls: ['./calculator-auto.component.less']
})
export class CalculatorAutoComponent extends BaseComponent implements OnInit {
  @Input()
  service: AutoShipping;

  @Input()
  form: FormGroup;

  @Output()
  formFilled: EventEmitter<Shipping> = new EventEmitter();

  private departurePlaceSource$: BehaviorSubject<string> = new BehaviorSubject(null);
  private arrivalPlaceSource$: BehaviorSubject<string> = new BehaviorSubject(null);

  public departurePlace$ = this.departurePlaceSource$.asObservable();
  public arrivalPlace$ = this.arrivalPlaceSource$.asObservable();

  public placesSelected$: Observable<boolean> = combineLatest([
    this.departurePlace$, this.arrivalPlace$
  ]).pipe(
    map(([departurePlace, arrivalPlace]) => !!departurePlace && !!arrivalPlace),
    shareReplay({ bufferSize: 1, refCount: true })
  );


  // public tariffs$: Observable<AutoTariff[]>;

  private routeLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public routeLoading$ = this.routeLoadingSource$.asObservable();

  public routeInfo$: Observable<any>;

  private loadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public loading$: Observable<boolean> = this.loadingSource$.asObservable();

  public dataReady$: Observable<boolean>;

  public cost$: Observable<number>;

  public calculationReady$: Observable<boolean>;

  // public selectedTariff$: Observable<AutoTariff>;

  public cargoWeight$: Observable<number>;

  public weightExceeded$: Observable<boolean>;

  public autoSettings$: Observable<AutoSettings>;

  constructor(private yMapsService: YMapsService,
              private ngZone: NgZone,
              private tariffsService: ShippingTariffsService,
              private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const cargoWeightControl = this.form.get('cargo_weight');
    const cargoSizeControl = this.form.get('cargo_size');
    const cargoTypeControl = this.form.get('cargo_type');
    const cargoWidthControl = this.form.get('cargo_width');
    const cargoLengthControl = this.form.get('cargo_length');
    const cargoHeightControl = this.form.get('cargo_height');

    combineLatest([
      cargoLengthControl.valueChanges.pipe(startWith(cargoLengthControl.value)),
      cargoHeightControl.valueChanges.pipe(startWith(cargoHeightControl.value)),
      cargoWidthControl.valueChanges.pipe(startWith(cargoWidthControl.value))
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((values: string[]) => values.map(v => parseFloat(v))),
      filter((values: number[]) => values.findIndex(v => isNaN(v)) == -1)
    ).subscribe(([l, h, w]: number[]) => {
      this.form.patchValue({
        'cargo_size': Math.ceil(l * h * w)
      })
    });

    // this.tariffs$ = this.tariffsService.autoTariffs()
    //   .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.autoSettings$ = this.tariffsService.autoSettings()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    combineLatest([
      this.yMapsService.libraryLoaded$,
      this.autoSettings$
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
      delay(0)
    ).subscribe(() => {
      // this.calculateRoute();
      this.loadingSource$.next(false);
    });

    this.routeInfo$ = combineLatest([
      this.placesSelected$, this.departurePlace$, this.arrivalPlace$
    ]).pipe(
      tap(() => setTimeout(() => this.routeLoadingSource$.next(true), 0)),
      // delay(1000),
      switchMap(([selected, departurePlace, arrivalPlace]) => selected ?
        this.yMapsService.getRoute(departurePlace, arrivalPlace).pipe(
          filter((data: any) => data.properties),
          map((data: any) => data.properties.get('RouterRouteMetaData')),
          finalize(() => setTimeout(() => this.routeLoadingSource$.next(false), 0))
        ) : of(null)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.routeLoadingSource$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      setTimeout(() => {
        if (!this.ref['destroyed']) this.ref.detectChanges()
      }, 0);
    });

    this.cargoWeight$ = cargoWeightControl.valueChanges.pipe(
      startWith(cargoWeightControl.value),
      map((data) => data ? parseFloat(data) : null),
      shareReplay({ bufferSize: 1, refCount: true }));

    const cargoSize$: Observable<number> = cargoSizeControl.valueChanges.pipe(
      startWith(cargoSizeControl.value),
      map((data) => data ? parseFloat(data) : null),
      shareReplay({ bufferSize: 1, refCount: true }));

    const cargoType$: Observable<string> = cargoTypeControl.valueChanges.pipe(
      startWith(cargoTypeControl.value),
      shareReplay({ bufferSize: 1, refCount: true }));

    const sizeSelected$ = combineLatest([
      this.cargoWeight$, cargoSize$, cargoType$
    ]).pipe(
      map(([cargoWeight, cargoSize, cargoType]) =>
        (cargoType === 'COMPLEX' && !!cargoWeight && !!cargoSize) ||
        (cargoType === 'FULL' && !!cargoWeight)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const isComplexSize$ = combineLatest([
      this.cargoWeight$, cargoSize$, cargoType$
    ]).pipe(map(([weight, size, cargoType]) =>
      cargoType === 'FULL' || size > 8 || (size > 7 && weight > 5000)
    ))

    this.dataReady$ = combineLatest([
      sizeSelected$, this.placesSelected$
    ]).pipe(
      map(([sizeSelected, placesSelected]) => sizeSelected && placesSelected),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // this.selectedTariff$ = combineLatest([
    //   this.cargoWeight$, this.tariffs$
    // ]).pipe(
    //   map(([cargoWeight, tariffs]) =>
    //     tariffs.find(tariff => (cargoWeight >= tariff.weight_from || !tariff.weight_from) &&
    //                            (cargoWeight < tariff.weight_to || !tariff.weight_to))
    //   )
    // );

    // const autoSettings$ = of({
    //   fullCostPerKm: 1,
    //   fullAddCost: 200,
    //   complexDiffCost: 0.2,
    //   complexAddCost: 200
    // });

    // sizeSelected$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {});
    // this.dataReady$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {});
    // this.selectedTariff$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {});

    this.weightExceeded$ = this.cargoWeight$.pipe(
      map(weight => weight >= 21000)
    );

    this.cost$ = combineLatest([
      this.routeInfo$, isComplexSize$, this.autoSettings$, this.cargoWeight$, cargoSize$
    ]).pipe(
      map(([routeInfo, isComplexSize, settings, weight, size]) => {
        if (isComplexSize) {
          return routeInfo.Length.value / 1000 * settings.fullCostPerKm + settings.fullAddCost;
        } else {
          return (routeInfo.Length.value / 1000 * (1 + (size < 5 ? 0 : Math.ceil(size) - 5) * settings.complexDiffCost) + settings.complexAddCost) / 2;
        }
      })
    )

    this.departurePlaceSource$.next(this.f.departure_place.value);
    this.arrivalPlaceSource$.next(this.f.arrival_place.value);

    merge(this.routeInfo$, this.dataReady$, this.weightExceeded$).pipe(
      takeUntil(this.ngUnsubscribe$),
      withLatestFrom(combineLatest([this.routeInfo$, this.dataReady$, this.weightExceeded$])),
      delay(0)
    ).subscribe(([, [routeInfo, dataReady, weightExceeded]]) => {
      const formFilled = dataReady && !weightExceeded && routeInfo !== null;
      this.formFilled.emit(formFilled ? plainToClass(AutoShipping, this.form.value) : null);
    });
  }

  get f() {
    return this.form.controls;
  }

  onDepartureSelected(data) {
    this.departurePlaceSource$.next(data ? data.name : null);
  }

  onArrivalSelected(data) {
    this.arrivalPlaceSource$.next(data ? data.name : null);
  }
}

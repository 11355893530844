<ng-container *ngIf="user$ | async as user">
  <div class="certification" *ngIf="service">
    <form [formGroup]="serviceForm" class="certification-form" ngxsForm="order.serviceForm">   
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="30px">
          <mat-card fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="certification-form__group" fxFlex>
                  <mat-radio-group formControlName="certification_type" fxLayout="column" fxLayoutGap="10px">
                    <mat-radio-button fxFlex value="CS">Серийный выпуск</mat-radio-button>
                    <mat-radio-button fxFlex value="CP">Партия</mat-radio-button>
                    <mat-radio-button fxFlex value="CR">Российский изготовитель</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field  appearance="outline" fxFlex="grow">
                    <mat-label>Описание товара</mat-label>
                    <textarea rows="2" matInput placeholder="Описание товара"
                      formControlName="description" name="description" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>

                <ng-container *ngIf="f.certification_type.value == 'CS'">
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Изготовитель" formControlName="manufacturer">
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="f.certification_type.value == 'CP'">
                  <div class="certification-form__group" fxFlex
                      fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
                    <mat-form-field class="certification-form__field" fxFlex="shrink">
                      <input matInput placeholder="Контракт №" name="contract_number" autocomplete="off" formControlName="contract_number">
                    </mat-form-field>

                    <mat-form-field class="certification-form__field" fxFlex="shrink">
                      <input matInput placeholder="от" name="contract_date" autocomplete="off" formControlName="contract_date">
                    </mat-form-field>
                  </div>

                  <div class="certification-form__group" fxFlex
                      fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
                    <mat-form-field class="certification-form__field" fxFlex="shrink">
                      <input matInput placeholder="Инвойс №" name="invoice_number" autocomplete="off" formControlName="invoice_number">
                    </mat-form-field>

                    <mat-form-field class="certification-form__field" fxFlex="shrink">
                      <input matInput placeholder="от" name="invoice_date" autocomplete="off" formControlName="invoice_date">
                    </mat-form-field>
                  </div>

                  <div class="certification-form__group" fxFlex>
                    <mat-form-field class="certification-form__field" fxFlex="grow">
                      <input matInput placeholder="Партия №" name="party_number" autocomplete="off" formControlName="party_number">
                    </mat-form-field>
                  </div>
                </ng-container>

                <ng-container *ngIf="f.certification_type.value == 'CR'">
                  <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('CRNO')"
                    type="CRNO" title="Нормативный документ"></tamo-documents-uploader>
                </ng-container>

                <div class="certification-form__group" fxFlex *ngIf="f.certification_type.value">
                  <mat-form-field  appearance="outline" fxFlex="grow">
                    <mat-label>Комментарий</mat-label>
                    <textarea rows="2" matInput placeholder="Комментарий"
                      formControlName="comment" name="comment" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="certification-form__group" fxFlex *ngIf="!user.isStaff">
                  <dadata-suggest (optionSelected)="onSelectionChanged($event)"></dadata-suggest>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="ИНН" formControlName="inn">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Наименование компании" formControlName="org_name">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="ОГРН" formControlName="ogrn">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="КПП" formControlName="kpp">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Юридический адрес" formControlName="legal_address">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Физический адрес" formControlName="physical_address">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Адрес для корреспонденции" formControlName="correspondence_address">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="Должность руководителя" formControlName="director_position">
                  </mat-form-field>
                </div>

                <div class="certification-form__group" fxFlex>
                  <mat-form-field class="certification-form__field" fxFlex="grow">
                    <input matInput placeholder="ФИО руководителя" formControlName="director_fio">
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

<!--         <mat-card fxFlex>
          <button type="submit" [disabled]="serviceForm.disabled"
            mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
          <button type="button" [disabled]="serviceForm.disabled" *ngIf="service.service_status.id == 'DR'" (click)="process()"
            mat-raised-button color="accent">Отправить в работу</button>
        </mat-card> -->
      </div>
    </form>
  </div>
</ng-container>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from "@angular/common/http"
import { plainToClass } from 'class-transformer';
import { Store, Select } from '@ngxs/store';

import { AuthState, Login } from '../../shared/store';
import { FormatErrorsPipe } from '../../shared/pipes';
import { AuthService } from '../../shared/services';
import { Registration } from '../../shared/models';
import { SmsConfirmationComponent } from '../../sms-confirmation/sms-confirmation.component'
import { RemoteErrorsService } from '../../remote-errors/remote-errors.service';

@Component({
  selector: 'tamo-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
  providers: [RemoteErrorsService, FormatErrorsPipe]
})
export class RegisterComponent implements OnInit {
  @ViewChild('smsConfirmation') smsCmp: SmsConfirmationComponent;
  public registerForm: FormGroup;
  public loading = false;
  public valid = false;
  public checking = false;
  public registrationData: Registration;

  get f() { return this.registerForm.controls; }

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private formatErrors: FormatErrorsPipe,
    private errSrv: RemoteErrorsService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      last_name: [''],
      first_name: [''],
      middle_name: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.required]
    });
  }

  onSubmit() {
    this.registrationData = plainToClass(
      Registration, this.registerForm.value as Object)
    if (this.registerForm.invalid) {
      return;
    }

    this.registrationData = plainToClass(
      Registration, this.registerForm.value as Object)

    this.authService.validate(this.registrationData).subscribe(() => {
      this.valid = true;
      this.registerForm.disable();
      this.smsCmp.sendSms();
    }, this.errSrv.handleErrors(this.registerForm));
  }

  public onSmsCanceled() {
    this.valid = false;
    this.errSrv.clearErrors();
    this.registerForm.enable();
  }

  public onSmsConfirmed(code) {
    this.checking = true;
    this.errSrv.clearErrors();
    this.authService.register(this.registrationData.phone, code, this.registrationData).subscribe(
      () => {
        this.store.dispatch(new Login({
          username: this.registrationData.username,
          password: this.registrationData.password
        }));
        // this.authService.login()
        //   .subscribe(() => {
        //     this.router.navigate(['/questionary'])
        //   }, err => {
        //     this.router.navigate(['/auth'])
        //   });
      },
      err => {
        this.errSrv.handleErrors(this.registerForm)(err);
        this.checking = false;
      }
    );
  }
}

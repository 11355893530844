import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MomentModule } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { SharedModule } from '../shared/shared.module';
import { HintsModule } from '../hints/hints.module';
import { OrdersComponent } from './orders';
import { OrdersDetailsComponent } from './orders-details';
import { OrdersListComponent } from './orders-list';
import { DocumentsUploaderModule } from '../documents-uploader/documents-uploader.module';
import { OrdersMessagesComponent } from './orders-messages/orders-messages.component';
import { OrdersProfileComponent } from './orders-profile/orders-profile.component';
import { OrdersFilterComponent } from './orders-filter/orders-filter.component';
import { OrdersCreationComponent } from './orders-creation';
import { OrdersUIService } from './orders-ui.service';
import { OrderStatusModule } from '../order-status';
import { ServicesModule } from '../services';
import { KladrModule } from '../kladr/kladr.module';

@NgModule({
  imports: [
    CommonModule,
    DocumentsUploaderModule,
    HintsModule,
    NgxDatatableModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    FormsModule,
    KladrModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatListModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatDividerModule,
    MomentModule,
    MatBadgeModule,
    MatChipsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSidenavModule,
    MatMenuModule,
    NgxsFormPluginModule,
    OrderStatusModule,
    ToastrModule.forRoot(),
    ServicesModule,
    VirtualScrollerModule
  ],
  providers: [
    OrdersUIService
  ],
  declarations: [
    OrdersComponent,
    OrdersListComponent,
    OrdersDetailsComponent,
    OrdersMessagesComponent,
    OrdersProfileComponent,
    OrdersFilterComponent,
    OrdersCreationComponent
  ],
  exports: [
  ]
})
export class OrdersModule { }

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take, finalize, first } from 'rxjs/operators';

import { RemoteErrorsService } from '../../remote-errors/remote-errors.service';
import { ProfileService } from '../../shared/services/profile.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
  providers: [RemoteErrorsService]
})
export class ResetPasswordComponent implements OnInit {
  public resetForm: FormGroup;
  public complete: boolean = false;

  get f() { return this.resetForm.controls; }

  constructor(private formBuilder: FormBuilder,
              private profileService: ProfileService,
              public errSrv: RemoteErrorsService) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  onSubmit() {
    if (this.resetForm.invalid) {
        return;
    }

    this.resetForm.disable();
    this.profileService.resetPassword(this.f.username.value, this.f.email.value)
      .pipe(
        first(),
        finalize(() => this.resetForm.enable())
      )
      .subscribe(
        () => {
          this.errSrv.clearErrors();
          this.resetForm.reset();
          this.complete = true;
        },
        this.errSrv.handleErrors(this.resetForm)
      )
  }
}

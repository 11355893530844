<div class="upload-file"
    fxLayout="column"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)">
  <div fxFlex="grow" fxLayout="row" class="upload-file__preview" fxLayoutAlign="center center" [title]="file.title">
    <div class="upload-file__toolbar" fxLayout="column" fxLayoutGap="2px" [fxShow]="hover$ | async">
      <file-manager-toolbar-button (action)="cancelFile($event, file)" icon="clear" color="red"></file-manager-toolbar-button>
    </div>

    <div class="upload-file__progress"><mat-progress-spinner color="accent"
        diameter="50" [value]="file.progress"></mat-progress-spinner></div>
    <mat-icon class="upload-file__icon" fontSet="fa"
      [fontIcon]="file.content_type.icon" [style.color]="file.content_type.color"></mat-icon>
  </div>
  <div fxFlex="40px" fxLayout="row" class="upload-file__title" fxLayoutAlign="flex-start center"
    ellipsis [ellipsis-content]="file.title" [title]="file.title"></div>
  <div fxFlex="15px" class="upload-file__date"></div>
</div>
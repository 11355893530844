import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EllipsisModule } from 'ngx-ellipsis';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { ngfModule } from 'angular-file';

import { FileManagerComponent } from './file-manager/file-manager.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FileComponent } from './file/file.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { FolderHeaderComponent } from './folder-header/folder-header.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FolderSelectComponent } from './folder-select/folder-select.component';
import { FolderComponent } from './folder/folder.component';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component';
import { FileChooserComponent } from './file-chooser/file-chooser.component';
import { RangeSelectionDirective } from './range-selection';

@NgModule({
  declarations: [
    FileManagerComponent,
    BreadcrumbsComponent,
    FileComponent,
    UploadFileComponent,
    FolderHeaderComponent,
    ToolbarComponent,
    FolderSelectComponent,
    FileChooserComponent,
    FolderComponent,
    ToolbarButtonComponent,
    RangeSelectionDirective
  ],
  imports: [
    CommonModule,
    EllipsisModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRippleModule,
    MatMenuModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTreeModule,
    MomentModule,
    ngfModule,
    NgxsFormPluginModule,
    FormsModule,
    // FileUploadModule,
    // UploaderModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  exports: [
    FileManagerComponent,
    FolderSelectComponent,
    FileChooserComponent
  ],
  entryComponents: [
    FolderSelectComponent,
    FileChooserComponent
  ]
})
export class FileManagerModule { }

import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Observable, of, concat, Subject } from 'rxjs';
import { mergeMap, filter, shareReplay, map, debounceTime, startWith,
  skip, distinctUntilChanged, tap, catchError, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DadataOrg } from './dadata.org';
import { DadataService } from './dadata.service';

@Component({
  selector: 'dadata-suggest',
  templateUrl: './dadata.component.html',
  styleUrls: ['./dadata.component.less']
})
export class DadataComponent {
  @Output() optionSelected: EventEmitter<DadataOrg> = new EventEmitter();
  public loading: boolean = false;

  @Input()
  public formControl = new FormControl();

  @Input()
  public appendTo = null;

  public variants$: Observable<DadataOrg[]>;

  public typeheadInput$ = new Subject<string>();

  constructor(private dadataService: DadataService) { }

  ngOnInit() {
    this.variants$ = concat(
      of([]),
      this.typeheadInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => this.dadataService.suggest(term).pipe(
          catchError(() => of([])),
          tap(() => this.loading = false)
        ))
      )
    );
  }

  trackByFn(org: DadataOrg) {
    return org.value;
  }

  public onSelectionChanged(model: DadataOrg) {
    // const model: DadataOrg = event$.option.value;
    this.formControl.setValue(model.value);
    this.optionSelected.next(model);
  }
}

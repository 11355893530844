import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
// import { Store, Select } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';

// import { FileManagerState, SelectFolder, ToggleFilesSelection, ChangeFileName, DeleteFile, DownloadFile } from '../../shared/store';
import { StorageFile, ContentType } from '../../shared/models';
import { ReactiveComponent } from '../../shared/components';

@Component({
  selector: 'file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent extends ReactiveComponent {
  private hoverSource$: Subject<boolean> = new Subject();

  public hover$: Observable<boolean> = this.hoverSource$.asObservable();

  @Input()
  file: StorageFile;

  @Input()
  editable: boolean = true;

  @Input()
  selected: boolean = false;

  @Input()
  hovered: boolean = false;

  @Input()
  disabled: boolean = false;

  @Output()
  onSelectFile = new EventEmitter();

  @Output()
  onDeleteFile = new EventEmitter();

  @Output()
  onEditFile = new EventEmitter();

  @Output()
  onDownloadFile = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  editFile(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.file && this.editable) {
      this.onEditFile.emit();
    }
  }

  deleteFile(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.file && this.editable) {
      this.onDeleteFile.emit();
    }
  }

  downloadFile(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.file && this.editable) {
      this.onDownloadFile.emit();
    }
  }

  selectFile(file) {
    if (this.file) {
      this.onSelectFile.emit();
    }
  }

  onMouseEnter(evt: MouseEvent) {
    this.hoverSource$.next(true);
  }

  onMouseLeave(evt: MouseEvent) {
    this.hoverSource$.next(false);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Manager, PagedResult } from '../../shared/models';
import { ManagersService } from '../../shared/services';

@Component({
  selector: 'app-managers-list',
  templateUrl: './managers-list.component.html',
  styleUrls: ['./managers-list.component.less']
})
export class ManagersListComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  private listLoaderSource$: BehaviorSubject<number> =
    new BehaviorSubject(1);

  private listLoader$: Observable<number> =
    this.listLoaderSource$.asObservable();

  @ViewChild('managersDrawer', { static: true })
  private managersDrawer: MatDrawer;

  public loading: boolean = false;

  public managers: Manager[];

  public displayedColumns: string[] = [
    // 'first_name',
    // 'last_name',
    // 'middle_name',
    'username',
    'email',
    'fio',
    'phone'
  ];

  constructor(private router: Router,
              private managersService: ManagersService) { }

  ngOnInit() {
    this.loading = true;

    this.listLoader$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        mergeMap(page => this.managersService.all()),
        map(value => value as Manager[])
      )
      .subscribe((managers: Manager[]) => {
        this.managers = managers;
        this.loading = false;        
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onManagerChanged(manager: Manager) {
    this.listLoaderSource$.next(null);
    this.managersDrawer.close();
  }

  openManagerDialog($event) {
    $event.preventDefault();
    this.managersDrawer.toggle();
  }
}

import { Type, Transform } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';
import { immerable } from 'immer';

export class Message {
  [immerable] = true;

  id: number;

  message: string;
  
  username: string;

  object_id: number;

  type: string;

  is_client: boolean;

  resourcetype: string;

  @Transform(val => val ? moment(val) : null, { toClassOnly: true })
  date_creation: Moment;
}
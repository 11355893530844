import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, of, combineLatest } from 'rxjs';
import { plainToClass } from "class-transformer";
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { OrderState, UpdateService, AuthState } from '../../shared/store';
import { DadataOrg } from '../../dadata';
import {
  Analytics,
  AnalyticsService,
  ReactiveComponent,
  AuthUser
} from '../../shared';

@Component({
  selector: 'tamo-analytics-details',
  templateUrl: './analytics-details.component.html',
  styleUrls: ['./analytics-details.component.less']
})
export class AnalyticsDetailsComponent extends ReactiveComponent {
  @Input()
  public service: Analytics;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  public serviceForm: FormGroup;

  constructor(
      private store: Store,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private dataSrv: AnalyticsService) {
    super();

    this.serviceForm = this.fb.group({
      product_description: [''],
      comment: ['']
    });
  }

  get f() {
    return this.serviceForm.controls;
  }

  ngOnInit() {
    this.observePropertyCurrentValue<Analytics>('service')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((service: Analytics) => {
        this.serviceForm.patchValue(service);
      });

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.serviceForm.disable();
      } else {
        this.serviceForm.enable();
      }
    });
  }

  // onSubmit(process = false) {
  //   if(!this.serviceForm.valid) {
  //     return;
  //   }

  //   const model = this.serviceForm.value;
  //   const toSave = plainToClass(Analytics, model as Object);

  //   this.store.dispatch(new UpdateService(toSave, process));
  // }

  // public process() {
  //   this.onSubmit(true);
  // }
}

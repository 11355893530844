<h1 mat-dialog-title>Обратите внимание</h1>
<div mat-dialog-content>
	<p>
		Здесь вы можете в автоматическом режиме получить ставки на перевозку обычных
		грузов по основным направлениям. Если же Вам требуется специальная перевозка-негабарит по размерам или весу,
		перевозка с соблюдением температурного режима, у Вас опасный груз, Вам нужно консолидировать груз с разных складов,
		У Вас очень дорогой груз, тогда Вы можете отправить заявку на расчет и Вам будет предоставлен отдельный расчет.
	</p>
	<p>
		Все цены указаны ориентировочно. Окончательная цена на перевозку будет зафиксирована после размещения букинга.
	</p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ок</button>
</div>


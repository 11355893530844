import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit, createSelector } from '@ngxs/store';
import { Observable, of, throwError, combineLatest } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';

import {
  InitFileManager,
  SelectFolder,
  ResetFileManager,
  CreateFolder,
  ResetFilesSelection,
  ToggleFilesSelection,
  ChangeFileName,
  LoadFolderFiles,
  DeleteFile,
  DeleteFolder,
  ChangeFolderName,
  MoveFile,
  MoveSelectedFiles,
  DeleteSelectedFiles,
  DownloadFile,
  ShowUnsorted
} from '../actions/filemanager.actions';
import {
  SetUploaderUrl,
  FileUploadStarted,
  FileUploadProgress,
  FileUploadSuccess,
  FileUploadFailed,
  FileUploadCanceled
} from '../actions/uploader.actions';

import { UploadFile } from '../../models';
import { AccountServiceFactory, AccountService, GlobalUploaderService } from '../../services';


export interface FileUploaderStateModel {
  uploadingFiles: UploadFile[]
}

const defaults: FileUploaderStateModel = {
  uploadingFiles: []
}

@State<FileUploaderStateModel>({
  name: 'uploader',
  defaults,
  children: []
})
@Injectable()
export class FileUploaderState {
  @Selector([FileUploaderState])
  @ImmutableSelector()
  static uploadingFiles(state: FileUploaderStateModel): UploadFile[] {
    return state.uploadingFiles;
  }

  @Selector([FileUploaderState.uploadingFiles])
  static uploadingFilesByContextId(files: UploadFile[]) {
    return (contextId: string) => {
      return files.filter(file => {
        return file.contextId === contextId
      });
    };
  }

  @Selector([FileUploaderState])
  static uploading(state: FileUploaderStateModel): boolean {
    return state.uploadingFiles.length > 0;
  }

  constructor(
    private store: Store,
    private accountServiceFactory: AccountServiceFactory,
    private toastr: ToastrService) {
  }

  @Action(FileUploadStarted)
  @ImmutableContext()
  uploadStarted(
    { setState, getState, dispatch }: StateContext<FileUploaderStateModel>,
    { uploadFile }: FileUploadStarted
  ) {
    setState((state: FileUploaderStateModel) => {
      state.uploadingFiles.push(uploadFile);
      return state;
    });
  }

  @Action(FileUploadProgress)
  @ImmutableContext()
  uploadProgress(
    { setState, getState, dispatch }: StateContext<FileUploaderStateModel>,
    { uploadFileId, progress }: FileUploadProgress
  ) {
    setState((state: FileUploaderStateModel) => {
      const foundIndex = state.uploadingFiles.findIndex(file => file.id === uploadFileId)
      if (foundIndex !== -1) {
        state.uploadingFiles[foundIndex].progress = progress;
      }
      return state;
    });
  }

  @Action(FileUploadSuccess)
  @ImmutableContext()
  uploadSuccess(
    { setState, getState, dispatch }: StateContext<FileUploaderStateModel>,
    { uploadFileId, storageFile, contextId }: FileUploadSuccess
  ) {
    setState((state: FileUploaderStateModel) => {
      state.uploadingFiles = state.uploadingFiles.filter(file => file.id !== uploadFileId);
      return state;
    });
    
    if (storageFile && storageFile.title) {
      this.toastr.success(`Файл успешно загружен: ${storageFile.title}`, null, { positionClass: 'toast-bottom-right' });
    }

    // else {
    //   this.toastr.success(`Файл успешно загружен`, null, { positionClass: 'toast-bottom-right' });
    // }
  }

  @Action(FileUploadFailed)
  @ImmutableContext()
  uploadFailed(
    { setState, getState, dispatch }: StateContext<FileUploaderStateModel>,
    { uploadFileId, error }: FileUploadFailed
  ) {
    setState((state: FileUploaderStateModel) => {
      state.uploadingFiles = state.uploadingFiles.filter(file => file.id !== uploadFileId);
      return state;
    });

    this.toastr.error(`Ошибка при загрузке файла: ${error}`);
  }

  @Action(FileUploadCanceled)
  @ImmutableContext()
  uploadCanceled(
    { setState, getState, dispatch }: StateContext<FileUploaderStateModel>,
    { uploadFileId }: FileUploadCanceled
  ) {
    setState((state: FileUploaderStateModel) => {
      state.uploadingFiles = state.uploadingFiles.filter(file => file.id !== uploadFileId);
      return state;
    });

    this.toastr.warning(`Загрузка файла отменена`);
  }
}
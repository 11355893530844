import { Type } from "class-transformer";

export class KladrCity {
  id: number;

  zip: string;

  name: string;

  type: string;

  typeShort: string;
}
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="login__group">
    <mat-form-field class="login__field">
      <input matInput placeholder="Логин" formControlName="username">
      <mat-icon matSuffix>person</mat-icon>
      <mat-error *ngIf="f.username.invalid && f.username.errors.required">Поле обязательно для заполнения</mat-error>
      <mat-error *ngIf="f.username.invalid && f.username.errors.remote"><tamo-remote-errors controlName="username"></tamo-remote-errors></mat-error>
    </mat-form-field>
  </div>

  <div class="login__group">
    <mat-form-field class="login__field">
      <input matInput type="password" formControlName="password" placeholder="Пароль">
      <mat-icon matSuffix>lock</mat-icon>
      <mat-error *ngIf="f.password.invalid && f.password.errors.required">Поле обязательно для заполнения</mat-error>
      <mat-error *ngIf="f.password.invalid && f.password.errors.remote"><tamo-remote-errors controlName="password"></tamo-remote-errors></mat-error>
    </mat-form-field>
  </div>

  <div class="login__group" *ngIf="errSrv.hasError('non_field_errors')">
    <mat-error>Логин и пароль не совпадают</mat-error>
  </div>

  <div class="login__group">
    <button mat-raised-button type="submit" [disabled]="loginForm.disabled"
      color="accent" class="login__submit">Войти</button>
  </div>
  
  <div class="login__reset">
    <a [routerLink]="['/auth/reset-password']">Забыли пароль?</a>
  </div>
</form>
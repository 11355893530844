import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ngfModule } from 'angular-file';

import { FileManagerModule } from '../file-manager';
import { DocumentsUploaderComponent } from './documents-uploader.component';
import { FileDownloaderModule } from '../file-downloader';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatDialogModule,
    ngfModule,
    MomentModule,
    FileDownloaderModule,
    FileManagerModule
  ],
  declarations: [
    DocumentsUploaderComponent
  ],
  exports: [
    DocumentsUploaderComponent
  ]
})
export class DocumentsUploaderModule { }

import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, switchMap, debounceTime, shareReplay, filter, tap, map } from 'rxjs/operators';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { plainToClassFromExist } from 'class-transformer';

import { Service, ServiceType, Declaration, ReactiveComponent, AuthUser, Status } from '../../shared';
import { AuthState, OrderState, SelectServiceById, PatchService } from '../../shared/store';

@Component({
  selector: 'tamo-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.less']
})
export class ServiceDetailsComponent extends ReactiveComponent {
  @Select(OrderState.serviceSaving)
  serviceSaving$: Observable<boolean>;

  @Select(OrderState.selectedService)
  service$: Observable<Service>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  @Select(OrderState.serviceSaved)
  serviceSaved$: Observable<boolean>;

  scrollToTnvedCodesEnabled$: Observable<boolean> = this.service$.pipe(
    map((service: Declaration) => service.tnved_status == 'TC'));

  scrollToExtraDocumentsEnabled$: Observable<boolean> = this.service$.pipe(
    map((service: Declaration) => ['FI', 'AS', 'RL'].includes(service.dt_status) && service.service_status.id == 'WA'));

  readonly ServiceType: typeof ServiceType = ServiceType;

  public statuses = (Status.getValues() as Status[]);

  constructor(
      private store: Store,
      private scrollToService: ScrollToService) {
    super();
  }

  ngOnInit(): void {
  }

  scrollToTnvedCodes(evt) {
    evt.preventDefault();

    this.scrollToService.scrollTo({
      container: 'scrollContainer',
      target: 'tnvedCodes'
    });
  }

  scrollToExtraDocuments(evt) {
    evt.preventDefault();

    this.scrollToService.scrollTo({
      container: 'scrollContainer',
      target: 'extraDocuments'
    });
  }

  updateStatus(key, statusId) {
    this.store.dispatch(new PatchService({ [key]: statusId }));
  }

  // updateDtStatus(statusId) {
  //   this.store.dispatch(new PatchService({ dt_status: statusId }));
  // }

  // updateTnvedStatus(confirmed) {
  //   this.store.dispatch(new PatchService({ tnved_confirmed: confirmed }));
  // }
}

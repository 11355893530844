import { EnumModel } from './enum';

export class ShippingType extends EnumModel {
  static readonly SEA = new ShippingType('SEA', 'Sea', 'ports');
  static readonly RAILWAY = new ShippingType('RAILWAY', 'Railway', 'stations');
  static readonly AVIA = new ShippingType('AVIA', 'Avia', 'airports');
  static readonly AUTO = new ShippingType('AUTO', 'Auto', null);

  private constructor(
    key: string, value: string,
    public placesPrefix: string | null) {
    super(key, value);
  }

  static notFound(value: any): ShippingType {
    return new ShippingType(value, value, null);
  }
}
import { Type } from "class-transformer";


export class Name {
  full: string;
  full_with_opf: string;
  short: string;
  short_with_opf: string;
}

export class Management {
  name: string;
  post: string;
}

export class Address {
  unrestricted_value: string;
  value: string;
}

export class Data {
  inn: string;
  kpp: string;
  ogrn: string;
  @Type(() => Date) ogrn_date: Date;
  @Type(() => Name) name: Name;
  @Type(() => Management) management: Management;
  @Type(() => Address) address: Address;
}

export class DadataOrg {
  @Type(() => Data) data: Data;
  unrestricted_value: string;
  value: string;
}
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Profile } from '../../../shared/models';

@Component({
  selector: 'tamo-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.less']
})
export class DownloadFilesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;

  get f() { return this.form.controls; }

  constructor() { }

  ngOnInit() {
  }

}

import { Type, Transform, Expose } from 'class-transformer';
import { Moment } from 'moment';
import * as moment from 'moment';

import { ServiceType } from './service-type';
import { Status} from './service-status';

export class OrderFilter {
  @Expose()
  search: string;

  @Expose({ name: 'services__type', toPlainOnly: true })
  @Type(() => ServiceType)
  @Transform(data => data ? data.value.toLowerCase() : null, { toPlainOnly: true })
  service_type: ServiceType;

  @Expose({ name: 'services__status', toPlainOnly: true })
  @Type(() => Status)
  @Transform(data => data ? data.value : null, { toPlainOnly: true })
  status: Status;

  @Expose({ name: 'services__date_from', toPlainOnly: true })
  @Transform(data => data ? moment(data).format('YYYY-MM-DD') : null, { toPlainOnly: true })
  date_from: Date;

  @Expose({ name: 'services__date_to', toPlainOnly: true })
  @Transform(data => data ? moment(data).format('YYYY-MM-DD') : null, { toPlainOnly: true })
  date_to: Date;
}
import { EnumModel } from './enum';

export class ServiceType extends EnumModel {
  static readonly DECLARATION = new ServiceType('DECLARATION', 'Declaration', 'Оформление ДТ', 'import_export', 'Услуга по оформлению таможенной декларации');
  static readonly CONDITIONAL_RELEASE = new ServiceType('CONDITIONAL_RELEASE', 'ConditionalRelease', 'Условный выпуск', 'assignment_turned_in', 'Условный выпуск', true, true);
  // static readonly SHIPPING = new ServiceType('SHIPPING', 'Shipping', 'Перевозка', 'local_shipping', 'Услуга по оформлению перевозки груза');
  static readonly CERTIFICATION = new ServiceType('CERTIFICATION', 'Certification', 'Сертификация', 'event_note', 'ДС, СГР, Сертификация, Нотификация');
  static readonly SIGN = new ServiceType('SIGN', 'Sign', 'ЭЦП', 'playlist_add_check', 'Изготовление всех видов ЭЦП');
  static readonly ANALYTICS = new ServiceType('ANALYTICS', 'Analytics', 'Аналитика', 'image_search', 'Подбор кода ТНВЭД');
  static readonly CONSULTING = new ServiceType('CONSULTING', 'Consulting', 'Юридический Консалтинг', 'gavel', 'Дела об АП, изъятие, возврат условок');
  static readonly CLASSIFICATION = new ServiceType('CLASSIFICATION', 'Classification', 'Классификационные решения', 'account_tree', 'Предварительные КР, КР');

  static readonly AVIA_SHIPPING = new ServiceType('AVIA_SHIPPING', 'AviaShipping', 'Перевозка самолетом', 'airplanemode_active', 'Перевозка самолетом', false, true);
  static readonly SEA_SHIPPING = new ServiceType('SEA_SHIPPING', 'SeaShipping', 'Перевозка морем', 'waves', 'Перевозка морем', false, true);
  static readonly RAILWAY_SHIPPING = new ServiceType('RAILWAY_SHIPPING', 'RailwayShipping', 'Перевозка поездом', 'train', 'Перевозка поездом', false, true);
  static readonly AUTO_SHIPPING = new ServiceType('AUTO_SHIPPING', 'AutoShipping', 'Перевозка авто', 'drive_eta', 'Перевозка авто', false, true);

  private constructor(
    key: string, value: string,
    public readonly title: string,
    public readonly icon: string,
    public readonly hint: string,
    public readonly disabled: boolean = false,
    public readonly hidden: boolean = false) {
    super(key, value);
  }

  static notFound(value: any): ServiceType {
    return new ServiceType('UNKNOWN', value, 'UNKNOWN', '', 'UNKNOWN', true, true);
  }
}
<div class="orders-creation">
	<!-- <mat-toolbar class="orders-creation__toolbar">Создание заказа</mat-toolbar> -->

	<form [formGroup]="orderForm" (ngSubmit)="onSubmit()">
		<div class="orders-creation__content">
	    <mat-card>
	      <mat-card-title>Создание заказа</mat-card-title>
	      <mat-card-content>
	      	<div fxLayout="column" fxLayoutGap="10px">

						<mat-form-field fxFlex="100">
					    <mat-label>Название</mat-label>
					    <input matInput formControlName="name" placeholder="Введите название заказа">
					  </mat-form-field>

						<mat-selection-list formControlName="services">
						  <mat-list-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.value" [disabled]="serviceType.disabled">
						  	<mat-icon mat-list-icon>{{ serviceType.icon }}</mat-icon>
						    <h4 mat-line>{{ serviceType.title }}</h4>
						    <p mat-line> {{ serviceType.hint }} </p>
						    <p *ngIf="serviceType.disabled" mat-line><span style="color: red">В разработке</span></p>
						  </mat-list-option>
						</mat-selection-list>

						<div class="orders-creation__actions" fxLayout="row" fxLayoutAlign="flex-start">
							<button mat-raised-button color="primary" [disabled]="!f.services.value.length || orderForm.disabled">Создать</button>
						</div>
					</div>
	     	</mat-card-content>
	    </mat-card>
	  </div>
	</form>
</div>
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { StorageFolder, StorageFile, Importer, Profile } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AccountServiceFactory {
  private prefix: string = 'accounts';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  getService(accountId) {
    return new AccountService(`${this.apiUrl}/${this.prefix}/${accountId}`, this.http);
  }
}

export class AccountService {
  constructor(private apiUrl: string,
              private http: HttpClient) {
  }

  get uploadUrl() {
    return `${this.apiUrl}/files/upload/`;
  }

  getProfile(): Observable<Profile> {
    return this.http.get(`${this.apiUrl}/`).pipe(
      map(data => plainToClass(Profile, data as Object))
    );
  }

  getImporters(): Observable<Importer[]> {
    return this.http.get(`${this.apiUrl}/importers/`).pipe(
      map(data => plainToClass(Importer, data as Object[]))
    );
  }

  getFolders(): Observable<StorageFolder[]> {
    return this.http.get(`${this.apiUrl}/folders/`).pipe(
      map(data => plainToClass(StorageFolder, data as Object[]))
    );
  }

  createFolder(title: string, parent: number | null): Observable<StorageFolder> {
    return this.http.post(`${this.apiUrl}/folders/`, { title, parent }).pipe(
      map(data => plainToClass(StorageFolder, data as Object))
    );
  }

  deleteFolder(folderId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/folders/${folderId}/`);
  }

  getFolderFiles(folderId: number | null, onlySorted: boolean = true): Observable<StorageFile[]> {
    const folderQuery = folderId ? `folder_id=${folderId}` : `root=true`;
    return this.http.get(`${this.apiUrl}/files/?${folderQuery}&sorted=${onlySorted}`).pipe(
      map(data => plainToClass(StorageFile, data as Object[]))
    );
  }

  deleteFile(fileId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/files/${fileId}/`);
  }

  patchFile(fileId: number, data: any): Observable<StorageFile> {
    return this.http.patch(`${this.apiUrl}/files/${fileId}/`, data).pipe(
      map(data => plainToClass(StorageFile, data as Object))
    );
  }

  patchFolder(fileId: number, data: any): Observable<StorageFolder> {
    return this.http.patch(`${this.apiUrl}/folders/${fileId}/`, data).pipe(
      map(data => plainToClass(StorageFolder, data as Object))
    );
  }

  moveFiles(filesIds: number[], folderId: number): Observable<StorageFile[]> {
    return this.http.post(`${this.apiUrl}/files/move/`, { files_ids: filesIds, folder_id: folderId }).pipe(
      map(data => plainToClass(StorageFile, data as Object[]))
    );
  }

  deleteFiles(filesIds: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/files/delete/`, { files_ids: filesIds });
  }

  downloadFile(url) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }
}
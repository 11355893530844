import { Injectable } from '@angular/core';
import { Store, State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Observable, of, throwError, combineLatest } from 'rxjs';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';

import {
  InitFileManager,
  SelectFolder,
  ResetFileManager,
  CreateFolder,
  ResetFilesSelection,
  ToggleFilesSelection,
  ChangeFileName,
  LoadFolderFiles,
  DeleteFile,
  DeleteFolder,
  ChangeFolderName,
  MoveFile,
  MoveSelectedFiles,
  DeleteSelectedFiles,
  DownloadFile,
  ShowUnsorted
} from '../actions/filemanager.actions';
import {
  FileUploadSuccess,
  SetUploaderUrl
} from '../actions/uploader.actions';
import {
  FileUploaderState,
  FileUploaderStateModel
} from './uploader.state';

import { StorageFolder, StorageFile, UploadFile, TreeNode } from '../../models';
import { AccountServiceFactory, AccountService, GlobalUploaderService } from '../../services';


export interface FileManagerStateModel {
  accountId: number,
  foldersLoading: boolean,
  filesLoading: boolean,
  selectedFolderId: number,
  folders: StorageFolder[],
  files: StorageFile[],
  selectedFilesIds: number[],
  showUnsorted: boolean
}

const defaults: FileManagerStateModel = {
  accountId: null,
  selectedFolderId: null,
  filesLoading: false,
  foldersLoading: false,
  folders: [],
  files: [],
  selectedFilesIds: [],
  showUnsorted: false
}

export const MANAGER_CTX_PREFIX = 'manager_';

@State<FileManagerStateModel>({
  name: 'filemanager',
  defaults,
  children: []
})
@Injectable()
export class FileManagerState {
  private accountService: AccountService;

  @Selector([FileManagerState])
  @ImmutableSelector()
  static foldersTree(state: FileManagerStateModel): TreeNode[] {
    const groupedByParent = _.groupBy(state.folders, 'parent');

    const drillDown = (folder: StorageFolder | null) => {
      const result = {
        folder,
        children: []
      }

      const children = groupedByParent[folder ? folder.id : null] || [];
      const sortedChildren = _.orderBy(children, ['title'], ['asc']);
      for (const key in sortedChildren) {
        result.children[key] = drillDown(sortedChildren[key]);
      }

      return result;
    }

    const root = drillDown(null);
    return root && root.children ? [...root.children] : [];
  }  

  @Selector([FileManagerState])
  @ImmutableSelector()
  static selectedFiles(state: FileManagerStateModel): StorageFile[] {
    return state.files.filter(file => state.selectedFilesIds.includes(file.id));
  }

  @Selector([FileManagerState])
  @ImmutableSelector()
  static showUnsorted(state: FileManagerStateModel): boolean {
    return state.showUnsorted;
  }

  @Selector([FileManagerState])
  static selectedFilesIds(state: FileManagerStateModel): number[] {
    return state.selectedFilesIds;
  }

  @Selector([FileManagerState])
  static selectedFolderId(state: FileManagerStateModel): number | null {
    return state.selectedFolderId;
  }

  @Selector([FileManagerState])
  @ImmutableSelector()
  static selectedFolder(state: FileManagerStateModel): StorageFolder | null {
    return state.selectedFolderId ? state.folders.find(folder => folder.id === state.selectedFolderId) : null;
  }

  @Selector([FileManagerState])
  static foldersLoading(state: FileManagerStateModel): boolean {
    return state.foldersLoading;
  }

  @Selector([FileManagerState])
  static filesLoading(state: FileManagerStateModel): boolean {
    return state.filesLoading;
  }

  @Selector([FileManagerState, FileUploaderState])
  @ImmutableSelector()
  static selectedFolderUploadingFiles(state: FileManagerStateModel, uploaderState: FileUploaderStateModel): UploadFile[] {
    return uploaderState.uploadingFiles.filter(uploadFile => uploadFile.contextId === `${MANAGER_CTX_PREFIX}${state.selectedFolderId}`);
  }

  @Selector([FileManagerState, FileUploaderState])
  @ImmutableSelector()
  static managerUploadingFiles(state: FileManagerStateModel, uploaderState: FileUploaderStateModel): UploadFile[] {
    return uploaderState.uploadingFiles.filter(uploadFile => uploadFile.contextId.indexOf('{MANAGER_CTX_PREFIX}') !== -1);
  }

  @Selector([FileManagerState])
  @ImmutableSelector()
  static selectedFolderFolders(state: FileManagerStateModel): StorageFolder[] {
    return _(state.folders)
      .filter(folder => folder.parent === state.selectedFolderId)
      .orderBy(['title'], ['asc'])
      .value();
  }

  @Selector([FileManagerState])
  @ImmutableSelector()
  static selectedFolderFiles(state: FileManagerStateModel): StorageFile[] {
    return _(state.files)
      .filter(file => file.folder === state.selectedFolderId && file.sorted !== state.showUnsorted)
      .orderBy(['title'], ['asc'])
      .value();
  }

  @Selector([FileManagerState])
  @ImmutableSelector()
  static breadcrumbs(state: FileManagerStateModel): StorageFolder[] {
    const breadcrumbs = [];
    let cursor = state.folders.find(folder => folder.id === state.selectedFolderId);
    while (cursor) {
      breadcrumbs.push(cursor);
      cursor = state.folders.find(folder => folder.id === cursor.parent);
    }

    return breadcrumbs.reverse();
  }

  constructor(private store: Store,
              private accountServiceFactory: AccountServiceFactory,
              private toastr: ToastrService) {
  }

  @Action(ResetFileManager)
  @ImmutableContext()
  reset(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { }: ResetFileManager
  ) {
    setState((state: FileManagerStateModel) => {
      return Object.assign({}, defaults);
    });
  }

  @Action(InitFileManager)
  @ImmutableContext()
  init(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { accountId }: InitFileManager
  ) {
    if (accountId) {
      this.accountService = this.accountServiceFactory.getService(accountId);

      setState((state: FileManagerStateModel) => {
        state.folders = [];
        state.files = [];
        state.selectedFolderId = null;
        state.foldersLoading = true;
        state.selectedFilesIds = [];
        return state;
      });

      return this.accountService.getFolders().pipe(
        tap((folders: StorageFolder[]) => setState((state: FileManagerStateModel) => {
          state.folders = folders;
          state.foldersLoading = false;
          return state;
        })),
        map(() => dispatch([
          new SetUploaderUrl(this.accountService.uploadUrl),
          new SelectFolder(null)
        ])),
      );
    } else {
      this.accountService = null;
    }
  }

  @Action(SelectFolder)
  @ImmutableContext()
  selectFolder(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { folderId }: SelectFolder
  ) {
    setState((state: FileManagerStateModel) => {
      state.selectedFolderId = folderId;
      return state;
    });

    dispatch([
      // new SetUploaderContext({ 'folder': folderId }),
      new LoadFolderFiles(folderId)
    ]);
  }

  @Action(LoadFolderFiles)
  @ImmutableContext()
  loadFolderFiles(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { folderId, onlySorted }: LoadFolderFiles
  ) {
    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.getFolderFiles(folderId, onlySorted).pipe(
      map((files: StorageFile[]) => setState((state: FileManagerStateModel) => {
        const folderFilesIds = files.map(file => file.id);
        const filtered = state.files.filter(file => !folderFilesIds.includes(file.id))
        state.files = [...filtered, ...files];
        state.filesLoading = false;
        return state;
      }))
    );    
  }

  @Action(ChangeFileName)
  @ImmutableContext()
  changeFileName(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { fileId, title }: ChangeFileName
  ) {
    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.patchFile(fileId, { title }).pipe(
      map((file: StorageFile) => setState((state: FileManagerStateModel) => {
        state.filesLoading = false;
        state.files = [...state.files.filter(file => file.id !== fileId), file];
        return state;
      }))
    );
  }

  @Action(MoveFile)
  @ImmutableContext()
  changeFileFolder(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { fileId, folderId }: MoveFile
  ) {
    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.patchFile(fileId, { folder: folderId }).pipe(
      map((file: StorageFile) => setState((state: FileManagerStateModel) => {
        state.filesLoading = false;
        state.files = [...state.files.filter(file => file.id !== fileId), file];
        return state;
      }))
    );
  }

  @Action(MoveSelectedFiles)
  @ImmutableContext()
  moveSelectedFiles(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { folderId }: MoveSelectedFiles
  ) {
    const { selectedFilesIds } = getState();

    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.moveFiles(selectedFilesIds, folderId).pipe(
      tap((files: StorageFile[]) => setState((state: FileManagerStateModel) => {
        state.filesLoading = false;
        state.files = [...state.files.filter(file => !selectedFilesIds.includes(file.id)), ...files];
        return state;
      })),
      map(() => dispatch([
        new ResetFilesSelection(),
        new SelectFolder(
          this.store.selectSnapshot(FileManagerState.selectedFolderId))
      ]))
    );
  }  


  @Action(ChangeFolderName)
  @ImmutableContext()
  changeFolderName(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { folderId, title }: ChangeFolderName
  ) {
    setState((state: FileManagerStateModel) => {
      state.foldersLoading = true;
      return state;
    });

    return this.accountService.patchFolder(folderId, { title }).pipe(
      map((folder: StorageFolder) => setState((state: FileManagerStateModel) => {
        state.foldersLoading = false;
        state.folders = [...state.folders.filter(folder => folder.id !== folderId), folder];
        return state;
      }))
    );
  }
  
  
  @Action(DeleteFile)
  @ImmutableContext()
  deleteFile(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { fileId }: DeleteFile
  ) {
    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.deleteFile(fileId).pipe(
      map(() => setState((state: FileManagerStateModel) => {
        state.filesLoading = false;
        state.files = state.files.filter(file => file.id !== fileId);
        state.selectedFilesIds = state.selectedFilesIds.filter(selectedId => selectedId !== fileId);
        return state;
      }))
    );
  }

  @Action(DeleteSelectedFiles)
  @ImmutableContext()
  deleteSelectedFile(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { }: DeleteSelectedFiles
  ) {
    const { selectedFilesIds } = getState();

    setState((state: FileManagerStateModel) => {
      state.filesLoading = true;
      return state;
    });

    return this.accountService.deleteFiles(selectedFilesIds).pipe(
      tap(() => setState((state: FileManagerStateModel) => {
        state.filesLoading = false;
        state.files = [...state.files.filter(file => !selectedFilesIds.includes(file.id))];
        return state;
      })),
      map(() => dispatch(new ResetFilesSelection()))
    );
  }

  @Action(DeleteFolder)
  @ImmutableContext()
  deleteFolder(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { folderId }: DeleteFolder
  ) {
    const { folders } = getState();
    const foundIndex = folders.findIndex(folder => folder.id === folderId);
    const foundFolder = foundIndex !== -1 ? folders[foundIndex] : null;

    setState((state: FileManagerStateModel) => {
      state.foldersLoading = true;
      return state;
    });

    return this.accountService.deleteFolder(folderId).pipe(
      tap(() => setState((state: FileManagerStateModel) => {
        state.foldersLoading = false;
        state.folders = state.folders.filter(folder => folder.id !== folderId)

        for (const index in state.files) {
          const file = state.files[index];
          if (file.folder === folderId) {
            state.files[index].folder = null;
          }
        }

        return state;
      })),
      map(() => {
        return dispatch(new SelectFolder(
          this.store.selectSnapshot(FileManagerState.selectedFolderId)));
      })
    );
  }

  @Action(CreateFolder)
  @ImmutableContext()
  createFolder(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { title, parentId }: CreateFolder
  ) {
    setState((state: FileManagerStateModel) => {
      state.foldersLoading = true;
      return state;
    });

    return this.accountService.createFolder(title, parentId).pipe(
      map((newFolder: StorageFolder) => setState((state: FileManagerStateModel) => {
        state.foldersLoading = false;
        state.folders.unshift(newFolder);
        return state;
      }))
    );
  }

  @Action(ResetFilesSelection)
  @ImmutableContext()
  resetSelection(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { }: ResetFilesSelection
  ) {
    setState((state: FileManagerStateModel) => {
      state.selectedFilesIds = [];
      return state;
    });
  }

  @Action(ToggleFilesSelection)
  @ImmutableContext()
  toggleSelection(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { filesIds, selected, replace }: ToggleFilesSelection
  ) {
    setState((state: FileManagerStateModel) => {
      state.selectedFilesIds = replace ? [] : state.selectedFilesIds.filter(selectedFileId =>
        !filesIds.includes(selectedFileId));

      if (selected) {
        state.selectedFilesIds = [...state.selectedFilesIds, ...filesIds];
      }

      return state;
    });
  }

  @Action(DownloadFile)
  @ImmutableContext()
  downloadFile(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { url }: DownloadFile
  ) {
    const filename = url.split('/').pop();
    this.accountService.downloadFile(url).subscribe((data) => {
      saveAs(data.body, filename);
    });
  }

  @Action(ShowUnsorted)
  @ImmutableContext()
  showUnsorted(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { showUnsorted }: ShowUnsorted
  ) {
    const { selectedFolderId } = getState();

    setState((state: FileManagerStateModel) => {
      state.showUnsorted = showUnsorted;
      state.files = [];
      return state;
    });

    return dispatch([
      new ResetFilesSelection(),
      new LoadFolderFiles(selectedFolderId, !showUnsorted)
    ]);
  }

  @Action(FileUploadSuccess)
  @ImmutableContext()
  uploadSuccess(
    { setState, getState, dispatch }: StateContext<FileManagerStateModel>,
    { uploadFileId, storageFile, contextId }: FileUploadSuccess
  ) {
    if (contextId.indexOf(MANAGER_CTX_PREFIX) === -1) {
      return;
    }

    setState((state: FileManagerStateModel) => {
      state.files.push(storageFile);
      return state;
    });
  }
}
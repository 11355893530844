import { Transform, Type } from "class-transformer";

export class OrderLog {
  status_from: string;

  status_to: string;

  service_id: number;

  sent: boolean;

  read: boolean;

  service_type: string;

  order_id: number;

  order_number: string;

  @Type(() => Date)
  date: Date;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SmsConfirmationComponent } from './sms-confirmation.component';
import { SmsService } from '../shared/services/sms.service';
import { RemoteErrorsModule } from '../remote-errors/remote-errors.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    RemoteErrorsModule,
    ToastrModule,
    MatIconModule
  ],
  providers: [
    SmsService
  ],
  declarations: [
    SmsConfirmationComponent
  ],
  exports: [
    SmsConfirmationComponent
  ]
})
export class SmsConfirmationModule { }

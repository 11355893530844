import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Inject,
  Output,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, filter, mergeMap, tap, map, finalize } from 'rxjs/operators';
import { deserialize } from "class-transformer";
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { FileChooserComponent } from '../file-manager';
import { ReactiveComponent } from '../shared/components';
import { DocumentsService } from '../shared/services/documents.service';
import { Order } from '../shared/models/order';
import { StorageFile, UploadFile } from '../shared/models/file-manager';
import { UploadFiles, FileUploadSuccess, CancelFileUpload } from '../shared/store/actions';
import { FileUploaderState } from '../shared/store/state';

@Component({
  selector: 'tamo-documents-uploader',
  templateUrl: './documents-uploader.component.html',
  styleUrls: ['./documents-uploader.component.less']
})
export class DocumentsUploaderComponent extends ReactiveComponent {
  @Input()
  public disabled: boolean = false;

  @Input()
  public type: string;

  @Input()
  public counts: any;

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public prefix: string;

  @Input()
  public extraContext: any = {};

  @Input()
  public fileManager: boolean = true;

  @Output()
  uploadComplete: EventEmitter<StorageFile> = new EventEmitter();

  private loadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public loading$: Observable<boolean> = this.loadingSource$.asObservable();

  private deletingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public deleting$: Observable<boolean> = this.deletingSource$.asObservable();

  private selectingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public selecting$: Observable<boolean> = this.selectingSource$.asObservable();

  private documentsSource$: BehaviorSubject<StorageFile[]> = new BehaviorSubject([]);

  public documents$: Observable<StorageFile[]> = this.documentsSource$.asObservable();

  private fileOverSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public fileOver$: Observable<boolean> = this.fileOverSource$.asObservable();

  private expendedSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public expended$: Observable<boolean> = this.expendedSource$.asObservable();

  public files: File[] = [];

  public uploadingFiles$: Observable<UploadFile[]>;

  constructor(
      @Inject('apiUrl') private apiUrl: string,
      private docService: DocumentsService,
      private dialog: MatDialog,
      private http: HttpClient,
      private store: Store,
      private cdRef: ChangeDetectorRef,
      private actions$: Actions) {
    super();
  }

  get uploadUrl() {
    return `${this.apiUrl}${this.prefix}upload/`;
  }

  get uploaderContextId() {
    return `documents-uploader_${this.prefix}_${this.type}`;
  }

  onValidDrag(event) {
    this.fileOverSource$.next(!!event);
  }

  expand() {
    this.expendedSource$.next(true);
  }

  collapse() {
    this.expendedSource$.next(false);
  }

  cancelFile(file: UploadFile) {
    this.store.dispatch(new CancelFileUpload(file.id));
  }

  ngOnInit() {
    this.uploadingFiles$ = combineLatest([
      this.store.select(FileUploaderState.uploadingFilesByContextId),
      this.observePropertyCurrentValue<string>('prefix')
    ]).pipe(
      map(([filterFn,]) => filterFn(this.uploaderContextId)),
      tap(() => setTimeout(() => this.cdRef.detectChanges(), 0))
    );

    this.actions$.pipe(
      ofActionDispatched(FileUploadSuccess),
      filter(({ uploadFileId, contextId }: FileUploadSuccess) => contextId === this.uploaderContextId)
    ).subscribe(({ uploadFileId, contextId, storageFile }: FileUploadSuccess) => {
      const documents = this.documentsSource$.getValue();
      this.uploadComplete.next(storageFile);

      documents.unshift(storageFile);
      this.documentsSource$.next(documents);

      this.counts++;
    });
  }

  uploadFiles(files: File[]) {
    this.store.dispatch(new UploadFiles(files, {
      contextId: `${this.uploaderContextId}`,
      context: Object.assign({ 'type': this.type }, this.extraContext),
      uploadUrl: this.uploadUrl
    }));

    this.files = [];
    this.expand();
    this.cdRef.detectChanges();
  }

  removeDocument(document: StorageFile) {
    this.deletingSource$.next(true);
    this.docService.remove(`${this.prefix}documents/`, this.type, document.id).pipe(
      finalize(() => this.deletingSource$.next(false))
    ).subscribe(() => {
      const documents = this.documentsSource$.getValue();

      this.counts--;
      let index = documents.indexOf(document);
      if (index !== -1) {
        documents.splice(index, 1);
      }

      this.documentsSource$.next(documents);
    });
  }

  loadFiles() {
    this.refreshDocuments();
  }

  openManager() {
    if (!this.fileManager) {
      return;
    }

    const dialogRef = this.dialog.open(FileChooserComponent, {
      autoFocus: false,
      height: '95%',
      width: '95%',
      panelClass: 'dialog_relative'
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.ngUnsubscribe$),
      filter(result => result && result.length > 0),
      tap(() => this.selectingSource$.next(true)),
      mergeMap((result) => this.http.post(`${this.apiUrl}${this.prefix}select/`, {
        files_ids: result.map(file => file.id),
        type: this.type
      }).pipe(
        finalize(() => this.selectingSource$.next(false)),
        map(() => result))
      ),
      tap(() => this.expand())
    ).subscribe(result => {
      const documents = this.documentsSource$.getValue();
      const newFilesIds = result.map(file => file.id);
      const filtered = documents.filter(document => !newFilesIds.includes(document.id));

      const newDocuments = [...result, ...filtered];
      this.documentsSource$.next([...result, ...filtered]);
      this.counts = newDocuments.length;
    });
  }

  refreshDocuments() {
    this.loadingSource$.next(true);
    this.docService.byType(`${this.prefix}documents/`, this.type).subscribe(
      (documents) => {
        this.documentsSource$.next(documents);
        this.counts = documents.length;
      },
      (err) => {},
      () => this.loadingSource$.next(false)
    );
  }

  trackByFileId(index, file: UploadFile) {
    return file.id;
  }
}

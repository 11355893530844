import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, of, combineLatest } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Moment } from 'moment';

import { OrderState, UpdateService, AuthState, PatchService } from '../../shared/store';
import { DadataOrg } from '../../dadata';
import {
  ConditionalRelease,
  ConditionalReleaseService,
  ReactiveComponent,
  AuthUser
} from '../../shared';

@Component({
  selector: 'tamo-conditional-release-details',
  templateUrl: './conditional-release-details.component.html',
  styleUrls: ['./conditional-release-details.component.less']
})
export class ConditionalReleaseDetailsComponent extends ReactiveComponent {
  @Input()
  public service: ConditionalRelease;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  public serviceForm: FormGroup;

  public operatorForm: FormGroup;

  public uploadUrl$: Observable<string>;

  constructor(
      @Inject('apiUrl') private apiUrl: string,
      private store: Store,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private dataSrv: ConditionalReleaseService,
      private toastr: ToastrService) {
    super();

    this.serviceForm = this.fb.group({
      product_description: [''],
      extra_response_date: [null],
      comment: ['']
    });

    this.operatorForm = this.fb.group({
      request_date: [null],
      provision_end_date: [null],
      extra_issue_date: [null],
      deposit_cost: [''],
      income_number: ['']
    });
  }

  get f() {
    return this.serviceForm.controls;
  }

  get fo() {
    return this.operatorForm.controls;
  }

  ngOnInit() {
    const service$ = this.observePropertyCurrentValue<ConditionalRelease>('service');
    // this.observePropertyCurrentValue<ConditionalRelease>('service')
    service$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((service: ConditionalRelease) => {
      this.serviceForm.patchValue(service);
      this.operatorForm.patchValue(service);
    });

    this.uploadUrl$ = service$.pipe(
      map(service => `${this.apiUrl}/conditionalrelease/${service.id}/managerupload/`));

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.operatorForm.disable();
        this.serviceForm.disable();
      } else {
        this.operatorForm.enable();
        this.serviceForm.enable();
      }
    });
  }

  // onSubmit(process = false) {
  //   if(!this.serviceForm.valid) {
  //     return;
  //   }

  //   const model = this.serviceForm.value;
  //   model.extra_response_date = model.extra_response_date ?
  //     model.extra_response_date.format('YYYY-MM-DD') : null;
  //   const toSave = plainToClass(ConditionalRelease, model as Object);

  //   this.store.dispatch(new UpdateService(toSave, process));
  // }

  onOperatorSubmit() {
    if (!this.operatorForm.valid) {
      return;
    }

    const value = this.operatorForm.value;
    const data = Object.assign({}, value, {
      request_date: value['request_date'] ? moment(value['request_date']).format('YYYY-MM-DD') : null,
      provision_end_date: value['provision_end_date'] ? moment(value['provision_end_date']).format('YYYY-MM-DD') : null,
      extra_issue_date: value['extra_issue_date'] ? moment(value['extra_issue_date']).format('YYYY-MM-DD') : null
    });

    this.store.dispatch(new PatchService(data));
  }

  onFileUploaded(fileName) {
    this.toastr.success(`Файл "${fileName}" успешно загружен`, 'Файл загружен');
  }
}

export class Duty {
  code: string;
  parent_code: string;
  name: string;
  full_name: string;
  preferences: string;
  duty_type: string;
  percantage_cost: number;
  fixed_cost: number;
  unit: string;
}
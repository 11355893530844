import { AuthRequest, AuthResponse } from '../../models';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: AuthRequest) {}
}

export class RefreshAuth {
  static readonly type = '[Auth] RefreshAuth';
  constructor() {}
}

export class AuthComplete {
  static readonly type = '[Auth] AuthComplete';
  constructor(public payload: AuthResponse, public redirect: boolean = false) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}

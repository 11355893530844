import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { Observable, BehaviorSubject, forkJoin, merge } from 'rxjs';
import { tap, shareReplay, takeUntil } from 'rxjs/operators';
import { classToClass } from 'class-transformer';

import { OrderState, UpdateService, AuthState } from '../../shared/store';
import { SeaShipping, ReactiveComponent, AuthUser, ShippingPoint, ShippingPointsService, ContainerType } from '../../shared';

@Component({
  selector: 'tamo-shipping-sea',
  templateUrl: './shipping-sea.component.html',
  styleUrls: ['./shipping-sea.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingSeaComponent extends ReactiveComponent {
  @Input()
  service: SeaShipping;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  serviceForm: FormGroup;

  constructor(
      private store: Store,
      private fb: FormBuilder,
      private cdRef: ChangeDetectorRef) {
    super();
    this.serviceForm = this.fb.group({
      containers_count: [''],
      cargo_name: [''],
      invoice_number: [''],
      sender_contact: [''],
      comment: [''],
      insurance: [''],

      departure_place: [''],
      delivery_address: [''],
      arrival_place: [''],
      container_type: [''],
      delivery_local: [false],
      cargo_weight: ['']
    });

    const service$ = this.observePropertyCurrentValue<SeaShipping>('service');
    service$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((service: SeaShipping) => {
      this.serviceForm.patchValue(classToClass(service));
    });
  }

  ngOnInit(): void {
  }
}

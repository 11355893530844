import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DadataOrg } from '../../dadata';
import { Manager, Registration } from '../../shared/models';
import { ManagersService } from '../../shared/services';
import { FormatErrorsPipe } from '../../shared/pipes';
import { RemoteErrorsService } from '../../remote-errors';

@Component({
  selector: 'tamo-managers-details',
  templateUrl: './managers-details.component.html',
  styleUrls: ['./managers-details.component.less'],
  providers: [RemoteErrorsService, FormatErrorsPipe]
})
export class ManagersDetailsComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  public managerForm: FormGroup;

  public saving: boolean = false;

  @Input() manager: Manager;

  @Output() managerChange: EventEmitter<Manager> = new EventEmitter();

  constructor(private fb: FormBuilder,
              private managersService: ManagersService,
              private errSrv: RemoteErrorsService,
              private snackBar: MatSnackBar) {
    this.managerForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      middle_name: [''],
      password: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      username: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      phone: ['', Validators.compose([Validators.required])],
      note: ['']
    });
  }

  get f() { return this.managerForm.controls; }

  ngOnInit() {
  }

  saveImporter() {
    if (this.managerForm.invalid) {
      return;
    }
    const manager = plainToClass(Registration,
      this.managerForm.value as Object);

    this.saving = true;
    this.errSrv.clearErrors();
    this.managerForm.disable();
    this.managersService.create(manager)
      .pipe(finalize(() => this.saving = false))
      .subscribe(
        (newData: Manager) => {
          this.managerForm.enable();
          this.snackBar.open('Менеджер успешно создан', 'закрыть');
          this.managerChange.emit(newData);
        },
        (err) => {
          this.managerForm.enable();
          this.errSrv.handleErrors(this.managerForm)(err);
        }
      );
  }
}

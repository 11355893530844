import { Component, EventEmitter, Output, Input, forwardRef } from '@angular/core';
import { Observable, of, BehaviorSubject  } from 'rxjs';
import { mergeMap, tap, filter, shareReplay, map, debounceTime, startWith, skip } from 'rxjs/operators';
import { FormControl  } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { KladrCity } from './kladr.city';
import { KladrService } from './kladr.service';

@Component({
  selector: 'kladr-suggest-city',
  templateUrl: './kladr.component.html',
  styleUrls: ['./kladr.component.less']
})
export class KladrComponent {
  @Output()
  public optionSelected: EventEmitter<KladrCity> = new EventEmitter();

  @Output()
  public valueChanged: EventEmitter<string> = new EventEmitter();

  public loading: boolean = false;

  @Input()
  public appearance: MatFormFieldAppearance = 'outline';

  @Input()
  public formControl = new FormControl();

  public variants$: Observable<KladrCity[]>;

  private selectedOptionSource$: BehaviorSubject<KladrCity> = new BehaviorSubject(null);

  public selectedOption$: Observable<KladrCity> = this.selectedOptionSource$.asObservable();

  public hint$: Observable<string> = this.selectedOption$.pipe(
    map((option) => option ? option.name : 'Начните вводить название города'));

  @Input()
  public placeholder: string = 'Город';
  
  constructor(private kladrService: KladrService) { }

  ngAfterViewInit() {
    this.variants$ = this.formControl.valueChanges
      .pipe(
        skip(1),
        startWith(''),
        debounceTime(200),
        tap((value) => this.valueChanged.next(value)),
        filter(value => value),
        mergeMap((value: string) => {
          if(value.length > 1) {
            this.loading = true;
            return this.kladrService.findCities(value);
          } else {
            return of([]);
          }
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        map(value => value as KladrCity[])
      );
    this.variants$.subscribe(() => this.loading = false);
  }

  onSelectionChanged(event$) {
    const model: KladrCity = event$.option.value;
    this.formControl.patchValue(model.name);
    this.optionSelected.next(model);
    this.selectedOptionSource$.next(model);
  }

  clearData() {
    this.formControl.setValue('')
    this.optionSelected.next(null);
    this.selectedOptionSource$.next(null);
  }
}

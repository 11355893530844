import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { ConditionalRelease, PagedResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ConditionalReleaseService {
  private prefix: string = '/conditionalrelease/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(page: number = 1): Observable<PagedResult<ConditionalRelease>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<ConditionalRelease>(ConditionalRelease), res)));
  }

  public get(id: number): Observable<ConditionalRelease> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(ConditionalRelease, res)));
  }

  public save(id, data): Observable<ConditionalRelease> {
    // console.log(data);
    // console.log(classToPlain(data));
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(ConditionalRelease, res)));
  }

  public create(data): Observable<ConditionalRelease> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(ConditionalRelease, res)));
  }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private prefix: string = '/countries/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(): Observable<Country[]> {
    return this.http.get(`${this.apiUrl}${this.prefix}`)
      .pipe(map(res => plainToClass(Country, res as Object[])));
  }
}
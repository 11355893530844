<div class="booking">
	<h3 fxLayout="row" fxLayoutGap="10px" style="margin-top: 0" fxLayoutAlign="flex-start center">
	  <mat-icon fontSet="fa" fontIcon="fa-calculator" [inline]="true"></mat-icon>
	  <span>Расчет стоимости перевозки</span>
	</h3>

  <div fxLayout="column" fxLayoutGap="20px">
  	<tamo-shipping-calculator (formFilled)="onFormFilled($event)"></tamo-shipping-calculator>

    <mat-card fxFlex>
      <button type="button" (click)="saveService()" [disabled]="disabled$ | async"
        mat-raised-button color="accent">Создать букинг</button>
    </mat-card>
  </div>
</div>
import { StorageFolder, StorageFile } from '../../models';

export class InitFileManager {
  static readonly type = '[filemanager] InitFileManager';
  constructor(public accountId: number) {}
}

export class SelectFolder {
  static readonly type = '[filemanager] SelectFolder';
  constructor(public folderId: number | null) {}
}

export class LoadFolderFiles {
  static readonly type = '[filemanager] LoadFolderFiles';
  constructor(public folderId: number | null, public onlySorted: boolean = true) {}
}

export class ResetFileManager {
  static readonly type = '[filemanager] ResetFileManager';
  constructor() {}
}

export class CreateFolder {
  static readonly type = '[filemanager] CreateFolder';
  constructor(public title: string, public parentId: number | null) {}
}

export class ResetFilesSelection {
  static readonly type = '[filemanager] ResetFilesSelection';
  constructor() {}
}

export class ToggleFilesSelection {
  static readonly type = '[filemanager] ToggleFilesSelection';
  constructor(public filesIds: number[], public selected: boolean, public replace: boolean = false) {}
}

export class MoveFile {
  static readonly type = '[filemanager] MoveFile';
  constructor(public fileId: number, public folderId: number) {}  
}

export class MoveSelectedFiles {
  static readonly type = '[filemanager] MoveSelectedFiles';
  constructor(public folderId: number) {}  
}

export class ChangeFileName {
  static readonly type = '[filemanager] ChangeFileName';
  constructor(public fileId: number, public title: string) {}  
}

export class ChangeFolderName {
  static readonly type = '[filemanager] ChangeFolderName';
  constructor(public folderId: number, public title: string) {}  
}

export class DeleteFile {
  static readonly type = '[filemanager] DeleteFile';
  constructor(public fileId: number) {}    
}

export class DeleteFolder {
  static readonly type = '[filemanager] DeleteFolder';
  constructor(public folderId: number) {}
}

export class DeleteSelectedFiles {
  static readonly type = '[filemanager] DeleteSelectedFiles';
  constructor() {}  
}

export class DownloadFile {
  static readonly type = '[filemanager] DownloadFile';
  constructor(public url: string) {}  
}

export class ShowUnsorted {
  static readonly type = '[filemanager] ShowUnsorted';
  constructor(public showUnsorted: boolean) {}  
}
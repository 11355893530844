import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, zip, of, combineLatest } from 'rxjs';
import { plainToClass } from "class-transformer";
import {
  startWith, mergeMap, map, tap, switchMap, skip, filter,
  debounceTime, finalize, catchError, shareReplay, takeUntil, first,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { OrderState, UpdateService, AuthState } from '../../shared/store';
import { DadataOrg } from '../../dadata';
import {
  Certification,
  CertificationService,
  ReactiveComponent,
  AuthUser
} from '../../shared';

@Component({
  selector: 'tamo-certification-details',
  templateUrl: './certification-details.component.html',
  styleUrls: ['./certification-details.component.less']
})
export class CertificationDetailsComponent extends ReactiveComponent {
  @Input()
  public service: Certification;

  @Select(OrderState.serviceSaving)
  saving$: Observable<boolean>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  public serviceForm: FormGroup;

  constructor(
      private store: Store,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private certSrv: CertificationService) {
    super();

    this.serviceForm = this.fb.group({
      certification_type: [''],
      description: [''],
      comment: [''],
      manufacturer: [''],
      party_number: [''],
      invoice_number: [''],
      invoice_date: [''],
      contract_number: [''],
      contract_date: [''],

      org_name: [''],
      inn: [''],
      ogrn: [''],
      kpp: [''],
      legal_address: [''],
      physical_address: [''],
      director_position: [''],
      director_fio: [''],
      correspondence_address: ['']
    });
  }

  get f() {
    return this.serviceForm.controls;
  }

  ngOnInit() {
    this.observePropertyCurrentValue<Certification>('service')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((service: Certification) => {
        this.serviceForm.patchValue(service);
      });

    this.saving$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(saving => {
      if (saving) {
        this.serviceForm.disable();
      } else {
        this.serviceForm.enable();
      }
    });
  }

  onSelectionChanged(org: DadataOrg) {
    this.serviceForm.patchValue({
      inn: org.data.inn,
      kpp: org.data.kpp,
      ogrn: org.data.ogrn,
      org_name: org.data.name.short_with_opf,

      director_position: org.data.management ? org.data.management.post : '',
      director_fio: org.data.management ? org.data.management.name : '',

      legal_address: org.data.address ? org.data.address.value : '',
      physical_address: org.data.address ? org.data.address.value : '',
      correspondence_address: org.data.address ? org.data.address.value: ''
    });
    this.serviceForm.get('inn').markAsDirty();
  }

}

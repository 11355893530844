import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { Duty } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DutyService {
  private prefix: string = '/duty/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public search(query: string):
      Observable<Duty[]> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?search=${query}`)
      .pipe(map(res => plainToClass(Duty, res as Object[])));
  }
}

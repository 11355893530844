import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, switchMap, debounceTime, shareReplay, filter, tap, map } from 'rxjs/operators';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';

import { SelectOrderById, OrderState, PatchOrderName, PatchOrderManager, AddService, AddServiceSuccess, AuthState } from '../../shared/store';
import {
  AuthService,
  Order,
  OrdersService,
  OrderLog,
  Message,
  Manager,
  MessagesService,
  User,
  ReactiveComponent,
  Service,
  ServiceType,
  AuthUser
} from '../../shared';
import { SocketService } from '../../socket';
import { ManagersPickerComponent } from '../../managers';

@Component({
  selector: 'tamo-orders-details',
  styleUrls: ['orders-details.component.scss'],
  templateUrl: 'orders-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersDetailsComponent extends ReactiveComponent {
  @Select(OrderState.selectedOrder)
  order$: Observable<Order>;

  @Select(OrderState.orderSaving)
  orderSaving$: Observable<boolean>;

  @Select(OrderState.orderLoading)
  orderLoading$: Observable<boolean>;

  @Select(OrderState.orderServices)
  services$: Observable<Service[]>;

  @Select(OrderState.orderEpsentServiceTypes)
  epsentServiceTypes$: Observable<ServiceType[]>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  private saveRequestedSource$: Subject<string> = new Subject<string>();

  private saveRequested$ = this.saveRequestedSource$.asObservable();

  // public order: Order;

  public unreadMessagesCount: number;

  public selectedServiceId$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private store: Store,
      private actions$: Actions,
      private ordersService: OrdersService,
      private socketService: SocketService,
      private bottomSheet: MatBottomSheet,
      private authService: AuthService,
      private messagesService: MessagesService) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(params => {
      const orderId = parseInt(params['orderid']);
      this.store.dispatch(new SelectOrderById(orderId));
      this.messagesService.unread(orderId).subscribe(count => this.unreadMessagesCount = count);
    });

    this.services$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((services: Service[]) => {
      if (services.length > 0 && this.route.children.length === 0) {
        this.router.navigate([`services/${services[0].id}`], { relativeTo: this.route });
      }
    });

    this.saveRequested$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        debounceTime(500)
      ).subscribe(name => {
        this.store.dispatch(new PatchOrderName(name));
      });

    // this.messagesService.touchComplete$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe$),
    //     filter((orderId: number) => this.order.id == orderId)
    //   )
    //   .subscribe(() => this.unreadMessagesCount = 0);

    // this.socketService.messageSent$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe$),
    //     filter((message: Message) => message.object_id == this.order.id)
    //   )
    //   .subscribe(() => this.unreadMessagesCount += 1);

    this.actions$.pipe(
      takeUntil(this.ngUnsubscribe$),
      ofActionDispatched(AddServiceSuccess)
    ).subscribe(({ service }) =>
      this.router.navigate([`services/${service.id}`], { relativeTo: this.route }));
  }

  public openManagersSelect(event: MouseEvent) {
    event.preventDefault();
    
    const sheetRef = this.bottomSheet.open(ManagersPickerComponent);
    sheetRef.afterDismissed()
      .subscribe((manager: Manager) => {
        if(manager) {
          this.store.dispatch(new PatchOrderManager(manager));
        }
      });
  }

  public removeManager(event: MouseEvent) {
    event.preventDefault();
    this.store.dispatch(new PatchOrderManager(null));
  }

  public createService(serviceType: ServiceType) {
    this.store.dispatch(new AddService(serviceType));

    // this.ordersService
    //   .patch(this.order.id, {
    //     services: [{
    //       'resourcetype': serviceType.type
    //     }]
    //   }).subscribe(
    //     (order: Order) => {
    //       order.manager = this.order.manager;
    //       const service = order.services.find(srv => srv.service_type.type == serviceType.type);
    //       this.ordersDetailsService.setOrder(order);
    //       this.router.navigate([`services/${service.id}`], { relativeTo: this.route })
    //     },
    //     err => { },
    //     () => {
    //       this.managerSaving = false;
    //     }
    //   );
  }

  public saveName($event) {
    this.saveRequestedSource$.next($event);
  }
}

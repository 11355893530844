import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, finalize, tap, filter } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OrdersUIService } from '../orders-ui.service';
import { OrdersService, ReactiveComponent, ServiceType } from '../../shared';

@Component({
  selector: 'app-orders-creation',
  templateUrl: './orders-creation.component.html',
  styleUrls: ['./orders-creation.component.less']
})
export class OrdersCreationComponent extends ReactiveComponent {
  public serviceTypes = (ServiceType.getValues() as ServiceType[]).filter(serviceType => !serviceType.hidden);

	public orderForm: FormGroup;

  constructor(private fb: FormBuilder,
  						private ordersSrv: OrdersService,
  						private uiService: OrdersUIService,
              private route: ActivatedRoute,
              private router: Router) {
    super();

    this.orderForm = fb.group({
    	name: ['Новый заказ'],
    	services: [[]]
    });
  }

  ngOnInit() {

  }

  get f() {
  	return this.orderForm.controls;
  }

  onSubmit() {
    if(this.orderForm.invalid) {
      return;
    }

    const model = this.orderForm.value;
    this.orderForm.disable();
    this.uiService.createStarted();
    this.ordersSrv.create({
      name: model['name'],
      manager_id: null,
      services: model['services'].map(type => {
        return {
          'resourcetype': type
        }
      })
    }).pipe(
      takeUntil(this.ngUnsubscribe$),
      finalize(() => {
        this.orderForm.enable();
      	this.uiService.createCompleted();
      })
    ).subscribe(
      (order) => {
        this.orderForm.reset({ services: [], name: "Новый заказ" });
        this.uiService.close();
        this.router.navigate([`${order.id}`], { relativeTo: this.route });
      },
      err => {}
    );
  }
}

<div *ngIf="importer" class="importer">
  <form [formGroup]="importerForm" (ngSubmit)="saveImporter()" class="importer-form">
    <div fxLayout="column" fxLayoutGap="10px" class="importer-form__content">
      <div class="importer-form__group">
        <dadata-suggest (optionSelected)="onSelectionChanged($event)"></dadata-suggest>
      </div>

      <mat-divider></mat-divider>
      <mat-card>
        <mat-card-title>
          {{ isAdd ? 'Новая организация' : importer.org_name }}
        </mat-card-title>
        <mat-card-content>
          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="ИНН" formControlName="inn">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="Наименование компании" formControlName="org_name">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="ОГРН" formControlName="ogrn">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="КПП" formControlName="kpp">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="Юридический адрес" formControlName="legal_address">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="Физический адрес" formControlName="physical_address">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="Адрес для корреспонденции" formControlName="correspondence_address">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="Должность руководителя" formControlName="director_position">
            </mat-form-field>
          </div>

          <div class="importer-form__group">
            <mat-form-field class="importer-form__field">
              <input matInput placeholder="ФИО руководителя" formControlName="director_fio">
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="importer-form__toolbar">
      <mat-toolbar fxLayout="row" fxLayoutAlign="flex-end center">
        <button *ngIf="!isAdd" mat-raised-button color="accent" [disabled]="saving">Сохранить</button>
        <button *ngIf="isAdd" mat-raised-button color="primary" [disabled]="saving">Добавить</button>
        <!-- <button mat-raised-button color="primary">Закрыть</button> -->
      </mat-toolbar>
    </div>
  </form>
</div>
<div *ngIf="!profile"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

<div *ngIf="profile" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="questionary" id="questionary">
  <mat-card class="card_big" *ngIf="profile">
    <mat-horizontal-stepper linear="true" #stepper>
      <mat-step [stepControl]="questionaryForm">
        <form [formGroup]="questionaryForm">
          <ng-template matStepLabel>Шаг 1</ng-template>
          <div *ngIf="!submitted">
            <tamo-personal-info [form]="questionaryForm" [profile]="profile"></tamo-personal-info>
          </div>
        </form>
        <div *ngIf="submitted">
          <mat-card class="card">
            <p>Вы заполнили первый шаг<br />Теперь необходимо загрузить информацию о компании</p>
            <button mat-raised-button color="accent" matStepperNext>Продолжить</button>
          </mat-card>
        </div>
      </mat-step>

      <mat-step [stepControl]="questionaryForm">
        <form [formGroup]="questionaryForm">
          <ng-template matStepLabel>Шаг 2</ng-template>
          <div *ngIf="!submitted">
            <tamo-direction-activity [form]="questionaryForm"></tamo-direction-activity>
          </div>
        </form>
        <div *ngIf="submitted">
          <mat-card class="card">
            <p>Вы заполнили второй шаг<br /></p>
          <button mat-raised-button color="accent" type="button" matStepperNext>Продолжить</button>
        </mat-card>
        </div>
      </mat-step>

      <mat-step [stepControl]="questionaryForm">
        <form [formGroup]="questionaryForm">
          <ng-template matStepLabel>Шаг 3</ng-template>
          <div *ngIf="!submitted">
            <tamo-organization [form]="orgForm"></tamo-organization>
          </div>
        </form>
        <div *ngIf="submitted">
          <mat-card class="card">
            <p>Вы заполнили третий шаг<br /></p>
            <button mat-raised-button color="accent" type="button" matStepperNext>Продолжить</button>
        </mat-card>
        </div>
      </mat-step>

      <mat-step [stepControl]="questionaryForm">
        <form [formGroup]="questionaryForm">
          <div *ngIf="!submitted">
            <ng-template matStepLabel>Шаг 4</ng-template>
            <tamo-documents-uploader [prefix]="profile.getUploadPrefix()" counts="-"
              type="PROF" title="ИНН, КПП, ОГРН, устав"></tamo-documents-uploader>
          </div>
        </form>
         <div *ngIf="submitted">
          <mat-card class="card">
            <p>Вы заполнили четвертый шаг<br/>А мы тем временем подготовим договор</p>
          <button mat-raised-button color="accent" type="button" matStepperNext>Продолжить</button>
        </mat-card>
        </div>
      </mat-step>

      <mat-step>
        <h2>Поздравляем!</h2>
        <p>
          Вы успешно заполнили анкеты и можете начать пользоваться сервисом.<br/>
          Для продолжения нажмите "Готово".
          <!-- <button mat-button color="primary" type="button">Ознакомтесь с договором</button> -->
        </p>
      </mat-step>

    </mat-horizontal-stepper>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button color="primary" (click)="save()">Пропустить анкету</button>
    
      <div *ngIf="!submitted" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
        <div *ngIf="stepper.selectedIndex > 0" class="questionary__prev">
          <button (click)="prev(stepper)" mat-raised-button type="button">Назад</button>
        </div>

        <div *ngIf="stepper.selectedIndex < 4" class="questionary__next">
          <button mat-raised-button color="accent" (click)="next(stepper)">Далее</button>
        </div>

        <div *ngIf="stepper.selectedIndex == 4" class="questionary__next">
          <button mat-raised-button color="primary" (click)="save()">Готово</button>
        </div>
      </div>
    </div>
  </mat-card>
</div>

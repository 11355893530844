import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { NotificationsService } from './notifications.service';
import { Order, PagedResult, OrderLog, OrderFilter } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private prefix: string = '/orders';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient,
              private notifyService: NotificationsService) { }

  public all(page: number = 1): Observable<PagedResult<Order>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}/?page=${page}&expand=manager,client&status=DR`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<Order>(Order), res)));
  }

  public filter(query: OrderFilter, page: number = 1): Observable<PagedResult<Order>> {
    const queryString = Object.entries(classToPlain(query, { excludeExtraneousValues: true }))
      .filter(([key, val]) => val !== null && val !== undefined)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    return this.http
      .get(`${this.apiUrl}${this.prefix}/?page=${page}&expand=manager,client` +
        (queryString.length > 0 ? `&${queryString}` : ''))
      .pipe(map(res => plainToClassFromExist(new PagedResult<Order>(Order), res)));
  }

  public get(id: number): Observable<Order> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}/${id}/?expand=manager,client`)
      .pipe(map(res => plainToClass(Order, res)));
  }

  public save(id, data): Observable<Order> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}/${id}/?expand=manager,client`, classToPlain(data))
      .pipe(map(res => plainToClass(Order, res)));
  }

  public patch(id, data): Observable<Order> {
    return this.http
      .patch(`${this.apiUrl}${this.prefix}/${id}/?expand=manager,client`, classToPlain(data))
      .pipe(map(res => plainToClass(Order, res)));
  }

  public create(data): Observable<Order> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Order, res)));
  }
}

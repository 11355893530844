import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileDownloaderDirective } from './file-downloader.directive';

@NgModule({
  declarations: [
    FileDownloaderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileDownloaderDirective
  ]
})
export class FileDownloaderModule { }

<div class="section" *ngIf="section$ | async as section">
  <div class="section__loader" *ngIf="loading">
    <mat-spinner class="section__spinner"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <h3 class="section__title">{{ section.name }}</h3>

    <swiper [config]="sliderConfig" [(index)]="currentIndex">
      <div *ngFor="let hint of section.hints; let i = index" class="section__slide section-hint">
        <div [ngClass]="{'mat-elevation-z3': i === currentIndex, 'mat-elevation-z1': i !== currentIndex}"
            class="section-hint__wrapper" [tamoRemoteHint]="hint.slug" tamoRemoteHintEvent="dblclick">
          <img class="section-hint__image" src="{{ hint.imageUrl }}" alt="{{ hint.title }}" width="100%">
          <div class="section-hint__content">
            <div class="section-hint__name">{{ hint.title }}</div>
          </div>
        </div>
      </div>
    </swiper>
  </div>
</div>
import { Exclude, Transform, Type } from 'class-transformer';

import { Service } from './service';
import { DocumentOwner } from './document-owner'


export class Sign extends Service implements DocumentOwner {
  org_name: string;

	inn: string;

	ogrn: string;

	legal_address: string;

	physical_address: string;

  correspondence_address: string;

	director_position: string;

	director_fio: string;

  kpp: string;

  getPrefix() {
    return 'sign';
  }  
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { OrdersStatusComponent } from './order-status.component';
import { OrderStatusPipe, OrderStatusClassPipe, OrderStatusIconPipe } from './order-status.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ],
  providers: [
    OrderStatusPipe,
    OrderStatusClassPipe,
    OrderStatusIconPipe
  ],
  declarations: [
    OrdersStatusComponent,
    OrderStatusPipe,
    OrderStatusClassPipe,
    OrderStatusIconPipe
  ],
  exports: [
    OrdersStatusComponent,
    OrderStatusPipe,
    OrderStatusClassPipe,
    OrderStatusIconPipe
  ]
})
export class OrderStatusModule { }

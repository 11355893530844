import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, switchMap, shareReplay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';

import { OrdersUIService } from '../orders-ui.service';
import {
  Order, PagedResult, Manager, User,
  OrdersService, AuthService,
  ReactiveComponent, AuthUser,
  ServiceType
} from '../../shared';
import { AuthState, OrdersState, ChangeOrdersPage, ResetOrdersFilter } from '../../shared/store';

@Component({
  selector: 'tamo-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent extends ReactiveComponent implements OnInit {
  @Select(OrdersState.loading)
  loading$: Observable<boolean>;

  @Select(OrdersState.filterApplied)
  filterApplied$: Observable<boolean>;

  @Select(OrdersState.orders)
  orders$: Observable<Order[]>;

  @Select(OrdersState.page)
  page$: Observable<number>;

  @Select(OrdersState.total)
  total$: Observable<number>;

  @Select(OrdersState.pageSize)
  pageSize$: Observable<number>;

  @ViewChild('ordersTable', { static: false })
  public table: any;

  readonly ServiceType: typeof ServiceType = ServiceType;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  constructor(
      private store: Store,
      private router: Router,
      private route: ActivatedRoute,
      private authService: AuthService,
      private uiService: OrdersUIService) {
    super();
  }

  ngOnInit() {

    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(params => {
      if(params['create']) {
        this.uiService.requestCreationForm();
      } else {
        this.uiService.close();
      }
    });

    this.store.dispatch(new ResetOrdersFilter());
  }

  public selectRow(evt) {
    const order = evt.selected[0];
    this.router.navigate([`app/orders/${order.id}`]);
  }

  public openService(evt, order, service) {
    evt.preventDefault();
    evt.stopPropagation();
    this.router.navigate([`app/orders/${order.id}/services/${service.id}`]);
    return false;
  }

  public createOrder() {
    this.uiService.requestCreationForm();
  }

  public setPage(pageInfo) {
    // console.log(pageInfo);
    // this.listLoaderSource$.next(pageInfo.offset + 1);
    this.store.dispatch(new ChangeOrdersPage(pageInfo.offset + 1));
  }

  public selectManager() {
    console.log('Select manager');
    return;
  }

  public toggleExpandRow(evt, row) {
    evt.stopPropagation();
    this.table.rowDetail.toggleExpandRow(row);
    return;
  }
}

import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'tamo-shipping-result',
  templateUrl: './shipping-result.component.html',
  styleUrls: ['./shipping-result.component.less']
})
export class ShippingResultComponent implements OnInit {

  @Input() arrivalPlace: string;

  @Input() arrivalIcon: string;

  @Input() departurePlace: string;

  @Input() departureIcon: string;

  @Input() cost: string;

  @Input() currency: string;

  @Input() routeIcon: string;

  @ContentChild('resultInfoTemplate')
  public resultInfoTemplate: TemplateRef<ElementRef>;

  @ContentChild('resultNoteTemplate')
  public resultNoteTemplate: TemplateRef<ElementRef>;

  constructor() { }

  ngOnInit(): void {
  }

}

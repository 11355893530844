<ng-container *ngIf="{
  user: user$ | async,
  serviceSaved: serviceSaved$ | async,
  serviceSaving: serviceSaving$ | async,
  service: service$ | async
} as ctx">
  <div class="service-details">
    <ng-container *ngIf="ctx.service">
      <h3 fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center" class="service-details__title">
        <mat-icon>{{ ctx.service.service_type.icon }}</mat-icon>
        <span>{{ ctx.service.service_type.title }}</span>

        <ng-container [ngSwitch]="ctx.service.service_type">
          <ng-container *ngSwitchCase="ServiceType.DECLARATION">
            <ng-container *ngTemplateOutlet="serviceStatusTpl"></ng-container>

            <ng-container *ngIf="!ctx.user.isStaff">
              <tamo-order-status [status]="ctx.service.dt_status"></tamo-order-status>

              <tamo-order-status class="service-form__tnved-status" [status]="ctx.service.tnved_status" [clickable]="true"
                (click)="scrollToTnvedCodes($event)"></tamo-order-status>
            </ng-container>

            <ng-container *ngIf="ctx.user.isStaff">
              <tamo-order-status *ngIf="ctx.user.isStaff" [status]="ctx.service.dt_status"
                [disabled]="ctx.serviceSaving" [selectable]="true" [matMenuTriggerFor]="dtStatusesMenu"></tamo-order-status>

              <tamo-order-status class="service-form__tnved-status" [status]="ctx.service.tnved_status"
                [disabled]="ctx.serviceSaving" [selectable]="true" [matMenuTriggerFor]="tnvedStatusesMenu"></tamo-order-status>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="ServiceType.CONDITIONAL_RELEASE">
            <tamo-order-status *ngIf="!ctx.user.isStaff" [status]="ctx.service.decision.id"></tamo-order-status>

            <tamo-order-status *ngIf="ctx.user.isStaff" [status]="ctx.service.decision.id"
              [disabled]="ctx.serviceSaving" [selectable]="true" [matMenuTriggerFor]="decisionsMenu"></tamo-order-status>
          </ng-container>
          
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="serviceStatusTpl"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #serviceStatusTpl>
          <tamo-order-status *ngIf="!ctx.user.isStaff"
            [status]="ctx.service.service_status?.id" [clickable]="true"
            (click)="scrollToExtraDocuments($event)"></tamo-order-status>

          <tamo-order-status *ngIf="ctx.user.isStaff"
            [disabled]="ctx.serviceSaving"
            [status]="ctx.service.service_status?.id" [selectable]="true"
            [matMenuTriggerFor]="statusesMenu"></tamo-order-status>          
        </ng-template>

        <mat-menu #statusesMenu="matMenu">
          <button *ngFor="let status of statuses"mat-menu-item (click)="updateStatus('status', status.value)"
            ><tamo-order-status [status]="status.value"></tamo-order-status></button>
<!--           <button mat-menu-item (click)="updateStatus('status', 'WA')"><tamo-order-status status="WA"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('status', 'CH')"><tamo-order-status status="CH"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('status', 'PR')"><tamo-order-status status="PR"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('status', 'CO')"><tamo-order-status status="CO"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('status', 'PA')"><tamo-order-status status="PA"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('status', 'CL')"><tamo-order-status status="CL"></tamo-order-status></button> -->
        </mat-menu>

        <mat-menu #dtStatusesMenu="matMenu">
          <button mat-menu-item (click)="updateStatus('dt_status', 'RD')"><tamo-order-status status="RD"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('dt_status', 'FI')"><tamo-order-status status="FI"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('dt_status', 'AS')"><tamo-order-status status="AS"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('dt_status', 'CR')"><tamo-order-status status="CR"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('dt_status', 'RL')"><tamo-order-status status="RL"></tamo-order-status></button>
        </mat-menu>

        <mat-menu #tnvedStatusesMenu="matMenu">
          <button mat-menu-item (click)="updateStatus('tnved_confirmed', true)"><tamo-order-status status="TC"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('tnved_confirmed', false)"><tamo-order-status status="TN"></tamo-order-status></button>
        </mat-menu>

        <mat-menu #decisionsMenu="matMenu">
          <button mat-menu-item (click)="updateStatus('decision', 'E')"><tamo-order-status status="E"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('decision', 'D')"><tamo-order-status status="D"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('decision', 'W')"><tamo-order-status status="W"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('decision', 'R')"><tamo-order-status status="R"></tamo-order-status></button>
          <button mat-menu-item (click)="updateStatus('decision', 'O')"><tamo-order-status status="O"></tamo-order-status></button>
        </mat-menu>
      </h3>   

      <div fxLayout="row">
        <div fxFlex="50%" fxFlex.lt-md="100%">
          <tamo-documents-uploader type="MAAC" title="Бухгалтерские документы"
            [disabled]="!ctx.user.isStaff"
            [fileManager]="false"
            [extraContext]="{ 'is_account': false }"
            [prefix]="ctx.service.getUploadPrefix()"
            [counts]="ctx.service.getCounts('MAAC')"></tamo-documents-uploader>
        </div>
      </div>

      <ng-container [ngSwitch]="ctx.service.service_type">
        <tamo-declaration-details *ngSwitchCase="ServiceType.DECLARATION" [service]="ctx.service"></tamo-declaration-details>
        <tamo-conditional-release-details *ngSwitchCase="ServiceType.CONDITIONAL_RELEASE" [service]="ctx.service"></tamo-conditional-release-details>
        <tamo-shipping-service *ngSwitchCase="ServiceType.SHIPPING" [service]="ctx.service"></tamo-shipping-service>
        <tamo-certification-details *ngSwitchCase="ServiceType.CERTIFICATION" [service]="ctx.service"></tamo-certification-details>
        <tamo-sign-details *ngSwitchCase="ServiceType.SIGN" [service]="ctx.service"></tamo-sign-details>
        <tamo-analytics-details *ngSwitchCase="ServiceType.ANALYTICS" [service]="ctx.service"></tamo-analytics-details>
        <tamo-consulting-details *ngSwitchCase="ServiceType.CONSULTING" [service]="ctx.service"></tamo-consulting-details>
        <tamo-classification-details *ngSwitchCase="ServiceType.CLASSIFICATION" [service]="ctx.service"></tamo-classification-details>
        <tamo-shipping-avia *ngSwitchCase="ServiceType.AVIA_SHIPPING" [service]="ctx.service"></tamo-shipping-avia>
        <tamo-shipping-auto *ngSwitchCase="ServiceType.AUTO_SHIPPING" [service]="ctx.service"></tamo-shipping-auto>
        <tamo-shipping-railway *ngSwitchCase="ServiceType.RAILWAY_SHIPPING" [service]="ctx.service"></tamo-shipping-railway>
        <tamo-shipping-sea *ngSwitchCase="ServiceType.SEA_SHIPPING" [service]="ctx.service"></tamo-shipping-sea>

        <ng-container *ngSwitchDefault>Ошибка при выборе услуги</ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
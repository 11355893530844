import { Pipe, PipeTransform } from '@angular/core';

import { serviceStatuses } from '../shared';


// const STATUSES = {
//   'DR': {
//     'label': 'черновик',
//     'class': 'status_draft',
//     'icon': 'fiber_new'
//   },
//   'WA': {
//     'label': 'запрошены документы',
//     'class': 'status_wait',
//     'icon': 'access_time'
//   },
//   'CH': {
//     'label': 'на проверке',
//     'class': 'status_checking',
//     'icon': 'search'
//   },
//   'PR': {
//     'label': 'заказ в работе',
//     'class': 'status_processing',
//     'icon': 'settings'
//   },
//   'CO': {
//     'label': 'заказ выполнен',
//     'class': 'status_complete',
//     'icon': 'check'
//   },
//   'PA': {
//     'label': 'ожидает оплаты',
//     'class': 'status_payment',
//     'icon': 'payment'
//   },
//   'CL': {
//     'label': 'заказ закрыт',
//     'class': 'status_closed',
//     'icon': 'lock'
//   },
//   'RD': {
//     'label': 'ДТ готова к подаче',
//     'class': 'status_dt',
//     'icon': 'event_note'
//   },
//   'FI': {
//     'label': 'ДТ Подана',
//     'class': 'status_dt',
//     'icon': 'event_note'
//   },
//   'AS': {
//     'label': 'ДТ Присвоена',
//     'class': 'status_dt',
//     'icon': 'event_note'
//   },
//   'RL': {
//     'label': 'ДТ выпущена',
//     'class': 'status_dt',
//     'icon': 'event_note'
//   },
//   'TC': {
//     'label': 'ТНВЭД',
//     'class': 'status_tnved status_tnved-completed',
//     'icon': 'check'
//   },
//   'TN': {
//     'label': 'ТНВЭД',
//     'class': 'status_tnved status_tnved-not-completed',
//     'icon': 'close'
//   }
// }

@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {
  transform(status: string): string {
    const found = serviceStatuses.find((s) => s.id == status);
    if(found) {
      return found.label;
    }
    return '';
  }
}

@Pipe({ name: 'orderStatusClass' })
export class OrderStatusClassPipe implements PipeTransform {
  transform(status: string): string {
    const found = serviceStatuses.find((s) => s.id == status);
    if(found) {
      return found.class;
    }
    return 'status_empty';
  }
}

@Pipe({ name: 'orderStatusIcon' })
export class OrderStatusIconPipe implements PipeTransform {
  transform(status: string): string {
    const found = serviceStatuses.find((s) => s.id == status);
    if(found) {
      return found.icon;
    }
    return 'remove';
  }
}
import { Exclude, Transform, Type } from 'class-transformer';

import { Service } from './service';
import { DocumentOwner } from './document-owner'


export class Classification extends Service implements DocumentOwner {
  product_description: string;

  getPrefix() {
    return 'classification';
  }
}
import { Injectable, Inject } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { startWith, map, tap, filter, shareReplay } from 'rxjs/operators';

import { Selectable } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class ShippingCalculatorService {
  constructor() { }

  public autocompleteDebounce<T extends Selectable>(
      listObs$: Observable<any>, valueObs$: Observable<T | string>, defaultValue: T): Observable<any[]> {
    return combineLatest(
      listObs$, valueObs$.pipe(startWith(defaultValue || ''))
    ).pipe(
      filter(([elements, value]) => elements.length > 0 && value != null),
      map(([elements, value]) => [elements, typeof value === "string" ? value : value.name]),
      map(([elements, value]) => elements.filter(
        element => element.name.toLowerCase().includes(value.toLowerCase()))),
      shareReplay({ bufferSize: 1, refCount: true })
    )
  }
}

<mat-dialog-content>
  <div class="duty-search__list">
    <mat-action-list>
      <button *ngFor="let variant of variants$ | async" mat-list-item (click)="selectVariant(variant)">
        <h4 mat-line><strong>[{{ variant.code }}]</strong> {{ variant.name }}</h4>
        <p mat-line>{{ variant.full_name }}</p>
      </button>
    </mat-action-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <mat-form-field class="duty-search__field" hintLabel="Для поиска начните ввод названия (мин 4 символа)">
    <input matInput class="duty-search__input"
      type="search"
      name="duty"
      autocomplete="off"
      placeholder="Поиск пошлины по товару"
      aria-label="Поиск пошлины"
      [formControl]="formControl">
    <mat-spinner *ngIf="loading" diameter="14" matSuffix></mat-spinner>
<!--     <mat-autocomplete #dutyAuto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
      <mat-option *ngFor="let variant of duties$ | async" [value]="variant">
        <strong>{{ variant.name }}</strong> {{ variant.full_name }}
      </mat-option>
    </mat-autocomplete> -->
  </mat-form-field>
</mat-dialog-actions>
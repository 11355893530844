import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { Analytics, PagedResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private prefix: string = '/analytics/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(page: number = 1): Observable<PagedResult<Analytics>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<Analytics>(Analytics), res)));
  }

  public get(id: number): Observable<Analytics> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(Analytics, res)));
  }

  public save(id, data): Observable<Analytics> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Analytics, res)));
  }

  public create(data): Observable<Analytics> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(Analytics, res)));
  }

}

import { Type } from 'class-transformer';

export class DataChanges {
  field_name: string;
}

export class ServiceChanges {
  service_id: string;

  order_id: string;

  @Type(() => DataChanges)
  changes: DataChanges[];
}
<div class="documents">
  <div fxLayout="row">
    <mat-card fxFlex.gt-md="50" fxFlex="100" class="documents__card">
      <ng-container *ngIf="!(loading$ | async) else loadingTpl">
        <ng-container *ngIf="(documentsCount$ | async) > 0 else emptyListTpl">
          <button mat-raised-button color="primary"
            (click)="downloadFiles($event)"
            [disabled]="downloading$ | async">Скачать все файлы</button>

          <ng-container *ngIf="documentsGroups$ | async as documentsGroups">
            <ng-container *ngFor="let typeKey of typesKeys">
              <mat-list *ngIf="documentsGroups[typeKey] as documents">
                <h3>{{ DocumentType[typeKey] }}</h3>
                <mat-list-item *ngFor="let item of documents">
                  <mat-icon mat-list-icon fontSet="fa"
                    [fontIcon]="item.content_type.icon" [style.color]="item.content_type.color"></mat-icon>
                  <h4 mat-line><a class="uploader__link" [tamoFileDownloader]="item.file" target="_blank">{{ item.title }}</a></h4>
                  <p mat-line nowrap class="uploader__date">{{ item.modified | amDateFormat: 'DD.MM.YY HH:mm' }}</p>
<!--                   <button type="button" mat-icon-button color="warn" (click)="removeDocument(item, documents)">
                    <mat-icon>delete_forever</mat-icon>
                  </button> -->
                </mat-list-item>
              </mat-list>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #emptyListTpl>Нет загруженных файлов для данной услуги</ng-template>

      <ng-template #loadingTpl>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </mat-card>
  </div>
</div>
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { ActivityKind, MainStream } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DictsService {
  private prefix: string = '/dicts/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public getActivities(): Observable<ActivityKind[]> {
    return this.http.get(`${this.apiUrl}${this.prefix}activities/`)
      .pipe(map(res => plainToClass(ActivityKind, res as Object[])));
  }

  public getStreams(): Observable<MainStream[]> {
    return this.http.get(`${this.apiUrl}${this.prefix}streams/`)
      .pipe(map(res => plainToClass(MainStream, res as Object[])));
  }
  // public get(slug: string): Observable<Hint> {
    // return this.http.get(`${environment.api}${this.prefix}${slug}/`)
    //   .pipe(map(res => plainToClass(Hint, res)));
  // }

  // public getSection(slug: string): Observable<Section> {
  //   return this.http.get(`${environment.api}/sections/${slug}/`)
  //     .pipe(map(res => plainToClass(Section, res)));
  // }
}
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, merge } from 'rxjs';
import { takeUntil, switchMap, debounceTime, shareReplay, filter, tap } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';

import { ComponentCanDeactivate } from '../../shared/guards';
import { OrderState, SelectServiceById, SaveServiceForm } from '../../shared/store';
import {
  AuthService,
  User,
  ReactiveComponent,
  serviceStatuses,
  Service,
  OrderLog
} from '../../shared';
import { SocketService } from '../../socket';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.less']
})
export class ServiceComponent extends ReactiveComponent implements ComponentCanDeactivate {
  @Select(OrderState.selectedService)
  service$: Observable<Service>;

  @Select(OrderState.serviceLoading)
  serviceLoading$: Observable<boolean>;

  @Select(OrderState.serviceSaving)
  serviceSaving$: Observable<boolean>;

  @Select(OrderState.serviceSaved)
  serviceSaved$: Observable<boolean>;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.store.selectSnapshot(OrderState.serviceSaved);
  }

  getPreventMessage() {
    return 'Внимание!!! У вас есть несохраненные данные. Чтобы не потерять изменения нажмите "Отмена" и сохраните форму.';
  }

  constructor(private route: ActivatedRoute,
              private store: Store,
              private socketService: SocketService,
              private authService: AuthService,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(params => {
        this.store.dispatch(new SelectServiceById(parseInt(params['serviceid'])));
      });

    merge(this.serviceLoading$, this.serviceSaving$, this.serviceSaved$, this.service$).pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      this.cdRef.detectChanges();
    })

    // this.socketService.statusChange$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe$),
    //     tap((orderLog: OrderLog) => console.log(orderLog.service)),
    //     tap((orderLog: OrderLog) => console.log(this.service.id)),
    //     filter((orderLog: OrderLog) => orderLog.service == this.service.id)
    //   )
    //   .subscribe((orderLog) => {
    //     console.log(orderLog);
    //     if(['DR', 'WA', 'PR', 'CO'].includes(orderLog.status_to)) {
    //       this.service.service_status = serviceStatuses.find(st => st.id === orderLog.status_to);
    //     } else if(['RD', 'FI', 'AS', 'RL'].includes(orderLog.status_to)) {
    //       this.service.dt_status = orderLog.status_to;
    //     } else if(['TC', 'TN'].includes(orderLog.status_to)) {
    //       this.service.tnved_confirmed = orderLog.status_to == 'TC';
    //     }
    //   });
  }

  saveService() {
    this.store.dispatch(new SaveServiceForm());
  }

  sendToProcess() {
    this.store.dispatch(new SaveServiceForm(true));
  }
}

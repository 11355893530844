import { Type, Transform } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';

export class News {
  title: string;

  text: string;

  @Type(() => Date)
  @Transform(val => val ? moment(val, 'YYYY-MM-DD') : null, { toClassOnly: true })
  date_creation: Moment;

  @Type(() => Date)
  @Transform(val => val ? moment(val, 'YYYY-MM-DD') : null, { toClassOnly: true })
  date_modify: Moment;
}
<!-- <div class="head"></div>
<div class="block"> -->
<div class="auth main-theme">
  <div fxLayout="row" fxLayoutAlign="center start">
    <mat-card class="auth__form" fxFlex="none" fxFlex.xs="100%">
      <div class="auth__sitename">VedClub</div>
      <div class="auth__slogan">Таможня - это просто!</div>
      <mat-card class="auth__card">
        <router-outlet></router-outlet>
      </mat-card>
      <nav mat-tab-nav-bar headerPosition="below">
        <a mat-tab-link
         [routerLink]="['login']"
         routerLinkActive #rla1="routerLinkActive"
         [active]="rla1.isActive">Вход
      </a>
        <a mat-tab-link
         [routerLink]="['register']"
         routerLinkActive #rla2="routerLinkActive"
         [active]="rla2.isActive">Регистрация
      </a>
      </nav>
    </mat-card>
  </div>
</div>
<!-- </div> -->

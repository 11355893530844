<ng-container *ngIf="{
	breadcrumbs: breadcrumbs$ | async,
	foldersLoading: foldersLoading$ | async
} as ctx">
	<mat-chip-list [disabled]="ctx.foldersLoading" class="breadcrumbs">
	  <mat-chip (click)="selectFolderById(null)"
	  	[disabled]="ctx.breadcrumbs.length === 0"><mat-icon>home</mat-icon></mat-chip>
		<ng-container *ngFor="let folder of ctx.breadcrumbs; let last = last; trackBy: trackByFolderId">
			<mat-chip class="breadcrumbs__item" color="accent" (click)="selectFolderById(folder.id)" [title]="folder.title"
				ellipsis [ellipsis-content]="folder.title" [disabled]="last"></mat-chip>
		</ng-container>
	</mat-chip-list>
</ng-container>

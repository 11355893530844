import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { DocumentsComponent } from './documents.component';
import { FileManagerModule } from '../file-manager';

@NgModule({
  declarations: [
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    FileManagerModule,
    MatButtonModule
  ]
})
export class DocumentsModule { }

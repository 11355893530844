import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deserialize, plainToClass } from 'class-transformer';

import { ReactiveComponent } from '../../shared/components';
import { StorageFolder, StorageFile, UploadFile } from '../../shared/models';
import {
  FileManagerState, SelectFolder, CreateFolder, DeleteFolder, ChangeFolderName,
  ChangeFileName, DeleteFile, DownloadFile, ToggleFilesSelection, ResetFilesSelection,
  ShowUnsorted, UploadFiles, MANAGER_CTX_PREFIX
} from '../../shared/store';
import { RangeSelectionEvent } from '../range-selection';

@Component({
  selector: 'tamo-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileManagerComponent extends ReactiveComponent {
  @Select(FileManagerState.foldersLoading)
  foldersLoading$: Observable<boolean>;

  @Select(FileManagerState.filesLoading)
  filesLoading$: Observable<boolean>;

  @Select(FileManagerState.selectedFolderFolders)
  levelFolders$: Observable<StorageFolder[]>;

  @Select(FileManagerState.selectedFolderFiles)
  levelFiles$: Observable<StorageFile[]>;

  @Select(FileManagerState.selectedFolder)
  selectedFolder$: Observable<StorageFolder>;

  @Select(FileManagerState.selectedFiles)
  selectedFiles$: Observable<StorageFile[]>;

  @Select(FileManagerState.selectedFilesIds)
  selectedFilesIds$: Observable<number[]>;

  @Select(FileManagerState.selectedFolderUploadingFiles)
  selectedFolderUploadingFiles$: Observable<UploadFile[]>;

  @Select(FileManagerState.showUnsorted)
  showUnsorted$: Observable<boolean>;

  @Input()
  editable: boolean = true;

  @Input()
  showBreadcrumbs: boolean = true;

  @Output()
  onSelectFiles: EventEmitter<StorageFile[]>;

  @ContentChild('actionsTemplate')
  actionsTemplate: TemplateRef<ElementRef>;

  private hoveredFilesIdsSource$: BehaviorSubject<number[]> = new BehaviorSubject([]);

  public hoveredFilesIds$: Observable<number[]> = this.hoveredFilesIdsSource$.asObservable();

  public files: File[] = [];

  constructor(
      private store: Store,
      private fb: FormBuilder,
      private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new SelectFolder(null));
    this.store.dispatch(new ResetFilesSelection());
    this.store.dispatch(new ShowUnsorted(false));
  }

  selectFolderById(folderId: number | null) {
    this.store.dispatch(new SelectFolder(folderId));
  }

  selectFolder(folder: StorageFolder) {
    this.store.dispatch(new SelectFolder(folder.id));
  }

  deleteFolder(folder: StorageFolder) {
    if (confirm(`Вы действительно хотите удалить папку "${folder.title}"?`)) {
      this.store.dispatch(new DeleteFolder(folder.id));
    }
  }

  editFile(file: StorageFile) {
    const title = prompt("Введите название файла", file.title);
    if (title !== null && title.length > 0 && title !== file.title) {
      this.store.dispatch(new ChangeFileName(file.id, title));
    }
  }

  deleteFile(file: StorageFile) {
    if (confirm(`Вы действительно хотите удалить файл "${file.title}"?`)) {
      this.store.dispatch(new DeleteFile(file.id));
    }
  }

  downloadFile(file: StorageFile) {
    this.store.dispatch(new DownloadFile(file.file));
  }

  selectFile(file: StorageFile) {
    const selectedFilesIds = this.store.selectSnapshot(FileManagerState.selectedFilesIds);
    this.store.dispatch(new ToggleFilesSelection([file.id], !selectedFilesIds.includes(file.id)));
  }

  deselectFile(file: StorageFile) {
    this.store.dispatch(new ToggleFilesSelection([file.id], false));
  }

  editFolder(folder: StorageFolder) {
    const title = prompt("Введите название папки", folder.title);
    if (title !== null && title.length > 0 && title !== folder.title) {
      this.store.dispatch(new ChangeFolderName(folder.id, title));
    }
  }

  trackByFileId(index, file: StorageFile) {
    return file.id;
  }

  trackByFolderId(index, folder: StorageFolder) {
    return folder.id;
  }

  showUnsorted(evt) {
    this.store.dispatch(new ShowUnsorted(evt.checked));
  }

  uploadFiles(files: File[]) {
    const folderId = this.store.selectSnapshot(FileManagerState.selectedFolderId);
    this.store.dispatch(new UploadFiles(files, {
      contextId: `${MANAGER_CTX_PREFIX}${folderId}`,
      context: { 'folder': folderId }
    }));

    this.files = [];
    this.cdRef.detectChanges();
  }

  selectionComplete(event: RangeSelectionEvent) {
    const ids = event.selectedElements.map(element => parseInt(element.dataset.selectable));
    const mouseEvent = event.originalEvent as MouseEvent;

    if (!mouseEvent.shiftKey) {
      this.store.dispatch(new ResetFilesSelection());
    }
    this.store.dispatch(new ToggleFilesSelection(ids, true));
    this.hoveredFilesIdsSource$.next([]);
  }

  selectionMove(event: RangeSelectionEvent) {
    const ids = event.selectedElements.map(element => parseInt(element.dataset.selectable));
    this.hoveredFilesIdsSource$.next(ids);
    this.cdRef.detectChanges();
  }

  selectionStarted(event: RangeSelectionEvent) {

  }
}

import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, tap, switchMap, shareReplay } from 'rxjs/operators';

import { News, ReactiveComponent } from '../shared';
import { NewsState, LoadNews } from '../shared/store';

@Component({
  selector: 'tamo-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less']
})
export class NewsComponent implements OnInit {
  @Select(NewsState.loading)
  loading$: Observable<boolean>;

  @Select(NewsState.news)
  news$: Observable<News[]>;

  @Select(NewsState.page)
  page$: Observable<number>;

  @Select(NewsState.total)
  total$: Observable<number>;

  @Select(NewsState.pageSize)
  pageSize$: Observable<number>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new LoadNews(1));
  }

}

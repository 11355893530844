import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain, plainToClassFromExist } from "class-transformer";

import { Shipping, PagedResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private prefix: string = '/shipping/';

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public all(page: number = 1): Observable<PagedResult<Shipping>> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}?page=${page}`)
      .pipe(map(res => plainToClassFromExist(new PagedResult<Shipping>(Shipping), res)));
  }

  public get(id: number): Observable<Shipping> {
    return this.http
      .get(`${this.apiUrl}${this.prefix}${id}/`)
      .pipe(map(res => plainToClass(Shipping, res)));
  }

  public save(id, data): Observable<Shipping> {
    return this.http
      .put(`${this.apiUrl}${this.prefix}${id}/`, classToPlain(data))
      .pipe(map(res => plainToClass(Shipping, res)));
  }

  public create(data): Observable<Shipping> {
    return this.http
      .post(`${this.apiUrl}${this.prefix}`, classToPlain(data))
      .pipe(map(res => plainToClass(Shipping, res)));
  }
}

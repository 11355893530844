import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  private prefix: string = `/sms/`;

  constructor(@Inject('apiUrl') private apiUrl: string,
              private http: HttpClient) { }

  public sendSms(phone: string): Observable<any> {
    return this.http.post(`${this.apiUrl}${this.prefix}`, { phone });
  }
}
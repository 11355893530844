import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Output, EventEmitter, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { FolderSelectComponent } from '../folder-select/folder-select.component';
import { StorageFolder, ReactiveComponent, UploadFile, GlobalUploaderService, StorageFile } from '../../shared';
import { FileManagerState, FileUploaderState, ResetFilesSelection, DeleteSelectedFiles, MoveSelectedFiles, UploadFiles, MANAGER_CTX_PREFIX } from '../../shared/store';

@Component({
  selector: 'file-manager-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent extends ReactiveComponent {
  @Select(FileManagerState.managerUploadingFiles)
  uploadingFiles$: Observable<UploadFile[]>;

  @Select(FileManagerState.selectedFilesIds)
  selectedFilesIds$: Observable<number[]>;

  @Input()
  editable: boolean = true;

  @Output()
  onSelectFiles: EventEmitter<StorageFile[]>;

  @Input()
  actionsTemplate: TemplateRef<ElementRef>;

  public files: File[] = [];

  constructor(private store: Store,
              public dialog: MatDialog,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
  }

  removeSelected(evt: MouseEvent) {
    if (confirm(`Вы действительно хотите удалить выбранные файлы?`)) {
      this.store.dispatch(new DeleteSelectedFiles());
    }
  }

  moveSelection(evt: MouseEvent) {
    const dialogRef = this.dialog.open(FolderSelectComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      minWidth: '300px'
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(result => {
      if (result !== undefined) {
        this.store.dispatch(new MoveSelectedFiles(result));
      }
    });
  }

  clearSelection() {
    this.store.dispatch(new ResetFilesSelection());
  }

  uploadFiles(files: File[]) {
    const folderId = this.store.selectSnapshot(FileManagerState.selectedFolderId);
    this.store.dispatch(new UploadFiles(files, {
      contextId: `${MANAGER_CTX_PREFIX}${folderId}`,
      context: { 'folder': folderId }
    }));

    this.files = [];
    this.cdRef.detectChanges();
  }
}

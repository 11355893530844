import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { switchMap, takeUntil, finalize, tap, filter, map, shareReplay } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { plainToClass } from 'class-transformer';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flash } from 'ng-animate';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

import { AuthState, OrderState, LoadChatMessages, SendChatMessage, AddChatMessage } from '../../shared/store';
import {
  Message,
  MessagesService,
  AuthService,
  ReactiveComponent,
  Order,
  AuthUser,
  Profile,
  AccountServiceFactory
} from '../../shared';

@Component({
  selector: 'app-orders-profile',
  templateUrl: './orders-profile.component.html',
  styleUrls: ['./orders-profile.component.less'],
  animations: [
    trigger('flash', [transition('* => pushed', useAnimation(flash))])
  ]
})
export class OrdersProfileComponent extends ReactiveComponent implements OnInit {
  @Select(OrderState.selectedOrder)
  order$: Observable<Order>;

  @Select(AuthState.user)
  user$: Observable<AuthUser>;

  account$: Observable<Profile>;

  accountId$: Observable<number> = this.order$.pipe(
    map((order: Order) => order.account_id)
  );

  uplaodPrefix$: Observable<string> = this.accountId$.pipe(
    map((accountId: number) => `/accounts/${accountId}/`)
  );

  profileForm = this.formBuilder.group({
    last_name: ['', Validators.required],
    first_name: ['', Validators.required],
    middle_name: [''],
    company: [''],
    phone: [{value:'', disabled: true}],
    email: [{value:'', disabled: true}],
    city: [''],
    notify_status: [false],
    notify_message: [false],
    notify_complete: [false]
  });

  constructor(
      private formBuilder: FormBuilder,
      private store: Store,
      private accountFactory: AccountServiceFactory) {
    super();
    this.profileForm.disable();
  }

  get f() {
    return this.profileForm.controls;
  }

  ngOnInit() {
    this.account$ = this.accountId$.pipe(
      switchMap((accountId: number) => {
        const service = this.accountFactory.getService(accountId);
        return service.getProfile();
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.account$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((account: Profile) => {
      this.profileForm.patchValue(account);
    })
  }
}

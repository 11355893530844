<mat-card class="direction__card" [formGroup]="form">
  <div class="direction__group">
    <kladr-suggest-city placeholder="Укажите ваш город"
      (optionSelected)="onSelectionChanged($event)"></kladr-suggest-city>
  </div>
  
  <div class="direction__group">
    <div class="direction__topic">
      <label class="lable_class">Выберете организационно правовую форму</label>
    </div>
    <mat-radio-group class="vertical-radio-group" formControlName="org_type">
      <mat-radio-button class="vertical-radio-button" value="U">Юридическое лицо </mat-radio-button>
      <mat-radio-button class="vertical-radio-button" value="F">Физическое лицо</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="direction__group">
    <div class="direction__topic">
      <label class="lable_class">Выберете вид деятельности</label>
    </div>
    <mat-radio-group class="vertical-radio-group" formControlName="activity_kind">
      <mat-radio-button *ngFor="let activity of activities$ | async"
        class="vertical-radio-button" [value]="activity.id">{{ activity.name }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="direction__group">
    <div class="direction__topic">
      <label class="lable_class">Выберете основные напрвления</label>
    </div>
    
    <div *ngFor="let stream of streams" >
      <mat-checkbox (change)="onStreamsChanged($event)"
        [value]="stream.id">{{ stream.name }}</mat-checkbox>
    </div>
<!--     <mat-radio-group class="vertical-radio-group" formControlName="main_streams">
      <mat-radio-button *ngFor="let stream of $streams | async"
        class="vertical-radio-button" [value]="stream.name">{{ stream.name }}</mat-radio-button>
    </mat-radio-group> -->
  </div>
</mat-card>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DadataOrg } from '../../dadata';
import { Importer } from '../../shared/models';
import { ImportersService } from '../../shared/services';
import { RemoteErrorsService } from '../../remote-errors/remote-errors.service';

@Component({
  selector: 'tamo-profile-importers-form',
  templateUrl: './profile-importers-form.component.html',
  styleUrls: ['./profile-importers-form.component.less']
})
export class ProfileImportersFormComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  public importerForm: FormGroup;

  public saving: boolean = false;

  @Input() importer: Importer;

  @Output() importerChange: EventEmitter<Importer> = new EventEmitter();

  constructor(private fb: FormBuilder,
              private importersService: ImportersService,
              private errSrv: RemoteErrorsService,
              private snackBar: MatSnackBar) {
    this.importerForm = this.fb.group({
      org_name: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      inn: [''],
      ogrn: [''],
      kpp: [''],
      legal_address: [''],
      physical_address: [''],
      correspondence_address: [''],
      director_position: [''],
      director_fio: ['']
    });
  }

  get f() { return this.importerForm.controls; }

  get isAdd() {
    return !this.importer.id;
  }

  ngOnInit() {
    this.importerForm.patchValue(classToPlain(this.importer));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  saveImporter() {
    if (this.importerForm.invalid) {
      return;
    }

    this.saving = true;
    this.importerForm.disable();
    const importerData = plainToClass(Importer,
      this.importerForm.value as Object);

    const saving$ = this.importer.id ?
      this.importersService.save(this.importer.id, importerData) :
      this.importersService.create(importerData);

    saving$
      .pipe(
        finalize(() => {
          this.saving = false;
          this.importerForm.enable();
          this.snackBar.open('Импортер успешно сохранен', 'закрыть');
        })
      )
      .subscribe(
        (newData: Importer) => {
          this.importerChange.emit(newData);
          this.importer = newData;
        },
        this.errSrv.handleErrors(this.importerForm)
      );
  }

  onSelectionChanged(org: DadataOrg) {
    this.importerForm.patchValue({
      inn: org.data.inn,
      kpp: org.data.kpp,
      ogrn: org.data.ogrn,
      org_name: org.data.name.short_with_opf,

      director_position: org.data.management ? org.data.management.post : '',
      director_fio: org.data.management ? org.data.management.name : '',

      legal_address: org.data.address ? org.data.address.value : '',
      physical_address: org.data.address ? org.data.address.value : '',
      correspondence_address: org.data.address ? org.data.address.value: ''
    });
  }

}

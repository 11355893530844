<ng-container *ngIf="{
  selectedServiceType: selectedServiceType$ | async
} as ctx">
  <div class="shipping" ngClass.lt-md="shipping_md" ngClass.lt-xs="shipping_xs">
    <form [formGroup]="shippingForm" class="shipping-form" (ngSubmit)="onSubmit()">
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.>
        <mat-card class="shipping__card">
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="25px">
                <!-- <h2>Перевозка</h2> -->

                <div fxFlex>
                  <h4 class="shipping-form__title">
                    Направление перемещения

                    <button type="button" class="btn-help" mat-button color="accent"
                        tamoRemoteHint="sh_1-1">Что такое направление перемещения
                      <mat-icon>help</mat-icon>
                    </button>
                  </h4>

                  <div fxLayout="row">
                    <mat-radio-group formControlName="direction" fxLayout="column" fxLayoutGap="10px" [color]="accent">
                      <mat-radio-button fxFlex value="I">Импорт</mat-radio-button>
                      <!-- <mat-radio-button fxFlex value="E">Экспорт</mat-radio-button> -->
                    </mat-radio-group>
                  </div>
                </div>

                <div fxFlex>
                  <h4 class="shipping-form__title">Выбор средства доставки</h4>

                  <mat-chip-list>
                    <mat-chip color="accent" [selected]="ctx.selectedServiceType === ServiceType.SEA_SHIPPING"
                      (click)="selectRoute(ServiceType.SEA_SHIPPING)"><i class="fa fa-ship"></i>&nbsp;Море</mat-chip>
                    <mat-chip color="accent" [selected]="ctx.selectedServiceType === ServiceType.AUTO_SHIPPING"
                      (click)="selectRoute(ServiceType.AUTO_SHIPPING)"><i class="fa fa-car"></i>&nbsp;Авто</mat-chip>
                    <mat-chip color="accent" [selected]="ctx.selectedServiceType === ServiceType.RAILWAY_SHIPPING"
                      (click)="selectRoute(ServiceType.RAILWAY_SHIPPING)"><i class="fa fa-train"></i>&nbsp;ЖД</mat-chip>
                    <mat-chip color="accent" [selected]="ctx.selectedServiceType === ServiceType.AVIA_SHIPPING"
                      (click)="selectRoute(ServiceType.AVIA_SHIPPING)"><i class="fa fa-plane"></i>&nbsp;Авиа</mat-chip>
                  </mat-chip-list>
                </div>

              </div>         

              <!-- <div fxFlex fxLayout="column" fxLayoutGap="25px">
                <h2>Таможенные платежи</h2>

                <div fxFlex>
                  <h4 class="shipping-form__title">
                    Ввод стоимости товара
                    <button type="button" class="btn-help" mat-button color="accent"
                        tamoRemoteHint="1-2">Как вводить?
                      <mat-icon>help</mat-icon>
                    </button>
                  </h4>

                  <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field>
                      <input matInput formControlName="product_cost" autocomplete="off"
                        placeholder="Стоимость товара">
                    </mat-form-field>

                    <mat-form-field fxFlex="80px">
                      <input matInput placeholder="Валюта" formControlName="product_cost_currency" [matAutocomplete]="currencies">
                      <mat-autocomplete #currencies="matAutocomplete">
                        <mat-option value="RUB"><i class="fa fa-rub"></i> Рубль</mat-option>
                        <mat-option value="USD"><i class="fa fa-usd"></i> Доллар</mat-option>
                        <mat-option value="EUR"><i class="fa fa-eur"></i> Евро</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>

                <div fxFlex>
                  <h4 class="shipping-form__title">
                    Рассчет пошлины
                    <button type="button" class="btn-help" mat-button color="accent"
                        tamoRemoteHint="1-2">Что это?
                      <mat-icon>help</mat-icon>
                    </button>
                  </h4>

                  <div fxLayout="column" fxLayoutGap="10px">
                    <tamo-duties-search (dutySelect)="onDutySelected($event)"></tamo-duties-search>
                    <div *ngIf="duty">
                      <div>
                        <strong>[{{ duty.code }}]</strong> {{ duty.name }}
                      </div>
                      <div>
                        <div *ngIf="duty.duty_type == 'P'">
                          Процент: {{ duty.percantage_cost }}% <span *ngIf="duty.fixed_cost">не менее {{ duty.fixed_cost }} {{ duty.unit }}/шт</span>
                        </div>
                        <div *ngIf="duty.duty_type == 'F'">
                          {{ duty.fixed_cost }} {{ duty.unit }}/шт
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

            </div>
          </mat-card-content>
        </mat-card>

        <ng-container *ngIf="ctx.selectedServiceType">
          <div fxLayout="column" fxLayoutGap="20px">
            <mat-card class="product-form">
              <ng-container [ngSwitch]="ctx.selectedServiceType">
                <ng-container *ngSwitchCase="ServiceType.AVIA_SHIPPING">                 
                  <mat-card-header><mat-card-title><i class="fa fa-plane"></i> Авиа</mat-card-title></mat-card-header>
                  <mat-card-content><div fxFlex="50%" fxFlex.lt-lg="100%"><shipping-calculator-avia [form]="shippingForm" (formFilled)="onFormFilled($event)"></shipping-calculator-avia></div></mat-card-content>
                </ng-container>

                <ng-container *ngSwitchCase="ServiceType.AUTO_SHIPPING">
                  <mat-card-header><mat-card-title><i class="fa fa-car"></i> Авто</mat-card-title></mat-card-header>
                  <mat-card-content><div fxFlex="50%" fxFlex.lt-lg="100%"><shipping-calculator-auto [form]="shippingForm" (formFilled)="onFormFilled($event)"></shipping-calculator-auto></div></mat-card-content>
                </ng-container>

                <ng-container *ngSwitchCase="ServiceType.SEA_SHIPPING">
                  <mat-card-header><mat-card-title><i class="fa fa-ship"></i> Морская перевозка</mat-card-title></mat-card-header>
                  <mat-card-content><div fxFlex="50%" fxFlex.lt-lg="100%"><shipping-calculator-sea [form]="shippingForm" (formFilled)="onFormFilled($event)"></shipping-calculator-sea></div></mat-card-content>
                </ng-container>

                <ng-container *ngSwitchCase="ServiceType.RAILWAY_SHIPPING">
                  <mat-card-header><mat-card-title><i class="fa fa-train"></i> Железная дорога</mat-card-title></mat-card-header>
                  <mat-card-content><div fxFlex="50%" fxFlex.lt-lg="100%"><shipping-calculator-railway [form]="shippingForm" (formFilled)="onFormFilled($event)"></shipping-calculator-railway></div></mat-card-content>
                </ng-container>
              </ng-container>
            </mat-card>
          </div>

<!--           <mat-card fxFlex>
            <mat-card-content>
              <button type="submit"
                [disabled]="saving$ | async" 
                mat-raised-button color="accent"
                style="margin-right: 20px">{{ service.id ? 'Сохранить' : 'Создать букинг' }}</button>
            </mat-card-content>
          </mat-card> -->
        </ng-container>
      </div>
    </form>
  </div>
</ng-container>
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { debounceTime, tap, delay, map, finalize, catchError } from 'rxjs/operators';
import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { original, produce } from 'immer';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Order, Service, ServiceType, OrderLog, Message, StorageFile, getServiceCls } from '../../models';
import {
  SelectOrderById,
  SelectServiceById,
  UpdateService,
  AddService,
  AddServiceSuccess,
  PatchOrderManager,
  PatchOrderName,
  LoadServiceDocuments,
  LoadServiceStatuses,
  DownloadDocuments,
  DownloadDocumentsSuccess,
  AddChatMessage,
  LoadChatMessages,
  SendChatMessage,
  PatchService,
  ChangeServiceStatus,
  SaveServiceForm
} from '../actions';
import { OrdersService, OrderServicesService, MessagesService } from '../../services';


export interface OrderStateModel {
  order: Order;
  orderLoading: boolean;
  orderSaving: boolean;

  selectedServiceId: number;
  serviceLoading: boolean;
  serviceSaving: boolean;
  serviceDocuments: StorageFile[];
  documentsLoading: boolean;
  downloading: boolean;
  serviceStatuses: OrderLog[];
  statusesLoading: boolean;

  messages: Message[];
  messagesLoading: boolean;
  messageSending: boolean;
  serviceForm: {
    model?: any,
    dirty: boolean
  }
}

const defaults: OrderStateModel = {
  order: null,
  orderLoading: false,
  orderSaving: false,

  selectedServiceId: null,
  serviceLoading: false,
  serviceSaving: false,
  serviceDocuments: null,
  documentsLoading: false,
  downloading: false,
  serviceStatuses: null,
  statusesLoading: false,
  messages: null,
  messagesLoading: false,
  messageSending: false,
  serviceForm: {
    model: undefined,
    dirty: false
  }
}

const extractErrors = (errorsList, key = null) => {
  const errors = [];

  if (_.isObject(errorsList)) {
    const isArray = _.isArray(errorsList);
    for (const [cursor, value] of Object.entries(errorsList)) {
      if (isArray) {
        errors.push(extractErrors(value, key));
      } else {
        errors.push(extractErrors(value, key !== null ? `${key}.${cursor}` : cursor));
      }
    }
  } else {
    const keyPrefix = key !== null ? `[${key}]: ` : '';
    return `${keyPrefix}${errorsList}`;
  }

  return errors;
}

@State<OrderStateModel>({
  name: 'order',
  defaults
})
@Injectable()
export class OrderState {
  constructor(
    private ordersService: OrdersService,
    private servicesService: OrderServicesService,
    private store: Store,
    private toastr: ToastrService,
    private messagesService: MessagesService) { }

  @Selector([OrderState])
  static selectedOrder(state: OrderStateModel) {
    return state.order;
  }

  @Selector([OrderState])
  static nonEmptySelectedOrder(state: OrderStateModel) {
    return state.order || {};
  }

  @Selector([OrderState.nonEmptySelectedOrder])
  @ImmutableSelector()
  static orderServices(order: Order) {
    const sortedTypes = ServiceType.getValues() as ServiceType[];
    if (order && order.id) {
      return order.services.sort((s1, s2) =>
        sortedTypes.indexOf(s1.service_type) - sortedTypes.indexOf(s2.service_type));
    }
    return [];
  }

  @Selector([OrderState.orderServices])
  @ImmutableSelector()
  static orderEpsentServiceTypes(services: Service[]) {
    const sortedTypes = ServiceType.getValues() as ServiceType[];
    return sortedTypes
      .filter(serviceType => !services.find(service => service.service_type === serviceType))
      .filter((serviceType: ServiceType) => !serviceType.hidden && !serviceType.disabled);
  }

  @Selector([OrderState])
  static orderLoading(state: OrderStateModel) {
    return state.orderLoading;
  }

  @Selector([OrderState])
  static orderSaving(state: OrderStateModel) {
    return state.orderSaving;
  }

  @Selector([OrderState.orderServices, OrderState.selectedServiceId])
  @ImmutableSelector()
  static selectedService(services: Service[], selectedServiceId: number) {
    return services.find(service => service.id === selectedServiceId);
  }

  @Selector([OrderState])
  static selectedServiceId(state: OrderStateModel) {
    return state.selectedServiceId;
  }

  @Selector([OrderState])
  static serviceLoading(state: OrderStateModel) {
    return state.serviceLoading;
  }

  @Selector([OrderState])
  static documentsLoading(state: OrderStateModel) {
    return state.documentsLoading;
  }

  @Selector([OrderState])
  static serviceDocuments(state: OrderStateModel) {
    return state.serviceDocuments;
  }

  @Selector([OrderState])
  static statusesLoading(state: OrderStateModel) {
    return state.statusesLoading;
  }

  @Selector([OrderState])
  static serviceStatuses(state: OrderStateModel) {
    return state.serviceStatuses;
  }

  @Selector([OrderState])
  static serviceSaving(state: OrderStateModel) {
    return state.serviceSaving;
  }

  @Selector([OrderState])
  static downloading(state: OrderStateModel) {
    return state.downloading;
  }

  @Selector([OrderState])
  static messages(state: OrderStateModel) {
    return state.messages || [];
  }

  @Selector([OrderState.messages])
  @ImmutableSelector()
  static orderedMessages(messages: Message[]) {
    return messages.sort((m1: Message, m2: Message) =>
      moment(m1.date_creation).diff(moment(m2.date_creation)));
  }

  @Selector([OrderState])
  static messagesLoading(state: OrderStateModel) {
    return state.messagesLoading;
  }

  @Selector([OrderState])
  static messageSending(state: OrderStateModel) {
    return state.messageSending;
  }

  @Selector([OrderState])
  static serviceSaved(state: OrderStateModel): boolean {
    return !state.serviceForm.dirty;
  }

  @Action(SelectOrderById)
  @ImmutableContext()
  selectOrderById(
    { setState }: StateContext<OrderStateModel>,
    { orderId }: SelectOrderById
  ) {
    setState((state: OrderStateModel) => {
      state.order = null;
      state.selectedServiceId = null;
      state.orderLoading = true;
      state.serviceDocuments= null;
      state.serviceStatuses = null;
      state.messages = null;
      return state;
    });

    return this.ordersService.get(orderId).pipe(
      tap((order: Order) => {
        setState((state: OrderStateModel) => {
          state.order = order;
          state.orderLoading = false;
          return state;
        });
      })
    );
  }

  @Action(SelectServiceById)
  @ImmutableContext()
  selectService(
    { setState }: StateContext<OrderStateModel>,
    { serviceId }: SelectServiceById
  ) {
    setState((state: OrderStateModel) => {
      state.selectedServiceId = serviceId;
      state.serviceDocuments= null;
      state.serviceStatuses = null;
      state.messages = null;
      state.serviceForm = defaults.serviceForm;
      return state;
    });
  }


  @Action(PatchOrderName)
  @ImmutableContext()
  patchOrderName(
    { setState, getState }: StateContext<OrderStateModel>,
    { name }: PatchOrderName
  ) {
    const selectedOrder = getState().order;

    if (!selectedOrder) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.orderSaving = true;
      return state;
    });

    return this.ordersService.patch(selectedOrder.id, { name }).pipe(
      tap((order: Order) => {
        setState((state: OrderStateModel) => {
          state.order = order;
          return state;
        });
      }),
      finalize(() => setState((state: OrderStateModel) => {
        state.orderSaving = false;
        return state;
      }))
    );
  }

  @Action(PatchOrderManager)
  @ImmutableContext()
  patchOrderManager(
    { setState, getState }: StateContext<OrderStateModel>,
    { manager }: PatchOrderManager
  ) {
    const { order: selectedOrder } = getState();

    if (!selectedOrder) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.orderSaving = true;
      return state;
    });

    return this.ordersService.patch(selectedOrder.id, { manager_id: manager ? manager.id : null }).pipe(
      tap((order: Order) => {
        setState((state: OrderStateModel) => {
          state.order = order;
          return state;
        });
      }),
      finalize(() => setState((state: OrderStateModel) => {
        state.orderSaving = false;
        return state;
      }))
    );
  }

  @Action(SaveServiceForm)
  saveServiceForm(
    { setState, getState, dispatch }: StateContext<OrderStateModel>,
    { sendToProcess }: SaveServiceForm
  ) {
    const { serviceForm: { model } } = getState();
    const selectedService = this.store.selectSnapshot(OrderState.selectedService);

    if (!model || !selectedService) {
      return;
    }

    const toSave = produce(model, (state) => {
      for (const key in state) {
        if (moment.isMoment(state[key])) {
          state[key] = state[key].toDate();
        }
      }
    });

    const service = plainToClassFromExist(getServiceCls(selectedService.service_type.value), toSave);
    return dispatch(new UpdateService(service, sendToProcess));
  }


  @Action(UpdateService)
  @ImmutableContext()
  updateService(
    { setState, getState }: StateContext<OrderStateModel>,
    { toUpdate, sendToProcess }: UpdateService
  ) {
    const { order: selectedOrder } = getState();
​    const selectedService = this.store.selectSnapshot(OrderState.selectedService);

    if (!selectedOrder || !selectedService) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.serviceSaving = true;
      return state;
    });

    toUpdate.service_type = selectedService.service_type;
    if (sendToProcess) {
      toUpdate.status = 'PR';
    }

    return this.servicesService.save(selectedOrder.id, selectedService.id, toUpdate).pipe(
      tap((service: Service) => {
        setState((state: OrderStateModel) => {
          const foundIndex = state.order.services.findIndex(service => service.id === selectedService.id);
          if (foundIndex !== -1) {
            state.order.services.splice(foundIndex, 1, service);
          }
          state.serviceForm = defaults.serviceForm;
          return state;
        });
      }),
      tap(() => {
        if (sendToProcess) {
          this.toastr.success('Спасибо! Услуга создана и принята в работу.');
        } else {
          this.toastr.success('Услуга успешно сохранена.');
        }
      }),
      catchError(response => {
        let messages: any = [];
        if (typeof response === 'object' && response.error) {
          messages = extractErrors(response.error);
        }

        this.toastr.error(messages.join('<br>'), `Ошибка при сохранении услуги`, { enableHtml: true });

        return throwError(response);
      }),
      finalize(() => setState((state: OrderStateModel) => {
        state.serviceSaving = false;
        return state;
      }))
    );
  }

  @Action(PatchService)
  @ImmutableContext()
  patchService(
    { setState, getState }: StateContext<OrderStateModel>,
    { data }: PatchService
  ) {
    const { order: selectedOrder } = getState();
​    const selectedService = this.store.selectSnapshot(OrderState.selectedService);

    if (!selectedOrder || !selectedService) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.serviceSaving = true;
      return state;
    });

    return this.servicesService.patch(selectedOrder.id, original(original(selectedService)), data).pipe(
      tap((service: Service) => {
        setState((state: OrderStateModel) => {
          const foundIndex = state.order.services.findIndex(service => service.id === selectedService.id);
          if (foundIndex !== -1) {
            state.order.services.splice(foundIndex, 1, service);
          }
          return state;
        });
      }),
      finalize(() => setState((state: OrderStateModel) => {
        state.serviceSaving = false;
        return state;
      }))
    );
  }

  @Action(ChangeServiceStatus)
  @ImmutableContext()
  changeServiceStatus(
    { setState, getState, dispatch }: StateContext<OrderStateModel>,
    { data }: ChangeServiceStatus
  ) {
    const { order: selectedOrder, selectedServiceId } = getState();

    if (selectedOrder &&
        selectedOrder.id !== data.order_id &&
        selectedServiceId !== data.service_id) {
      return;
    }

    // setState((state: OrderStateModel) => {
    //   return state;
    // });

    // setState((state: OrderStateModel) => {
    //   const foundIndex = state.order.services.findIndex(service => service.id === data.service_id);
    //   if (foundIndex !== -1) {
    //     state.order.services[foundIndex].status = 
    //   }
    //   return state;
    // });
    return dispatch(new LoadServiceStatuses());
  }

  @Action(AddService)
  @ImmutableContext()
  addService(
    { setState, getState, dispatch }: StateContext<OrderStateModel>,
    { serviceType }: AddService
  ) {
    const { order: selectedOrder } = getState();

    if (!selectedOrder || !!selectedOrder.services.find(service => service.service_type === serviceType)) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.orderSaving = true;
      return state;
    });

    return this.ordersService.patch(selectedOrder.id, { services: [{ 'resourcetype': serviceType.value }] }).pipe(
      tap((order: Order) => {
        setState((state: OrderStateModel) => {
          state.order = order;
          return state;
        });
      }),
      map((order) => dispatch(
        new AddServiceSuccess(order.services.find(service => service.service_type === serviceType)))),
      finalize(() => setState((state: OrderStateModel) => {
        state.orderSaving = false;
        return state;
      }))
    );
  }

  @Action(LoadServiceDocuments)
  @ImmutableContext()
  loadServiceDocuments(
    { setState, getState }: StateContext<OrderStateModel>,
    { }: LoadServiceDocuments
  ) {
    const { order: selectedOrder, selectedServiceId } = getState();

    if (!selectedOrder || !selectedServiceId) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.serviceDocuments = null;
      state.documentsLoading = true;
      return state;
    });

    return this.servicesService.documents(selectedOrder.id, selectedServiceId).pipe(
      tap((documents: StorageFile[]) => {
        setState((state: OrderStateModel) => {
          state.serviceDocuments = documents;
          state.documentsLoading = false;
          return state;
        });
      })
    );
  }

  @Action(LoadServiceStatuses)
  @ImmutableContext()
  loadServiceStatuses(
    { setState, getState }: StateContext<OrderStateModel>,
    { }: LoadServiceStatuses
  ) {
    const { order: selectedOrder, selectedServiceId } = getState();

    if (!selectedOrder || !selectedServiceId) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.serviceStatuses = null;
      state.statusesLoading = true;
      return state;
    });

    return this.servicesService.statuses(selectedOrder.id, selectedServiceId).pipe(
      tap((statuses: OrderLog[]) => {
        setState((state: OrderStateModel) => {
          state.serviceStatuses = statuses;
          state.statusesLoading = false;
          return state;
        });
      }),
      tap(() => this.servicesService.touch(selectedOrder.id, selectedServiceId))
    );
  }

  @Action(DownloadDocuments)
  @ImmutableContext()
  downloadDocuments(
    { setState, getState, dispatch }: StateContext<OrderStateModel>,
    { }: DownloadDocuments
  ) {
    const { order: selectedOrder, selectedServiceId } = getState();

    if (!selectedOrder || !selectedServiceId) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.downloading = true;
      return state;
    });

    return this.servicesService.zip(selectedOrder.id, selectedServiceId).pipe(
      map((data: any) => dispatch(new DownloadDocumentsSuccess(
        data, `documents_${selectedOrder.id}_${selectedServiceId}.zip`))),
      finalize(() => setState((state: OrderStateModel) => {
        state.downloading = false;
        return state;
      }))
    );
  }

  @Action(LoadChatMessages)
  @ImmutableContext()
  loadChatMessages(
    { setState, getState }: StateContext<OrderStateModel>,
    { }: LoadChatMessages
  ) {
    const { order: selectedOrder } = getState();

    if (!selectedOrder) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.messages = null;
      state.messagesLoading = true;
      return state;
    });

    return this.messagesService.all(selectedOrder.id).pipe(
      tap((messages: Message[]) => {
        setState((state: OrderStateModel) => {
          state.messages = messages;
          state.messagesLoading = false;
          return state;
        });
      }),
      tap(() => this.messagesService.touch(selectedOrder.id))
    );
  }

  @Action(AddChatMessage)
  @ImmutableContext()
  addChatMessage(
    { setState, getState }: StateContext<OrderStateModel>,
    { data }: AddChatMessage
  ) {
    const { messages, messagesLoading, order: selectedOrder } = getState();

    if (messages === null || messagesLoading) {
      return;
    }

    if (data.object_id !== selectedOrder.id) {
      return;
    }

    setState((state: OrderStateModel) => {
      const found = state.messages.find(message => message.id === data.id);
      if (!found) {
        state.messages.push(data);
      }
      return state;
    });
  }

  @Action(SendChatMessage)
  @ImmutableContext()
  sendChatMessage(
    { setState, getState }: StateContext<OrderStateModel>,
    { text }: SendChatMessage
  ) {
    const { order: selectedOrder } = getState();

    if (!selectedOrder) {
      return;
    }

    setState((state: OrderStateModel) => {
      state.messageSending = true;
      return state;
    });
      

    console.log(`Message: ${text}`);

    return this.messagesService.create(selectedOrder.id, text).pipe(
      tap((payload: Message) => {
        setState((state: OrderStateModel) => {
          state.messageSending = false;
          if (state.messages && state.order && state.order.id === payload.object_id) {
            const found = state.messages.find(message => message.id === payload.id);
            if (!found) {
              state.messages.push(payload);
            }
          }
          return state;
        });
      })
    );
  }
}
import { Exclude, Transform, Type } from 'class-transformer';

import { Service } from './service';
import { Manager } from './manager';
import { ShippingTariff } from './shipping-tariff';
import { ShippingPoint } from './shipping-point';
import { Selectable } from './selectable';

export class Shipping extends Service {
  direction: string;
  product_cost: number;
  product_cost_currency: string;
  duty: string;
  sender_contact: string;
  comment: string;
  insurance: boolean;
  cargo_name: string;
  invoice_number: string;

  @Type(() => ShippingTariff)
  selected_tariff: ShippingTariff;

  getPrefix() {
    return 'shippings';
  }
}

export class ContainerType extends Selectable {
  enabled: boolean;
}

export class AutoShipping extends Shipping {
  departure_place: string;
  arrival_place: string;
  cargo_type: string;

  @Transform(value => parseFloat(value) || null)
  cargo_weight: number;

  @Transform(value => parseFloat(value) || null)
  cargo_size: number;

  @Transform(value => parseFloat(value) || null)
  cargo_length: number;

  @Transform(value => parseFloat(value) || null)
  cargo_height: number;

  @Transform(value => parseFloat(value) || null)
  cargo_width: number;

  @Transform(value => parseFloat(value) || null)
  distance: number;

  @Transform(value => parseFloat(value) || null)
  duration: number;

  load_address: string;
  upload_address: string;
  upload_contact: string;

  getPrefix() {
    return 'auto';
  }
}

export class ShippingWithDeliveryRoute extends Shipping  {
  @Transform(value => parseFloat(value) || null)
  distance: number;
  
  delivery_address: string;
  delivery_local: boolean;
}

export class AviaShipping extends Shipping {
  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  arrival_place: ShippingPoint;

  @Transform(value => parseFloat(value) || null)
  cargo_weight: number;
  
  @Transform(value => parseFloat(value) || null)
  cargo_size: number;
  
  @Transform(value => parseFloat(value) || null)
  cargo_length: number;
  
  @Transform(value => parseFloat(value) || null)
  cargo_height: number;
  
  @Transform(value => parseFloat(value) || null)
  cargo_width: number;

  load_address: string;
  upload_address: string;
  upload_contact: string;
  
  places_count: number;
  
  getPrefix() {
    return 'avia';
  }
}

export class RailwayShipping extends Shipping {
  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  arrival_place: ShippingPoint;

  @Transform(value => parseFloat(value) || null)
  cargo_weight: number;

  @Type(() => ContainerType)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  container_type: ContainerType;

  load_address: string;
  upload_address: string;
  upload_contact: string;

  getPrefix() {
    return 'railway';
  }
}

export class SeaShipping extends Shipping {
  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  arrival_place: ShippingPoint;

  @Type(() => ContainerType)
  @Transform(value => value ? value.id : null, { toPlainOnly: true })
  container_type: ContainerType;

  @Transform(value => parseFloat(value) || null)
  cargo_weight: number;

  containers_count: number;

  getPrefix() {
    return 'sea';
  }
}

export class CalculationResult {
  cost: number;
  duration: number;
  distance: number;
  departureAddress: string;
  arrivalAddress: string;
}
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Profile } from '../../../shared/models';

@Component({
  selector: 'tamo-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.less']
})
export class PersonalInfoComponent implements OnInit {
	@Input() form: FormGroup;
  @Input() profile: Profile;

	get f() { return this.form.controls; }

  constructor() { }

  ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { plainToClass } from "class-transformer";

export class RemoteError {
  key: string;
  messages: string[];

  format(isHtml=false) {
    if(isHtml) {
      return this.messages.join('<br>');
    } else {
      return this.messages.join('\n');
    }
  }
}

@Injectable()
export class RemoteErrorsService {
  static readonly NON_FIELDS_ERROR: string = 'non_field_errors';
  private _errors: RemoteError[] = [];

  constructor() { }

  public getErrorMessage(key: string, isHtml: boolean = false) {
    let error = this.getError(key);
    if(error) {
      return error.format(isHtml);
    } else {
      return '';
    }
  }

  public getError(key) {
    return this._errors.find(err => err.key == key);
  }

  public hasError(key) {
    return this.getError(key) != null;
  }

  public clearErrors() {
    this._errors = [];
  }

  public handleErrors(target: FormGroup | FormControl) {
    return (response: any) => {
      this.clearErrors();
      if(typeof response === 'object' && response.error) {
        if(response.error.detail) {
          this._errors.push(plainToClass(RemoteError, { key: RemoteErrorsService.NON_FIELDS_ERROR, messages: [response.error.detail] }));
        } else {
          for (let [key, value] of Object.entries(response.error)) {
            this._errors.push(plainToClass(RemoteError, { key, messages: value as string[] }));
          }
        }
      } else if(typeof response === 'string') {
        this._errors.push(plainToClass(RemoteError, { key: RemoteErrorsService.NON_FIELDS_ERROR, messages: [response] }));
      } else {
        console.log(`Can't process ${typeof response}`)
      }

      if(target instanceof FormGroup) {
        this._errors.forEach(err => {
          if(target.controls[err.key]) {
            target.controls[err.key].setErrors({ remote: true });
          }
        });
      } else if(target instanceof FormControl) {
        target.setErrors({ remote: true });
      }
    }
  }
}
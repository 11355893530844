import { Component, OnInit, ViewChild, ElementRef, ContentChild, ChangeDetectorRef } from '@angular/core';
import { of, Observable, BehaviorSubject, merge } from 'rxjs';
import { combineLatest, map, takeUntil } from 'rxjs/operators';
import { plainToClass, classToClass } from 'class-transformer';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ResizeObserver } from '@juggle/resize-observer';

import { Shipping, BookingData, BookingService, ShippingService, ReactiveComponent } from '../shared';

@Component({
  selector: 'tamo-iframe-calculator',
  templateUrl: './iframe-calculator.component.html',
  styleUrls: ['./iframe-calculator.component.less']
})
export class IframeCalculatorComponent extends ReactiveComponent {
  private modelSource$: BehaviorSubject<Shipping> = new BehaviorSubject(null);
  public model$: Observable<Shipping> = this.modelSource$.asObservable();

  public disabled$: Observable<boolean> = this.model$.pipe(map((model) => model === null));

  public sendForm: FormGroup;

  public sendComplete: boolean = false;

  @ViewChild('calculatorWrapper')
  set calculatorWrapper(el: ElementRef) {
    if (el.nativeElement) {
      this.resizeObserver.observe(el.nativeElement);
    }
  };

  // private shipping: Shipping;

  private resizeObserver: ResizeObserver;

  @ViewChild('stepper')
  private stepper: MatStepper;

  constructor(
      private fb: FormBuilder,
      private bookingService: BookingService,
      private element: ElementRef,
      private shippingSrv: ShippingService,
      private cdRef: ChangeDetectorRef) {
    super();
    this.sendForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.required]
    });

    this.resizeObserver = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const {left, top, width, height} = entry.contentRect;

        if (window && window.parent) {
          const evtData = {
            'type': 'calculator',
            'method': 'resize',
            left, top, width, height
          };
          window.parent.postMessage(JSON.stringify(evtData), "*");
        }
      }
    });

    merge(this.model$, this.disabled$).pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      setTimeout(() => {
        this.cdRef.detectChanges()
      }, 0)
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.sendForm.controls;
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  sendData(evt) {
    const service = this.modelSource$.getValue();
    if (this.sendForm.invalid || !service) {
        return;
    }

    const formData = this.sendForm.value;
    this.sendForm.disable();

    const bookingData = plainToClass(BookingData, {
      name: formData.name,
      email: formData.email,
      phone: `+7${formData.phone}`
    });
    bookingData.shipping = service;
    bookingData.shipping.resourcetype = service.service_type.value;

    this.bookingService.booking(bookingData)
      .subscribe((res) => {
        this.sendComplete = true;
        this.sendForm.enable()
      },
      (err) => {
        this.sendForm.enable()
      })
  }


  gotoEmail() {
    this.stepper.next();
  }

  onFormFilled(model: Shipping) {
    this.modelSource$.next(model)
  }
}

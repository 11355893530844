import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { KladrModule } from '../kladr/kladr.module';
import { DadataModule } from '../dadata/dadata.module';
import { ProfileService, DictsService } from '../shared/services';
import { QuestionaryComponent } from './questionary/questionary.component';
import { PersonalInfoComponent } from './steps/personal-info/personal-info.component';
import { DirectionActivityComponent } from './steps/direction-activity/direction-activity.component';
import { OrganizationComponent } from './steps/organization/organization.component';
import { DownloadFilesComponent } from './steps/download-files/download-files.component';
import { DocumentsUploaderModule } from '../documents-uploader/documents-uploader.module';


@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    FormsModule,
    MatInputModule, 
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatIconModule,
    FlexLayoutModule,
    MatTabsModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    DadataModule,
    KladrModule,
    DocumentsUploaderModule
  ],
  declarations: [
    QuestionaryComponent,
    PersonalInfoComponent,
    DirectionActivityComponent,
    OrganizationComponent,
    DownloadFilesComponent
  ],
  providers: [
    ProfileService,
    DictsService
  ]
})
export class QuestionaryModule { }
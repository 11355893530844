import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first, take, mergeMap } from 'rxjs/operators';
import { plainToClass } from "class-transformer";

import { ProfileService, ImportersService } from '../../shared/services';
import { Profile, Importer } from '../../shared/models';

@Component({
  selector: 'tamo-questionary',
  templateUrl: './questionary.component.html',
  styleUrls: ['./questionary.component.less']
})
export class QuestionaryComponent implements OnInit {
	public questionaryForm: FormGroup;
  public orgForm: FormGroup;
	public submitted: boolean = false;

  public profile: Profile;

	confirmStep() {
    this.submitted = true;
  }

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private profileService: ProfileService,
              private importersService: ImportersService
  	) { }

  ngOnInit() {
    this.profileService.get().subscribe(profile => {
      this.profile = profile;
    });

  	this.questionaryForm = this.formBuilder.group({
      city: [''],

      //organization
      // inn: [''],
      // org_name: [''],
      // ogrn: [''],
      // kpp: [''],
      // legal_address: [''],
      // physical_address: [''],
      // correspondence_address: [''],
      // director_position: [''],
      // director_fio: [''],

      //direction-activity 
      main_streams: [[]],
      activity_kind: [''],
      org_type: ['']
    });

    this.orgForm = this.formBuilder.group({
      org_name: [''],
      inn: [''],
      ogrn: [''],
      kpp: [''],
      legal_address: [''],
      physical_address: [''],
      correspondence_address: [''],
      director_position: [''],
      director_fio: ['']
    });
  }

  selectionChanged(data) {
    this.submitted = false;
  }

  next(stepper: MatStepper) {
    stepper.next();
  }

  prev(stepper: MatStepper) {
    stepper.previous();
  }

  save() {
    const profileData = Object.assign({ registration_completed: true }, this.questionaryForm.value);
    const profile = plainToClass(Profile, profileData);

    const importer = plainToClass(Importer,
      this.orgForm.value as Object);

    this.profileService.save(profile).pipe(
      mergeMap((profile: Profile) => this.importersService.create(importer))
    ).subscribe(() => {
      this.router.navigate(['/app/orders']);
    });
  }
}

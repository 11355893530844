<ng-container *ngIf="user$ | async as user">
  <div class="service" *ngIf="service">
    
  <!--     <h3 fxLayout="row" fxLayoutGap="10px" style="margin-top: 0" fxLayoutAlign="flex-start center">
        <mat-icon>assignment_turned_in</mat-icon>
        <span>Условный выпуск</span>
        <tamo-order-status [status]="service.service_status.id"></tamo-order-status>
        <tamo-order-status *ngIf="service.decision" [status]="service.decision.id"></tamo-order-status>
      </h3> -->

    <div fxLayout="column" fxLayoutGap="20px">
<!--       <div fxLayout="row" fxLayoutGap="30px"> -->
      <ng-container *ngIf="user.isStaff">
        <form [formGroup]="operatorForm" (ngSubmit)="onOperatorSubmit()">
          <mat-card class="service-card service-form__card">
            <mat-card-content class="service-card__content">
              <div fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" *ngIf="user.isStaff" fxLayoutGap="30px" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="10px">
                  <div fxFlex="50%" fxFlex.lt-xl="70%" fxFlex.lt-lg="80%" fxFlex.lt-md="100%" 
                      fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="flex-start">

                    <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
                      <mat-form-field fxFlex>
                        <input matInput [matDatepicker]="pickerRequestDate" formControlName="request_date"
                          placeholder="Дата выставления запроса">
                        <button mat-button *ngIf="fo.request_date.value" matSuffix mat-icon-button
                            aria-label="Очистить" (click)="fo.request_date.setValue(null)">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-datepicker-toggle matSuffix [for]="pickerRequestDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerRequestDate></mat-datepicker>
                      </mat-form-field>

                      <file-uploader fxFlex
                        buttonTitle="Запрошенные документы"
                        blockTitle=""
                        queueLimit="1"
                        [postData]="{ 'file_path': 'requested_documents_file' }"
                        [uploadUrl]="uploadUrl$ | async"
                        [autoUpload]="true"
                        [removeAfterUpload]="true"
                        (onCompleteAll)="onFileUploaded('Запрошенные документы')"></file-uploader>
                    </div>

                    <div fxLayout="row">
                      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
                        <input matInput [matDatepicker]="pickerPovisionEndDate" formControlName="provision_end_date"
                          placeholder="Конечтная дата предоставления документов">
                        <button mat-button *ngIf="fo.provision_end_date.value" matSuffix mat-icon-button
                            aria-label="Очистить" (click)="fo.provision_end_date.setValue(null)">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-datepicker-toggle matSuffix [for]="pickerPovisionEndDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPovisionEndDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxFlex fxLayout.lt-sm="column">
                      <mat-form-field fxFlex>
                        <input matInput formControlName="deposit_cost" placeholder="Сумма депозита">
                      </mat-form-field>

                      <file-uploader fxFlex
                        buttonTitle="Сумма депозита"
                        blockTitle=""
                        queueLimit="1"
                        [postData]="{ 'file_path': 'deposit_file' }"
                        [uploadUrl]="uploadUrl$ | async"
                        [autoUpload]="true"
                        [removeAfterUpload]="true"
                        (onCompleteAll)="onFileUploaded('Сумма депозита')"></file-uploader>
                    </div>

                    <div fxLayout="row">
                      <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%">
                        <input matInput formControlName="income_number" placeholder="Номер входящего документа">
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
                      <mat-form-field fxFlex>
                        <input matInput [matDatepicker]="pickerExtraIssueDate" formControlName="extra_issue_date"
                          placeholder="Дата на доп запрос">
                        <button mat-button *ngIf="fo.extra_issue_date.value" matSuffix mat-icon-button
                            aria-label="Очистить" (click)="fo.extra_issue_date.setValue(null)">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-datepicker-toggle matSuffix [for]="pickerExtraIssueDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerExtraIssueDate></mat-datepicker>
                      </mat-form-field>

                      <file-uploader fxFlex
                        buttonTitle="Дополнительный запрос"
                        blockTitle=""
                        queueLimit="1"
                        [postData]="{ 'file_path': 'extra_file' }"
                        [uploadUrl]="uploadUrl$ | async"
                        [autoUpload]="true"
                        [removeAfterUpload]="true"
                        (onCompleteAll)="onFileUploaded('Дополнительный запрос')"></file-uploader>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="flex-start">
                      <button type="submit" [disabled]="operatorForm.disabled"
                        mat-raised-button color="primary">Сохранить</button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </form>
      </ng-container>

      <form [formGroup]="serviceForm" class="service-form" ngxsForm="order.serviceForm">
        <mat-card fxFlex="50%">
          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="10px">
              <table class="props">
                <tr>
                  <td class="props__label">Конечная дата предоставления документов</td>
                  <td class="props__value">{{ service.provision_end_date ? (service.provision_end_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}</td>
                </tr>

                <tr>
                  <td class="props__label">Дней осталось</td>
                  <td class="props__value" [ngClass]="{ 'props__value_attention': service.days_left <= 7 }">{{ service.days_left }}</td>
                </tr>

                <tr>
                  <td class="props__label">Дата выставления запроса</td>
                  <td class="props__value">
                    {{ service.request_date ? (service.request_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}
                  </td>
                </tr>

                <tr *ngIf="service.requested_documents_file">
                  <td class="props__label">Запрошенные документы</td>
                  <td class="props__value">
                    <a target="_blank" href="{{ service.requested_documents_file }}">скачать</a>
                  </td>
                </tr>

                <tr *ngIf="service.deposit_cost">
                  <td class="props__label">Депозит</td>
                  <td class="props__value">
                    {{ service.deposit_cost }}
                    <a target="_blank" href="{{ service.deposit_file }}">скачать</a>
                  </td>
                </tr>

                <tr *ngIf="service.income_number">
                  <td class="props__label">Номер входящего в таможне</td>
                  <td class="props__value">{{ service.income_number }}</td>
                </tr>

                <tr *ngIf="service.extra_file">
                  <td class="props__label">Дополнительный запрос</td>
                  <td class="props__value">
                    {{ service.extra_issue_date ? (service.extra_issue_date | amLocal | amDateFormat: 'DD.MM.YYYY') : '-' }}
                    <a target="_blank" href="{{ service.extra_file }}">скачать</a>
                  </td>
                </tr>
              </table>

              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('CORE')"
                type="CORE" title="Предоставленные документы"></tamo-documents-uploader>
              

              <mat-form-field>
                <input matInput [matDatepicker]="picker" formControlName="extra_response_date"
                  placeholder="Дата предоставления ответа на доп запрос">
                <button mat-button *ngIf="f.extra_response_date.value" matSuffix mat-icon-button
                    aria-label="Очистить" (click)="f.extra_response_date.setValue(null)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('COEX')"
                type="COEX" title="Документы по доп запросу"></tamo-documents-uploader>


              <div class="service-form__group" fxFlex>
                <mat-form-field  appearance="outline" fxFlex="grow">
                  <mat-label>Комментарий</mat-label>
                  <textarea rows="2" matInput placeholder="Комментарий"
                    formControlName="comment" name="comment" autocomplete="off"></textarea>
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>
      </form>
      <!-- </div> -->

<!--       <mat-card fxFlex>
        <button type="submit" [disabled]="serviceForm.disabled" (click)="onSubmit()"
          mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
      </mat-card> -->
    </div>
  </div>
</ng-container>
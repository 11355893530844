import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';
import { FileDownloaderService } from './file-downloader.service';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';

import { ReactiveComponent } from '../shared';

@Directive({
  selector: '[tamoFileDownloader]'
})
export class FileDownloaderDirective extends ReactiveComponent {

  @Input('tamoFileDownloader')
  link: string;

  constructor(private el: ElementRef,
              private dlService: FileDownloaderService,
              private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    if (this.el.nativeElement.tagName === 'A') {
      this.renderer.setAttribute(this.el.nativeElement, 'href', '#');
    }

    fromEvent(this.el.nativeElement, 'click').pipe(
      takeUntil(this.ngUnsubscribe$),
      tap((evt: MouseEvent) => evt.preventDefault()),
      switchMap(() => this.dlService.downloadFile(this.link))
    ).subscribe(data => {
      const filename = decodeURI(this.link.split('/').pop());
      saveAs(data.body, filename);
    });
  }
}

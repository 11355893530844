import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, filter, shareReplay, map, debounceTime, startWith, skip, takeUntil,
  tap, switchMap, finalize, catchError } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { Duty, DutyService } from '../shared';

@Component({
  selector: 'app-duties-search-modal',
  templateUrl: './duties-search-modal.component.html',
  styleUrls: ['./duties-search-modal.component.less']
})
export class DutiesSearchModalComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  public formControl = new FormControl();

  public variants$: Observable<Duty[]>;
  
  public loading: boolean = false;

  constructor(private dialogRef: MatDialogRef<DutiesSearchModalComponent>,
              private dutyService: DutyService) { }

  ngOnInit() {
    this.variants$ = this.formControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        startWith<string | Duty>(''),
        map((data) => data instanceof Duty ? data.name : data),
        filter(value => value.length > 3),
        debounceTime(500),
        tap(() => setTimeout(() => this.loading = true, 0)),
        switchMap(value => this.dutyService.search(value)
          .pipe(
            finalize(() => setTimeout(() => this.loading = false, 0)),
            catchError(err => of([]))
          )
        )
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  selectVariant(variant: Duty) {
    this.dialogRef.close(variant);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
 
import { Hint } from '../shared/models/hint';
import { HintsService } from '../shared/services/hints.service';

declare var jsPanel: any;

@Injectable() 
export class RemoteHintService {
  private panels: any = {};

  constructor(private dataService: HintsService,
              private router: Router) {
    router.events.pipe(
      filter(e => e instanceof RouterEvent)
    ).subscribe(e => {
      for (let key in this.panels) {
        let panel = this.panels[key];
        // panel.close();
        panel.minimize();
      }
    });
  }

  public open(slug: string) {
    if(this.panels[slug]) {
      this.panels[slug].normalize();
      this.panels[slug].front();
      return;
    }

    const modalHeight = Math.min(window.innerHeight, 400);
    const modalWidth = Math.min(window.innerWidth, 800);
    this.dataService.get(slug).subscribe((hint: Hint) => {
      panel.content.innerHTML = '<div class="hint-content">' + hint.text + '</div>';
      panel.headertitle.innerHTML = hint.title;
      panel.resize(`${modalWidth} ${modalHeight}`);
      panel.reposition();

      if(hint.file) {
        let tbar = `<a href="${hint.file}" style="color: black; font-size: 14px; text-decoration: none;" target="_blank" class="jsPanel-ftr-btn">скачать <i class="fa fa-cloud-download"></i></a>`;
        panel.addToolbar('footer', tbar);
      }
    });

    var panel = jsPanel.create({
      position: 'center',
      contentSize: "600 100",
      maximizedMargin: 10,
      headerTitle: "Загрузка...",
      content: `<div class="hint-content"><mat-progress-bar aria-valuemax="100" aria-valuemin="0" class="mat-progress-bar mat-primary" mode="indeterminate" role="progressbar" aria-valuenow="0"><svg class="mat-progress-bar-background mat-progress-bar-element" focusable="false" height="5" width="100%"><defs><pattern height="5" patternUnits="userSpaceOnUse" width="10" x="5" y="0" id="mat-progress-bar-1"><circle cx="2.5" cy="2.5" r="2.5"></circle></pattern></defs><rect height="100%" width="100%" fill="url('/components/progress-bar/overview#mat-progress-bar-1')"></rect></svg><div class="mat-progress-bar-buffer mat-progress-bar-element"></div><div class="mat-progress-bar-primary mat-progress-bar-fill mat-progress-bar-element" style="transform: scaleX(0);"></div><div class="mat-progress-bar-secondary mat-progress-bar-fill mat-progress-bar-element"></div></mat-progress-bar></div>`,
      theme: "black",
      animateIn:  'animated bounceInLeft faster',
      animateOut: 'animated bounceOutRight faster',
      onclosed: () => {
        delete this.panels[slug];
      }
    });

    this.panels[slug] = panel;
  }
}
import { Exclude, Transform, Type, Discriminator, Expose } from 'class-transformer';
import * as moment from "moment";
import { Moment } from "moment";

import { ShippingPoint } from './shipping-point';


export class ShippingTariff {
  @Transform(value => parseFloat(value) || null)
  cost: number;

  cost_currency: string;
  
  duration: number;

  @Transform(val => val ? moment(val, 'YYYY-MM-DD') : null, { toClassOnly: true })
  date_start: Moment;

  @Transform(val => val ? moment(val, 'YYYY-MM-DD') : null, { toClassOnly: true })
  date_end: Moment;
}

export class RailwayTariff extends ShippingTariff {
  container_type: number;
 
  @Type(() => ShippingPoint)
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  arrival_place: ShippingPoint;
}

export class SeaTariff extends ShippingTariff {
  container_type: number;

  @Type(() => ShippingPoint)
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  arrival_place: ShippingPoint;
}

export class AviaTariff extends ShippingTariff {
  @Type(() => ShippingPoint)
  departure_place: ShippingPoint;

  @Type(() => ShippingPoint)
  arrival_place: ShippingPoint;

  @Transform(value => parseFloat(value) || null)
  weight_from: number;

  @Transform(value => parseFloat(value) || null)
  weight_to: number;

  @Transform(value => parseFloat(value) || null)
  size_from: number;
  
  @Transform(value => parseFloat(value) || null)
  size_to: number;
}

export class AviaTariffSettings {
  @Transform(value => parseFloat(value) || null)
  bulk_weight: number;
  
  @Transform(value => parseFloat(value) || null)
  cost_per_kg: number;

  @Transform(value => parseFloat(value) || null)
  add_cost: number;
}


export class AutoTariff extends ShippingTariff {
  @Transform(value => parseFloat(value) || null)
  weight_from: number;

  @Transform(value => parseFloat(value) || null)
  weight_to: number;
}

export class AutoDeliverySettings {
  @Expose({ name: 'local_duration' })
  localDuration: number;

  @Expose({ name: 'local_cost' })
  @Transform(value => parseFloat(value) || null)
  localCost: number;

  @Expose({ name: 'delivery_cost_factor' })
  deliveryCostFactor: number;

  @Expose({ name: 'delivery_extra_cost' })
  @Transform(value => parseFloat(value) || null)
  deliveryExtraCost: number;

  @Expose({ name: 'delivery_cost_per_ton' })
  @Transform(value => parseFloat(value) || null)
  deliveryCostPerTon: number;
}

export class AutoSettings {
  @Expose({ name: 'full_cost_per_km' })
  @Transform(value => parseFloat(value) || null)
  fullCostPerKm: number;

  @Expose({ name: 'full_add_cost' })
  @Transform(value => parseFloat(value) || null)
  fullAddCost: number;

  @Expose({ name: 'complex_diff_cost' })
  @Transform(value => parseFloat(value) || null)
  complexDiffCost: number;

  @Expose({ name: 'complex_add_cost' })
  @Transform(value => parseFloat(value) || null)
  complexAddCost: number;
}





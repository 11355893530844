import { Type } from 'class-transformer';

import { Service } from './service'
import { servicesDiscriminator } from './order';

export class BookingData {
  @Type(() => Service, {
    discriminator: servicesDiscriminator,
    keepDiscriminatorProperty: true
  })
  shipping: Service;

  name: string;
  email: string;
  phone: string;
}
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'file-manager-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarButtonComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  size: number = 20;

  @Input()
  color: string = 'black';

  @Input()
  @HostBinding()
  disabled: boolean = false;

  @Output()
  action: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(evt: MouseEvent) {
    evt.preventDefault();
    if (this.disabled) {
      return;
    }

    this.action.emit(evt);
  }
}

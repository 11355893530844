<div class="main-container statuses">
  <ng-container *ngIf="!(loading$ | async) else loadingTpl">
    <section *ngIf="(statusesCount$ | async) > 0; else emptyTpl"
      id="timeline" class="timeline-outer">
      <div class="container" id="content">
        <div class="row">
          <div class="col s12 m12 l12">
            <ul class="timeline">
              <li class="event" *ngFor="let status of statuses$ | async"
                  [attr.data-date]="status.date | amLocale: 'ru' | amDateFormat: 'MMM D, HH:mm'">
                <div class="timeline__statuses" fxLayout="row" fxLayoutAlign="flex-start center">
                  <tamo-order-status [status]="status.status_from"></tamo-order-status>
                  <mat-icon>arrow_right_alt</mat-icon>
                  <tamo-order-status [status]="status.status_to"></tamo-order-status>
                </div>
                <p>{{ status.message }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

<!--     <div class="statuses__empty">
      <span *ngIf="!loading">Статус заказа не менялся</span>
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div> -->

    <ng-template #emptyTpl>
      <div class="statuses__empty"><span>Статус заказа не менялся</span></div>
    </ng-template>
  </ng-container>

  <ng-template #loadingTpl>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>
<ng-container *ngIf="user$ | async as user">
  <div class="service" *ngIf="service">
    <form [formGroup]="serviceForm" class="service-form" ngxsForm="order.serviceForm">   
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="30px">
          <mat-card fxFlex="50">
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">

                <div class="service-form__group" fxFlex>
                  <mat-form-field  appearance="outline" fxFlex="grow">
                    <mat-label>Описание товара</mat-label>
                    <textarea rows="2" matInput placeholder="Описание товара"
                      formControlName="product_description" name="product_description" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>

                <tamo-documents-uploader [disabled]="user.isStaff" [prefix]="service.getUploadPrefix()" [counts]="service.getCounts('ANCO')"
                  type="ANCO" title="Документы"></tamo-documents-uploader>

                <div class="service-form__group" fxFlex>
                  <mat-form-field  appearance="outline" fxFlex="grow">
                    <mat-label>Комментарий</mat-label>
                    <textarea rows="2" matInput placeholder="Комментарий"
                      formControlName="comment" name="comment" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>

<!--         <mat-card fxFlex>
          <button type="submit" [disabled]="serviceForm.disabled"
            mat-raised-button color="primary" style="margin-right: 20px">Сохранить</button>
          <button type="button" [disabled]="serviceForm.disabled" *ngIf="service.service_status.id == 'DR'" (click)="process()"
            mat-raised-button color="accent">Отправить в работу</button>
        </mat-card> -->
      </div>
    </form>
  </div>
</ng-container>